import * as React from "react";
import type { SVGProps } from "react";
const SvgContracts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#4B4B4B"
      d="M10 16.667H4.165a.833.833 0 0 1-.833-.834V4.167a.833.833 0 0 1 .833-.834h4.167v2.5a2.5 2.5 0 0 0 2.5 2.5h2.5v.834a.833.833 0 1 0 1.666 0V7.45a1 1 0 0 0-.05-.225V7.15a.9.9 0 0 0-.158-.233l-5-5a.9.9 0 0 0-.233-.159.3.3 0 0 0-.075 0 .7.7 0 0 0-.275-.091H4.166a2.5 2.5 0 0 0-2.5 2.5v11.666a2.5 2.5 0 0 0 2.5 2.5h5.833a.833.833 0 1 0 0-1.666m0-12.159 2.158 2.159h-1.325a.833.833 0 0 1-.834-.834zM5.832 6.667a.833.833 0 1 0 0 1.666h.833a.833.833 0 1 0 0-1.666zM18.09 16.908l-.975-.966a2.866 2.866 0 0 0-.45-3.442 2.908 2.908 0 0 0-5 2.075 2.883 2.883 0 0 0 4.275 2.542l.967.975a.833.833 0 0 0 1.183 0 .83.83 0 0 0 0-1.184m-2.642-1.458a1.284 1.284 0 0 1-1.758 0 1.25 1.25 0 0 1 .008-1.758c.223-.221.52-.35.834-.359a1.223 1.223 0 0 1 1.3 1.242 1.25 1.25 0 0 1-.384.875M10.833 10h-5a.834.834 0 0 0 0 1.667h5a.833.833 0 1 0 0-1.667m-1.667 5a.833.833 0 1 0 0-1.667H5.833a.833.833 0 1 0 0 1.667z"
    />
  </svg>
);
export default SvgContracts;
