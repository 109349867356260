import numeral from 'numeral';
export const formatNumber00a =(num:number)=>{
   const data={
    origin:num,
    omit:numeral(num).format('0.0a')
   } 
   return data
}

export const formatNumber0a =(num:number)=>{
   const data={
    origin:num,
    omit:numeral(num).format('0 a')
   } 
   return data
}

export const formatNumberWithThousandCode =(num:number)=>{
   const data={
    origin:num,
    omit:numeral(num).format('0,000')
   } 
   return data
}