import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { buildAuthInfoCacheData } from '../service/user';
import { AuthInfoData } from '../redux/presist/auth-local-data';
import { persistActions } from '../redux';
import { useDispatch } from 'react-redux';
import { to } from 'mathjs';
// import { useParams } from 'react-router'



const GoogleLoginCallback: React.FC = () => {
  // const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch();
 const [token, setToken] = useState('')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const t = searchParams.get('token');
   t &&  setToken(t)
    return () => {
      
    }
  }, [location])
  

  const updateAuthInfoCacheData = (data: AuthInfoData) =>
  dispatch(persistActions.local.authLocalData.update(data));


  useEffect(() => {
    console.log('token**',token)
    if(!!token) updateToken(token)
    return () => {
      
    }
  }, [token])

 const updateToken = (token:string)=>{
  const loginData = buildAuthInfoCacheData({
    accessToken: token,
    sender: 'fairip-web',
  });
  // const jsonStr = JSON.stringify(loginData);
  // const encodeStr = encode(jsonStr);
  // if (authLocalData != encodeStr) { updateAuthInfoCacheData(loginData); setAuthLocalData(encodeStr); }
  updateAuthInfoCacheData(loginData);
 }
  
  return <section className='flex flex-row'>
  </section>
}

export default GoogleLoginCallback