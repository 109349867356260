import * as React from "react";
import type { SVGProps } from "react";
const SvgSecurity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#4B4B4B"
      d="M16.359 3.042a.83.83 0 0 0-.7-.167 6.67 6.67 0 0 1-5.184-1.058.83.83 0 0 0-.95 0 6.67 6.67 0 0 1-5.183 1.058.833.833 0 0 0-1.008.817V9.9a7.5 7.5 0 0 0 3.141 6.108l3.042 2.167a.83.83 0 0 0 .966 0l3.042-2.167A7.5 7.5 0 0 0 16.667 9.9V3.692a.83.83 0 0 0-.309-.65M15 9.9a5.83 5.83 0 0 1-2.441 4.75L10 16.475 7.442 14.65A5.83 5.83 0 0 1 5 9.9V4.65a8.33 8.33 0 0 0 5-1.158 8.33 8.33 0 0 0 5 1.158zm-3.716-1.908-2.242 2.25-.742-.75a.837.837 0 1 0-1.183 1.183l1.333 1.333a.833.833 0 0 0 1.184 0L12.5 9.167a.837.837 0 0 0-1.183-1.184z"
    />
  </svg>
);
export default SvgSecurity;
