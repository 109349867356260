import { createContext, useContext } from 'react';
import RandomNumberPicker from '../utils/RandomNumberPicker';
import RandomNumberPicker2 from '../utils/RandomNumberPicker2';

interface RandomPicker{
  search: RandomNumberPicker;
  analysis: RandomNumberPicker;
  chick: RandomNumberPicker2;
}

const RandomQuestionContext = createContext<RandomPicker | undefined>(undefined);
type Props = {
    children?: React.ReactNode
  }

export const RandomQuestionProvider = ({ children }:Props) => {
  const questionMin = 2;
  const searchQuestionMax = 6;
  const analysisQuestionMax = 3;
  
  const searchPicker = new RandomNumberPicker(questionMin, searchQuestionMax);
  const analysisPicker = new RandomNumberPicker(questionMin, analysisQuestionMax);
  const chickPicker = new RandomNumberPicker2(0, 2);
  const pickers={
    search:searchPicker,
    analysis:analysisPicker,
    chick:chickPicker
  }

  return (
    <RandomQuestionContext.Provider value={pickers}>
      {children}
    </RandomQuestionContext.Provider>
  );
};

export const useRandomQuestion = () => {
  return useContext(RandomQuestionContext);
};