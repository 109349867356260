import * as React from "react";
import type { SVGProps } from "react";
const SvgContract = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 60 60"
    {...props}
  >
    <path
      fill="#F2F2F2"
      d="M22.9 50.137h.035l.916.105c.04.006.076.012.117.012h.017c1.515 2.325 4.25-4.062 6.153-2.22h-.035q-.3-.002-.599.007c-3.78.017-7.567.04-11.348.047h-.188c-.91.011-1.702.135-1.626 1.414.158 2.701-1.256 4.427-3.499 5.636-4.779.23-6.693-1.573-6.693-6.328-.005-8.724 0-10.779-.005-19.503.011-1.186.029-2.366.046-3.551.006-.347.006-.693.012-1.034v-.064c2.542 4.996 4.585 10.268 8.09 14.735-.012.012-.018.03-.03.041-.029.047-.064.094-.093.141-.012 0-.018-.006-.03-.006l-.234-.035a3 3 0 0 0-.5-.041h-.082q-.105.008-.211.006c-.135 0-.27-.006-.41-.006h-.118c-.617 0-1.186.106-1.204.986-.017.928.599 1.04 1.245 1.04h.123c.106 0 .211-.006.311-.006h.523c.704-.006 1.409-.07 2.107-.106.036.04.065.088.094.135q.028.043.047.094c.03.035.053.07.082.1.006.011.018.017.024.029.47.57.94 1.133 1.409 1.703a.2.2 0 0 1-.03.04l-.105.16h-.035q-.063.002-.124-.007h-.006c-1.409-.03-2.817-.07-4.22-.082h-.065c-.675 0-1.38.094-1.41.98-.023.91.694 1.034 1.38 1.034.505 0 1.01-.006 1.515-.006 1.515-.012 3.035-.035 4.55-.047h.035c.188.053.382.1.57.153h.005l1.52 2.4h-.028c-.177 0-.353.006-.529.006"
    />
    <path
      fill="#E5EBFE"
      d="m14.293 39.593-.152-.018c.04-.059.082-.123.117-.182zM17.19 43.655c.04-.064.087-.129.129-.194l.035.2c-.053-.006-.112-.006-.165-.006M19.389 45.669s0 .006.005.006c0 .005-.005.005-.005-.006q0 .009 0 0M38.593 25.82a7 7 0 0 1-.365.482c.047-.13.089-.253.13-.382.006-.006.011-.006.011-.012q.116-.045.224-.088M38.352 20.419c-.041-.123-.076-.24-.123-.364.135.164.258.323.37.487-.053-.017-.106-.04-.165-.059zM41.55 20.478c.065-.135.13-.265.206-.4v.194a5 5 0 0 0-.14.182q-.029.008-.065.024M41.756 26.108v.176c-.076-.135-.14-.27-.205-.405q.007.008.023.006.087.115.182.223"
    />
    <path
      fill="#F2F2F2"
      d="M44.844 48.728c-4.608.017-9.217-.617-13.82-.6q-.457-.042-.915-.093c-1.902-1.844-4.638 4.544-6.153 2.219h.012c.065-.012.123-.018.188-.03l.094-.017h.012l.088-.018c.017-.005.035-.005.046-.011.036-.006.071-.018.112-.024a1 1 0 0 0 .094-.03.05.05 0 0 0 .03-.011q.085-.028.164-.065.05-.018.094-.047a.5.5 0 0 0 .094-.058c.017-.012.029-.024.047-.035.011-.012.029-.024.04-.036.012-.011.03-.023.042-.04.012-.013.023-.024.035-.042.018-.023.041-.047.059-.076q.01-.007.011-.024a1 1 0 0 0 .047-.094.1.1 0 0 0 .012-.04c.006-.007.006-.018.012-.024.006-.018.012-.03.012-.047a.2.2 0 0 0 .011-.065c.006-.017.006-.035.012-.058v-.036c0-.011 0-.029.006-.04v-.13c-.035-.863-.757-.986-1.456-.986-.828.006-1.661.04-2.49.064-.081-.029-.158-.052-.24-.082a1 1 0 0 1-.117-.04c-.036-.019-.076-.024-.118-.042-.505-.622-1.015-1.779-1.52-2.401h.035c3.358-.03 6.716-.047 10.074-.094.81-.012 1.938.164 1.908-1.04-.03-1.185-1.156-.974-1.96-.985-4.034-.047-8.061-.07-12.094-.106h-.035q-.705-.847-1.41-1.703c.006-.011.006-.017.012-.029.012-.035.024-.065.035-.1q10.454-.051 20.906-.111c.675-.006 1.356-.012 2.02-.124.422-.07.733-.405.727-.898-.005-.487-.317-.822-.74-.892-.663-.106-1.344-.112-2.019-.118-7.514-.035-15.035-.064-22.549-.093h-.035c-3.487-4.45-5.53-9.693-8.055-14.671l-.035-.065c-.035-.47-.065-.945-.1-1.415.018-4.708-.03-9.41.076-14.113.042-1.855-.551-3.252-2.072-4.274q18.142-.01 36.275-.012c3.51 0 4.468.987 4.48 4.533.011 2.876.011 5.753.017 8.624-1.339.229-3.282-.44-3.088 2.072-1.174-.006-2.354-.018-3.528-.023-1.527-6.458-7.626-10.773-14.225-10.045-6.64.734-11.776 6.487-11.776 13.191 0 6.699 5.125 12.446 11.77 13.192 6.587.74 12.687-3.575 14.219-10.063 1.174-.005 2.354-.017 3.528-.023-.194 2.513 1.744 1.85 3.082 2.072.012 8.795.012 11.577.012 20.372"
    />
    <path fill="#B6C5E1" d="M19.394 45.68h-.005v-.005q.006-.001.005.005" />
    <path
      fill="#D2D4D7"
      d="M54.127 28.268c-4.145.047-5.132.059-9.283.088-1.338-.229-3.281.435-3.082-2.072v-6.206c-.193-2.512 1.75-1.849 3.088-2.072 4.145.03 5.132.047 9.282.088 3.54.035 5.789 1.955 5.865 4.96.077 3.107-2.236 5.179-5.87 5.214"
    />
    <path
      fill="#BDBDBD"
      d="M41.756 20.079v.193a5 5 0 0 0-.14.182.3.3 0 0 1-.065.024c.064-.135.135-.265.205-.4M41.568 25.885q.091.115.188.223v.176c-.076-.135-.14-.27-.205-.405.006 0 .011.006.017.006"
    />
    <path
      fill="#D2D4D7"
      d="M6.175 24.658a707.16 707.16 0 0 1-.059 4.65C.005 29.242.005 29.242.005 23.16c0-4.809-.006-9.617.006-14.425 0-1.08-.188-2.242 1.021-2.912.81-.962 1.902-1.033 3.041-.968 1.52 1.021 2.114 2.413 2.072 4.274-.105 4.702-.058 9.41-.076 14.113z"
    />
    <path
      fill="#BDBDBD"
      d="M22.465 15.587c-2.977 1.28-4.556 3.628-5.125 6.728-.135.746-.047 1.72-1.098 1.65-1.016-.065-.916-1.027-.945-1.603.17-3.54 3.282-7.75 6.375-8.677.858-.258 2.131-.663 2.513.306.505 1.268-.94 1.262-1.72 1.596M38.434 20.484q.078.033.164.058a6 6 0 0 0-.37-.487c.042.123.083.24.124.364zm-.076 5.442c-.041.129-.082.252-.13.381a9 9 0 0 0 .364-.481l-.223.088c-.005 0-.005.006-.011.012"
    />
    <path
      fill="#D2D4D7"
      d="M38.351 20.419c-.041-.123-.076-.24-.123-.364-1.52-6.481-7.62-10.796-14.219-10.068-6.64.733-11.777 6.487-11.777 13.191 0 6.699 5.126 12.446 11.771 13.192 6.587.74 12.687-3.576 14.219-10.063a9 9 0 0 0 .13-5.888m-15.886-4.832c-2.976 1.28-4.556 3.628-5.125 6.728-.135.746-.047 1.72-1.098 1.65-1.016-.065-.916-1.028-.945-1.603.17-3.54 3.282-7.75 6.375-8.677.858-.258 2.131-.663 2.513.306.505 1.268-.94 1.262-1.72 1.596"
    />
    <path
      fill="#fff"
      d="M26.184 30.27c-3.605 0-6.3-2.544-6.3-6.064s2.695-6.063 6.316-6.063c1.56 0 2.94.455 3.99 1.311.41.335.373.945-.013 1.307-.4.377-1.024.341-1.476.028-.702-.486-1.506-.726-2.4-.726-2.442 0-4.21 1.718-4.21 4.143s1.768 4.143 4.21 4.143c.89 0 1.692-.238 2.392-.727.456-.318 1.09-.355 1.492.029.382.363.418.97.01 1.304-1.052.858-2.437 1.314-4.011 1.314"
    />
    <path
      fill="#E4E4E4"
      d="M39.604 40.49c.005.494-.306.829-.728.9-.658.11-1.345.117-2.02.122q-10.453.06-20.906.112h-.152l-.041-.235q-.602-.741-1.204-1.473c-.088-.112-.176-.218-.264-.33l-.035-.199c7.526.03 15.058.059 22.584.094.675.006 1.356.012 2.02.118.434.07.74.405.745.892M31.406 44.548c.03 1.203-1.098 1.027-1.908 1.039-3.37.04-6.74.064-10.103.094l-.006-.006v.006c-.188-.053-.382-.1-.57-.153-.117-.153-.235-.3-.352-.452q-.431-.547-.863-1.104l-.246-.317-.036-.2c4.045.036 8.084.06 12.13.106.797.012 1.93-.2 1.954.987"
    />
    <path fill="#BDBDBD" d="M19.394 45.68h-.005v-.005q.006-.001.005.005" />
    <path
      fill="#E4E4E4"
      d="M41.616 20.448q.07-.095.14-.182v5.836a3 3 0 0 1-.187-.223c-.006 0-.012-.006-.024-.006q.1.203.206.405c-1.174.006-2.354.018-3.528.023.129-.158.252-.322.364-.481l-.223.088c-.006.006-.012.006-.012.012a8.96 8.96 0 0 0-.006-5.507l.082.065q.078.033.165.058a6 6 0 0 0-.37-.487c1.174.006 2.354.018 3.528.024-.07.135-.141.264-.206.399a.2.2 0 0 0 .07-.024"
    />
    <path
      fill="#D2D4D7"
      d="M38.37 25.914a2 2 0 0 1 .223-.088 7 7 0 0 1-.365.481c.047-.129.089-.252.13-.381.006-.006.006-.012.011-.012M38.229 20.055c.135.164.258.323.37.487-.053-.017-.106-.04-.165-.059-.03-.023-.059-.04-.082-.064q-.062-.187-.123-.364M41.756 20.079v.193a5 5 0 0 0-.14.182.3.3 0 0 1-.065.024c.064-.135.135-.265.205-.4M41.568 25.885q.091.115.188.223v.176c-.076-.135-.14-.27-.205-.405.006 0 .011.006.017.006M18.82 45.522c-2.02.024-4.045.053-6.065.059-.698 0-1.444-.106-1.414-1.033.023-.916.775-.987 1.467-.98 1.41.01 2.818.052 4.221.081h.006q.081-.002.153.006c.053 0 .111 0 .164.006l.247.317.863 1.104c.123.14.24.293.358.44M15.769 41.395c-.036 0-.07.006-.106.006-.804.04-1.609.117-2.407.105-.798-.011-1.92.218-1.896-1.027.023-1.239 1.15-.94 1.943-.98.194-.012.916-.106.969-.106.088.112.21.417.299.528q.59.732 1.198 1.474"
    />
    <path
      fill="#F9F9F9"
      d="M22.465 15.587c-2.977 1.28-4.556 3.628-5.125 6.728-.135.746-.047 1.72-1.098 1.65-1.016-.065-.916-1.027-.945-1.603.17-3.54 3.282-7.75 6.375-8.677.858-.258 2.131-.663 2.513.306.505 1.268-.94 1.262-1.72 1.596"
    />
    <path
      fill="#D2D4D7"
      d="M52.987 48.058c-2.706.13-5.424.024-8.142.018l-13.82.053q-.457-.043-.916-.094-.3-.001-.598.006c-3.781.017-7.568.04-11.348.047h-.188c-.91.011-1.703.135-1.626 1.415.158 2.7-1.257 4.426-3.5 5.636l38.001.035c2.454-.693 3.887-2.255 4.169-4.832.164-1.526-.218-2.366-2.032-2.284"
    />
  </svg>
);
export default SvgContract;
