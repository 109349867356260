import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import SimilarityScore from '../SimilarityScore'
import { ImgDocument, ImgStar } from '../../images'
import { ButtonText, ButtonRoundBorder } from '../Button'
import { InfringementReport } from '../Report'

import { TrademarksMonitoringDataItem } from '../../api/ai/response'

type Props = {
  yourTrademarkUrl: string,
  data: TrademarksMonitoringDataItem[]
  displayMore: boolean,
  onMoreClick?: () => void
}

const tableTitle = [
  'Platform',
  'Store name',
  'Store link',
  // 'Store level',
  'Product Title',
  'Product Brand',
  'Product Price',
  'Selling Unit',
  'Total amount',
  // 'Product Origin',
  // 'Product link',
  // 'Infringement Score',
  // 'Rights owner’s trademark',
  'Infringing trademark',
  // 'Probability of Infringement',
]

// const tableDataItem = {
//   platform: 'amazon',
//   storeName: 'uowik',
//   storeLink: 'amazon.com.au/Visual-Spatial-Thinking-Advanced-Learners-Grades/dp/1032199237/ref=sr_1_3?',
//   storeLevel: 5,
//   productTitle: 'baby doll',
//   productBrand: 'N/A',
//   productPrice: '$250.75',
//   sellingUnit: '10',
//   totalAmount: '$2,500.75',
//   productOrigin: 'Indonesian',
//   productlink: 'www.amazon.com.au/Visual-Spatial-Thinking-Advanced-Learners-Grades/dp/1032199237/ref=sr_1_3?',
//   infringementScore: 'Indonesian',
//   rightsOwnertrademark: '',
//   infringingTrademark: '',
//   similarityScore: 99,
// }

// const tableData = [
//   tableDataItem,
//   tableDataItem,
//   tableDataItem,
//   tableDataItem,
//   tableDataItem,
//   tableDataItem,
//   tableDataItem,
//   tableDataItem,
// ]

const TrademarkInfringeTable: React.FC<Props> = ({ yourTrademarkUrl, data, displayMore, onMoreClick }: Props) => {

  const reportRef = useRef<any>(null)
  const [trademarksReport, setTrademarksReport] = useState<TrademarksMonitoringDataItem>()

  const onReportClick = async (item: TrademarksMonitoringDataItem) => {
    console.log('********** onReportClick -----item:-----', item)
    setTrademarksReport(item)
    reportRef.current?.show(yourTrademarkUrl, item)
  }

  return <section className='flex flex-col max-w-[920px]'>
    <div className='flex flex-row'>
      <div className='flex flex-row overflow-auto'>
        <div className='flex flex-col'>
          <ul className='flex flex-row'>
            {
              tableTitle.map((item, index) => (
                <li key={index} className='inline-flex items-center px-4 w-[140px] h-16 bg-[#F2F2F2] border-t border-[#F2F2F2] first:rounded-tl-2xl'>
                  <span className='text-sm text-[#111111]'>{item}</span>
                </li>
              ))
            }
          </ul>
          {
            data.map((item, index) => <ul key={uuidv4()} className='flex flex-row'>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='text-sm text-[#111111]'>{item.platform}</span>
              </li>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='text-sm text-[#111111] line-clamp-2'>{item.storeName}</span>
              </li>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <ButtonText className='max-w-full' onClick={()=>{window.open(item.storeUrl)}}>
                  <span className='line-clamp-1'>{item.storeUrl}</span>
                </ButtonText>
              </li>
              {/* <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='flex flex-row space-x-1'>
                  {
                    Array.from(Array(item.storeLevel).keys())
                      .map((index) => (<img key={index} className='size-4' src={ImgStar} />))
                  }
                </span>
              </li> */}
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='text-sm text-[#111111] line-clamp-2'>{item.productTitle}</span>
              </li>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='text-sm text-[#111111] line-clamp-2'>{item.trademark}</span>
              </li>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='text-sm text-[#111111]'>{item.productPrice}</span>
              </li>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='text-sm text-[#111111]'>{item.sellingUnit}</span>
              </li>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='text-sm text-[#111111]'>{item.totalAmount}</span>
              </li>
              {/* <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='text-sm text-[#111111]'>{item.productOrigin}</span>
              </li>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <ButtonText className='max-w-full'>
                  <span className='line-clamp-1'>{item.productlink}</span>
                </ButtonText>
              </li>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <span className='text-sm text-[#111111]'>{item.infringementScore}</span>
              </li>
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <img className='h-6 object-contain' src={item.imageUrl} />
              </li> */}
              <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <img className='h-6 object-contain' src={item.imageUrl} />
              </li>
              {/* <li className='inline-flex items-center px-4 w-[140px] h-16 bg-white border-t border-[#F9F9F9]'>
                <SimilarityScore score={item.similarityScore} />
              </li> */}
            </ul>)
          }
        </div>
      </div>
      <div className='flex flex-col'>
        <li className='inline-flex items-center px-4 w-[100px] h-16 bg-[#F2F2F2] border-t border-[#F2F2F2] rounded-tr-2xl shadow-3xl z-10'>
          <span className='text-sm text-[#111111]'>Report</span>
        </li>
        {
          data.map((item, index) => (
            <li key={uuidv4()} className='inline-flex items-center px-4 w-[100px] h-16 bg-white border-t border-[#F9F9F9] shadow-3xl z-10'>
              <button className='inline-flex' onClick={() => { onReportClick(data[index]) }}>
                <img className='size-6' src={ImgDocument} />
              </button>
            </li>
          ))
        }
      </div>
    </div>
    {
      displayMore && <>
        <div className='flex flex-row justify-center mt-2'>
          <ButtonRoundBorder onClick={onMoreClick}>More</ButtonRoundBorder>
        </div>
      </>
    }
    <InfringementReport ref={reportRef} />
  </section>
}

export default TrademarkInfringeTable