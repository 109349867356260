import React from 'react'

type Props = {
  color?: 'blue' | 'green',
  children: React.ReactNode,
  disabled?: boolean,
  onClick?: () => void
}

const ButtonRoundBorder: React.FC<Props> = ({ color = 'green', children, disabled, onClick }: Props) => {

  if (color === 'blue') {
    return <button
      className='px-4 py-2 text-sm text-[#4B4B4B] border border-[#DDDDDD] rounded-full
                hover:text-blue hover:border-blue
                active:text-blue active:border-blue'
      disabled={disabled}
      onClick={onClick}>
      {children}
    </button>
  }

  return <button
    className='px-4 py-2 text-sm text-[#4B4B4B] border border-[#DDDDDD] rounded-full
                hover:text-green hover:border-green
                active:text-green active:border-green'
    disabled={disabled}
    onClick={onClick}>
    {children}
  </button>
}

export default ButtonRoundBorder