import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import { Button, ButtonRound } from '../Button'
import { CloseDialog } from '../../svgr/components'
import { ImgUpload } from '../../images'

type Props = {
  pickImage: (goodFile: any, goodUrl: string,file: any, url: string) => void,
  onSubmit?: () => void
}

const InfringementComparison = forwardRef(({ pickImage, onSubmit }: Props, ref) => {

  const dialogRef = useRef<HTMLDialogElement>(null)

  const [trademarkUrl, setTrademarkUrl] = useState<string>('');
  const [trademarkFile, setTrademarkFile] = useState<any>();
  const [infringingUrl, setInfringingUrl] = useState<string>('');
  const [infringingFile, setInfringingFile] = useState<any>();

  // useEffect(() => {
  //   return () => {
  //     //释放选中的图片
  //     if (trademarkUrl) {
  //       window.URL.revokeObjectURL(trademarkUrl);
  //     }
  //     if (infringingUrl) {
  //       window.URL.revokeObjectURL(infringingUrl);
  //     }
  //   };
  // }, []);

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }))

  const show = () => {
     //释放选中的图片
    //  if (trademarkUrl) {
    //   window.URL.revokeObjectURL(trademarkUrl);
    // }
    // if (infringingUrl) {
    //   window.URL.revokeObjectURL(infringingUrl);
    // }
    setTrademarkUrl('')
    setInfringingUrl('')
    setTrademarkFile(undefined)
    setInfringingFile(undefined)
    dialogRef.current?.showModal()

  }

  const hide = () => {
    dialogRef.current?.close()
  }

  const handleTrademarkPicker = (event: any) => {
    const files = event.target.files;
    console.log("handlePicker**files**", files)
    if (files && files.length > 0) {
      console.log("handlePicker**trademarkUrl**", trademarkUrl)
      if (trademarkUrl) {
        window.URL.revokeObjectURL(trademarkUrl);
      }
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      console.log("handlePicker**file**", file)
      console.log("handlePicker**imageUrl**", imageUrl)
      setTrademarkUrl(imageUrl);
      setTrademarkFile(file);
      // pickImage(file, imageUrl)
    }
  };

  const handleInfringingPicker = (event: any) => {
    const files = event.target.files;
    console.log("handlePicker**files**", files)
    if (files && files.length > 0) {
      console.log("handlePicker**trademarkUrl**", infringingUrl)
      if (infringingUrl) {
        // window.URL.revokeObjectURL(trademarkUrl);
        window.URL.revokeObjectURL(infringingUrl);
      }
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      console.log("handlePicker**file**", file)
      console.log("handlePicker**imageUrl**", imageUrl)
      setInfringingUrl(imageUrl);
      setInfringingFile(file);
      // pickImage(file, imageUrl)
    }
  };

  return <dialog className='bg-white rounded-4xl backdrop:bg-black/80' ref={dialogRef}>
    <div className='relative flex flex-col p-16'>
      <ButtonRound
        className='absolute top-4 right-[19px]'
        onClick={hide}
      >
        <CloseDialog color='#000'/>
      </ButtonRound>
      <div className='flex flex-col w-[420px]'>
        <h6 className='mb-[27px] text-2.5xl leading-[34px] font-semibold text-black text-center'>Infringement comparison</h6>
        <div className='flex flex-col space-y-4'>
          <div className='flex flex-col'>
            <span className='mb-1 text-sm text-[#4B4B4B]'>Trademark image</span>
            <div className='relative flex flex-col items-center py-6 border border-dashed border-[#1BA4A4] rounded-4xl'>
              <img className='size-[61px]' src={trademarkUrl ? trademarkUrl : ImgUpload} />
              <h6 className='mt-6 mb-2.5 text-base font-semibold text-black'>Add or drop a trademark image here.</h6>
              <span className='text-sm text-[#4B4B4B]'>JPG, PNG / Max. 60 MB / Min. 224px X 224px</span>
              <input
                className='absolute inset-0 z-10 opacity-0'
                type='file'
                // accept={'image/*'}
                accept=".png, .jpg, .jpeg"
                onChange={(event) => {
                  handleTrademarkPicker(event);
                  event.target.value = '';
                }}
              />
            </div>
          </div>
          <span className='text-sm text-[#4B4B4B] text-center'>And</span>
          <div className='flex flex-col'>
            <span className='mb-1 text-sm text-[#4B4B4B]'>Infringing images</span>
            <div className='relative flex flex-col items-center py-6 border border-dashed border-[#1BA4A4] rounded-4xl'>
              <img className='size-[61px]' src={infringingUrl ? infringingUrl : ImgUpload} />
              <h6 className='mt-6 mb-2.5 text-base font-semibold text-black'>Add or drop a trademark image here.</h6>
              <span className='text-sm text-[#4B4B4B]'>JPG, PNG / Max. 60 MB / Min. 224px X 224px</span>
              <input
                className='absolute inset-0 z-10 opacity-0'
                type='file'
                accept=".png, .jpg, .jpeg"
                // accept={'image/*'}
                onChange={(event) => {
                  handleInfringingPicker(event);
                  event.target.value = '';
                }}
              />
            </div>
          </div>
          <Button
            type='second'
            onClick={() => {
              if(!!trademarkUrl && !!infringingUrl){
                pickImage(trademarkFile,trademarkUrl,infringingFile,infringingUrl)
                hide()
              }
             }}
          >Analysis</Button>
        </div>
      </div>
    </div>
  </dialog>
})

export default InfringementComparison