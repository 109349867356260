import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import { Button, ButtonRound } from '../Button'
import { CloseDialog, Download as IconDownload } from '../../svgr/components'
import { usePopDownload } from '../../api/contract/hook'
import { onContractDetail } from '../../api/contract/service'
//@ts-ignore
import htmlToDocx from 'html-docx-js/dist/html-docx'
import { saveAs } from 'file-saver'
import { gaDownloadContractEvent } from '../../service/ga4Service'
import { useGA4React } from 'ga-4-react'

type Props = {

}

const AgreementPreview = forwardRef(({ }: Props, ref) => {

  const dialogRef = useRef<HTMLDialogElement>(null)

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }))

  const [contractId, setContractId] = useState<string | number>()

  const show = (id: string | number) => {
    setContractId(id)

    dialogRef.current?.show()
  }

  const hide = () => {
    setContent('')
    setContractId(undefined)
    dialogRef.current?.close()
  }

  useEffect(() => {
    if (!contractId) return;
    getDetail();

    return () => {

    }
  }, [contractId])


  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [docUrl, setDocUrl] = useState('')
  // useEffect(() => {
  //   content && dialogRef.current?.show()

  //   return () => {

  //   }
  // }, [content])


  const getDetail = async () => {
    if (!contractId) return;
    const result = await onContractDetail(contractId);
    console.log("onContractDetail**result**", result.data);

    if (result.success && result.data?.content) {
       setContent(result.data?.content)
       setTitle(result.data?.title)
       setDocUrl(result.data?.docUrl)
      // setHasSearchResult(true)
      return true;
    }
    return false;
  };

  const docRef = useRef<HTMLDivElement>(null)

  const [downloading, setDownloading] = useState(false)

  const onDownloadClick = () => {
    const cssHTML = `
    `;
    const outerHTML = docRef.current?.outerHTML
    let contentDocument = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <style>
          ${cssHTML}
        </style>
      </head>
      <body>
        ${outerHTML}
      </body>
    </html>
    `;
    // const contentDocument = '<!DOCTYPE html>' + outerHTML;
    const converted = htmlToDocx.asBlob(contentDocument, { orientation: 'portrait' }) as Blob;
    saveAs(converted, `${title}.docx`);
  }

  const onDownloadClick2 = async() => {
    if(downloading) return;
    submitGA({id:contractId})
    // docUrl && window.open(docUrl)
    setDownloading(true)
   await handleDownload(docUrl,title)
   setDownloading(false)
  }

  const handleDownload = async (fileUrl:string,fileName:string) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // 设置下载文件的名称
      document.body.appendChild(a);
      a.click(); // 触发点击事件以启动下载
      a.remove(); // 移除元素
      window.URL.revokeObjectURL(url); // 释放内存
    } catch (error) {
      console.error('下载文件时发生错误:', error);
    }
  };

  const ga4 =useGA4React()

  const submitGA = (data:any)=>{
    ga4&& gaDownloadContractEvent(ga4,data)
  }

  return <dialog className='fixed inset-0 w-screen h-screen bg-[#000000CC] z-[99]' ref={dialogRef}>
    <section
      className='flex flex-row w-full h-full'
      onClick={hide}
    >
      <div 
        className='relative flex flex-col self-center mx-auto h-[90%]'
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}>
        <ButtonRound
          className='absolute top-0 right-[-64px] !bg-[#F2F2F280]'
          onClick={hide}
        >
          <CloseDialog color='#fff' />
        </ButtonRound>
        <div className='relative flex flex-col items-center w-[1000px] h-full p-8 bg-white rounded-2xl overflow-y-auto overflow-x-hidden notranslate' ref={docRef} dangerouslySetInnerHTML={{ __html: content }}>
          {/* <p style={{ fontSize: '16px' }}>EX-10 5 ex10m.htm 10(M):2ND AMEND/CREDIT AGMT</p>
          <p style={{ fontSize: '14px', textAlign: 'right' }}>EXHIBIT 10(n)</p>
          <p style={{ fontSize: '14px', textAlign: 'center' }}>SECOND AMENDMENT TO SECOND AMENDED AND RESTATED CREDIT AGREEMENT</p>
          <p style={{ fontSize: '14px', textIndent: '2em' }}>
            THIS SECOND AMENDMENT TO SECOND AMENDED AND RESTATED CREDIT AGREEMENT (the "Amendment"), dated as of December 5, 2003 is among ACCOION CORPORATION, a Delaware Corporation (the "Borrower"), the lenders party hereto, and JPMORGAN CHASE BANK, as the agent (the "Agent").
          </p>
          <p style={{ fontSize: '14px', textAlign: 'center' }}>RECITALS:</p>
          <p style={{ fontSize: '14px', textIndent: '2em' }}>
            A. The Borrower, the Agent, and the lenders party thereto have entered into that certain Second Amended and Restated Credit Agreement dated as of February 5, 2003 (as amended by that certain First Amendment to Second Amended and Restated Credit Agreement dated as of August 10, 2003 and as the same may be further amended from time to time, the "Agreement").
          </p>
          <p style={{ fontSize: '14px', textIndent: '2em' }}>
            B. The Borrower has informed the Agent and the Lenders that it desires to form a new wholly-owned subsidiary under the laws of the United Kingdom that shall be named "Acxiom European Holdings Limited" to acquire from WWU Dutch company, and certain of its affiliates the stock of certain companies described below in this Amendment. Additionally, the Borrower has informed the Agent and the Lenders that it is currently negotiating the purchase of additional Persons located in the United States of America.
          </p>
          <p style={{ fontSize: '14px', textIndent: '2em' }}>
            C. The Borrower has requested that the Agent and the Lenders amend certain provisions of the Agreement to allow the Borrower to conclude such acquisitions. Subject to satisfaction of the conditions set forth herein, the Agent and the lenders party hereto are willing to amend the Agreement as set forth herein.
          </p>
          <p style={{ fontSize: '14px', textIndent: '2em' }}>
            NOW, THEREFORE, in consideration of the premises herein contained and other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the parties hereto agree as follows effective as of the date hereof unless otherwise indicated:
          </p>
          <p style={{ fontSize: '14px' }}>ARTICLE I</p>
          <p style={{ fontSize: '14px', textAlign: 'center' }}>Definitions</p>
          <p style={{ fontSize: '14px', textIndent: '2em' }}>
            Section 1.1. Definitions. Capitalized terms used in this Amendment, to the extent not otherwise defined herein, shall have the same meanings as in the Agreement, as amended hereby.
          </p>
          <p style={{ fontSize: '14px' }}>ARTICLE II</p>
          <p style={{ fontSize: '14px', textAlign: 'center' }}>Amendments</p>
          <p style={{ fontSize: '14px', textIndent: '2em' }}>
            Section 2.1. Amendment to Section 1.01-Defined Terms. Section 1.01 of the Agreement is amended to add the following definitions thereto in proper alphabetical order.
          </p>
          <p style={{ fontSize: '14px', textIndent: '2em' }}>
            "Acquired Companies" means Claritas SA (France), Claritas Europe BV (Netherlands), Claritas Polska Sp. Z o.o. (Poland), RTA Claritas Espana S.A. (Spain), Claritas UK Limited (United Kingdom), Claritas Deutschland Data (Germany), Claritas Nederland BV (Netherlands), Claritas Portugal Lda (Portugal), Atwood Systems Limited (United Kingdom), BKR Groep BV (Netherlands), BRK Data Creative Teams BV (Netherlands), and BPK Projects BV (Netherlands).
          </p> */}
        </div>
        <div className='absolute bottom-0 left-0 right-0 py-5 text-center'>
          <button className={downloading ?'inline-flex items-center justify-center w-[200px] py-3 mx-auto bg-blue rounded-4xl text-white cursor-not-allowed'
          :'inline-flex items-center justify-center w-[200px] py-3 mx-auto bg-blue rounded-4xl text-white'} onClick={() => {
             content &&title && onDownloadClick2()
          }}>
            <span className='text-2xl'>
              <IconDownload />
            </span>
            <span className='text-base font-semibold'>Download</span>
          </button>
        </div>
      </div>
    </section>
  </dialog>
})

export default AgreementPreview