import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { decode, encode } from "../utils/saltBase64";
import { MD5 } from "../utils/saltMD5";
import { DASHBOARD} from "../router/constants";

import * as stringUtils from "../utils/string";
import {
  checkContainSpeicalChars,
  checkIsEmail,
  checkIsPassword,
  checkIsVerifyCode,
} from "../utils/regularUtils";


import { useSelector } from "react-redux";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import { persistSelectors, sharedSelectors } from "../redux";
import { authLocalData } from "../redux/presist";
import { FormUnitType2 } from "../service/formdata";
import useLogin from "./login-hook";
import { PWD_MAX_LEN, PWD_MIN_LEN } from "../service/constant";
import { checkCode, resetPwd, sendCode } from "../api/user/app";
import { ResponseData } from "../api/response";

export type SigninFormDataType2 = {
  email: FormUnitType2;
  password: FormUnitType2;
  confirmpassword: FormUnitType2;
  code: FormUnitType2;
  step:number;
};

export const initSignupFormData2: SigninFormDataType2 = {
  email: {
    value: "",
    type: "empty",
  },
  code:{
    value: "",
    type: "empty",
  },
  password: {
    value: "",
    type: "empty",
  },
  confirmpassword: {
    value: "",
    type: "empty",
  },
  step:0
};

const useResetPwd = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("usesignup1**", domain, projectName);
  const [formData, setFormData] =
    useState<SigninFormDataType2>(initSignupFormData2);
  //是否能发邮件获取验证码
  const [canSendEmailForRegister, setCanSendEmailForRegister] =
    useState<boolean>(false);//
  //是否能点击注册按钮
  const [canClickNextBtn, setCanClickNextBtn] = useState<boolean>(false);
  //是否点击了按钮
  const [nextClicked, setNextClicked] = useState(false);

  const [errorMsg, setErrorMsg] = useState('')
  const [cfToken, setCfToken] = useState<string>("123");

 

  let didCancel = false;
  


 
const userInfoCacheData = useSelector<
    AppState,
    userInfoLocalData.Selectors
  >(({ shared }) =>
    sharedSelectors.local.userInfoLocalData(shared.local.userInfoLocalData, {})
  );

  const authInfoCacheData = useSelector<
    AppState,
    authLocalData.Selectors
  >(({ persist }) =>
    persistSelectors.local.authLocalData(persist.local.authLocalData, {})
  );

  useEffect(() => {
    if(authInfoCacheData?.getData?.accessToken && userInfoCacheData?.getData?.email){
        navigate(DASHBOARD,{replace:true})
    }
   
  
    return () => {
      
    }
  }, [userInfoCacheData?.getData?.uid,authInfoCacheData?.getData?.accessToken])
  


  useEffect(() => {
    setErrorMsg('')
    // !nextClicked && checkCanClickNextBtn();
    return () => {
      didCancel = true;
    };
  }, [formData]);


  // const checkCanClickNextBtn = () => {
  //   const val = checkAvailableListForRegister(formData);
  //   console.log("checkCanClickNextBtn**", val, formData);
  //   setCanClickNextBtn(val);
  // };

  // const checkAvailableListForRegister = (list: any): boolean => {
  //   let available = true;
  //   // console.log("checkAvailableListForRegister**list**", list);
  //   for (const item in list) {
  //     if (typeof list[item] === "object") {
  //       // console.log("checkAvailableListForRegister**listitem**", typeof (list[item]), item, list[item], item == 'isIllegal')
  //       available = available && checkAvailableListForRegister(list[item]);
  //       // console.log("available0**", item, list[item], available)
  //     } else if (item == "type") {
  //       available =
  //         available && (list[item] == "legal" || list[item] == "useful");
  //       // console.log("checkAvailableListForRegister**listitem**available1**", item, !!!list[item])
  //       if (!available) return available; //不满足的情况直接可以返回false
  //     }
  //   }
  //   // console.log("checkAvailableListForRegister**listitem**allavailable**",available)
  //   return available;
  // };


  const [requesting, setRequesting] = useState(false);


  const btnAvailable = canClickNextBtn && !requesting && !!cfToken;
  
  // console.log("usesignup***btnAvailable**", btnAvailable);
  // console.log("usesignup***canClickNextBtn**", canClickNextBtn);
  // console.log("usesignup***!requesting**", !requesting);
  // console.log("usesignup***!!cfToken**", !!cfToken, cfToken);



  const setEmail = (value: string) => {
    const isEmail = checkIsEmail(value);
    // console.log("@@@@@@@ --- checkIsEmail:", isEmail,value);
    // console.log("@@@@@@@ --- value:", value);
    // console.log(hasSpeicalChar ? "illegal" : !value ? "empty" : "legal");
    setFormData({
      ...formData,
      email: {
        value: value || "",
        type: !isEmail ? "illegal" : !value ? "empty" : "legal",
      },
    });
  };

  const setCode= (value: string) => {
    const isCode = checkIsVerifyCode(value);
    // console.log("@@@@@@@ --- hasSpeicalChar:", hasSpeicalChar);
    // console.log("@@@@@@@ --- value:", value);
    // console.log(hasSpeicalChar ? "illegal" : !value ? "empty" : "legal");
    setFormData({
      ...formData,
      code: {
        value: value || "",
        type: !isCode ? "illegal" : !value ? "empty" : "legal",
      },
    });
  };

  

  const setPasssword = (value: string) => {
    const passwordType = !value
      ? "empty"
      : value.length < PWD_MIN_LEN
      ? "tooshort"
      : value.length > PWD_MAX_LEN
      ? "toolong"
      : !checkIsPassword(value)
      ? "illegal"
      : "legal";
    // console.log("passwordType**", passwordType);
    setFormData({
      ...formData,
      password: {
        value,
        type: passwordType,
      },
      confirmpassword: {
        value: formData.confirmpassword.value,
        type: !formData.confirmpassword.value ? 'empty' : (value !== formData.confirmpassword.value ? 'illegal' : 'legal')
      }

    });
  };

  const setConfirmPasssword = (value: string) => {
    setFormData({
      ...formData, confirmpassword: {
        value: value || '',
        type: !value ? 'empty' : (value !== formData.password.value ? 'illegal' : 'legal')
      }
    })
  };

  const goNextStep = () => {
    setFormData({
      ...formData, step:++formData.step
    })
  };

  const [errorMessage, setErrorMessage] = useState("");

  const onSendEmail = async () => {
    if (!formData) return;
    setErrorMessage("");
    const result = await sendCode({email: formData.email.value,vt:'CHANGE_PASSWORD'});
    const responseData: ResponseData<boolean> = result.data;
    console.log("getEmailVerifyCode**responseData**", responseData);
    if (!responseData.success) setErrorMessage(responseData.msg);
    else{
      goNextStep();
    }
  };

  const onCheckCode = async () => {
    if (!formData) return;
    setErrorMessage("");
    const result = await checkCode({email: formData.email.value,code:formData.code.value,vt:'CHANGE_PASSWORD'});
    const responseData: ResponseData<boolean> = result.data;
    console.log("getEmailVerifyCode**responseData**", responseData);
    if (!responseData.success) setErrorMessage(responseData.msg);
    else{
      goNextStep();
    }
  };

  const onResetPwd = async () => {
    if (!formData) return;
    setErrorMessage("");
    const result = await resetPwd({email: formData.email.value,verifyCode:formData.code.value,newPassword:MD5(formData.password.value)});
    const responseData: ResponseData<boolean> = result.data;
    console.log("getEmailVerifyCode**responseData**", responseData);
    if (!responseData.success) setErrorMessage(responseData.msg);
    else{
      goNextStep();
    }
  };

  return {  btnAvailable, formData,setEmail,setPasssword,setConfirmPasssword,setCode,errorMsg ,onSendEmail,onCheckCode,onResetPwd};
};

export default useResetPwd;
