import React, { useEffect, useRef, useState } from 'react'

import { ButtonRoundBorder } from '../../components/Button'
import { ImgAnswer, ImgDocument } from '../../images'
import { QuestionExample } from '../Question'
import { SummaryTableData, SummaryTableItemData } from '../Chat/chatdatatype'
import { TrademarkDetailReport } from '../Report'
import SimilarityScore from '../SimilarityScore'
import { QuestionData } from '../../service/chatService'
import classData from '../../data/class.json'

type Props = {
  hasPercent?: boolean
  title: string
  tableData: SummaryTableData
  questions?: QuestionData[]
}

// 定义数据的类型
interface ClassData {
  [key: string]: string; // 使用字符串索引签名
}

const AnswerTable: React.FC<Props> = ({ hasPercent = false, title, tableData, questions }: Props) => {

  const reportRef = useRef<any>(null)
  const [showTableData, setShowTableData] = useState<SummaryTableData>([])
  const [pageIndex, setPageIndex] = useState(0)
  useEffect(() => {
    // if(tableData && tableData.length>0 ){
    //   console.log('answertable',((1-tableData[0].score)*100).toFixed(0),hasPercent,tableData[0])
    // }

    if (!tableData) setShowTableData([])
    else setShowTableData(tableData.filter((item, index) => index < 10))

    return () => {

    }
  }, [tableData])

  useEffect(() => {
    console.log("chatList***update**", showTableData, Date.now());
    return () => {

    }
  }, [showTableData])


  const buildClass = (item: string) => {
    return item;
    const data :ClassData=classData
    return data[Number(item).toString()];
    //   try {
    //     const data= JSON.parse(item)
    //  return Object.keys(data[0])[0]
    //   } catch (error) {
    //     return ''
    //   }

  }

  const tableItemRef = useRef<SummaryTableItemData>();


  return <li className='flex flex-row'>
    <img className='size-10 rounded-full' src={ImgAnswer} />
    <div className='flex flex-col items-start space-y-4'>
      <div className='flex flex-col items-start ml-4'>
        <h6 className='text-base font-semibold text-[#4B4B4B]'>Answer</h6>
        {hasPercent && tableData && tableData.length > 0 && <div className='text-4.5xl font-semibold text-[#F54040]'>{((1 - tableData[0].score) * 100).toFixed(0)}%</div>}
        {/* <div className='text-base text-[#4B4B4B]'>Trademark registration success rate</div> */}
        <div className='text-base text-[#4B4B4B]'>{title}</div>
        <ul className='flex flex-col rounded-2xl'>
          <li className='flex flex-row text-sm text-[#111111] bg-[#F2F2F2]'>
            <span className='inline-flex items-center w-[153px] h-16 px-4'>Name</span>
            <span className='inline-flex items-center w-[101px] h-16 px-4'>Logo</span>
            <span className='inline-flex items-center w-[127px] h-16 px-4'>Status</span>
            <span className='inline-flex items-center w-[127px] h-16 px-4'>Filing Date</span>
            <span className='inline-flex items-center w-[127px] h-16 px-4'>Class</span>
            <span className='inline-flex items-center w-[126px] h-16 px-4'>Owner</span>
            <span className='inline-flex items-center w-[104px] h-16 px-4'>Similarity Score</span>
            <span className='inline-flex items-center w-[100px] h-16 px-4'>Details</span>
          </li>
          {/* <li className='flex flex-row text-sm text-[#111111] bg-[#fff]'>
          <span className='inline-flex items-center w-[153px] h-16 px-4'>BabieB</span>
          <span className='inline-flex items-center w-[101px] h-16 px-4'>
            <img className='h-6 object-contain' src='' />
          </span>
          <span className='inline-flex items-center w-[127px] h-16 px-4'>Valid</span>
          <span className='inline-flex items-center w-[127px] h-16 px-4'>2021 Sep 30</span>
          <span className='inline-flex items-center w-[127px] h-16 px-4'>Toy</span>
          <span className='inline-flex items-center w-[126px] h-16 px-4'>Peter Zenh</span>
          <span className='inline-flex items-center w-[104px] h-16 px-4'>
            <SimilarityScore score={99} />
          </span>
          <span className='inline-flex justify-center items-center w-[100px] h-16 px-4'>
            <button className='inline-flex' onClick={() => { reportRef.current?.show() }}>
              <img className='size-6' src={ImgDocument} />
            </button>
          </span>
        </li> */}
          {showTableData?.map((item, index) => {
            return (
              <li className='flex flex-row text-sm text-[#111111] bg-[#fff]' key={index}>
                <span className='inline-flex items-center w-[153px] h-16 px-4'>
                  <span className='line-clamp-2'>{item.name}</span>
                </span>
                <span className='inline-flex items-center w-[101px] h-16 px-4'>
                  <img className='h-6 object-contain' src={item.logo} />
                </span>
                <span className='inline-flex items-center w-[127px] h-16 px-4'>
                  <span className='line-clamp-2'>{item.status}</span>
                </span>
                <span className='inline-flex items-center w-[127px] h-16 px-4'>
                  <span>{item.fillingDate}</span>
                </span>
                <span className='inline-flex items-center w-[127px] h-16 px-4'>
                  <span>{buildClass(item.class)}</span>
                </span>
                <span className='inline-flex items-center w-[126px] h-16 px-4'>
                  <span className='line-clamp-2'>{item.owner}</span>
                </span>
                <span className='inline-flex items-center w-[104px] h-16 px-4'>
                  <SimilarityScore score={item.score * 100} />
                </span>
                <span className='inline-flex justify-center items-center w-[100px] h-16 px-4'>
                  <button className='inline-flex' onClick={() => {
                    // tableItemRef.current=item;
                    // console.log('tableItemRef.current**',tableItemRef.current)
                    reportRef.current?.show(item)
                  }}>
                    <img className='size-6' src={ImgDocument} />
                  </button>
                </span>
              </li>
            )
          })}
          {tableData && tableData?.length > 10 && showTableData?.length !== tableData?.length && <div className='flex flex-row justify-center mt-2' onClick={() => {
            setPageIndex(1)
            setShowTableData(tableData)
          }}>
            <ButtonRoundBorder>More</ButtonRoundBorder>
          </div>}
        </ul>
        {/* {questions && <QuestionExample question={[
        'Trademark industry classification',
        'Countries and regions for trademark registration'
      ]} />} */}
      </div>
      {questions && <QuestionExample question={questions} />}
    </div>
    {<TrademarkDetailReport ref={reportRef} />}
  </li>
}

export default AnswerTable