import React from 'react'

import { ImgLogo, ImgGoolge } from '../images'
import { Banner, Input } from '../components'
import { Button, ButtonText } from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { GOOGLE_LOGIN, PASSWORD_RESET, SIGNUP } from '../router/constants'
import useSignin from '../hooks/useSignin'
import { Logo } from '../components'

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { onSigninClick, btnAvailable, formData, setUsername, setPasssword, errorMsg } = useSignin();
  return <section className='flex flex-row'>
    <Banner />
    <div className='flex flex-1 h-screen overflow-auto'>
      <div className='relative flex flex-1 flex-col justify-center min-h-[926px]'>
        <div className='absolute top-[64px] left-[64px]'>
          <Logo type={'normal'}/>
        </div>
        <form
          className='flex flex-col mx-auto w-[420px]'
          onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            return false
          }}>
          <h5 className='text-3.5xl font-semibold text-black'>Welcome back</h5>
          <span className='text-sm text-[#4B4B4B]'>Create an Trademark LLM account for free trial.</span>
          <div className='flex flex-col space-y-4 mt-12'>
            <Input
              title='Email'
              onClearValue={() => {
                setUsername('')
              }}
              onValueChanged={(e) => {
                setUsername(e)
              }}
            />
            <Input
              title='Password'
              type='password'
              error={errorMsg}
              onClearValue={() => {
                setPasssword('')
              }}
              onValueChanged={(e) => {
                setPasssword(e)
              }}
            />
          </div>
          <div className='flex flex-row justify-end mt-2'>
            <ButtonText onClick={() => { navigate(PASSWORD_RESET) }}>Forgot Password?</ButtonText>
          </div>
          <div className='flex flex-col my-12'>
            <Button type='primary' disabled={!btnAvailable} onClick={() => { onSigninClick() }}>Log in</Button>
            <div className='flex flex-row justify-center mt-4'>
              <span className='text-sm'>Don't have an account?&nbsp;</span>
              <ButtonText onClick={() => { navigate(SIGNUP) }}>Sign up for free</ButtonText>
            </div>
          </div>
          <div className='mb-4 text-center'>
            <span className='text-base text-[#9D9FA1] text-center'>or</span>
          </div>
          <Button type='normal' onClick={() => { window.location.href = GOOGLE_LOGIN }}>
            <img className='size-8 mr-[10px]' src={ImgGoolge} />
            <span>Sign in with Google</span>
          </Button>
        </form>
      </div>
    </div>
  </section>
}

export default Login