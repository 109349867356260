import React, { useEffect, useState } from "react";
import { ButtonRoundBorder } from "../Button";
import { ChatMode } from "../Solution/ChatList";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/app-state";
import { chatlistLocalData } from "../../redux/local";
import { sharedActions, sharedSelectors } from "../../redux";
import {
  QuestionData,
  buildAnalysisQuickQuestionData,
  buildSearchQuickQuestionData,
} from "../../service/chatService";
import { ChatData } from "../Chat/chatdatatype";
import { useRandomQuestion } from "../../contexts/RandomQuestionContext";

type Props = {
  question: QuestionData[];
  onUploadClick?: () => void
  
};

const QuestionExample: React.FC<Props> = ({ question,onUploadClick }: Props) => {
  // return null

  const [mode, setMode] = useState<ChatMode>();
  useEffect(() => {
    console.log(
      "QuestionExample**",
      question,
      chatlistCacheData?.getData.chatMode
    );
    setMode(chatlistCacheData?.getData.chatMode);

    return () => { };
  }, [question]);

  const chatlistCacheData = useSelector<AppState, chatlistLocalData.Selectors>(
    ({ shared }) =>
      sharedSelectors.local.chatlistLocalData(
        shared.local.chatlistLocalData,
        {}
      )
  );
  const dispatch = useDispatch();
  const random = useRandomQuestion();
  const resetChatData = () => {
    dispatch(sharedActions.local.chatlistLocalData.reset());
    random?.search?.reset();
    random?.analysis?.reset();
  }


  const updateModel = (data: ChatMode) =>
    dispatch(sharedActions.local.chatlistLocalData.updateChatMode(data));

  const updateNewMsg = (data: ChatData[]) =>
    dispatch(sharedActions.local.chatlistLocalData.updateNewMsg(data));

  return (
    <div className="flex flex-col space-y-4 ml-[16px]">
      <h6 className="text-base text-[#4B4B4B] font-semibold">
        You might want to know
      </h6>
      {question?.map((item, index) => {
        // console.log('question***item**',item)
        return (
          <div key={index} className="inline-flex">
            <ButtonRoundBorder
              onClick={() => {
                console.log("question click", item, mode);
                if(item.type === 'upload-analysis'){
                  //打开上传框
                  onUploadClick && onUploadClick();
                  return;
                }
                if (item.type === "restart") {
                  resetChatData();

                  return;
                }
                if (mode === "search") {
                  random?.search?.remove(Number(item.id))
                  const chats = buildSearchQuickQuestionData(item);
                  console.log("example**", chats);
                  if (chats && chats.length > 0) {
                    //  updateModel('search')
                    updateNewMsg(chats);
                  }
                } else if (mode === "analysis") {
                  random?.analysis?.remove(Number(item.id))
                  const chats = buildAnalysisQuickQuestionData(item);
                  console.log("example**", chats);
                  if (chats && chats.length > 0) {
                    //  updateModel('search')
                    updateNewMsg(chats);
                  }
                }
              }}
            >
              {item?.data.message}
            </ButtonRoundBorder>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionExample;
