import * as React from "react";
import type { SVGProps } from "react";
const SvgRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.828 8.412a1.167 1.167 0 0 0-1.656 0l-8.692 8.703-3.652-3.663a1.192 1.192 0 1 0-1.656 1.715l4.48 4.48a1.166 1.166 0 0 0 1.656 0l9.52-9.52a1.167 1.167 0 0 0 0-1.715"
    />
  </svg>
);
export default SvgRight;
