import * as React from "react";
import type { SVGProps } from "react";
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.167 9.167H7.842l2.75-2.742a.837.837 0 0 0-1.184-1.183L5.242 9.408a.8.8 0 0 0-.175.275.83.83 0 0 0 0 .634q.06.154.175.275l4.166 4.166a.833.833 0 0 0 1.184 0 .83.83 0 0 0 0-1.183l-2.75-2.742h6.325a.833.833 0 0 0 0-1.666"
    />
  </svg>
);
export default SvgArrowLeft;
