import React, { useState } from 'react'

import { Button } from '../../components/Button'
import { Upload } from '../'
import { ImgUpload } from '../../images'
import { persistActions, sharedActions } from '../../redux'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { INFRINGEMENT_REPORT, TEST_COMPARE, TRADEMARK_REPORT } from '../../router/constants'
import useStateRef from 'react-usestateref'
import { useGA4React } from 'ga-4-react'
import { gaCompareTrademarkEvent } from '../../service/ga4Service'

type Props = {

}

const InfringingAnalysis: React.FC<Props> = ({ }: Props) => {
  const dispatch = useDispatch();
  const navigate=useNavigate();

  const updateComparisonTrademarks = (data: string[]) =>
  dispatch(
    persistActions.local.trademarkListLocalData.updateComparisonTrademarks(data)
  );



  // const [avatorUrl, setAvatorUrl] = useState<string>();s
  const [headFile, setHeadFile] = useState<File>();
  const [headFile1, setHeadFile1] = useState<File>();

 const [marks, setMarks,markRef] = useStateRef<string[]>([])

  const onSubmit = ()=>{
    if(headFile && headFile1){
      submitGA({'file1':headFile.name,'file2':headFile1.name})
      const reader = new FileReader();

      reader.onload = (e) => {
        console.log('headFile***',e.target?.result)
        if(markRef.current.length > 0){
          const marks = ([e.target?.result as string,...markRef.current])
          updateComparisonTrademarks(marks);
          navigate(INFRINGEMENT_REPORT);
        }
        else{
          setMarks([e.target?.result as string])
        }
      };
      reader.readAsDataURL(headFile);

      const reader1 = new FileReader();

      reader1.onload = (e) => {
        console.log('headFile1***',e.target?.result)
        if(markRef.current.length > 0){
          const marks = ([...markRef.current,e.target?.result as string])
          updateComparisonTrademarks(marks);
          navigate(INFRINGEMENT_REPORT);
        }
        else{
          setMarks([e.target?.result as string])
        }
      };
      reader1.readAsDataURL(headFile1);
    }
    // navigate(TEST_COMPARE)
  }

  const ga4 =useGA4React()

  const submitGA = (data:any)=>{
    ga4&& gaCompareTrademarkEvent(ga4,data)
  }

  return <>
    <div className='flex flex-col w-[420px]'>
      <ul className='flex flex-col mb-8'>
        <li className='flex flex-col'>
          <div className='mb-1 text-sm text-[#4B4B4B]'>Trademark image</div>
          <Upload onChanged={(e)=>{
            setHeadFile(e)
          }}/>
        </li>
        <li className='flex flex-col my-4'>
          <span className='text-sm text-[#4B4B4B] text-center'>And</span>
        </li>
        <li className='flex flex-col'>
          <div className='mb-1 text-sm text-[#4B4B4B]'>Infringing images</div>
          <Upload  onChanged={(e)=>{
            setHeadFile1(e)
          }}/>
        </li>
      </ul>
      <Button type='second'disabled={!headFile || !headFile1} onClick={()=>{onSubmit()}}>Analysis</Button>
    </div>
  </>
}

export default InfringingAnalysis