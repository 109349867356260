import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { decode, encode } from "../utils/saltBase64";
import { MD5 } from "../utils/saltMD5";
import { DASHBOARD } from "../router/constants";

import * as stringUtils from "../utils/string";
import {
  checkContainSpeicalChars,
  checkIsEmail,
  checkIsPassword,
} from "../utils/regularUtils";

import { useSelector } from "react-redux";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import { persistSelectors, sharedSelectors } from "../redux";
import { authLocalData } from "../redux/presist";
import { FormAnyUnitType, FormUnitType2 } from "../service/formdata";
import useLogin from "./login-hook";
import useUserInfo from "./userinfo-hook";
import { uplaodFeedbackImage } from "../api/file/app";
import { FeedbackType, feedback } from "../api/feedback/app";
import { ServiceResponse } from "../service/data";

export type FeedbackPageData = {
  content: FormUnitType2;
  imageFile: FormAnyUnitType;
  type: FormAnyUnitType;
};

const initData: FeedbackPageData = {
  content: {
    value: "",
    type: "empty",
  },
  imageFile: {
    value: undefined,
    type: "empty",
  },
  type: {
    value: "",
    type: "empty",
  },
};

const useFeedbck = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userName } = useUserInfo();
  const [formData, setFormData] = useState<FeedbackPageData>({...initData});

  const setContent = (value: string) => {
    const type = !!value ? "useful" : "empty";
    console.log("setContent**", type);
    setFormData({
      ...formData,
      content: {
        value,
        type: type,
      },
    });
  };

  const setImageFile = (value: any) => {
    const type = !!value ? "useful" : "empty";
    console.log("imageFile**", type);
    setFormData({
      ...formData,
      imageFile: {
        value,
        type: type,
      },
    });
  };

  const setType = (value: FeedbackType) => {
    const type = !!value ? "useful" : "empty";
    console.log("setType**", type);
    setFormData({
      ...formData,
      type: {
        value,
        type: type,
      },
    });
  };

  const onSubmit= async():Promise<ServiceResponse>  => {
    if (formData.content.type !== "useful" || formData.type.type !== 'useful') {
      return {success:false,error:'para error'};
    }
    //上传图片
    let imgUrl=undefined;
    if(formData.imageFile.type === "useful"){
     const uploadResult= (await uplaodFeedbackImage(formData.imageFile.value))
     if(uploadResult?.status !== 200){
      return {success:false,error:'upload error'};
     }
     imgUrl = uploadResult?.data
    }
    //提交反馈
    const data = {
      attachmentUrl: imgUrl,
      content: formData.content.value,
      type: formData.type.value as FeedbackType
    }
    const result = (await feedback(data))?.data?.success
    console.log('feedback**',result)
    return {success:result,error:'feedback '+result.toString()};
  };

  const initFormData =()=>{
setFormData({...initData})
  }

  return { setImageFile, setContent,setType,initFormData, onSubmit };
};

export default useFeedbck;
