import { getInfringementData, search, searchName, uploadImage } from "../../api/ai/app";
import { ContractSearchResponseData, MarkInfoData, ReportData, ReportResponseData, UploadResponseData } from "../../api/ai/response";
import { useSupabase } from "../../contexts/SupabaseContext";
import { buildReportData } from "../../service/chatService";
import { SummaryTableData } from "../Chat/chatdatatype";
import { format2StatusType } from "../Trademark/Status";

export const useTrademarkList = ()=>{

  const supabase = useSupabase();
  const fetchSupabaseRowData = async (id: number) => {
    if (!supabase) return;
    try {
      const { data, error } = await supabase
        .from("law_tech_trademarks_all_data") // 表名
        .select("*")
        .eq("us_serial_number", id); // 选择列

      if (error) {
        console.error("supadata***", error);
        // 处理错误
      }

      if (data && data?.length > 0) {
        // 使用数据
        // console.log("supadata***", data);
        return data[0];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    return undefined;
  };

  const searchTrademarksByName = async (name: string): Promise<SummaryTableData|undefined > => {
    console.log("searchTrademarksByName**");
    if (!!name) {
      //静止数s，直接调用没结果
      // delayTime(2);
      //调用搜索接口
      const searchResponseData: ContractSearchResponseData = (
        await searchName(name)
      ).data;

      if (searchResponseData?.data?.data) {
        const searchResult = searchResponseData.data?.data;
        console.log("searchResult**", searchResult);
        //处理搜索结果，并返回对应的组件
        const tableData: SummaryTableData = await Promise.all(
          searchResult.map(async (item) => {
            const rowData: MarkInfoData = await fetchSupabaseRowData(
              item.us_serial_number
            );
            const status = !!rowData.status
              ? rowData.status.split(".")[0]
              : "";
            // const status=!!rowData.status?'Valid':'Invalid'
            const data = JSON.parse(rowData.goods_and_services);
            const type =
              data && data.length > 0 ? Object.keys(data[0])[0] : "";
            const type2 = type && type.length > 2 ? type.substring(0, 3) : "";
            return {
              name: rowData.mark,
              logo: item.trademark.url,
              // status:rowData.status,
              // status: status,
              status:format2StatusType(status),
              fillingDate: rowData.application_filing_date,
              class: type2, //rowData.goods_and_services,
              owner: rowData.owner_name,
              score: item.score,
              details: rowData,
            };
          })
        );
        console.log("tableData**", tableData);
        return tableData
      }
    }
  };

  

  const searchTrademarksByImage = async (file: any): Promise<SummaryTableData|undefined > => {
    console.log("searchTrademarksByImage**",file);
    if (!!file) {
      const uploadResponseData: UploadResponseData = (await uploadImage(file))
        .data;
      console.log("uploadResponseData**", uploadResponseData);
      const uploadImgUrl = uploadResponseData?.data.url;
      if (uploadImgUrl) {
        //静止数s，直接调用没结果
        // delayTime(2);
        //调用搜索接口
        const searchResponseData: ContractSearchResponseData = (
          await search(uploadImgUrl)
        ).data;

        if (searchResponseData?.data?.data) {
          const searchResult = searchResponseData.data?.data;
          console.log("searchResult**", searchResult);
          //处理搜索结果，并返回对应的组件
          const tableData: SummaryTableData = await Promise.all(
            searchResult.map(async (item) => {
              const rowData: MarkInfoData = await fetchSupabaseRowData(
                item.us_serial_number
              );
              // const status=!!rowData.status?rowData.status.split('.')[0]:'Invalid'
              // const status = !!rowData.status ? "Valid" : "Invalid";
              const status = !!rowData.status
                ? rowData.status.split(".")[0]
                : "";

              const data = JSON.parse(rowData.goods_and_services);
              const type =
                data && data.length > 0 ? Object.keys(data[0])[0] : "";
              const type2 =
                type && type.length > 2 ? type.substring(0, 3) : "";

              return {
                name: rowData.mark,
                logo: item.trademark.url,
                // status:rowData.status,
                status: format2StatusType(status),
                fillingDate: rowData.application_filing_date,
                class: type2,
                owner: rowData.owner_name,
                score: item.score,
                details: rowData,
              };
            })
          );
         
          console.log("tableData**", tableData);
          return tableData
        }
      }
    }
  };

  const compareTrademarksByImages = async (
    goodUrl: string,
    goodFile: any,
    imgUrl: string,
    trademarkFile: any
  ) => {
    console.log("compareTrademarksByImages**");
    const uploadResponseData: UploadResponseData = (
      await uploadImage(goodFile)
    ).data;
    console.log("uploadResponseData**", uploadResponseData);
    const goodWebUrl = uploadResponseData?.data.url;

    const uploadResponseData1: UploadResponseData = (
      await uploadImage(trademarkFile)
    ).data;
    console.log("uploadResponseData1**", uploadResponseData1);
    const trademarkWebUrl = uploadResponseData1?.data.url;

    const response: ReportResponseData = (
      await getInfringementData(goodWebUrl, trademarkWebUrl)
    )?.data;
    console.log(
      "********** getInfringementData ----response success---- ",
      response.success
    );
    console.log(
      "********** getInfringementData ----response data---- ",
      response.data
    );
    if (response.success) {
      const responseData = response.data as ReportData;
      const report = responseData.data;
      const chat = buildReportData(
        report,
        goodUrl,
        imgUrl,
        goodWebUrl,
        trademarkWebUrl
      );
      return chat
    } else {
      // setState('CHECK_INFRINGEMENT_FAILED')
    }
  };
return{
  searchTrademarksByName,
  searchTrademarksByImage,
compareTrademarksByImages
}
}