
export type InputType =
  | "empty"
  | "illegal"
  | "legal"
  | "registered"
  | "notinwhitelist"
  | "useful"
  | "toolong"
  | "tooshort"
  | "manual-error";
type formUnitType = {
  value: string;
  isIllegal: boolean;
};

export type FormDataType = {
  username: formUnitType & {
    hasSpeicalChar: boolean;
    isRegisterd: boolean;
    knowIsRegisterd: boolean;
  };
  email: formUnitType & { isRegisterd: boolean; knowIsRegisterd: boolean };
  password: formUnitType;
  confirmpassword: formUnitType;
};

export type FormUnitType2 = {
  value: string;
  type: InputType;
};

export type FormAnyUnitType = {
  value: any;
  type: InputType;
};

// export const initFormData: FormDataType2 = {
//   username: {
//     value: "",
//     type: "empty",
//   },
//   email: {
//     value: "",
//     type: "empty",
//   },
//   password: {
//     value: "",
//     type: "empty",
//   },
//   confirmpassword: {
//     value: "",
//     type: "empty",
//   },
//   canRegister: false,
//   codeMix: "init",
// };


// export const initSignupFormData: SignupFormDataType = {
//   username: {
//     value: "",
//     type: "empty",
//   },
//   email: {
//     value: "",
//     type: "empty",
//   },
//   company: {
//     value: "",
//     type: "empty",
//   },
//   password: {
//     value: "",
//     type: "empty",
//   },
//   inviteCode: '',
//   confirmpassword: {
//     value: "",
//     type: "empty",
//   },
//   canRegister: false,
//   codeMix: "init",
//   error:''
// };

export type MixState = "init" | "needmix" | "mixed";

// export type FormDataType2 = {
//   username: FormUnitType2;
//   email: FormUnitType2;
//   password: FormUnitType2;
//   confirmpassword: FormUnitType2;
//   // inviteCode: FormUnitType2;
//   canRegister: boolean;
//   codeMix: MixState;
// };

// export type SignupFormDataType = {
//   username: FormUnitType2;
//   email: FormUnitType2;
//   company: FormUnitType2;
//   password: FormUnitType2;
//   confirmpassword: FormUnitType2;
//   inviteCode: string;
//   canRegister: boolean;
//   codeMix: MixState;
//   error: string;
// };

export const initSignupFormData2: SignupFormDataType2 = {
  username: {
    value: "",
    type: "empty",
  },
  email: {
    value: "",
    type: "empty",
  },
  password: {
    value: "",
    type: "empty",
  },
  company: {
    value: "",
    type: "empty",
  },
  inviteCode: "",
  confirmpassword: {
    value: "",
    type: "empty",
  },
  codeMix: "init",
  error:''
};

export type SignupFormDataType2 = {
  username: FormUnitType2;
  email: FormUnitType2;
  password: FormUnitType2;
  company: FormUnitType2;
  confirmpassword: FormUnitType2;
  inviteCode: string;
  codeMix: MixState;
  error: string;
};

export const initFPFormData: FormDataTypeFP = {
  email: {
    value: "",
    type: "empty",
  },
  canChangePassword: false,
};

export type FormDataTypeFP = {
  email: FormUnitType2;
  canChangePassword: boolean;
  code?: string;
};


