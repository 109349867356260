import {
  ActionType,
  createReducer,
  createStandardAction,
} from "typesafe-actions";
import { createStructuredSelector } from "reselect";
import { ChatData, ChatType } from "../../components/Chat/chatdatatype";
import { platform } from "os";
import { ChatMode } from "../../components/Solution/ChatList";

export interface TargetChatData{
  level:number,
  chatMode:ChatMode,
  type:ChatType,
  question:string,
  questionId:string
}

export type ChatListLocalData = {
  newMsg:ChatData[]|undefined,
  openFileDialog:boolean|undefined,
  chatMode:ChatMode,
  targetChat?:TargetChatData,
  trademark:string,
  comparisonTrademark:any,
  analysisTrademark:any
  comparisonTrademarks:any[]
};

const initialState = {
  newMsg : undefined,
  openFileDialog : undefined,
  chatMode:'init' as ChatMode,
  trademark:'',
  comparisonTrademark:undefined,
  analysisTrademark:undefined,
  comparisonTrademarks:[]
};

export type Selectors = {
  getData: ChatListLocalData;
};

const actions = {
  update: createStandardAction(
    "UPDATE_CHATLIST_CACHE_DATA"
  )<ChatListLocalData>(),
  reset: createStandardAction("RESET_CHATLIST_CACHE_DATA")(),
  updateOpenFileDialog: createStandardAction('UPDATE_CHATLIST_FILE_DIALOG_CACHE_DATA')<
  boolean |undefined
>(),
updateChatMode: createStandardAction('UPDATE_CHATLIST_CHATMODE_CACHE_DATA')<
ChatMode
>(),
updateTargetChat: createStandardAction('UPDATE_CHATLIST_TARGETCHAT_CACHE_DATA')<
TargetChatData
>(),
updateTrademark: createStandardAction('UPDATE_CHATLIST_TRADEMARK_CACHE_DATA')<
string
>(),
updateComparisonTrademark: createStandardAction('UPDATE_CHATLIST_COMPARISON_TRADEMARK_CACHE_DATA')<
any
>(),
updateComparisonTrademarks: createStandardAction('UPDATE_CHATLIST_COMPARISON_TRADEMARKS_CACHE_DATA')<
any[]
>(),
updateAnalysisTrademark: createStandardAction('UPDATE_CHATLIST_ANALYSIS_TRADEMARK_CACHE_DATA')<
any
>(),
updateNewMsg: createStandardAction('UPDATE_CHATLIST_NEWMSG_CACHE_DATA')<
ChatData[]
>(),

};

export type ChatListLocalDataActions = ActionType<typeof actions>;

const reducer = () =>
  createReducer<ChatListLocalData, ChatListLocalDataActions>(
    initialState
  )
    .handleAction(actions.update, (state, { payload }) => ({
      ...state,
      ...payload
    }))
    .handleAction(actions.updateOpenFileDialog, (state, { payload }) => {
      // console.log('updateOpenFileDialog**',state,payload)
      return {
        ...state,
        openFileDialog:payload
      }
    })
    .handleAction(actions.updateChatMode, (state, { payload }) => {
      // console.log('updateChatMode**',state,payload)
      return {
        ...state,
        chatMode:payload
      }
    })
    .handleAction(actions.updateTargetChat, (state, { payload }) => {
      console.log('updateTargetChat**',state,payload)
      return {
        ...state,
        targetChat:payload
      }
    })
    
    .handleAction(actions.updateTrademark, (state, { payload }) => {
      console.log('updateTrademark**',state,payload)
      return {
        ...state,
        trademark:payload
      }
    })
    .handleAction(actions.updateComparisonTrademark, (state, { payload }) => {
      // console.log('updateChatMode**',state,payload)
      return {
        ...state,
        comparisonTrademark:payload
      }
    })
    .handleAction(actions.updateComparisonTrademarks, (state, { payload }) => {
      // console.log('updateChatMode**',state,payload)
      return {
        ...state,
        comparisonTrademarks:payload
      }
    })
    .handleAction(actions.updateAnalysisTrademark, (state, { payload }) => {
      // console.log('updateChatMode**',state,payload)
      return {
        ...state,
        analysisTrademark:payload
      }
    })
    .handleAction(actions.updateNewMsg, (state, { payload }) => {
      // console.log('updateChatMode**',state,payload)
      return {
        ...state,
        newMsg:payload
      }
    })
    
    
    .handleAction(actions.reset, () => ({...initialState}
      
    ));


const select = createStructuredSelector<ChatListLocalData, {}, Selectors>({
  getData: (state) => state,
});

export { actions, reducer, select };
