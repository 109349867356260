import React, { } from 'react'

import { ImgSuccess } from '../../images'

const TipCopied: React.FC = () => {

  return <>
    <div className='inline-flex flex-row px-10 py-6 bg-white rounded-4xl space-x-2.5'>
      <img className='size-6' src={ImgSuccess} />
      <span className='text-base font-semibold text-black'>Copied</span>
    </div>
  </>
}

export default TipCopied