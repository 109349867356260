import React from 'react';
import { Document, Page, PDFDownloadLink, View, Text, Image, Font } from '@react-pdf/renderer'
import { createTw } from "react-pdf-tailwind";

import { ImgReportPrinting, ImgFairIP } from "../../images";
import { SummaryTableItemData } from "../Chat/chatdatatype";
import { MarkInfoData } from "../../api/ai/response";
// @ts-ignore
import timeRomanBold from "../../fonts/TimesNewRoman/timesnewromanbold.ttf"
// @ts-ignore
import timeRoman from "../../fonts/TimesNewRoman/timesnewroman.ttf"

Font.register({
  family: 'TT',
  fonts: [
    { src: timeRoman, fontWeight: 'normal' },
    { src: timeRomanBold, fontWeight: 'bold' },
  ]
});


const tw = createTw({
  theme: {
    fontFamily: {
      'timesRoman': ['TT'],
    },
    extend: {
      fontSize: {
        'xs': ['12px', '20px'],
        'xl': ['20px', '32px']
      },
    },
  },
});

type Props = {
  data?: SummaryTableItemData,
  detail?: MarkInfoData
  goodsDetail: string,
  firstUse: string,
  use_in_commerce: string,
};

const TrademarkDetailReportPdf = ({ data, detail, goodsDetail, firstUse, use_in_commerce }: Props) => {
  return (
    <>
      <Document>
        <Page size='A4' style={tw("py-[88px]")} wrap={true}>
          <View style={tw("absolute top-2 left-2 right-2")} fixed>
            <View style={tw("absolute left-0")}>
              <Image style={tw("w-[44px] h-[44px]")} src={ImgReportPrinting} />
            </View>
            <View style={tw("absolute right-0")}>
              <Image style={tw("rotate-90 w-[44px] h-[44px]")} src={ImgReportPrinting} />
            </View>
          </View>
          <View style={tw("relative flex flex-col font-timesRoman")}>
            <View style={tw("flex flex-col w-[595px]")}>
              <View style={tw("relative flex flex-col")}>
                <View style={tw("flex flex-col mx-auto w-[420px] gap-6 text-[#282828]")}>
                  <Text style={tw("text-xl leading-8 font-bold leading-8")}>Trademark Search and Opinion Report</Text>
                  <View style={tw("border-b border-[#D9D9D9]")}></View>
                  <View style={tw("flex flex-col gap-4")}>
                    <View style={tw("flex flex-row text-xs leading-[18px] mt-2")}>
                      <Text style={tw("w-[128px] font-bold text-[#1BA4A4]")}>Status:</Text>
                      <View style={tw("flex flex-col max-w-[291px] gap-2")}>
                        <Text>{data?.status?.toUpperCase()} </Text>
                        <View style={tw("flex flex-col max-w-[291px] gap-1")}>
                          <Text style={tw("flex flex-row")}>
                            <Text style={tw("font-bold")}>USPTo Status: </Text>
                            <Text>{data?.status?.toUpperCase()} </Text>
                          </Text>
                          <Text style={tw("flex flex-row")}>
                            <Text style={tw("font-bold")}>USPTo Status Date:{" "}</Text>
                            <Text>{detail?.status_date}</Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={tw("flex flex-row text-xs leading-[18px]")}>
                      <Text style={tw("w-[128px] font-bold text-[#1BA4A4]")}>Goods/Services:</Text>
                      <View style={tw("flex flex-col max-w-[291px] gap-1")}>
                        <Text style={tw("flex flex-row")}>
                          <Text style={tw("font-bold")}>International Class {data?.class}:{" "}</Text>
                          <Text>{goodsDetail?.toUpperCase()}</Text>
                        </Text>
                        <Text style={tw("flex flex-row")}>
                          <Text style={tw("font-bold")}>First Used: </Text>
                          <Text>{firstUse?.toUpperCase()}</Text>
                        </Text>
                        <Text style={tw("flex flex-row")}>
                          <Text style={tw("font-bold")}>In Commerce:  </Text>
                          <Text>{use_in_commerce?.toUpperCase()}</Text>
                        </Text>
                      </View>
                    </View>
                    <View style={tw("flex flex-row text-xs leading-[18px]")}>
                      <Text style={tw("w-[128px] font-bold text-[#1BA4A4]")}>Last Reported Owner:{" "}</Text>
                      <View style={tw("flex flex-col max-w-[291px] gap-1")}>
                        <View>
                          <Text>{detail?.owner_name?.toUpperCase()}</Text>
                        </View>
                        <View>
                          <Text>{detail?.owner_address?.toUpperCase()}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={tw("flex flex-row text-xs leading-[18px]")}>
                      <Text style={tw("w-[128px] font-bold text-[#1BA4A4]")}>Chronology:</Text>
                      <View style={tw("flex flex-row gap-2")}>
                        <View style={tw("flex flex-col max-w-[291px] gap-1")}>
                          <Text style={tw("flex flex-row")}>
                            <Text style={tw("font-bold")}>Filed: </Text>
                            <Text>{detail?.application_filing_date?.toUpperCase()}</Text>
                          </Text>
                          <Text style={tw("flex flex-row")}>
                            <Text style={{ fontWeight: 'bold' }}>Serial Number: </Text>
                            <Text>{detail?.us_serial_number}</Text>
                          </Text>
                          <Text style={tw("flex flex-row")}>
                            <Text style={tw("font-bold")}>Published For opposition:{" "}</Text>
                            <Text>{detail?.publication_date?.toUpperCase()}</Text>
                          </Text>
                          <Text style={tw("flex flex-row")}>
                            <Text style={tw("font-bold")}>Registered: </Text>
                            <Text>{detail?.registration_date?.toUpperCase()}</Text>
                          </Text>
                          <Text style={tw("flex flex-row")}>
                            <Text style={tw("font-bold")}>Registration Number:{" "}</Text>
                            <Text>{detail?.us_registration_number}</Text>
                          </Text>
                          <Text style={tw("flex flex-row")}>
                            <Text style={tw("font-bold")}>Earliest Date in Record:{" "}</Text>
                            <Text>{detail?.application_filing_date?.toUpperCase()}</Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={tw("border-b border-[#D9D9D9]")}></View>
                    <Text style={tw("w-[128px] font-bold text-xs text-[#1BA4A4] mt-2")}>Ownership Details:{" "}</Text>
                    <View style={tw("flex flex-row text-xs leading-[18px]")}>
                      <Text style={tw("w-[128px] font-bold text-[#1BA4A4]")}>Registrant:{" "}</Text>
                      <View style={tw("flex flex-col max-w-[291px] gap-1")}>
                        <View>
                          <Text>{detail?.owner_name?.toUpperCase()}</Text>
                        </View>
                        <View>
                          <Text>{detail?.owner_address?.toUpperCase()}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={tw("flex flex-row text-xs leading-[18px]")}>
                      <Text style={tw("w-[128px] font-bold text-[#1BA4A4]")}>Filing Correspondent:</Text>
                      <View style={tw("flex flex-col max-w-[291px] gap-1")}>
                        <View>
                          <Text>{detail ? detail["correspondent_name/address"] : ""}</Text>
                        </View>
                      </View>
                    </View>
                    <Text style={tw("w-[128px] font-bold text-xs text-[#1BA4A4]")}>In-Use Information</Text>
                    <View style={tw('flex flex-col gap-1')}>
                      <View style={tw("flex flex-row text-xs leading-[18px]")}>
                        <Text style={tw("w-[128px] font-bold")}>Citation Link:</Text>
                        <Text>None Found</Text>
                      </View>
                      <View style={tw("flex flex-row text-xs leading-[18px]")}>
                        <Text style={tw("w-[128px] font-bold")}>Owner Link:</Text>
                        <Text>None Found</Text>
                      </View>
                      <View style={tw("flex flex-row text-xs leading-[18px]")}>
                        <Text style={tw("w-[128px] font-bold")}>In-Use Link:</Text>
                        <Text>None Found</Text>
                      </View>
                    </View>
                    <View style={tw("flex flex-row justify-end w-[420px] mx-auto my-[85px]")}>
                      <Image style={tw("h-8 object-contain")} src={ImgFairIP} />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={tw("absolute bottom-2 left-2 right-2")} fixed>
            <View style={tw("absolute left-0 bottom-0")}>
              <Image style={tw("rotate-[270deg] w-[44px] h-[44px]")} src={ImgReportPrinting} />
            </View>
            <View style={tw("absolute right-0 bottom-0")}>
              <Image style={tw("rotate-180 w-[44px] h-[44px]")} src={ImgReportPrinting} />
            </View>
          </View >
        </Page >
      </Document >
    </>
  );
}

export default TrademarkDetailReportPdf;
