import * as React from "react";
import type { SVGProps } from "react";
const SvgAttachment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.814 9.819 3.654-3.654a1.167 1.167 0 0 1 1.65 0v0a1.167 1.167 0 0 1 0 1.65L6.464 11.47a2.333 2.333 0 0 1-3.3 0v0a2.333 2.333 0 0 1 0-3.3l4.361-4.36a3.5 3.5 0 0 1 4.95 0v0a3.5 3.5 0 0 1 0 4.949l-4.36 4.36"
    />
  </svg>
);
export default SvgAttachment;
