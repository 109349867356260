import * as React from "react";
import type { SVGProps } from "react";
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.278}
      d="M8.03 13.227h5.752M10.906 2.68a1.356 1.356 0 1 1 1.917 1.918l-7.99 7.99-2.556.638.639-2.556z"
    />
  </svg>
);
export default SvgEdit;
