import React from "react";

import { Download as IconDownload } from '../../svgr/components'
import { ImgContracts1, ImgContracts2 } from '../../images'
import useStateRef from "react-usestateref";

type ContractCardData = {
  src: string,
  name: string,
  desc: string,
  onPreviewClick?: () => void
  onDownloadClick?: () => void
}

const ContractCard: React.FC<ContractCardData> = ({ src, name, desc, onPreviewClick, onDownloadClick }: ContractCardData) => {
  const [loading, setLoading, loadingRef] = useStateRef(false);
  return <>
    <div className='relative flex flex-col shrink-0 w-[379px] py-6 pl-6 pr-4 bg-[#F9F9F9] rounded-2xl border border-[#F2F2F2]'>
      <button
        className='flex flex-row items-end space-x-4 mb-2'
        onClick={onPreviewClick}
      >
        <img className='h-9 object-contain' src={src} />
        <div className='inline-flex text-left'>
          <span className='font-semibold text-xl leading-8 text-black line-clamp-1'>{name}</span>
        </div>
      </button>
      <div className='flex flex-row items-center justify-between mt-6'>
        <div className="inline-flex max-w-[299px] max-h-[56px] overflow-hidden">
          <div className='text-sm text-[#383838] line-clamp-2' dangerouslySetInnerHTML={{ __html: desc }}></div>
        </div>
        <button
          className='inline-flex text-[24px] text-black'
          onClick={async(e)=>{
            if(loading)return;
            setLoading(true)
            onDownloadClick &&(await onDownloadClick())
            setLoading(false)
          }}
        >
          <IconDownload />
        </button>
      </div>
    </div>
  </>
}

export default ContractCard
