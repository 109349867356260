
// export const baseUrl = process.env.REACT_APP_AI_INTERFACE_URL;

export const api = '/business/api/chat'
export const createChatSession = api+'/create_thread'
export const msgInSession = api+'/messages'
export const sendMsg = api+'/send'
export const sessionsInUser = api+'/threads'

export const feedChick = '/business/push/send'

