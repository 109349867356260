import React, { useEffect, useState } from "react";

import { ImgCustomer } from "../images";
import { CloseDialog, ArrowLeft, Attachment } from "../svgr/components";
import { Button, ButtonRound } from "./Button";
import useUserInfo from "../hooks/userinfo-hook";
import useFeedbck from "../hooks/feedback-hook";
import { FeedbackType } from "../api/feedback/app";

type ReportDataType = { title: string; subTitle: string };
const ReportData: ReportDataType[] = [
  { title: "Report an issue", subTitle: "Found a bug? Let us know." },
  { title: "Request a feature", subTitle: "What would you like to see next?" },
  { title: "Send us a message", subTitle: "We are here to help." },
  {
    title: "Become a partner",
    subTitle: "Interested in a partnership? Join us.",
  },
];

type RESULT = 'SUCCESS' | 'FAIL' | 'NONE'

const CustomerService: React.FC = () => {
  const [minimize, setMinimize] = useState(true);

  const { userName } = useUserInfo();

  const { setImageFile, setContent, setType, initFormData, onSubmit } =
    useFeedbck();

  const [submitResult, setSubmitResult] = useState<RESULT>('NONE');
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [selectedReportType, setSelectedReportType] =
    useState<ReportDataType>();

  const initPageData = () => {
    console.log('initPageData**')
    initFormData();
    if (avatorUrl) {
      window.URL.revokeObjectURL(avatorUrl);
      setAvatorUrl("");
      setImgList([]);
    }
  };
  const onReportClick = (id: number) => {
    setShowReportDialog(true);
    setSelectedReportType(ReportData[id]);
    let type: FeedbackType | undefined = undefined;
    switch (id) {
      case 0:
        type = "REPORT_AN_ISSUE";
        break;
      case 1:
        type = "REQUEST_A_FEATURE";
        break;
      case 2:
        type = "SEND_US_A_MESSAGE";
        break;
      case 3:
        type = "BECOME_A_PARTNER";
        break;
      default:
        break;
    }
    !!type && setType(type);
  };

  const [imgList, setImgList] = useState<string[]>([]);

  const [avatorUrl, setAvatorUrl] = useState<string>();
  // const [headFile, setHeadFile] = useState<any>();
  const handlePicker = (event: any) => {
    const files = event.target.files;
    console.log("handlePicker**files**", files);
    if (files && files.length > 0) {
      console.log("handlePicker**avatorUrl**", avatorUrl);
      if (avatorUrl) {
        window.URL.revokeObjectURL(avatorUrl);
      }
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      console.log("handlePicker**file**", file);
      console.log("handlePicker**imageUrl**", imageUrl);
      setAvatorUrl(imageUrl);
      setImgList([imageUrl]);
      // setHeadFile(file);
      setImageFile(file);
      // pickImage(file, imageUrl)
    }
  };

  useEffect(() => {
    if (minimize) {
      setSubmitResult('NONE')
    }
  }, [minimize])


  const renderSuccess = () => {
    return (
      <div className="flex flex-col p-6 bg-white shadow-[0px_0px_12px_0px_#0000001A] rounded-2xl border border-[#F2F2F2] space-y-4">
        <div className="flex flex-row items-center justify-between">
          <ButtonRound onClick={() => {
            setSubmitResult('NONE')
            setShowReportDialog(false)
          }} >
            <ArrowLeft />
          </ButtonRound>
          <ButtonRound onClick={() => { setMinimize(true); }}>
            <CloseDialog color="#000" />
          </ButtonRound>
        </div>
        <div className="relative flex flex-col w-[320px] text-center">
          <span className='text-[64px]'>🥳</span>
          <span className='mt-8 mb-4 font-semibold text-3.5xl text-black'> Success! </span>
          <span className='mb-[52px] text-sm text-[#4B4B4B]'>We will get back to you as soon as possible.</span>
        </div>
        <Button
          type="normal"
          size='medium'
          onClick={async () => {
            setSubmitResult('NONE')
            setShowReportDialog(false)
            setMinimize(true);
          }}
        >Confirm</Button>
      </div>
    )
  }

  return (
    <article className="absolute bottom-[108px] right-8 z-50">
      {(minimize && (
        <button
          className="inline-flex"
          onClick={() => {
            setMinimize(false);
          }}
        >
          <img className="size-16" src={ImgCustomer} />
        </button>
      )) || (
          <>
            {
              submitResult === 'NONE' && <>
                {!showReportDialog && (
                  <>
                    <div className="flex flex-col p-6 bg-white shadow-[0px_0px_12px_0px_#0000001A] rounded-2xl border border-[#F2F2F2]">
                      <div className="flex flex-row items-center justify-between">
                        <img className="size-12" src={ImgCustomer} />
                        <button
                          className="inline-flex items-center justify-center size-12 text-xl rounded-full bg-[#F2F2F280]"
                          onClick={() => {
                            setMinimize(true);
                          }}
                        >
                          <CloseDialog color="#00" />
                        </button>
                      </div>
                      <h6 className="max-w-[255px] mt-4 mb-8 text-[28px] leading-[34px] font-semibold text-black">
                        Hi {userName}👋 How can we help?
                      </h6>
                      <ul className="flex flex-col w-[320px] space-y-2">
                        <li
                          className="flex flex-col p-4 bg-[#F9F9F9] rounded-2xl space-y-2 cursor-pointer"
                          onClick={() => {
                            onReportClick(0);
                          }}
                        >
                          <div className="text-sm font-semibold text-[#282828]">
                            Report an issue
                          </div>
                          <span className="text-sm text-[#4B4B4B]">
                            Found a bug? Let us know.
                          </span>
                        </li>
                        <li
                          className="flex flex-col p-4 bg-[#F9F9F9] rounded-2xl space-y-2 cursor-pointer "
                          onClick={() => {
                            onReportClick(1);
                          }}
                        >
                          <div className="text-sm font-semibold text-[#282828]">
                            Request a feature
                          </div>
                          <span className="text-sm text-[#4B4B4B]">
                            What would you like to see next?
                          </span>
                        </li>
                        <li
                          className="flex flex-col p-4 bg-[#F9F9F9] rounded-2xl space-y-2 cursor-pointer"
                          onClick={() => {
                            onReportClick(2);
                          }}
                        >
                          <div className="text-sm font-semibold text-[#282828]">
                            Send us a message
                          </div>
                          <span className="text-sm text-[#4B4B4B]">
                            We are here to help.
                          </span>
                        </li>
                        <li
                          className="flex flex-col p-4 bg-[#F9F9F9] rounded-2xl space-y-2 cursor-pointer"
                          onClick={() => {
                            onReportClick(3);
                          }}
                        >
                          <div className="text-sm font-semibold text-[#282828]">
                            Become a partner
                          </div>
                          <span className="text-sm text-[#4B4B4B]">
                            Interested in a partnership? Join us.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
                <>
                  {showReportDialog && (
                    <>
                      <div className="flex flex-col p-6 bg-white shadow-[0px_0px_12px_0px_#0000001A] rounded-2xl border border-[#F2F2F2] space-y-4">
                        <div className="flex flex-row items-center justify-between">
                          <ButtonRound
                            onClick={() => {
                              setShowReportDialog(false);
                              initPageData();
                            }}
                          >
                            <ArrowLeft />
                          </ButtonRound>
                          <img className="size-12" src={ImgCustomer} />
                          <ButtonRound
                            onClick={() => {
                              setMinimize(true);
                            }}
                          >
                            <CloseDialog color="#000" />
                          </ButtonRound>
                        </div>
                        <li className="flex flex-col p-4 w-[320px] bg-[#F6F6F6] rounded-2xl space-y-2">
                          <div className="text-sm font-semibold text-[#282828]">
                            {selectedReportType?.title}
                          </div>
                          <span className="text-sm text-[#4B4B4B]">
                            {selectedReportType?.subTitle}
                          </span>
                        </li>
                        <div className="relative flex flex-col w-[320px] h-[166px] p-4 bg-[#F6F6F6] rounded-2xl ">
                          <ul className="flex flex-row mb-2 space-x-2">
                            {/* <li className="group relative inline-flex">
                              <img
                                className="h-10 object-cover"
                                src="https://s3-alpha-sig.figma.com/img/5aa7/d1ee/a2eacf1d2a67c494fc550c4eb4b74f65?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SKHjtyM4fgs5A~QyD13iZB2hDnvelV2OVD7DrUly5yhERbVr0-toCbbpMYqdxQqYM4fMsro6mwF~9Kbm3pzrQ6GcYQN4kfbCfQhIAi6dCwp2J9Npl-QUuUEfEGrnprLDz2e0P-yMaMp8WvYxzf50Dbis7XR8cXo7v~-m24mncOZUTvVKvk9hZRVJ3vrGeFs~JOJFxbNT9IV1VBqiGM6Ne9a53oIzizhS0E97b-l5TeK530JxAtx5s4SXHIHfxEFrB6QS36ekgOsNkXofwflrIgkeJf1AbK2uZHX7C5ZDL0~fsz9CMSla904YSxn-5HX3iTsB-liVvsnztR1~eE9TIw__"
                              />
                              <span className="group-hover:inline-flex items-center justify-center absolute top-[-5px] right-[-5px] size-2.5 hidden bg-white text-[5px] rounded-full cursor-pointer">
                                <CloseDialog color="#000" />
                              </span>
                            </li> */}
                            {imgList?.map((item) => {
                              return (
                                <li className="group relative inline-flex" key={item}>
                                  <img
                                    className="h-10 object-cover"
                                    //src="https://s3-alpha-sig.figma.com/img/5aa7/d1ee/a2eacf1d2a67c494fc550c4eb4b74f65?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SKHjtyM4fgs5A~QyD13iZB2hDnvelV2OVD7DrUly5yhERbVr0-toCbbpMYqdxQqYM4fMsro6mwF~9Kbm3pzrQ6GcYQN4kfbCfQhIAi6dCwp2J9Npl-QUuUEfEGrnprLDz2e0P-yMaMp8WvYxzf50Dbis7XR8cXo7v~-m24mncOZUTvVKvk9hZRVJ3vrGeFs~JOJFxbNT9IV1VBqiGM6Ne9a53oIzizhS0E97b-l5TeK530JxAtx5s4SXHIHfxEFrB6QS36ekgOsNkXofwflrIgkeJf1AbK2uZHX7C5ZDL0~fsz9CMSla904YSxn-5HX3iTsB-liVvsnztR1~eE9TIw__"
                                    src={item}
                                  />
                                  <span onClick={() => {
                                    setAvatorUrl('');
                                    setImgList([]);
                                    // setHeadFile(file);
                                    setImageFile(undefined);
                                  }} className="group-hover:inline-flex items-center justify-center absolute top-[-5px] right-[-5px] size-2.5 hidden bg-white text-[5px] rounded-full cursor-pointer">
                                    <CloseDialog color="#000" />
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                          <textarea
                            className="flex-1 mb-[42px] bg-transparent outline-none resize-none"
                            placeholder="Describe the issue"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              setContent(e.target.value);
                            }}
                          />
                          <div className="absolute bottom-4 left-4 z-10">
                            <span className="inline-flex items-center justify-center size-8 bg-white text-base rounded-full cursor-pointer">
                              <Attachment />
                            </span>
                            <input
                              className="absolute inset-0 opacity-0 cursor-pointer"
                              type="file"
                              onChange={(event) => {
                                console.log("onChange**event**", event);
                                handlePicker(event);
                                event.target.value = "";
                              }}
                            />
                          </div>
                        </div>
                        <Button
                          type="normal"
                          onClick={async () => {
                            if (await onSubmit()) {
                            initPageData();
                              setShowReportDialog(false);
                              setSubmitResult('SUCCESS')
                            }
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}
                </>
              </>
            }
            {
              submitResult === 'SUCCESS' && renderSuccess()
            }
          </>
        )}
    </article>
  );
};

export default CustomerService;
