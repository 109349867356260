import React from 'react'

import { Copy2 as IconCopy } from '../../svgr/components'

type Props = {
  children: React.ReactNode,
  onCopyClick?: () => void
}

const ClausesAgreement: React.FC<Props> = ({ children, onCopyClick }: Props) => {

  return <>
    <li className='flex flex-col flex-1 p-4 bg-[#F9F9F9] rounded-2xl space-y-4 hover:bg-[#F2F2F2]'>
      <div className='text-base leading-6 text-[#111]'>{children}</div>
      <div className="flex flex-row">
        <button
          className='inline-flex flex-row items-center px-4 py-2 bg-white text-[#282828] rounded-lg space-x-1 hover:text-blue'
          onClick={onCopyClick}
        >
          <span className='text-xl'>
            <IconCopy />
          </span>
          <span className='text-sm font-semibold'>Copy</span>
        </button>
      </div>
    </li>
  </>
}

export default ClausesAgreement