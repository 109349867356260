import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { ImgLogo, ImgLogo1, ImgLogo2, ImgLogo3 } from '../images'
import { DASHBOARD, SOLUTIONS, CONTRACTS } from '../router/constants'

type Props = {
  type: 'primary' | 'default' | 'normal'
}

const linkClassName = 'inline-flex'

const Logo: React.FC<Props> = ({ type }: Props) => {

  if (type === 'normal') {
    return <img className='h-8 object-contain' src={ImgLogo1} />
  }

  if (type === 'primary') {
    return <Link className={clsx(linkClassName)} to={SOLUTIONS}>
      <img className='h-8 object-contain' src={ImgLogo2} />
    </Link>
  }

  if (type === 'default') {
    return <Link className={clsx(linkClassName)} to={CONTRACTS}>
      <img className='h-8 object-contain' src={ImgLogo3} />
    </Link>
  }

  return <img className='h-8 object-contain' src={ImgLogo} />
}

export default Logo