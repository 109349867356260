// @ts-nocheck

// url地址转bace64
export function getImageBase64Data(imgSrc) {

  function getBase64(img) {

    let canvas = document.createElement("canvas");

    canvas.width = img.width;

    canvas.height = img.height;

    let ctx = canvas.getContext("2d");

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    let dataURL = canvas.toDataURL('image/jpeg');

    return dataURL;

  }

  let baseUrl = new Promise(function (resolve, reject) {

    let image = new Image();
    image.crossOrigin = 'Anonymous'; // 图片处理跨域
    image.src = `${imgSrc}?v=${new Date()}`;

    image.onload = function () {

      let imageBase64Data = getBase64(image);
      resolve(imageBase64Data);

    }

  });

  return baseUrl;

}