import { get, longPost, post, put, upload } from "../request";
import * as api from "./index";


export type FeedbackType = 'BECOME_A_PARTNER' | 'REPORT_AN_ISSUE' |'REQUEST_A_FEATURE'|'SEND_US_A_MESSAGE'

export type FeedbackRequestData = {
  attachmentUrl: string;
  content: string;
  type: FeedbackType;
};



export const feedback= (data:FeedbackRequestData) => {
  const url = `${api.feedback}`;
  const raw:FeedbackRequestData = data
  return post(url, raw);
};
