import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Tooltip } from 'react-tooltip'

type Props = {
  text: string,
}

const ClassText: React.FC<Props> = ({ text }: Props) => {

  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [id] = useState(uuidv4())

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const isOverflowing = textRef.current.scrollHeight > textRef.current.clientHeight;
        setIsTruncated(isOverflowing);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);

    return () => window.removeEventListener('resize', checkTruncation);
  }, [text]);

  return <>
    <div
      className="text-sm line-clamp-2"
      data-tooltip-id={id}
      ref={textRef}
    >
      <span>{text}</span>
    </div>
    <Tooltip
      className='!py-[5px] !px-3 !bg-[#4B4B4B] !rounded !max-w-[500px]'
      id={id}
      place="top"
      hidden={!isTruncated}
    >
      <div>
        <span className='text-sm'>{text}</span>
      </div>
    </Tooltip>
  </>
}

export default ClassText
