import * as React from "react";
import type { SVGProps } from "react";
const SvgAngleRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      d="M12.95 9.408 8.233 4.7A.833.833 0 1 0 7.05 5.875l4.125 4.167-4.125 4.125a.833.833 0 0 0 .27 1.36q.154.063.322.065a.83.83 0 0 0 .591-.25l4.717-4.709a.833.833 0 0 0 0-1.225"
    />
  </svg>
);
export default SvgAngleRight;
