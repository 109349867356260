import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { decode, encode } from "../utils/saltBase64";
import { MD5 } from "../utils/saltMD5";
import { DASHBOARD} from "../router/constants";

import * as stringUtils from "../utils/string";
import {
  checkContainSpeicalChars,
  checkIsEmail,
  checkIsPassword,
} from "../utils/regularUtils";


import { useSelector } from "react-redux";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import { persistSelectors, sharedSelectors } from "../redux";
import { authLocalData } from "../redux/presist";
import { FormUnitType2 } from "../service/formdata";


const useUserInfo = () => {
 

  


 
const userInfoCacheData = useSelector<
    AppState,
    userInfoLocalData.Selectors
  >(({ shared }) =>
    sharedSelectors.local.userInfoLocalData(shared.local.userInfoLocalData, {})
  );

  const authInfoCacheData = useSelector<
    AppState,
    authLocalData.Selectors
  >(({ persist }) =>
    persistSelectors.local.authLocalData(persist.local.authLocalData, {})
  );

  
  
  
  const uid = userInfoCacheData?.getData?.uid
  const userName = userInfoCacheData?.getData?.username
  const companyName = userInfoCacheData?.getData?.companyName
  const avatar = userInfoCacheData?.getData?.header_image  ||'/assets/default_avator.png'
  // console.log('useUserInfo**avatar**',avatar)
  const email = userInfoCacheData?.getData?.email
  return { uid,userName,avatar,email,companyName};
};

export default useUserInfo;
