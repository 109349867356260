import React from 'react'
import { ImgSearchNotFound } from '../images'

const NoDateFound: React.FC = () => {

  return <>
    <div className="flex flex-col items-center mt-[180px]">
      <img className="size-[124px]" src={ImgSearchNotFound} />
      <div className="mt-[13px] mb-[7px] text-base text-[#111] font-semibold">
        Oops!
      </div>
      <span className="text-xs text-[#9D9FA1]">No Date Found</span>
    </div>
  </>
}

export default NoDateFound