import React, { useEffect, useRef, useState } from "react";

import { Header, Navbar, ComingSoon, CustomerService } from "../components";
import { Search, InfringingAnalysis } from "../components/Trademark";
import { Right as IconRight } from "../svgr/components";
import {
  ImgLogos,
  ImgAdidas,
  ImgMap,
  ImgCheckInfringement,
  ImgMyTrademark,
  ImgBarrage,
} from "../images";
import { AnswerTable } from "../components/Answer";
import { AppState } from "../redux/app-state";
import { useDispatch, useSelector } from "react-redux";
import { chatlistLocalData } from "../redux/local";
import { persistActions, persistSelectors } from "../redux";
import { useTrademarkList } from "../components/Solution/TrademarkListHook";
import { SummaryTableData } from "../components/Chat/chatdatatype";
import { useNavigate } from "react-router";
import { trademarkListLocalData } from "../redux/presist";
import { base64ToFile, isFileInstance } from "../utils/FileUtils";
import { uniqBy, uniq, map, filter } from "lodash";
import { getDaysBeforeNow } from "../utils/time";

const periodDatas = [
  "All",
  "Last Year",
  "Last 5 Years",
  "Last 10 Years",
  "Last 20 Years",
  "Last 50 Years",
];

type kv = {
  key: string;
  selected: boolean;
};

const TestSearch: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const trademarkListCacheData = useSelector<
    AppState,
    trademarkListLocalData.Selectors
  >(({ persist }) =>
    persistSelectors.local.trademarkListLocalData(
      persist.local.trademarkListLocalData,
      {}
    )
  );

  const updateAnalysisTrademark = (data: any) =>
    dispatch(
      persistActions.local.trademarkListLocalData.updateAnalysisTrademark(data)
    );

  const [tableData, setTableData] = useState<SummaryTableData>();
  const [tableUIData, setTableUIData] = useState<SummaryTableData>();
  const [tableRadioUIData, setTableRadioUIData] = useState<SummaryTableData>();

  const { searchTrademarksByName, searchTrademarksByImage } =
    useTrademarkList();

  useEffect(() => {
    const t = trademarkListCacheData?.getData?.trademark;
    t &&
      (async () => {
        setTableData(await searchTrademarksByName(t));
      })();

    return () => {};
  }, [trademarkListCacheData?.getData?.trademark]);

  useEffect(() => {
    const t = trademarkListCacheData?.getData?.analysisTrademark;
    t &&
      (async () => {
        const imgFile = base64ToFile(t, Date.now().toString());
        const isFile = isFileInstance(imgFile);
        if (isFile) setTableData(await searchTrademarksByImage(imgFile));
        else updateAnalysisTrademark(undefined);
      })();

    return () => {};
  }, [trademarkListCacheData?.getData?.analysisTrademark]);

  const timerRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    console.log(
      "trademarkListCacheData?.getData***",
      trademarkListCacheData?.getData
    );
    if (
      !trademarkListCacheData?.getData?.analysisTrademark &&
      !trademarkListCacheData.getData.trademark
    ) {
      timerRef.current = setTimeout(() => {
        navigate(-1);
      }, 0.3 * 1000);
    } else {
      timerRef.current && clearTimeout(timerRef.current);
    }
    return () => {};
  }, [trademarkListCacheData?.getData]);

  const [classes, setClasses] = useState<kv[]>();
  const [owners, setOwners] = useState<kv[]>();

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const o = uniqBy(
        map(tableData, (tableItem) => ({
          key: tableItem.owner,
          selected: false,
        })),
        "key"
      );
      setOwners(o);
      const c = uniqBy(
        map(tableData, (tableItem) => ({
          key: tableItem.class,
          selected: false,
        })),
        "key"
      );
      setClasses(c);
      console.log("xxxx**", c, o);
      // setClasses(uniq(map(tableData, "class")));
    }
    setTableRadioUIData(tableData);
    return () => {};
  }, [tableData]);

  const [selectedClasses, setSelectedClasses] = useState<string[]>();
  useEffect(() => {
    const c = map(
      filter(classes, (item) => item.selected === true),
      "key"
    );
    const t = selectedOwners
      ? filterWithOwner(selectedOwners, tableData)
      : tableData;
    console.log("filterWithOwner**", t,selectedOwners);
    const t2 = filterWithClass(c, t);
    console.log("filterWithClass**", t2,c);
    setTableRadioUIData(t2);
    setSelectedClasses(c);

    return () => {};
  }, [classes]);
  const filterWithClass = (cs: string[], t: SummaryTableData | undefined) => {
    if (!cs || cs.length < 1 || !t) return t;
    else {
      return t?.filter((item) => {
        const targetIndex = cs.findIndex((item2) => item2 === item.class);
        if (targetIndex > -1) return true;
        return false;
      });
    }
  };

  const [selectedOwners, setSelectedOwners] = useState<string[]>();
  useEffect(() => {
    const o = map(
      filter(owners, (item) => item.selected === true),
      "key"
    );
    const t = selectedClasses
      ? filterWithClass(selectedClasses, tableData)
      : tableData;
    console.log("filterWithClass**", t,selectedClasses);
    const t2 = filterWithOwner(o, t);
    console.log("filterWithOwner**", t2,o);
    setTableRadioUIData(t2);
    setSelectedOwners(o);

    return () => {};
  }, [owners]);

  useEffect(() => {
    setTableUIData(tableRadioUIData);
  
    return () => {
    }
  }, [tableRadioUIData])
  
  const filterWithOwner = (cs: string[], t: SummaryTableData | undefined) => {
    if (!cs || cs.length < 1 || !t) return t;
    else {
      return t?.filter((item) => {
        const targetIndex = cs.findIndex((item2) => item2 === item.owner);
        if (targetIndex > -1) return true;
        return false;
      });
    }
  };

  return (
    <div className="flex flex-row">
      {tableUIData && (
        <AnswerTable
          questions={[]}
          hasPercent={false}
          key={"1"}
          title={"title"}
          tableData={tableUIData}
        />
      )}
      <div className="flex flex-col">
        <h1 className="font-bold">Class</h1>
        {classes?.map((item, index) => {
          return (
            <label key={index}>
              <input
                type="radio"
                value={item.key}
                checked={classes[index].selected}
                onChange={() => {}}
                onClick={(e) => {
                  console.log("onClick**classes***", e);
                  classes[index].selected = !classes[index].selected;
                  setClasses([...classes]);
                }}
                // onClick={() => {
                //   setTableRadioUIData(
                //     filter(tableData, (item2) => item2.class === item)
                //   );
                // }}
              ></input>
              {item.key}
            </label>
          );
        })}
      </div>
      <div className="flex flex-col">
        <h1 className="font-bold">Owner</h1>
        {owners?.map((item, index) => {
          return (
            <label key={index}>
              <input
                type="radio"
                checked={owners[index].selected}
                onClick={(e) => {
                  console.log("onClick**owners***", e);
                  owners[index].selected = !owners[index].selected;
                  setOwners([...owners]);
                }}
                onChange={() => {}}
                // onClick={() => {
                //   setTableRadioUIData(
                //     filter(tableData, (item2) => item2.owner === item)
                //   );
                // }}
              ></input>
              {item.key}
            </label>
          );
        })}
      </div>
      <div className="flex flex-col">
        <h1 className="font-bold">Registration date</h1>
        {periodDatas?.map((item, index) => {
          return (
            <button
              key={index}
              onClick={() => {
                // setTableRadioUIData(filter(tableData,item2=>item2.owner === item))
                const tableData = tableRadioUIData;
                if (!tableData) return;
                let filterTable;
                switch (item) {
                  case "All":
                    filterTable = tableData;
                    break;
                  case "Last week":
                    filterTable = tableData.filter(
                      (item) => getDaysBeforeNow(item.fillingDate) <= 7
                    );
                    break;
                  case "Last month":
                    filterTable = tableData.filter(
                      (item) => getDaysBeforeNow(item.fillingDate) <= 30
                    );
                    break;
                  case "Last 3 months":
                    filterTable = tableData.filter(
                      (item) => getDaysBeforeNow(item.fillingDate) <= 90
                    );
                    break;
                  case "Last 6 months":
                    filterTable = tableData.filter(
                      (item) => getDaysBeforeNow(item.fillingDate) <= 180
                    );
                    break;
                  case "Last Year":
                    filterTable = tableData.filter(
                      (item) => getDaysBeforeNow(item.fillingDate) <= 365
                    );
                    break;
                  case "Last 5 Years":
                    filterTable = tableData.filter(
                      (item) => getDaysBeforeNow(item.fillingDate) <= 365 * 5
                    );
                    break;
                  case "Last 10 Years":
                    filterTable = tableData.filter(
                      (item) => getDaysBeforeNow(item.fillingDate) <= 365 * 10
                    );
                    break;
                  case "Last 20 Years":
                    filterTable = tableData.filter(
                      (item) => getDaysBeforeNow(item.fillingDate) <= 365 * 20
                    );
                    break;
                  case "Last 50 Years":
                    filterTable = tableData.filter(
                      (item) => getDaysBeforeNow(item.fillingDate) <= 365 * 50
                    );
                    break;
                  default:
                    break;
                }
                setTableUIData(filterTable);
              }}
            >
              {item}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default TestSearch;
