export default class RandomNumberPicker2 {
    private min: number;
    private max: number;
    private numbers: number[];
    private pickNumbers: number[];
  
    constructor(min: number, max: number) {
      this.min = min;
      this.max = max;
      this.numbers = Array.from({ length: max - min + 1 }, (_, i) => i + min);
      this.pickNumbers =[]
    }
  
    public pickOneNumber(): number | null {
      // console.log('RandomNumberPicker2**pick**',this.numbers)
      if (this.numbers.length < 1) {
        this.reset()
        return null;
      }
      if (this.numbers.length < 2) {
        const a = this.numbers[0]
        this.numbers=[]
        //this.pickNumbers.push(a)
        // console.log('RandomNumberPicker2**pick**after',a)
        return a;
      }
  
      const newNumbers= this.numbers
      const randomIndex1 = Math.floor(Math.random() * newNumbers.length);
      const number1 = newNumbers[randomIndex1];
      newNumbers.splice(randomIndex1, 1);
  
      
      // console.log('RandomNumberPicker2**pick**after',number1,this.numbers)
      //this.pickNumbers.push(number1)
      return number1;
    }

    public pickTwoNumbers(): number[] | null {
      // console.log('RandomNumberPicker2**pick**',this.numbers)
      if (this.numbers.length < 1) {
        this.reset()
        return null;
      }
      if (this.numbers.length < 2) {
        const a = this.numbers[0]
        this.numbers=[]
        //this.pickNumbers.push(a)
        return [a];
      }
  
      const newNumbers= this.numbers
      const randomIndex1 = Math.floor(Math.random() * newNumbers.length);
      const number1 = newNumbers[randomIndex1];
      newNumbers.splice(randomIndex1, 1);
  
      const randomIndex2 = Math.floor(Math.random() * (newNumbers.length - 1));
      const number2 = newNumbers[randomIndex2];
      newNumbers.splice(randomIndex2, 1);
      // console.log('RandomNumberPicker2**pick**after',number1,number2, newNumbers,this.numbers)
      //this.pickNumbers.push(number1)
      //this.pickNumbers.push(number2)
      return [number1, number2];
    }

    public remove(i:number){
      console.log('RandomNumberPicker2**remove**',i,this.numbers)
      this.numbers=this.numbers.filter(item => item!=i)
      console.log('RandomNumberPicker2**remove**after',i,this.numbers)
    }

    public reset(){
      this.numbers = Array.from({ length: this.max - this.min + 1 }, (_, i) => i + this.min);
    }

    public resetPickNumbers(){
      this.pickNumbers=[]
    }

    public getPickNumbers(){
      return this.pickNumbers
    }

    public setPickNumbers(nums:number[]){
      return this.pickNumbers = nums
    }

    public setNumbersByPickNumbers(){
      return;
      this.numbers = Array.from({ length: this.max - this.min + 1 }, (_, i) => i + this.min).filter(item=>{
       const targetIndex = this.pickNumbers.findIndex(item2 => item2 === item)
       if(targetIndex > -1) return false
        return true
      })
    }
  }