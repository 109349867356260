import React, { useEffect, useRef, useState } from 'react'

import ContractCard from './ContractCard'
import { ImgContracts1, ImgContracts2 } from '../../images'
import { usePopDownload } from '../../api/contract/hook';
import { CONTRACT_TYPE_ID } from '../../service/constant';
import { AgreementPreview } from '../Dialog'
import { onAddDownloadCount, onContractDetail } from '../../api/contract/service';

//@ts-ignore
import htmlToDocx from 'html-docx-js/dist/html-docx'
import { saveAs } from 'file-saver'
import { gaDownloadContractEvent } from '../../service/ga4Service';
import { useGA4React } from 'ga-4-react';
import { ContractSearchResponseData } from '../../api/contract/response';
import { ContractSearchItemData } from '../../api/contract/response';

const PopularScroll: React.FC = () => {

  const agreementDialogRef = useRef<any>()
  const { data } = usePopDownload();

  useEffect(() => {
    console.log('usePopDownloaddata***', data)

    return () => {

    }
  }, [data])


  // const [content, setContent] = useState('')
  const getDetail= async (contractId:string) => {
    if(!contractId) return;
    const result = await onContractDetail(contractId);
    console.log("onContractDetail**result**", result.data);

    // if (result.success && result.data?.content) {
    //   onDownload(result.data?.title,result.data?.content)
    //   return true;
    // }
    if (result.success && result.data?.docUrl) {
     await handleDownload(result.data?.docUrl,result.data?.title)
      return true;
    }
    return false;
  };



  // const docRef = useRef<HTMLDivElement>(null)

  // useEffect(() => {
  //   content && onDownload();
  
  //   return () => {
      
  //   }
  // }, [content])
  
  const onDownload = (title:string,content:string) => {
    const cssHTML = `
    `;
    const outerHTML =content// docRef.current?.outerHTML
    let contentDocument = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <style>
          ${cssHTML}
        </style>
      </head>
      <body>
        ${outerHTML}
      </body>
    </html>
    `;
    // const contentDocument = '<!DOCTYPE html>' + outerHTML;
    const converted = htmlToDocx.asBlob(contentDocument, { orientation: 'portrait' }) as Blob;
    saveAs(converted, `${title}.docx`);
  }

  const handleDownload = async (fileUrl:string,fileName:string) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // 设置下载文件的名称
      document.body.appendChild(a);
      a.click(); // 触发点击事件以启动下载
      a.remove(); // 移除元素
      window.URL.revokeObjectURL(url); // 释放内存
    } catch (error) {
      console.error('下载文件时发生错误:', error);
    }
  };


  const ga4 =useGA4React()

  const submitGA = (data:any)=>{
    ga4&& gaDownloadContractEvent(ga4,data)
  }

  const onDownloadClick = async(item:ContractSearchItemData )=>{
    submitGA({id:item.id})
    onAddDownloadCount(item.id)
    await getDetail(item.id)
  }
  return <>
    <section className='infinite-scroll-root-container flex flex-col space-y-6 overflow-hidden'>
      <div className='flex flex-row'>
        <ul className='infinite-scroll-container'>
          {data?.list?.map(item => {
            return (<ContractCard key={item.id}
              src={item.type1Id === CONTRACT_TYPE_ID ? ImgContracts1 : ImgContracts2}
              name={item.title}
              desc={item.content}
              onPreviewClick={() => { agreementDialogRef.current?.show(item.id) }}
              onDownloadClick={async()=>{
                onDownloadClick(item)
              }}
            />)
          })}
        </ul>
        <ul className='infinite-scroll-container'>
          {data?.list?.map(item => {
            return (<ContractCard key={item.id}
              src={item.type1Id === CONTRACT_TYPE_ID ? ImgContracts1 : ImgContracts2}
              name={item.title}
              desc={item.content}
              onPreviewClick={() => { agreementDialogRef.current?.show(item.id) }}
              onDownloadClick={async()=>{
                onDownloadClick(item)
              }}
            />)
          })}
        </ul>
      </div>

      <div className='flex flex-row'>
        <ul className='infinite-scroll-container'>
          {data?.list?.map(item => {
            return (<ContractCard key={item.id}
              src={item.type1Id === CONTRACT_TYPE_ID ? ImgContracts1 : ImgContracts2}
              name={item.title}
              desc={item.content}
              onPreviewClick={() => { agreementDialogRef.current?.show(item.id) }}
              onDownloadClick={async()=>{
                onDownloadClick(item)
              }}
            />)
          })}
        </ul>
        <ul className='infinite-scroll-container'>
          {data?.list?.map(item => {
            return (<ContractCard key={item.id}
              src={item.type1Id === CONTRACT_TYPE_ID ? ImgContracts1 : ImgContracts2}
              name={item.title}
              desc={item.content}
              onPreviewClick={() => { agreementDialogRef.current?.show(item.id) }}
              onDownloadClick={async()=>{
                onDownloadClick(item)
              }}
            />)
          })}
        </ul>
      </div>

      {/* <div className='hidden'  ref={docRef}  dangerouslySetInnerHTML={{ __html: content }}>
        </div> */}
    </section>
    <AgreementPreview ref={agreementDialogRef} />
  </>
}

export default PopularScroll