import React from "react";
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom'
import { Trademark as IconTrademark, TrademarkSelected as IconTrademarkSelected, Contract as IconContract, ContractSelected as IconContractSelected } from '../svgr/components'

import { SOLUTIONS, MYTRADEMARK, CONTRACTS_HOME } from '../router/constants'
import { UpgradeVIP } from './VIP'

type Props = {
  to: string,
  icon: React.ReactNode,
  selectedIcon: React.ReactNode,
  mainColor: string,
  children: React.ReactNode
}

const NavItem: React.FC<Props> = ({ to, icon, selectedIcon, mainColor, children }: Props) => {

  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return <NavLink to={to} aria-disabled={!match} className={match?'group flex flex-row':'group flex flex-row'}  >
    {/* {
      match ? (
        <li className={'flex flex-row items-center h-[64px] px-4 rounded-4xl bg-green text-white'}>
          <span className="inline-flex text-2xl mr-2">
            {icon}
          </span>
          <div className="text-base">{children}</div>
        </li>
      ) : (
        <li className={'flex flex-row items-center h-[64px] px-4 rounded-4xl text-[#383838]'}>
          <span className="inline-flex text-2xl mr-2">
            {icon}
          </span>
          <div className="text-base">{children}</div>
        </li> 
      )
    } */}
    <span
      className='w-1 h-[86px] rounded-r-full'
      style={{
        background: match ? mainColor : 'transparent'
      }}
    ></span>
    <div className='flex flex-col flex-1 items-center space-y-1'>
      <span className='text-[60px]'>
        {match ? selectedIcon : icon}
      </span>
      <span className={match?'text-[#282828] text-base group-aria-disabled:text-[#9D9FA1]':'text-[#282828] text-base group-aria-disabled:text-[#9D9FA1]'}>{children}</span>
    </div>
  </NavLink>
}


const Navbar: React.FC = () => {

  // return <section className="flex flex-col justify-between w-[280px] px-2 py-4 mt-4 ml-1 mr-8 bg-white rounded-t-[32px]">
  //   <div className="flex flex-col">
  //     <span className="py-1.5 px-4 text-xs leading-5 text-[#BDBDBD]">Search & Opinion</span>
  //     <ul className="flex flex-col">
  //       <NavItem to={SOLUTIONS} icon={<IconPaper />}>Solutions</NavItem>
  //       <NavItem to={MYTRADEMARK} icon={<IconBookmark />}>My Trademark</NavItem>
  //     </ul>
  //   </div>
  //   <UpgradeVIP />
  // </section>

  return <section className='absolute left-1 top-0 bottom-0 flex flex-col justify-center z-50'>
    <div className='flex flex-col w-[116px] py-8 bg-white rounded-2xl space-y-12 shadow-5xl'>
    <NavItem
        to={CONTRACTS_HOME}
        icon={<IconContract />}
        selectedIcon={<IconContractSelected />}
        mainColor="#0A65FF"
      >Contract</NavItem>
      <NavItem
        to={SOLUTIONS}
        icon={<IconTrademark />}
        selectedIcon={<IconTrademarkSelected/>}
        mainColor="#1BA4A4"
      >
        Trademark
        </NavItem>
      
    </div>
  </section>
}

export default Navbar