import { AuthInfoData } from "../../redux/presist/auth-local-data";
import { UserInfoLocalData } from "../../redux/local/userinfo-local-data";
import { UserInfoData } from "../../api/user/response";



const buildUserInfoCacheData = (data: UserInfoData) => {
  const result: UserInfoLocalData = {
   ...data,
   uid:data.id,
   header_image:data.avatar,
   nickname:data.nickname,
   username:data.nickname,
   musicToken:undefined
  }
  return result;
}




const buildAuthInfoCacheData = (data: any) => {

  const result: AuthInfoData =  {...data,canAuth:true,userId: '',refreshToken:''};
  // result.avatar= resUrl+data.avatar;
  return result;
}


const initialAuthInfoCacheData: AuthInfoData = {
  accessToken: undefined,
  tokenType: undefined,
  refreshToken: undefined,
  userId: undefined,
  businessId: undefined,
  oauthId: undefined,
  avatar: undefined,
  authority: undefined,
  username: undefined,
  nickName: undefined,
  expiresIn: undefined,
  canAuth:false,
  sender:'fairip-web'
};



export {
  buildUserInfoCacheData,
  buildAuthInfoCacheData,
  initialAuthInfoCacheData, 
};
