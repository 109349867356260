import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Header, Loading, NoDateFound } from "../components";
import { ClausesAgreement, ContractAgreement } from "../components/Contract";
import { RankSelect } from "../components/Select";
import {
  Search as IconSearch,
  AngleRight as IconArrowLeft,
  Copy2 as IconCopy,
} from "../svgr/components";
import { AGREEMENT_DOWNLOAD } from "../router/constants";
import { DEFAULT_ARCTICLE_SEARCH_COUNT } from "../service/constant";
import {
  ClauseSearchItemData,
  ClauseSearchResponseData,
  ContractSearchItemData,
  ContractSearchResponseData,
} from "../api/contract/response";
import { onClauseSearch, onContractSearch } from "../api/contract/service";
import copy from "copy-to-clipboard";
import { filterHTML } from "../utils/string";
import { TipCopied } from "../components/Tips";
import toast from "react-hot-toast";
import useStateRef from "react-usestateref";

type Search4RequestData = {
  pageNo: number;
  pageSize: number;
  query?: string;
  type?: string;
};

const initSearchData: Search4RequestData = {
  pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
  pageNo: 1,
};



const Agreement: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { type } = params;

  useEffect(() => {
    console.log("Agreement**", type);
    if (type !== "clauses" && type !== "contracts") {
      navigate(-1);
    } else {
      // setSearchData(s=>{return {...s,type:type}});
    }
  }, [type]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");
  // const [contractId, setContractId] = useState("");

  const [searchData, setSearchData,searchDataRef] = useStateRef<Search4RequestData>({
    ...initSearchData,
  });



  useEffect(() => {
    if (searchParams && searchParams.size > 0) {
      const n = searchParams.get("n");
      n && setName(n);
      // w && searchParams.delete('q')

      const id = searchParams.get("id");
      // id && setContractId(id);

      if (id) {
        setSearchData((s) => {
          return { ...s, type: id };
        });
        doSearch({
          ...searchData,
          type: id,
        });
      }
    }

    return () => {};
  }, [searchParams]);

  // useEffect(() => {
  //   doSearch(searchData);

  //   return () => { };
  // }, [contractId]);

  const [keyword, setKeyword] = useState<string>("");

  const [hasSearchResult, setHasSearchResult] = useState<boolean>();

  const doSearch = async (searchData: Search4RequestData) => {
    setLoading(true);
    const s = searchData;
    console.log("doSearch**s**", s);
    // if(!s.query) return
    let result = false;
    (await Promise.all([getNewSearchResultList(s)]))?.map((item) => {
      result = result || item;
    });
    console.log("doSearch**result**", result);
    setHasSearchResult(result);
    setLoading(false);
  };

  const [contractResultList, setContractResultList,contractResultListRef] = useStateRef<
    ContractSearchResponseData | undefined
  >();

  // const [contractUIResultList, setContractUIResultList] = useState<
  //   ContractSearchResponseData|undefined
  // >();

  const [clasueResultList, setClasueResultList,clasueResultListRef] = useStateRef<
    ClauseSearchResponseData | undefined
  >();

  // const [clasueUIResultList, setClasueUIResultList] = useState<
  //   ClauseSearchResponseData|undefined
  // >();

  const getNewSearchResultList = async (searchData: Search4RequestData) => {
    const s = searchData;
    console.log("getNextSearchResultList**s**", s, "type**", type);
    if (type === "contracts") {
      setContractResultList(undefined);
      const result = await onContractSearch({
        pageNo: 1,
        pageSize: s.pageSize,
        query: s.query || "",
        typeId: s.type,
      });
      console.log("onContractSearch**result**",s, result.data);

      if (result.success && result.data && result.data?.total > 0) {
        result.data?.list && setContractResultList(result.data);
        // result.data?.list && setContractUIResultList(result.data);

        // setHasSearchResult(true)
        return true;
      }
      return false;
    } else {
      setClasueResultList(undefined);
      const result = await onClauseSearch({
        pageNo: 1,
        pageSize: s.pageSize,
        query: s.query || "",
        typeId: s.type,
      });
      console.log("onClauseSearch**result**", result.data);

      if (result.success && result.data && result.data?.total > 0) {
        result.data?.list && setClasueResultList(result.data);

        // result.data?.list && setClasueResultList({list:[...result.data?.list,...result.data?.list,...result.data?.list],total:3});

        return true;
      }
      return false;
    }

    return false;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setSearchData((s) => {
        return {
          ...s,
          pageNo:1,
          query: keyword,
        };
      });
      doSearch({ ...searchData, pageNo:1, query: keyword });
    }
  };

  const listRef = useRef(document.getElementById("root") as HTMLElement);
  // const listRef =ref as  React.LegacyRef<HTMLElement>
  const [loading, setLoading, loadingRef] = useStateRef<boolean>(false);
  const [hasMore, setHasMore, hasMoreRef] = useStateRef<boolean>(false);

  useEffect(() => {
    console.log("hasMore***", hasMore);

    return () => {};
  }, [hasMore]);

  //判断当前情况是否可以下拉加载
  useEffect(() => {
    console.log(
      "是否可以下拉加载***setHasMore**",
      contractResultList,
      clasueResultList,
      type
    );
    if (type === "contracts") {
      if (contractResultList === undefined) {
        setHasMore(false);
        return;
      }
      if (contractResultList.total > contractResultList.list.length) {
        setHasMore(true);
      } else setHasMore(false);
    } else {
      if (clasueResultList === undefined) {
        setHasMore(false);
        return;
      }
      if (clasueResultList.total > clasueResultList.list.length) {
        setHasMore(true);
      } else setHasMore(false);
    }

    return () => {};
  }, [clasueResultList, contractResultList, type]);

  useEffect(() => {
    //   // 添加滚动事件监听器
    const handleScroll = () => {
      // console.log("handleScroll***");

      if (listRef?.current) {
        const { scrollTop, scrollHeight, clientHeight } = (listRef as any)
          .current;
        //检查是否滚动到底部
        console.log(
          "handleScroll***sss",
          scrollTop + clientHeight >= scrollHeight - 5,
          !loadingRef.current,
          hasMoreRef.current,
          scrollTop,
          scrollHeight,
          clientHeight
        );
        if (
          scrollTop + clientHeight >= scrollHeight - 5 &&
          !loadingRef.current &&
          hasMoreRef.current
        ) {
          console.log("handleScroll***loadMoreData");
          loadMoreData();
        }
      }
    };

    listRef?.current?.addEventListener("scroll", handleScroll);

    return () => {
      listRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const loadMoreData = async () => {
    setLoading(true);

    const s: Search4RequestData = {
      ...searchDataRef.current,
      pageNo: searchDataRef.current.pageNo + 1,
    };
    // if(!s.query) return
    let result = false;
    (await Promise.all([getNextSearchResultList(s)]))?.map((item) => {
      result = result || item;
    });
    console.log("loadMoreData**result**",s, result);
    setHasSearchResult(result);
    setLoading(false);
  };

  const getNextSearchResultList = async (searchData: Search4RequestData) => {
    const s = searchData;
    console.log("getNextSearchResultList**s**", s, "t***", type);

    if (type === "contracts") {
      const result = await onContractSearch({
        pageNo: s.pageNo,
        pageSize: s.pageSize,
        query: s.query || "",
        typeId: s.type,
      });
      console.log("getNextSearchResultList***onContractSearch**result**",s, result.data);

      if (result.success && result.data && result.data?.total > 0) {
        if (result.data?.list) {
          if (contractResultListRef.current?.list) {
            setContractResultList({
              list: [...contractResultListRef.current?.list, ...result.data?.list],
              total: result.data?.total,
            });
          } else setContractResultList(result.data);
        }

        // setHasSearchResult(true)
        setSearchData({...s});
        return true;
      }
      return false;
    } else {
      const result = await onClauseSearch({
        pageNo: s.pageNo,
        pageSize: s.pageSize,
        query: s.query || "",
        typeId: s.type,
      });
      console.log("getNextSearchResultList***onClauseSearch**result**",s, result.data);

      if (result.success && result.data && result.data?.total > 0) {
        // result.data?.list && setClasueResultList(result.data);
          if (clasueResultListRef.current?.list) {
            setClasueResultList({
              list: [...clasueResultListRef.current?.list, ...result.data?.list],
              total: result.data?.total,
            });
          } else setClasueResultList(result.data);
          setSearchData({...s});
        return true;
      }
      return false;
    }

    return false;
  };

  const renderLoading = () => {
    return (
      <div className="flex-grow flex items-center justify-center">
        <Loading></Loading>
      </div>
    );
  };


  return (
    <section className="relative flex flex-col flex-1 h-full bg-white">
      <Header type="default" />
      <button
        className="inline-flex flex-row items-center mx-20 space-x-2"
        onClick={() => {
          navigate(-1);
        }}
      >
        <span className="text-xl rotate-180">
          <IconArrowLeft />
        </span>
        <span className="text-base font-semibold text-[#111] capitalize">
          {type}
        </span>
      </button>
      <div className="flex flex-row justify-between mx-20 my-6">
        <h6 className="text-5.5xl font-semibold text-black">{name}</h6>
        <div className="flex flex-row items-center w-[400px] h-[54px] px-[28px] border border-[#ddd] rounded-full">
          <button className="inline-flex text-[28px]">
            <IconSearch />
          </button>
          <input
            className="flex-1 ml-3.5 bg-transparent text-base text-[#111] outline-none placeholder:text-[#9D9FA1]"
            placeholder="Search "
            value={keyword}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
        </div>
      </div>
      {type === "clauses" && loading && !clasueResultListRef.current?.list && renderLoading()}
      {type === "contracts" && loading && !contractResultListRef.current?.list && renderLoading()}
      {<ul className="flex flex-col px-20 space-y-4">
        {type === "clauses" && (
          <>
             {/* className="mb-8" */}
            {clasueResultList?.list?.map((item) => {
              return (
                <ClausesAgreement
                  key={item.id}
                  onCopyClick={() => {
                    toast.custom((t) => <TipCopied />, {
                      duration: 500,
                    });
                    copy(filterHTML(item.content));
                  }}
                >
                  {/* {item.content} */}
                  <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                </ClausesAgreement>
              );
            })}
            {clasueResultListRef.current?.list && !loading && <div className="mb-8"></div>}
            {clasueResultListRef.current?.list && loading && renderLoading()}
          </>
        )}
        {type === "contracts" && (
          <>
            {contractResultList?.list?.map((item) => {
              return (
                <ContractAgreement
                  key={item.id}
                  type={item.typeName}
                  filed={item.filedDate}
                  jurisdiction={item.jurisdiction}
                  onClick={() => {
                    // const url = `${AGREEMENT_DOWNLOAD}/xxxx`;
                    // navigate(url);

                    const url = `${AGREEMENT_DOWNLOAD}/${item.id}?n=${name}`;
                    navigate(url);
                  }}
                >
                  {item.title}
                </ContractAgreement>
              );
            })}
            {contractResultListRef.current?.list && !loading && <div className="mb-8"></div>}
            {contractResultListRef.current?.list && loading && renderLoading()}
          </>
        )}
        {hasSearchResult === false && <NoDateFound />}
      </ul>}
    </section>
  );
};

export default Agreement;
