import moment, { months } from "moment";

const formatNowTime = () => {
  return moment().format('MMM DD,YYYY');
}

const formatTime = (time: string | undefined, format?: string) => {
  console.log(' ----- format time ----- ', time)
  if (!time) {
    return ''
  }
  return moment(time).format(format || 'MM/DD/YYYY')
}

const compareTime = (time1: number, time2: number) => {
  return time1 >= time2
}

//**使用毫秒时间戳比较,返回true，证明时间比现在晚或者一样
const compareMsTimestampWithNow = (time: number) => {
  const now = moment().valueOf()
  return compareTime(time, now)
}

///使用秒级时间戳比较，返回true，证明时间比现在晚或者一样
const compareSecTimestampWithNow = (time: number) => {
  const now = moment().unix()
  return compareTime(time, now)
}


const formatSecond = (second: number) => {
  var d = moment.duration(second, 'seconds');
  const day = Math.floor(d.asDays())
  const hours = d.hours()
  const minutes = d.minutes()
  const seconds = d.seconds()
  let time = ''
  if (day > 0) {
    // time += day + ' day '
    time += day > 1 ? (day + ' days ') : (day + ' day ')
  }
  if (hours > 0) {
    // time += hours + ' hrs '
    time += hours > 1 ? (hours + ' hrs ') : (hours + ' hr ')
  }
  if (time === '') {
    if (minutes > 0) {
      // time += minutes + ' mins '
      time += minutes > 1 ? (minutes + ' mins ') : (minutes + ' min ')
    }
    if (seconds > 0) {
      // time += seconds + ' secs'
      time += seconds > 1 ? (seconds + ' secs') : (seconds + ' sec')
    }
  }
  if (time === '') {
    time = '--'
  }
  //  Math.floor(d.asDays()) + ' day ' + d.hours() + ' hrs ' + d.minutes() + ' mins ' + d.seconds() + ' secs';
  return time
}

const formatSecond2Time = (second: number, ignoreSecond: boolean = true) => {
  const d = moment.duration(second, 'seconds');
  const day = Math.floor(d.asDays())
  const hours = d.hours()
  const minutes = d.minutes()
  const seconds = d.seconds()
  const result = []
  if (day > 0) {
    result.push({
      time: day,
      unit: day > 1 ? 'days' : 'day'
    })
  }
  if (hours > 0) {
    result.push({
      time: hours,
      unit: hours > 1 ? 'hrs' : 'hr'
    })
  }
  if (minutes > 0) {
    result.push({
      time: minutes,
      unit: minutes > 1 ? 'mins' : 'min'
    })
  }
  if (!ignoreSecond && seconds > 0) {
    result.push({
      time: seconds,
      unit: seconds > 1 ? 'secs' : 'sec'
    })
  }
  //0s特殊处理
  if(result.length ===0){
    result.push({
      time: 0,
      unit: seconds > 1 ? 'secs' : 'sec'
    })
  }
  return result
}



const getCalculateDay = () => {
  const now = moment().utc()
  const calculate = moment().utc().endOf('week')
  const day = calculate.diff(now, 'day')
  // console.log('@@@@ -- getCalculateDay now -- ', now.format('yyyy-MM-DD HH:mm:ss'))
  // console.log('@@@@ -- getCalculateDay calculate -- ', calculate.format('yyyy-MM-DD HH:mm:ss'))
  // console.log('@@@@ -- getCalculateDay day -- ', day)
  return day
}

const getCalculateSencondsFormat = () => {
  const now = moment().utc()
  const calculate = moment().utc().endOf('week')
  // const day = calculate.diff(now, 'day')
  const second = calculate.diff(now, 'second')

  const d = moment.duration(second, 'seconds');
  const day = Math.floor(d.asDays())
  const hours = d.hours()
  const minutes = d.minutes()
  const seconds = d.seconds()

  // const dayStr = day > 0 ? `${day >= 10 ? day : `0${day}`}${day > 1 ? ' Days ' : ' Day '}` : ''
  // const hoursStr = `${hours >= 10 ? hours : `0${hours}`}${hours > 1 ? ' Hours ' : ' Hour '}`
  // const minutesStr = `${minutes >= 10 ? minutes : `0${minutes}`}${minutes > 1 ? ' Minutes ' : ' Minute '}`
  // const secondsStr = `${seconds >= 10 ? seconds : `0${seconds}`}${seconds > 1 ? ' Seconds' : ' Second'}`

  const dayStr = day >= 10 ? `${day}` : `0${day}`
  const hoursStr = hours >= 10 ? `${hours}` : `0${hours}`
  const minutesStr = minutes >= 10 ? `${minutes}` : `0${minutes}`
  const secondsStr = seconds >= 10 ? `${seconds}` : `0${seconds}`

  // const value = dayStr + hoursStr + minutesStr + secondsStr
  // console.log("getCalculateSencondsFormat --- ", value)
  
  // console.log('@@@@ -- getCalculateSencondsFormat second -- ',  dayStr,
  // hoursStr,
  // minutesStr,
  // secondsStr)
  // return value
  return [
    dayStr,
    hoursStr,
    minutesStr,
    secondsStr
  ]
}

const getCountdownFormat = (time:string) => {
  const now = moment().utc()
  const calculate = moment(time).utc()
  // const day = calculate.diff(now, 'day')
  const second = calculate.diff(now, 'second')

  const d = moment.duration(second, 'seconds');
  const day = Math.floor(d.asDays())
  const hours = d.hours()
  const minutes = d.minutes()
  const seconds = d.seconds()

  const dayStr = day >= 10 ? `${day}` : `0${day}`
  const hoursStr = hours >= 10 ? `${hours}` : `0${hours}`
  const minutesStr = minutes >= 10 ? `${minutes}` : `0${minutes}`
  const secondsStr = seconds >= 10 ? `${seconds}` : `0${seconds}`

  return [
    dayStr,
    hoursStr,
    minutesStr,
    secondsStr
  ]
}

const getTimesFromSec = (second:number) => {

  const d = moment.duration(second, 'seconds');
  const day = Math.floor(d.asDays())
  const hours = d.hours()
  const minutes = d.minutes()
  const seconds = d.seconds()

  // const dayStr = day > 0 ? `${day >= 10 ? day : `0${day}`}${day > 1 ? ' Days ' : ' Day '}` : ''
  // const hoursStr = `${hours >= 10 ? hours : `0${hours}`}${hours > 1 ? ' Hours ' : ' Hour '}`
  // const minutesStr = `${minutes >= 10 ? minutes : `0${minutes}`}${minutes > 1 ? ' Minutes ' : ' Minute '}`
  // const secondsStr = `${seconds >= 10 ? seconds : `0${seconds}`}${seconds > 1 ? ' Seconds' : ' Second'}`

  const dayStr = day >= 10 ? `${day}` : `0${day}`
  const hoursStr = hours >= 10 ? `${hours}` : `0${hours}`
  const minutesStr = minutes >= 10 ? `${minutes}` : `0${minutes}`
  const secondsStr = seconds >= 10 ? `${seconds}` : `0${seconds}`

  // const value = dayStr + hoursStr + minutesStr + secondsStr
  // console.log("getCalculateSencondsFormat --- ", value)
  
  // console.log('@@@@ -- getCalculateSencondsFormat second -- ',  dayStr,
  // hoursStr,
  // minutesStr,
  // secondsStr)
  // return value
  return [
    dayStr,
    hoursStr,
    minutesStr,
    secondsStr
  ]
}

const getHoursFromSec = (second:number) => {

  const d = moment.duration(second, 'seconds');

  const allHours = d.asHours()
  // console.log('getHoursFromSec**allHours**',allHours)
  return allHours
}

const getDaysBeforeNow = (time:string) => {
  const now = moment().utc()
  const calculate = moment(time).utc()
  // const day = calculate.diff(now, 'day')
  const second = now.diff(calculate, 'second')

  const d = moment.duration(second, 'seconds');
  const day = d.asDays()

  return day
}

const getTimeArrayFromSec = (num:number)=>{
  if(num === undefined) return [];
  const ct = moment.unix(num).utc()
  var time = ct.format("HH:mm:ss");
  return time.split(':')
}

const getMsTimeStamp= ()=>{
  const now = moment().valueOf()
  return now
}


export {
  formatNowTime,
  formatTime,
  compareTime,
  compareMsTimestampWithNow,
  compareSecTimestampWithNow,
  formatSecond,
  formatSecond2Time,
  getCalculateDay,
  getCalculateSencondsFormat,
  getCountdownFormat,
  getTimesFromSec,
  getHoursFromSec,
  getDaysBeforeNow,
  getTimeArrayFromSec,
  getMsTimeStamp
}