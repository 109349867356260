import { get, longPost, post, put, upload } from "../request";
import * as api from "./index";

export const login = (userName: string, pwd: string) => {
  const raw = {
    username: userName,
    password: pwd,
  };
  const url = `${api.login}`;
  return post(url, raw);
};

export interface SignInRequestData {
  companyName: string;
  email: string;
  password: string;
  username: string;
  verifyCode: string;
}

export const signUp = (data: SignInRequestData) => {
  const url = `${api.signup}`;
  const raw = data;
  return post(url, raw);
};

export interface ResetPwdRequestData {
  email: string;
  newPassword: string;
  verifyCode: string;
}

export const resetPwd = (data: ResetPwdRequestData) => {
  const raw = data;
  const url = `${api.resetPwd}`;
  return post(url, raw);
};

export interface ResetPwdWithOldPwdRequestData {
  originPassword: string;
  newPassword: string;
}

export const resetPwdWithOldPwd = (data: ResetPwdWithOldPwdRequestData) => {
  const raw = data;
  const url = `${api.resetPwdWithOldPwd}`;
  return post(url, raw);
};

export const getUserInfo = () => {
  const url = `${api.userInfo}`;
  return get(url);
};

export interface UpdateUserInfoRequestData {
  nickname: string;
  avatar: string;
  companyName: string;
}

export const updateUserInfo = (data: UpdateUserInfoRequestData) => {
  const raw = data;
  const url = `${api.updateUserInfo}`;
  return post(url, raw);
};

export type EmailType = "CHANGE_PASSWORD" | "REGISTER";
export interface SendCodeRequestData {
  email: string;
  vt: EmailType;
}

export const sendCode = (data: SendCodeRequestData) => {
  const raw = data;
  const url = `${api.sendCode}`;
  return post(url, raw);
};

export interface CheckCodeRequestData {
  email: string;
  code: string;
  vt: EmailType;
}

export const checkCode = (data: CheckCodeRequestData) => {
  const raw = data;
  const url = `${api.checkCode}`;
  return post(url, raw);
};
