import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClearNavigate } from "../utils/router";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import {
  persistActions,
  persistSelectors,
  sharedActions,
  sharedSelectors,
} from "../redux";
import { authLocalData, persistSettingsLocalData } from "../redux/presist";
import { AuthInfoData } from "../redux/presist/auth-local-data";
import { login } from "../api/user/app";
import { LogInResponseData } from "../api/user/response";
import { buildAuthInfoCacheData, initialAuthInfoCacheData } from "../service/user";
import { removeAuth, setAuth } from "../api/request";
import { MD5 } from "../utils/saltMD5";
import { LOGIN } from "../router/constants";

const useLogin = () => {
  const dispatch = useDispatch();
  const clearNavigate = useClearNavigate();

  const updateAuthInfoCacheData = (data: AuthInfoData) =>
    dispatch(persistActions.local.authLocalData.update(data));

  const resetAuthInfoCacheData = () =>
    dispatch(persistActions.local.authLocalData.reset());

    const resetTrademarkListInfoCacheData = () =>
  dispatch(
    persistActions.local.trademarkListLocalData.reset()
  );

  const timerRef = useRef<number>();
  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  const onLogin = async (username: string, password: string,alreadyMD5=false) => {
    console.log("press login**");
    // const result = await logIn(username, MD5(password));
    const md5Pwd = alreadyMD5 ?password:MD5(password)
    const result = (await login(username, md5Pwd))?.data;
    const data: LogInResponseData = result;
    console.log("login**", data);
    if (data?.success ) {
      //登陆成功
      //更新auth信息
      const loginData = buildAuthInfoCacheData({
        accessToken: data.data.accessToken,
        sender: 'fairip-web',
      });
      resetTrademarkListInfoCacheData();
      // const jsonStr = JSON.stringify(loginData);
      // const encodeStr = encode(jsonStr);
      // if (authLocalData != encodeStr) { updateAuthInfoCacheData(loginData); setAuthLocalData(encodeStr); }
      updateAuthInfoCacheData(loginData);
      console.log("loginData***", loginData);

      setAuth(data.data.accessToken);
      //发送给插件，同步token
      // const msg: AuthMessageData = {
      //   accessToken: data.token,
      //   canAuth: true,
      //   sender: "effective-web",
      //   key: process.env.REACT_APP_DOMAIN_NAME || "",
      //   action: ActionType.Login,
      // };
      // console.error("发送消息给plugin****msg****", msg);
      // window.postMessage(JSON.stringify(msg), document.URL);
      return { success: true, error: undefined };
    } else {
      //登陆失败
      // setPageData({ ...pageData, errorMessage: data.msg });
      return {
        error: data.msg,
        success: false,
      };
    }
  };

  const authInfoCacheData = useSelector<AppState, authLocalData.Selectors>(
    ({ persist }) =>
      persistSelectors.local.authLocalData(persist.local.authLocalData, {})
  );

  const [logouting, setLogouting] = useState(false);

  useEffect(() => {
    if (authInfoCacheData?.getData?.canAuth === false && logouting) {
      setLogouting(false);
      console.log("登出重定向2");
      window.location.href = window.location.origin+LOGIN;
      // timerRef.current && clearTimeout(timerRef.current);
      // timerRef.current = window.setTimeout(() => {
      //   console.log("登出重定向3");
      //   window.location.href = window.location.origin;
      // }, 0.1 * 1000);
    }

    return () => {

    };
  }, [authInfoCacheData?.getData?.canAuth, logouting]);

  const onLogout = async (redirect = true) => {
    if(redirect) setLogouting(true);
    // //发送给插件，同步token
    // const msg: AuthMessageData = {
    //   accessToken: undefined,
    //   canAuth: false,
    //   sender: "effective-web",
    //   key: process.env.REACT_APP_DOMAIN_NAME || "",
    //   action:ActionType.Logout
    // };
    // console.error("web发送logout消息给plugin**", msg);
    // // console.error("发送消息给plugin****msg****", msg);
    // window.postMessage(JSON.stringify(msg), document.URL);
    console.log("onLogout**");
    resetTrademarkListInfoCacheData();
    removeAuth();
    // setAuthLocalData("");
    // resetAuthInfoCacheData();
    updateAuthInfoCacheData({ ...initialAuthInfoCacheData }); //, canAuth: false
    clearNavigate();
    
    // if (redirect) {
    //   console.log('登出重定向0')
    //   // window.location.href = window.location.origin;

    //   timerRef.current = window.setTimeout(() => {
    //     console.log('登出重定向1')
    //     window.location.href = window.location.origin;
    //   }, 0.1 * 1000);
    // }
    console.log("**onLogout**success**");
    return;
  };

  return { onLogout, onLogin };
};

export default useLogin;
