import * as React from "react";
import type { SVGProps } from "react";
const SvgCopy2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#copy2_svg__a)">
      <path
        fill="currentColor"
        d="M8.283.833a1 1 0 0 0-.225.05h-.075a.9.9 0 0 0-.233.159l-5 5a.9.9 0 0 0-.158.233v.075a.7.7 0 0 0-.092.275v6.708a2.5 2.5 0 0 0 2.5 2.5h.833v.834a2.5 2.5 0 0 0 2.5 2.5H15a2.5 2.5 0 0 0 2.5-2.5v-10a2.5 2.5 0 0 0-2.5-2.5h-.833v-.834a2.5 2.5 0 0 0-2.5-2.5H8.283m-2.941 5L7.5 3.675V5a.833.833 0 0 1-.833.833zm9.658 0a.833.833 0 0 1 .833.834v10A.834.834 0 0 1 15 17.5H8.333a.833.833 0 0 1-.833-.833v-.834h4.167a2.5 2.5 0 0 0 2.5-2.5v-7.5zM11.667 2.5a.833.833 0 0 1 .833.833v10a.833.833 0 0 1-.833.834H5a.833.833 0 0 1-.833-.834V7.5h2.5a2.5 2.5 0 0 0 2.5-2.5V2.5z"
      />
    </g>
    <defs>
      <clipPath id="copy2_svg__a">
        <path fill="#fff" d="M0 20V0h20v20z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCopy2;
