import { combineReducers } from 'redux';
// import * as authLocalData from '../presist/auth-local-data';
import * as userInfoLocalData from './userinfo-local-data';
import * as chatlistLocalData from './chatlist-local-data';



const combinedReducer = combineReducers({
  userInfoLocalData: userInfoLocalData.reducer(),
  chatlistLocalData: chatlistLocalData.reducer(),
  
});

const actions = {
  userInfoLocalData: userInfoLocalData.actions,
  chatlistLocalData: chatlistLocalData.actions,
 
};

const selectors = {
  userInfoLocalData: userInfoLocalData.select,
  chatlistLocalData: chatlistLocalData.select,
};

export {
  actions,
  selectors,
  combinedReducer,
  userInfoLocalData,
  chatlistLocalData
  // authLocalData,

};
