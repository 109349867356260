import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'

import { ButtonRound } from "../Button";
import { CloseDialog } from "../../svgr/components";
import { ImgReportPrinting, ImgFairIP } from "../../images";
import { SummaryTableItemData } from "../Chat/chatdatatype";
import { MarkInfoData } from "../../api/ai/response";
import htmlToPdf from "../../utils/pdf";
import { Download as IconDownload } from "../../svgr/components";
import TrademarkDetailReportPdf from "./TrademarkDetailReportPdf";

type Props = {};

const TrademarkDetailReport = forwardRef(({ }: Props, ref) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const [data, setData] = useState<SummaryTableItemData>();
  const show = (data: SummaryTableItemData) => {
    dialogRef.current?.showModal();
    setData(data);
    console.log("goodsDetail**setData**", data);
  };

  const hide = () => {
    dialogRef.current?.close();
  };

  const timerRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  const download = async () => {
    setDownloading(true)
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      const suc = () => {
        console.log("success");
        setDownloading(false)
      };
      let dom = document.getElementById("trademark-search-opinon-report");
      let pdf = new htmlToPdf(
        dom,
        "trademark-search-opinon-report",
        "splitPages",
        "breakPages",
        suc,
        2
      );
      pdf.outPutPdfFn();
    }, 0.2 * 1000);
  };

  useEffect(() => {
    data && buildState(data);

    return () => { };
  }, [data]);

  const [detail, setDetail] = useState<MarkInfoData>();
  const [goodsDetail, setGoodsDetail] = useState("");
  const [firstUse, setFirstUse] = useState("");
  const [use_in_commerce, setUse_in_commerce] = useState("");

  const buildState = (data: SummaryTableItemData) => {
    // console.log('goodsDetail**data?.details**',data?.details)
    const detail: MarkInfoData = data.details;
    setDetail(detail);
    // setOwnerName(detail.owner_name+',' +detail.owner_address)
    // setOwnerName(detail.owner_name+',' +detail.owner_address)
    const item = detail?.goods_and_services;
    //  console.log('goodsDetail**item**',item)
    if (!item) return;
    const json = JSON.parse(item);
    console.log("goodsDetail**json**", json);
    const type = json && json.length > 0 ? Object.keys(json[0])[0] : "";
    if (type) {
      const result = json[0][type].goods_and_services_for;
      setGoodsDetail(result || "");
      setFirstUse(json[0][type].first_use);
      setUse_in_commerce(json[0][type].use_in_commerce);
    }
  };

  const [downloading, setDownloading] = useState(false);

  return (
    <dialog
      className="fixed inset-0 w-screen h-screen bg-transparent z-[99] backdrop:bg-[#000000CC] notranslate"
      ref={dialogRef}
    >
      <section className="flex flex-row w-full h-full" onClick={hide} >
        <div
          className="relative flex flex-col self-center mx-auto h-[90%]"
          onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
          }}
          id='trademark-search-opinon-report'
        >
          <ButtonRound
            className="absolute top-0 right-[-64px] !bg-[#F2F2F280]"
            onClick={hide}
          >
            <CloseDialog color="#fff" />
          </ButtonRound>
          {/* <div className="relative text-black font-timeRoman"> */}

          <div className="relative flex flex-col items-center w-[612px] h-full p-[8px] bg-white overflow-y-auto overflow-x-hidden font-timeRoman">
            <div className="absolute top-[8px] left-[8px] right-[8px]">
              <span className="absolute left-0">
                <img className="size-[44px]" src={ImgReportPrinting} />
              </span>
              <span className="absolute right-0">
                <img
                  className="rotate-90 size-[44px]"
                  src={ImgReportPrinting}
                />
              </span>
            </div>
            <div className="flex flex-col w-[595px]">
              <div className="relative flex flex-col pt-[88px]">
                <div className="flex flex-col mx-auto w-[420px] space-y-6 text-[#282828]">
                  <h6 className="text-xl font-bold leading-8">
                    Trademark Search and Opinion Report
                  </h6>
                  <div className="border-b border-[#D9D9D9]"></div>
                  <div className="flex flex-col space-y-4">
                    <div className="flex flex-row text-xs leading-[18px] mt-2">
                      <div className="w-[128px] font-bold text-[#1BA4A4]">
                        Status:
                      </div>
                      <div className="flex flex-col max-w-[291px] space-y-2">
                        <div>{data?.status?.toUpperCase()} </div>
                        <ol className="flex flex-col max-w-[291px] space-y-1">
                          <li>
                            <span className="font-bold">USPTo Status: </span>
                            <span>{data?.status?.toUpperCase()}</span>
                          </li>
                          <li>
                            <span className="font-bold">
                              USPTo Status Date:{" "}
                            </span>
                            <span>{detail?.status_date}</span>
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div className="flex flex-row text-xs leading-[18px]">
                      <div className="w-[128px] font-bold text-[#1BA4A4]">
                        Goods/Services:
                      </div>
                      <ol className="flex flex-col max-w-[291px] space-y-1">
                        <li>
                          <span className="font-bold">
                            International Class {data?.class}:{" "}
                          </span>
                          <span>{goodsDetail?.toUpperCase()}</span>
                        </li>
                        <li>
                          <span className="font-bold">First Used: </span>
                          {/* <span>APR 08, 2008 (INTL. CL. 35)</span> */}
                          <span>{firstUse?.toUpperCase()}</span>
                        </li>
                        <li>
                          <span className="font-bold">In Commerce: </span>
                          {/* <span>APR 08, 2008</span> */}
                          <span>{use_in_commerce?.toUpperCase()}</span>
                        </li>
                      </ol>
                    </div>
                    <div className="flex flex-row text-xs leading-[18px]">
                      <div className="w-[128px] font-bold text-[#1BA4A4]">
                        Last Reported Owner:{" "}
                      </div>
                      <ol className="flex flex-col max-w-[291px] space-y-1">
                        <li>
                          <span>{detail?.owner_name?.toUpperCase()}</span>
                        </li>
                        <li>
                          <span>{detail?.owner_address?.toUpperCase()}</span>
                        </li>
                        {/* <li>
                    <span>150 SPEAR STREET SUITE 600</span>
                  </li>
                  <li>
                    <span>SAN FRANCISCO, CALIFORNIA 94105</span>
                  </li> */}
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[595px]">
              <div className="relative flex flex-col">
                <div className="flex flex-col mx-auto w-[420px] text-[#282828]">
                  <div className="flex flex-col space-y-4">
                    <div className="flex flex-row text-xs leading-[18px] breakPages">
                      <div className="w-[128px] font-bold text-[#1BA4A4]">
                        Chronology:
                      </div>
                      <div className="flex flex-col space-y-2">
                        <ol className="flex flex-col max-w-[291px] space-y-1">
                          <li>
                            <span className="font-bold">Filed: </span>
                            <span>
                              {detail?.application_filing_date?.toUpperCase()}
                            </span>
                          </li>
                          <li>
                            <span className="font-bold">Serial Number: </span>
                            {/* <span>77-427.671</span> */}
                            <span>{detail?.us_serial_number}</span>
                          </li>
                          <li>
                            <span className="font-bold">
                              Published For opposition:{" "}
                            </span>
                            {/* <span>FEB 10, 2009</span> */}
                            <span>
                              {detail?.publication_date?.toUpperCase()}
                            </span>
                          </li>
                          <li>
                            <span className="font-bold">Registered: </span>
                            <span>
                              {detail?.registration_date?.toUpperCase()}
                            </span>
                          </li>
                          <li>
                            <span className="font-bold">
                              Registration Number:{" "}
                            </span>
                            <span>{detail?.us_registration_number}</span>
                          </li>
                          <li>
                            <span className="font-bold">
                              Earliest Date in Record:{" "}
                            </span>
                            {/* <span>MAR 20,2008 (Filed)</span> */}
                            <span>
                              {detail?.application_filing_date?.toUpperCase()}
                            </span>
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div className="border-b border-[#D9D9D9]"></div>
                    <div className="w-[128px] font-bold text-xs text-[#1BA4A4] mt-2">
                      Ownership Details:{" "}
                    </div>
                    <div className="flex flex-row text-xs leading-[18px]">
                      <div className="w-[128px] font-bold text-[#1BA4A4]">
                        Registrant:{" "}
                      </div>
                      <ol className="flex flex-col max-w-[291px] space-y-1">
                        <li>
                          <span>{detail?.owner_name?.toUpperCase()}</span>
                        </li>
                        <li>
                          <span>{detail?.owner_address?.toUpperCase()}</span>
                        </li>
                        {/* <li>
                    <span>150 SPEAR STREET SUITE 600</span>
                  </li>
                  <li>
                    <span>SAN FRANCISCO, CALIFORNIA 94105</span>
                  </li> */}
                      </ol>
                    </div>
                    <div className="flex flex-row text-xs leading-[18px]">
                      <div className="w-[128px] font-bold text-[#1BA4A4]">
                        Filing Correspondent:
                      </div>
                      <ol className="flex flex-col max-w-[291px] space-y-1">
                        <li>
                          <span>
                            {detail ? detail["correspondent_name/address"] : ""}
                          </span>
                        </li>
                        {/* <li>
                    <span>DAVIS WRICHT TREMAINE LLP</span>
                  </li>
                  <li>
                    <span>1300 SW FIFTH AVENUE, SUITE 2300 </span>
                  </li>
                  <li>
                    <span>PORILAND OR 97201-5630</span>
                  </li> */}
                      </ol>
                    </div>
                    <div className="w-[128px] font-bold text-xs text-[#1BA4A4]">
                      In-Use Information
                    </div>
                    <ul>
                      <li className="flex flex-row text-xs leading-[18px]">
                        <div className="w-[128px] font-bold">
                          Citation Link:
                        </div>
                        <div>None Found</div>
                      </li>
                      <li className="flex flex-row text-xs leading-[18px]">
                        <div className="w-[128px] font-bold">Owner Link:</div>
                        <div>None Found</div>
                      </li>
                      <li className="flex flex-row text-xs leading-[18px]">
                        <div className="w-[128px] font-bold">In-Use Link:</div>
                        <div>None Found</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex flex-row mx-auto w-[420px] justify-end mt-[85px] mb-[85px]">
                  <img className="h-8 object-contain" src={ImgFairIP} />
                </div>
                <div
                  className="absolute bottom-0 left-[8px] right-[8px]"
                  id="123"
                >
                  <span className="absolute left-0 bottom-0">
                    <img
                      className="rotate-[270deg] size-[44px]"
                      src={ImgReportPrinting}
                    />
                  </span>
                  <span className="absolute right-0 bottom-0">
                    <img
                      className="rotate-180 size-[44px]"
                      src={ImgReportPrinting}
                    />
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="absolute bottom-[8px] left-[8px] right-[8px]">
            <span className="absolute left-0 bottom-0">
              <img
                className="rotate-[270deg] size-[44px]"
                src={ImgReportPrinting}
              />
            </span>
            <span className="absolute right-0 bottom-0">
              <img className="rotate-180 size-[44px]" src={ImgReportPrinting} />
            </span>
          </div> */}
          </div>
          <div className='absolute bottom-0 left-0 right-0 py-5 text-center'>
            {/* <button className={downloading ? 'hidden bg-[#FFFFFF00]'
              : 'inline-flex items-center justify-center w-[200px] py-3 mx-auto bg-[#1BA4A4] rounded-4xl text-white'} onClick={() => {
                download()
              }}>
              <span className='text-2xl'>
                <IconDownload />
              </span>
              <span className='text-base font-semibold'>Download</span>
            </button> */}
            <PDFDownloadLink
              fileName='trademark-search-opinon-report.pdf'
              document={(
                <TrademarkDetailReportPdf
                  data={data}
                  detail={detail}
                  goodsDetail={goodsDetail}
                  firstUse={firstUse}
                  use_in_commerce={use_in_commerce}
                />
              )}
            >
              {({ loading }) => (
                <>
                  <button
                    disabled={loading}
                    className={'inline-flex items-center justify-center w-[200px] py-3 mx-auto bg-[#1BA4A4] rounded-4xl text-white disabled:cursor-wait'}>
                    <span className='text-2xl'>
                      <IconDownload />
                    </span>
                    <span className='text-base font-semibold'>Download</span>
                  </button>
                </>
              )}
            </PDFDownloadLink>
          </div>
        </div>
      </section>
    </dialog>
  );
});

export default TrademarkDetailReport;
