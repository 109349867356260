import React, { useEffect, useRef, useState } from 'react'

import { Microphone, Send, Link } from '../svgr/components'
import { ButtonRound } from './Button'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../redux/app-state'
import { chatlistLocalData } from '../redux/local'
import { sharedActions, sharedSelectors } from '../redux'
import { ChatListLocalData } from '../redux/local/chatlist-local-data'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import useStateRef from 'react-usestateref'

const initState = { textareaValue: '' }

type Props = {
  displayExampleQuestion?: boolean,
  expanded: boolean,
  max: boolean,
  sendMsg: (msg: string) => void,
  // openFileDiaglog?:boolean
}


const TextInput: React.FC<Props> = ({ displayExampleQuestion, expanded, max, sendMsg }: Props) => {
  const dispatch = useDispatch();
  const chatlistCacheData = useSelector<
    AppState,
    chatlistLocalData.Selectors
  >(({ shared }) =>
    sharedSelectors.local.chatlistLocalData(shared.local.chatlistLocalData, {})
  );

  const updateOpenFileDiaglog = (data: boolean) =>
    dispatch(sharedActions.local.chatlistLocalData.updateOpenFileDialog(data));


  const updateChatListData = (data: ChatListLocalData) =>
    dispatch(sharedActions.local.chatlistLocalData.update(data));


  const [state, setState,stateRef] = useStateRef({ ...initState })
  // const stateRef = useRef(state)

  // useEffect(() => {
  //   stateRef.current = state;
  // console.log('state***',stateRef.current,state)
  //   return () => {

  //   }
  // }, [state])

//   useEffect(() => {
//     const handleKeyDown = (event: KeyboardEvent) => {
//       console.log(event)
//        // 检查是否是功能键，避免直接处理功能键
//        if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
//          // 在这里可以选择性地处理某些组合键，或者完全放行
//                 // 如果想让 Ctrl + Space 组合键有效，可以在这里不拦截
//                 if (event.key === ' ') {
//                   return; // 放行空格
//               }
//               return; // 其他组合键直接返回
//     }
//       if (event.key === "Enter") {
//         onSubmit();
//         return
//       }
//         // 获取按键对应的字符
//         const char = event.key;
        
//         // 排除功能键，比如 Shift, Ctrl, Alt 等
//         if (event.key.length === 1) {
//             setState((prev) => {return { textareaValue: prev.textareaValue+char }});
//         }
//     };

//     // 添加键盘按下事件监听
//     window.addEventListener('keydown', handleKeyDown);

//     // 清理事件监听
//     return () => {
//         window.removeEventListener('keydown', handleKeyDown);
//     };
// }, []);


  const initAllState = () => {
    setState({ ...initState })
  }

  const handleTextareaChange = (event: any) => {
    //使用全局事件监听
    setState({ textareaValue: event.target.value });
  }

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  }

  const onSubmit = () => {
    console.log('form***', stateRef.current.textareaValue)
    stateRef.current.textareaValue && sendMsg(stateRef.current.textareaValue)
    initAllState();
  }

  // ===================== 语音输入 =====================
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const handleButtonClick = (event: any) => {
    event.preventDefault(); // 阻止form的提交行为
    if (!browserSupportsSpeechRecognition) {
      console.error(`Browser doesn't support speech recognition`);
      return;
    }
    if (listening) return;
    console.log('Button audioinput clicked');
    SpeechRecognition.startListening();
  }

  useEffect(() => {

    if (!listening && transcript) {
      const t = transcript
      console.log('listening***', listening, transcript)
      setState({ textareaValue: t });
      // stateRef.current = { textareaValue: t };
      // resetTranscript();
    }

    return () => {

    }
  }, [listening])

  // useEffect(() => {
  //   console.log('state.textareaValue***', state.textareaValue)

  //   return () => {
  //   }
  // }, [state.textareaValue])







  const handleLinkButtonClick = (event: any) => {
    event.preventDefault(); // 阻止form的提交行为
    console.log('Button Link clicked');
    // updateOpenFileDiaglog(true)
    updateChatListData({ ...chatlistCacheData?.getData, openFileDialog: true, chatMode: 'search' })
  }



  // console.log('@@@@@@@@@@@@ ---- expanded: ', expanded)
  // console.log('@@@@@@@@@@@@ ---- displayExampleQuestion: ', displayExampleQuestion)
  const inputRef = useRef<HTMLTextAreaElement>(null); // 创建对 input 元素的引用

  useEffect(() => {
    // 组件加载后自动聚焦到输入框
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []); // 空依赖数组意味着 effect 只在组件首次渲染时运行

  return <section
    aria-expanded={expanded}
    aria-checked={displayExampleQuestion}
    aria-atomic={!max}
    className='absolute left-8 right-8 bottom-1 flex flex-col
    aria-expanded:right-[348px] aria-[atomic=true]:aria-[expanded=false]:right-[104px]'
  >
    {
      displayExampleQuestion && (
        <div className='flex flex-row items-center ml-[160px] mb-4'>
          <div className='mr-5 text-sm text-[#4B4B4B]'>you may want to know</div>
          <div className='flex flex-row space-x-4'>
            <button className='p-[15px] border border-[#DDDDDD] rounded-full text-sm text-[#4B4B4B]'>Trademark comparison</button>
            <button className='p-[15px] border border-[#DDDDDD] rounded-full text-sm text-[#4B4B4B]'>Trademark registration</button>
            <button className='p-[15px] border border-[#DDDDDD] rounded-full text-sm text-[#4B4B4B]'>Trademark review</button>
            <button className='p-[15px] border border-[#DDDDDD] rounded-full text-sm text-[#4B4B4B]'>Contact a lawyer</button>
          </div>
        </div>
      )
    }
    <form
      className='px-6 py-5 flex flex-row items-center bg-white rounded-4xl space-x-6'
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        onSubmit();
        return false
      }}
    >
      <ButtonRound>
        <Microphone onClick={handleButtonClick} />
      </ButtonRound>
      <ButtonRound>
        <Link onClick={handleLinkButtonClick} />
      </ButtonRound>
      <textarea
        className='flex flex-1 text-base
        placeholder:text-[#9D9FA1]
        outline-0 font-sans resize-none overflow-auto'
        placeholder='Send a message'
        rows={1}
        value={state.textareaValue}
        onChange={handleTextareaChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
      >
      </textarea>
      <ButtonRound className='bg-green'>
        <Send />
      </ButtonRound>
    </form>
  </section>
}

export default TextInput