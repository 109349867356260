import React from 'react'
import { Outlet } from 'react-router-dom'

import { Header, Navbar, CustomerService } from '../components'

const Main: React.FC = () => {

  return <main className='relative flex flex-col min-w-full h-full bg-main'>
    <div className='flex flex-col bg-white'>
      <Header type='primary' />
    </div>
    <div className='flex flex-row flex-1 overflow-hidden'>
      {/* <Navbar />   */}
      <Outlet />
      <CustomerService />
    </div>
  </main>
}

export default Main