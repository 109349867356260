import React from 'react'

type Props = {
  type: 'primary' | 'normal' | 'second'
  size?: 'large' | 'medium' | 'small',
  children: React.ReactNode,
  disabled?: boolean,
  onClick?: () => void,
}

const Button: React.FC<Props> = ({ type, children, size='large', disabled, onClick }: Props) => {


  if(size === 'medium'){
    if (type === 'primary') {
      return <button
        disabled={disabled}
        className={disabled ? 'cursor-not-allowed flex flex-row items-center justify-center h-[64px] text-base text-white font-semibold bg-[#1BA4A4] border border-[#1BA4A4] rounded-full font-san'
          : 'flex flex-row items-center justify-center h-[56px] text-sm text-white font-semibold bg-[#1BA4A4] border border-[#1BA4A4] rounded-full font-san'}
        onClick={onClick}
      >
        {children}
      </button>
    }
  
    return <button
      disabled={disabled}
      className={disabled ?
        'cursor-not-allowed flex flex-row items-center justify-center h-[64px] text-base text-[#4B4B4B] font-semibold bg-white border border-[#DDDDDD] rounded-full'
        : 'flex flex-row items-center justify-center h-[56px] text-sm text-[#4B4B4B] font-semibold bg-white border border-[#DDDDDD] rounded-full hover:bg-[#F2F2F2]'
      }
      onClick={onClick}
    >
      {children}
    </button>
  }

  if (type === 'primary') {
    return <button
      disabled={disabled}
      className={disabled ? 'cursor-not-allowed flex flex-row items-center justify-center h-[64px] text-base text-white font-semibold bg-[#000000] border border-[#000000] rounded-full font-san'
        : 'flex flex-row items-center justify-center h-[64px] text-base text-white font-semibold bg-[#000000] border border-[#000000] rounded-full font-san'}
      onClick={onClick}
    >
      {children}
    </button>
  }

  if (type === 'second') {
    return <button
      disabled={disabled}
      className={disabled ? 'cursor-not-allowed flex flex-row items-center justify-center h-[64px] text-base text-white font-semibold bg-[#1BA4A4] border border-[#1BA4A4] rounded-full font-san'
        : 'flex flex-row items-center justify-center h-[64px] text-base text-white font-semibold bg-[#1BA4A4] border border-[#1BA4A4] rounded-full font-san'}
      onClick={onClick}
    >
      {children}
    </button>
  }


  return <button
    disabled={disabled}
    className={disabled ?
      'cursor-not-allowed flex flex-row items-center justify-center h-[64px] text-base text-[#4B4B4B] font-semibold bg-white border border-[#DDDDDD] rounded-full'
      : 'flex flex-row items-center justify-center h-[64px] text-base text-[#4B4B4B] font-semibold bg-white border border-[#DDDDDD] rounded-full hover:bg-[#F2F2F2]'
    }
    onClick={onClick}
  >
    {children}
  </button>
}

export default Button