export const baseUrl =
  process.env.NODE_ENV === "development"
  ? "/localapi"
  : process.env.REACT_APP_BACKEND_INTERFACE_URL;

// console.error('bbbbbbbb*',baseUrl)
// export const baseUrl = process.env.REACT_APP_BACKEND_INTERFACE_URL;
// export const musicBaseUrl = process.env.REACT_APP_MUSIC_BASE_URL;
// export const musicPrefixUrl = process.env.REACT_APP_MUSIC_PREFIX;
// console.log('base***',process.env.REACT_APP_BASE_URL)




/// ===================== ai =====================
