import * as React from "react";
import type { SVGProps } from "react";
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.01 11.673a2.748 2.748 0 1 0-.914.914l1.1 1.106a.64.64 0 0 0 .907 0 .64.64 0 0 0 0-.907zm-1.279-.364a1.515 1.515 0 0 1-2.09 0 1.483 1.483 0 0 1 .48-2.41 1.477 1.477 0 0 1 2.041 1.365c0 .392-.155.767-.43 1.045m.64-8.948H3.422a1.917 1.917 0 0 0-1.917 1.917v5.753a1.917 1.917 0 0 0 1.917 1.917H5.98a.639.639 0 1 0 0-1.278H3.422a.64.64 0 0 1-.639-.64V6.197H13.01v.639a.64.64 0 0 0 1.278 0V4.278a1.92 1.92 0 0 0-1.917-1.917m.639 2.556H2.783v-.639a.64.64 0 0 1 .64-.639h8.948a.64.64 0 0 1 .639.64zM6.618 7.474H4.701a.64.64 0 0 0 0 1.278h1.917a.64.64 0 1 0 0-1.278"
    />
  </svg>
);
export default SvgCheck;
