import React from "react";
import { useNavigate, Link } from 'react-router-dom'

import { ButtonRoundBorder } from '../Button'
import { Copy as IconCopy } from '../../svgr/components'
import { BillingHistoryTable } from '../Table'
import { DefaultAvator } from '../'

import { PERSONAL_CENTER_MYPROFILE } from '../../router/constants'
import useUserInfo from "../../hooks/userinfo-hook";
import copy from 'copy-to-clipboard';

const PersonalCenter: React.FC = () => {

  const navigate = useNavigate()

  const { userName, uid, avatar, email,companyName } = useUserInfo()

  return <article className="relative flex flex-col flex-1 py-4 mt-4 rounded-4xl overflow-hidden space-y-4">
    <div className="flex flex-row p-8 bg-white rounded-4xl">
      <DefaultAvator
        name={userName}
        avatar={avatar}
        size={'medium'} />
      {/* <img className='size-[80px] rounded-full' src={avatar} /> */}
      <div className="flex flex-col justify-between ml-6 mr-10">
        <h6 className="text-2.5xl leading-[34px] text-[#111] font-semibold">{userName}</h6>
        <div className="flex flex-row self-start py-[1px] px-2 bg-[#F2F2F2] rounded-[6px]">
          <span className='text-sm text-[#4B4B4B]'>ID: {uid}</span>
          <button className="inline-flex items-center justify-center text-base" onClick={(e) => {
            uid && copy(uid)
          }}>
            <IconCopy />
          </button>
        </div>
      </div>
      <ul className="flex flex-row py-[14px] space-x-10">
        <li className="flex flex-col justify-between">
          <span className="text-sm text-[#9D9FA1]">Email</span>
          <div className="text-sm text-black">{email}</div>
        </li>
        <li className="flex flex-col justify-between">
          <span className="text-sm text-[#9D9FA1]">Phone Number</span>
          <div className="text-sm text-black">--</div>
        </li>
        <li className="flex flex-col justify-between">
          <span className="text-sm text-[#9D9FA1]">User Type</span>
          <div className="text-sm text-black">--</div>
        </li>
        <li className="flex flex-col justify-between">
          <span className="text-sm text-[#9D9FA1]">Company</span>
          <div className="text-sm text-black">{companyName}</div>
        </li>
      </ul>
      <div className='inline-flex items-start justify-end flex-1'>
        <ButtonRoundBorder onClick={() => { navigate(PERSONAL_CENTER_MYPROFILE) }}>Edit</ButtonRoundBorder>
      </div>
    </div>
    {/* <div className="flex flex-col flex-1 p-8 bg-white rounded-4xl overflow-auto">
      <h6 className='mb-6 font-semibold text-[20px] leading-[30px]'>Billing History</h6>
      <BillingHistoryTable
        displayMore
        onMoreClick={() => { }}
      />
    </div> */}
  </article>
}

export default PersonalCenter