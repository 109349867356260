import React, { useState } from 'react'

import ButtonRoundBorder from './ButtonRoundBorder'

type Props = {
  checked: boolean,
  children: React.ReactNode,
  onClick?: () => void,
}

const Radio: React.FC<Props> = ({ checked, children, onClick }: Props) => {


  return <button
    aria-checked={checked}
    className='px-4 py-2 text-sm text-[#4B4B4B] border border-[#DDDDDD] rounded-full
              hover:text-green hover:border-green
              aria-checked:text-green aria-checked:border-green'
    onClick={onClick}>
    {children}
  </button>
}

export default Radio