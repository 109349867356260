import React, { useEffect, useRef, useState } from 'react'

import { ImgYourTrademark, ImgCheckInfringement, ImgQueryLoading, ImgWarning, ImgNotFound } from '../images'
import { UploadTrademark } from '../components/Dialog'
import { TrademarkInfringeTable } from '../components/Table'
import { TrademarkCover } from '../components/MyTrademark'

import { monitorSearch } from '../api/ai/app'
import { TrademarksMonitoringData, TrademarksMonitoringDataItem, TrademarksMonitoringResponseData } from '../api/ai/response'

type File = {
  url: string,
  file: any
}

type State = 'UPPLOAD' | 'CHECK_INFRINGEMENT' | 'CHECK_INFRINGEMENT_LOADING' | 'CHECK_INFRINGEMENT_SUCCESS' | 'CHECK_INFRINGEMENT_FAILED'
const MAX_COUNT = 1

const MyTrademark: React.FC = () => {

  const uploadDialog = useRef<any>(null)

  const [state, setState] = useState<State>('UPPLOAD')
  const [trademarkFiles, setTrademarkFiles] = useState<File[]>([])
  const [trademarkIndex, setTrademarkIndex] = useState<number>(0)

  const [trademarksMonitoringData, setTrademarksMonitoringData] = useState<TrademarksMonitoringData>()
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loadingMore, setLoadingMore] = useState(false)

  useEffect(() => {
    if (state !== 'CHECK_INFRINGEMENT' && trademarkFiles.length > 0) {
      setState('CHECK_INFRINGEMENT')
      setTrademarkIndex(0)
    }
    if (trademarkFiles.length === 0) {
      //reset data
      setState('UPPLOAD')
      setTrademarksMonitoringData(undefined)
      setPageNum(1)
      setLoadingMore(false)
    }
  }, [trademarkFiles, trademarkFiles.length])

  // 内存释放
  useEffect(() => {
    return () => {
      trademarkFiles.forEach(item => {
        window.URL.revokeObjectURL(item.url);
      })
    }
  }, [])

  const queryData = async () => {
    setState('CHECK_INFRINGEMENT_LOADING')
    const trademarkFile = trademarkFiles[trademarkIndex]
    if (!trademarkFile) {
      setState('UPPLOAD')
      return
    }
    const response = await monitorSearch(pageNum, pageSize, trademarkFile.file)
    console.log('********** monitorSearch ----response status---- ', response.status)
    console.log('********** monitorSearch ----response data---- ', response.data)
    if (response.status === 200) {
      const responseData = (response.data as TrademarksMonitoringResponseData)
      const list = responseData.data.list
      setState(list.length > 0 ? 'CHECK_INFRINGEMENT_SUCCESS' : 'CHECK_INFRINGEMENT_FAILED')
      setTrademarksMonitoringData(responseData.data)
      setPageNum(pageNum)
    } else {
      setState('CHECK_INFRINGEMENT_FAILED')
    }
  }

  const queryMoreData = async () => {
    if (loadingMore) {
      console.log('********** queryMoreData loadingMore ------- ', loadingMore)
      return
    }
    const nextPageNum = 1 + pageNum
    const trademarkFile = trademarkFiles[trademarkIndex]
    if (!trademarkFile && trademarksMonitoringData === undefined) {
      return
    }
    setLoadingMore(true)
    const response = await monitorSearch(nextPageNum, pageSize, trademarkFile.file)
    console.log('********** monitorSearch ----response status---- ', response.status)
    console.log('********** monitorSearch ----response data---- ', response.data)
    if (response.status === 200) {
      const responseData = (response.data as TrademarksMonitoringResponseData)
      const list = responseData.data.list
      const newList = trademarksMonitoringData
        ? [...trademarksMonitoringData.list, ...list] : [...list]
      console.log('@@@@@@@@@@@@@@@--- newList:', newList)
      const newData = { ...trademarksMonitoringData, list: newList } as TrademarksMonitoringData
      setTrademarksMonitoringData(newData)
      setPageNum(nextPageNum)
    }
    setLoadingMore(false)
  }

  const addTrademark = (file: File) => {
    setTrademarkFiles([{ ...file }, ...trademarkFiles])
  }

  const delTrademark = (index: number) => {
    const file = trademarkFiles[index]
    if (file) {
      const newFiles = [...trademarkFiles]
      newFiles.splice(index, 1)
      setTrademarkFiles(newFiles)
    }
  }

  const replaceTrademark = (file: File) => {
    if (file) {
      const newFiles = [...trademarkFiles]
      newFiles.splice(trademarkFiles.length - 1, 1)
      setTrademarkFiles([{ ...file }, ...newFiles])
    }
  }

  return <section className='relative flex flex-col flex-1 py-4 overflow-hidden'>
    <div className='relative flex flex-col p-8 mb-4 bg-white rounded-4xl'>
      <h6 className='text-2.5xl leading-[34px] font-semibold'>Trademarks under monitoring</h6>
      <div className='mt-2 mb-4 text-base text-[#9D9FA1]'>Comprehensive automatic detection across the web, immediate notification of infringement cases</div>
      <button
        // disabled={trademarkFiles.length >= MAX_COUNT}
        className='absolute top-[69px] right-[35px] inline-flex px-4 py-2 bg-[#1BA4A4] text-sm text-white border border-[#DDDDDD] rounded-full disabled:cursor-not-allowed'
        onClick={() => { uploadDialog.current?.show(trademarkFiles.length >= MAX_COUNT) }}
      >{trademarkFiles.length >= MAX_COUNT ? 'Reupload' : 'upload'}</button>
      {
        trademarkFiles.length > 0 ? <>
          <ul className='flex flex-row space-x-4'>
            {
              trademarkFiles.map((item, index) => (
                <li key={index} className='inline-flex'>
                  <TrademarkCover
                    selected={index === trademarkIndex}
                    cover={item.url}
                    onClick={() => { setTrademarkIndex(index) }}
                    onDeletedClick={() => { delTrademark(index) }}
                  />
                </li>
              ))
            }
          </ul>
        </> : <>
          <div className='inline-flex items-center'>
            <button className='h-20 px-6 bg-[#F9F9F9] text-base text-[#9D9FA1] font-semibold rounded-2xl'>No trademarks</button>
          </div>
        </>
      }
    </div>
    <div className='relative flex flex-col flex-1 justify-center p-8 bg-white rounded-4xl overflow-hidden'>
      {
        state === 'UPPLOAD' && <>
          <img className='mx-auto size-[100px]' src={ImgYourTrademark} />
          <div className='flex flex-col items-center text-[#282828]'>
            <h6 className='text-xl leading-8 font-semibold'>Your trademark</h6>
            <div className='mt-2 mb-4  text-base'>Upload or enter your trademark to monitor its infringement status</div>
            <button
              className='inline-flex px-4 py-2 bg-[#1BA4A4] text-sm text-white border border-[#DDDDDD] rounded-full'
              onClick={() => { uploadDialog.current?.show() }}
            >upload</button>
          </div>
        </>
      }
      {
        state === 'CHECK_INFRINGEMENT' && <>
          <img className='mx-auto size-[120px]' src={ImgCheckInfringement} />
          <div className='flex flex-col items-center text-[#282828]'>
            <h6 className='my-4 text-xl leading-8 font-semibold'>Check the infringement status of that label</h6>
            <button
              className='inline-flex px-4 py-2 bg-[#1BA4A4] text-sm text-white border border-[#DDDDDD] rounded-full'
              onClick={() => { queryData() }}
            >To check</button>
          </div>
        </>
      }
      {
        state === 'CHECK_INFRINGEMENT_LOADING' && <>
          <img className='mx-auto size-[200px]' src={ImgQueryLoading} />
          <div className='flex flex-col items-center text-[#4B4B4B]'>
            <div className='mt-4 text-base'>The data query is expected to take approximately 2 minutes</div>
          </div>
        </>
      }
      {
        state === 'CHECK_INFRINGEMENT_FAILED' && <>
          <img className='mx-auto size-[100px]' src={ImgNotFound} />
          <div className='flex flex-col items-center text-[#282828]'>
            <h6 className='text-xl leading-8 font-semibold'>No results found</h6>
            <div className='mt-2 mb-4  text-base'>No infringement has been found for the trademark at this time.</div>
            <button
              className='inline-flex px-4 py-2 bg-white text-sm text-black border border-[#DDDDDD] rounded-full'
              onClick={() => { setState('CHECK_INFRINGEMENT') }}
            >Confirm</button>
          </div>
        </>
      }
      {
        state === 'CHECK_INFRINGEMENT_SUCCESS' && trademarksMonitoringData && <>
          <div className='flex flex-col flex-1 space-y-4 overflow-auto'>
            <div className='flex flex-row items-center space-x-2.5'>
              <img className='size-10' src={ImgWarning} />
              <span className='text-xl font-semibold text-[#F54040]'>Infringement warning</span>
            </div>
            <div className='flex flex-row'>
              <img className='h-[80px] object-contain' src={trademarkFiles[trademarkIndex]?.url} />
            </div>
            <div className='flex flex-row items-center font-semibold'>
              <span className='text-xl leading-8 text-[#4B4B4B] '>Estimated trademark infringement amount:&nbsp;</span>
              <span className='text-2xl text-[#13AE85]'>${trademarksMonitoringData.totalAmount}</span>
            </div>
            <div className='text-base text-[#9D9FA1]'>The amount above is an estimate, and the final amount recovered will prevail.</div>
            <TrademarkInfringeTable
              yourTrademarkUrl={trademarkFiles[trademarkIndex].url}
              data={trademarksMonitoringData.list}
              displayMore={trademarksMonitoringData.list.length < trademarksMonitoringData.totalCount}
              onMoreClick={() => { queryMoreData() }}
            />
          </div>
        </>
      }
    </div>
    <UploadTrademark
      ref={uploadDialog}
      pickImage={(file, url) => { }}
      onSubmit={(file, url) => {
        uploadDialog.current?.hide()
        if (file && url) {
          if (trademarkFiles.length >= MAX_COUNT) {
            replaceTrademark({ file: file, url: url })
          } else {
            addTrademark({ file: file, url: url })
          }
        }
      }}
    />
  </section>
}

export default MyTrademark