import { get, longPost, post, put, upload } from "../request";
import * as api from "./index";

export const searchName = (name: string) => {
  const raw = {
    name: name,
  };
  const url = `${api.search}`;
  return post(url, raw);
};

export const search = (imgUrl: string) => {
  const raw = {
    image: imgUrl,
  };
  const url = `${api.search}`;
  return post(url, raw);
};

export const compare = () => {
  const url = `${api.compare}`;
  return longPost(url);
};

export const uploadImage = (file: File) => {
  const url = `${api.upload}`;
  const form = new FormData();

  form.append("file", file, file.name);
  return upload(url, form);
};

export const monitorSearch = (
  pageNo: number = 1,
  pageSize: number = 10,
  file: File,
  trademark?: string
) => {
  const url = `${api.monitorSearch}`;
  const form = new FormData();

  form.append("file", file, file.name);
  trademark && form.append("trademark", trademark);
  form.append("pageNo", pageNo.toString());
  form.append("pageSize", pageSize.toString());
  return upload(url, form);
};

export const monitorCompareData = (imgUrl: string) => {
  const url = `${api.monitorCompareData}`;
  const form = new FormData();
  form.append("imgUrl", imgUrl);
  return upload(url, form);
};

export const getInfringementData = (
  good:string,
  trademark: string
) => {
  const url = `${api.infringement}`;
  const raw = {
    goods: good,
    trademark: trademark,
  };
  return post(url, raw);
};

export const getStoreInfo = (
  file: File,
  pageNo: number = 1,
  pageSize: number = 10,
  trademark?: string
) => {
  const url = `${api.storeInfo}`;
  const form = new FormData();

  form.append("file", file, file.name);
  trademark && form.append("trademark", trademark);
  form.append("pageNo", pageNo.toString());
  form.append("pageSize", pageSize.toString());
  return upload(url, form);
};

export const sendMsg = (msg:string,threadId?: string) => {
  const url = `${api.sendMsg}`;
  const raw = threadId?{
    message: msg,
    threadId: threadId,
  }:{
    message: msg,
  };
  return post(url, raw);
};