import { envType } from "../utils/env"

export const DASHBOARD = '/'
export const MAIN = '/main'
export const SOLUTIONS = `${MAIN}/solutions`
export const MYTRADEMARK = `${MAIN}/myTrademark`
export const TRADEMARK = `${MAIN}/trademark`
export const TRADEMARK_REPORT = `${MAIN}/trademark/report`
export const INFRINGEMENT_REPORT = `${MAIN}/infringement/report`

export const LOGIN = '/login'
export const NOT_LOGIN_DASHBOARD = LOGIN
export const SIGNUP = '/signup'
export const EMAIL_VERIFICATION = '/email/vertification'
export const PASSWORD_RESET = '/password/reset'

export const GOOGLE_LOGIN = process.env.REACT_APP_BACKEND_INTERFACE_URL+ '/business/oauth/render'
export const GOOGLE_CALLBACK = '/callback'

export const PERSONAL_CENTER = `${MAIN}/personalCenter`
export const PERSONAL_CENTER_PASSWORD_RESET = `${MAIN}/personalCenter/password/reset`
export const PERSONAL_CENTER_MYPROFILE = `${MAIN}/personalCenter/myprofile`

export const CONTRACTS = '/contract'
export const CONTRACTS_HOME = `${CONTRACTS}/contracts-home`
export const CONTRACTS_CONTRACTS = `${CONTRACTS}/contracts`
export const CONTRACTS_CLAUSES = `${CONTRACTS}/clauses`
export const AGREEMENT = `${CONTRACTS}/agreement`
export const AGREEMENT_DOWNLOAD = `${CONTRACTS}/agreement/download`
export const SEARCH = `${CONTRACTS}/search`

export const TEST_SEARCH = `${CONTRACTS}/testsearch`
export const TEST_COMPARE = `${CONTRACTS}/testcompare`