import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { decode, encode } from "../utils/saltBase64";
import { MD5 } from "../utils/saltMD5";
import { DASHBOARD} from "../router/constants";

import * as stringUtils from "../utils/string";
import {
  checkContainSpeicalChars,
  checkIsEmail,
  checkIsPassword,
} from "../utils/regularUtils";


import { useSelector } from "react-redux";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import { persistSelectors, sharedSelectors } from "../redux";
import { authLocalData } from "../redux/presist";
import { FormUnitType2 } from "../service/formdata";
import useLogin from "./login-hook";

export type SigninFormDataType2 = {
  username: FormUnitType2;
  password: FormUnitType2;
};

export const initSignupFormData2: SigninFormDataType2 = {
  username: {
    value: "",
    type: "empty",
  },

  password: {
    value: "",
    type: "empty",
  },
};

const useSignin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("usesignup1**", domain, projectName);
  const [formData, setFormData] =
    useState<SigninFormDataType2>(initSignupFormData2);
  //是否能发邮件获取验证码
  const [canSendEmailForRegister, setCanSendEmailForRegister] =
    useState<boolean>(false);//
  //是否能点击注册按钮
  const [canClickNextBtn, setCanClickNextBtn] = useState<boolean>(false);
  //是否点击了按钮
  const [nextClicked, setNextClicked] = useState(false);

  const [errorMsg, setErrorMsg] = useState('')
  const [cfToken, setCfToken] = useState<string>("123");

 

  let didCancel = false;
  


 
const userInfoCacheData = useSelector<
    AppState,
    userInfoLocalData.Selectors
  >(({ shared }) =>
    sharedSelectors.local.userInfoLocalData(shared.local.userInfoLocalData, {})
  );

  const authInfoCacheData = useSelector<
    AppState,
    authLocalData.Selectors
  >(({ persist }) =>
    persistSelectors.local.authLocalData(persist.local.authLocalData, {})
  );

  useEffect(() => {
    if(authInfoCacheData?.getData?.accessToken && userInfoCacheData?.getData?.email){
        navigate(DASHBOARD,{replace:true})
    }
   
  
    return () => {
      
    }
  }, [userInfoCacheData?.getData?.uid,authInfoCacheData?.getData?.accessToken])
  
  const {onLogin}= useLogin()

  const onSignin = async () => {
    console.log(
      "onRegister**",!formData , didCancel , !cfToken);
    if (!formData || didCancel || !cfToken) return;
    //注册获取验证码方法变更
    const getRegisterResult = (await onLogin(
      formData.username.value,
      (formData.password.value),//无法md5
    ))
    console.log("signin*****result**", getRegisterResult);
    if (getRegisterResult.success) {
      didCancel = true;
      //清空数据
      // navigate(DASHBOARD)
      //根据有没钱包地址再判断跳转页面
    } else {
      //todo显示错误
      const msg =getRegisterResult.error || ''
      // setFormData((f)=>{return {...f,error:msg}})
      setErrorMsg(msg)
    }
    setRequesting(false)
    setNextClicked(false)
    setCanSendEmailForRegister(false)
  };

  useEffect(() => {
    // console.log("canSendEmailForRegister**", canSendEmailForRegister)
    // canSendEmailForRegister && onSendEmail();
    //符合条件后进行注册
    canSendEmailForRegister && onSignin();
    return () => {};
  }, [canSendEmailForRegister]);

  useEffect(() => {
    setErrorMsg('')
    !nextClicked && checkCanClickNextBtn();
    return () => {
      didCancel = true;
    };
  }, [formData]);


  const checkCanClickNextBtn = () => {
    const val = checkAvailableListForRegister(formData);
    console.log("checkCanClickNextBtn**", val, formData);
    setCanClickNextBtn(val);
  };

  const checkAvailableListForRegister = (list: any): boolean => {
    let available = true;
    // console.log("checkAvailableListForRegister**list**", list);
    for (const item in list) {
      if (typeof list[item] === "object") {
        // console.log("checkAvailableListForRegister**listitem**", typeof (list[item]), item, list[item], item == 'isIllegal')
        available = available && checkAvailableListForRegister(list[item]);
        // console.log("available0**", item, list[item], available)
      } else if (item == "type") {
        available =
          available && (list[item] == "legal" || list[item] == "useful");
        // console.log("checkAvailableListForRegister**listitem**available1**", item, !!!list[item])
        if (!available) return available; //不满足的情况直接可以返回false
      }
    }
    // console.log("checkAvailableListForRegister**listitem**allavailable**",available)
    return available;
  };


  const [requesting, setRequesting] = useState(false);

 
  const onSigninClick = async () => {
    if(!btnAvailable) return;
    setNextClicked(true);
    console.log(
      "onSigninClick**",
      canSendEmailForRegister,
      canClickNextBtn,
      formData,
      requesting
    );
    setRequesting(true);
    //直接就可以注册了
    setCanSendEmailForRegister(true);
  };
  const btnAvailable = canClickNextBtn && !requesting && !!cfToken;
  
  // console.log("usesignup***btnAvailable**", btnAvailable);
  // console.log("usesignup***canClickNextBtn**", canClickNextBtn);
  // console.log("usesignup***!requesting**", !requesting);
  // console.log("usesignup***!!cfToken**", !!cfToken, cfToken);



  const setUsername = (value: string) => {
    const hasSpeicalChar = checkContainSpeicalChars(value);
    // console.log("@@@@@@@ --- hasSpeicalChar:", hasSpeicalChar);
    // console.log("@@@@@@@ --- value:", value);
    console.log(hasSpeicalChar ? "illegal" : !value ? "empty" : "legal");
    setFormData({
      ...formData,
      username: {
        value: value || "",
        type: hasSpeicalChar ? "illegal" : !value ? "empty" : "legal",
      },
    });
  };

  

  const setPasssword = (value: string) => {
    const passwordType = !value
      ? "empty"
      // : value.length < PASSWORD_MIN_LEN
      // ? "tooshort"
      // : value.length > PASSWORD_MAX_LEN
      // ? "toolong"
      // : !checkIsPassword(value)
      // ? "illegal"
      : "legal";
    console.log("passwordType**", passwordType);
    setFormData({
      ...formData,
      password: {
        value,
        type: passwordType,
      }
    });
  };



  return { onSigninClick, btnAvailable, formData,setUsername,setPasssword,errorMsg };
};

export default useSignin;
