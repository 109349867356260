import {
  ActionType,
  createReducer,
  createStandardAction,
} from "typesafe-actions";
import { createStructuredSelector } from "reselect";
import { ChatData, ChatType } from "../../components/Chat/chatdatatype";
import { platform } from "os";
import { ChatMode } from "../../components/Solution/ChatList";


export type TrademarkListLocalData = {
  trademark:string,
  analysisTrademark:string| undefined
  comparisonTrademarks:string[]
};

const initialState = {
  trademark:'',
  analysisTrademark:undefined,
  comparisonTrademarks:[]
};

export type Selectors = {
  getData: TrademarkListLocalData;
};

const actions = {
  update: createStandardAction(
    "UPDATE_TRADEMARKLIST_CACHE_DATA"
  )<TrademarkListLocalData>(),
  reset: createStandardAction("RESET_TRADEMARKLIST_CACHE_DATA")(),
  updateOpenFileDialog: createStandardAction('UPDATE_TRADEMARKLIST_FILE_DIALOG_CACHE_DATA')<
  boolean |undefined
>(),
updateTrademark: createStandardAction('UPDATE_TRADEMARKLIST_TRADEMARK_CACHE_DATA')<
string
>(),
updateComparisonTrademarks: createStandardAction('UPDATE_TRADEMARKLIST_COMPARISON_TRADEMARKS_CACHE_DATA')<
string[]
>(),
updateAnalysisTrademark: createStandardAction('UPDATE_TRADEMARKLIST_ANALYSIS_TRADEMARK_CACHE_DATA')<
string | undefined
>(),


};

export type ChatListLocalDataActions = ActionType<typeof actions>;

const reducer = () =>
  createReducer<TrademarkListLocalData, ChatListLocalDataActions>(
    initialState
  )
    .handleAction(actions.update, (state, { payload }) => ({
      ...state,
      ...payload
    }))
    .handleAction(actions.updateOpenFileDialog, (state, { payload }) => {
      // console.log('updateOpenFileDialog**',state,payload)
      return {
        ...state,
        openFileDialog:payload
      }
    })
    
    
    .handleAction(actions.updateTrademark, (state, { payload }) => {
      console.log('updateTrademark**',state,payload)
      return {
        ...state,
        trademark:payload
      }
    })
    
    .handleAction(actions.updateComparisonTrademarks, (state, { payload }) => {
      // console.log('updateChatMode**',state,payload)
      return {
        ...state,
        comparisonTrademarks:payload
      }
    })
    .handleAction(actions.updateAnalysisTrademark, (state, { payload }) => {
      // console.log('updateChatMode**',state,payload)
      return {
        ...state,
        analysisTrademark:payload
      }
    })
    
    
    .handleAction(actions.reset, () => ({...initialState}
      
    ));


const select = createStructuredSelector<TrademarkListLocalData, {}, Selectors>({
  getData: (state) => state,
});

export { actions, reducer, select };
