
import * as ga4Util from '../utils/gautils'
import GA4React from "ga-4-react";
import { GA4ReactResolveInterface } from "ga-4-react/dist/models/gtagModels";
import { envType } from '../utils/env';


export const getGids = () => {
    const gid = envType=== 'prod' ? 'G-J4Z83J9MJV' :'G-55ERF890Z3' 
    const additionGids = envType === 'dev' ? undefined : undefined
    console.log("gids**", gid, additionGids)
    return { gid, additionGids }
  }

const {gid,additionGids} = getGids()

export async function gaInitialize(debug:boolean) {
        const ga4react = new GA4React(gid, { debug_mode: debug,send_page_view:false });
        return ga4Util.gaInitialize(ga4react,gid)
}

export const gaEvent = (ga4: GA4ReactResolveInterface, action: string, value?: any) => {
    ga4Util.gaEvent(ga4,action,value);
};

export const gaClickEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    ga4Util.gaEvent(ga4,'click',value);
};

export const gaSearchTrademarkEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    const action='search-trademark'
    gaClickEvent(ga4,action)
    ga4Util.gaEventWithJson(ga4,action,value);

};

export const gaCompareTrademarkEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    const action='compare-trademark'
    gaClickEvent(ga4,action)
    ga4Util.gaEventWithJson(ga4,action,value);

};

export const gaFreeChickInfringementAnalysisEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    const action='free-chick-infringement-analysis'
    gaClickEvent(ga4,action)
    ga4Util.gaEventWithJson(ga4,action,value);
};


export const gaSearchContractEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    const action='search-contract'
    gaClickEvent(ga4,action)
    ga4Util.gaEventWithJson(ga4,action,value);
};


export const gaFreeChickAIAssistantEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    const action='free-chick-ai-assistant'
    gaClickEvent(ga4,action)
    ga4Util.gaEventWithJson(ga4,action,value);
};

export const gaDownloadContractEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    const action='download-contract'
    gaClickEvent(ga4,action)
    ga4Util.gaEventWithJson(ga4,action,value);
};

export const gaCopyClauseEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    const action='copy-clause'
    gaClickEvent(ga4,action)
    ga4Util.gaEventWithJson(ga4,action,value);
};



export const gaSetUser = (ga4: GA4ReactResolveInterface, userId: string) => {
    ga4Util.gaSetUser(ga4,userId);
};

export const gaPageView = (ga4: GA4ReactResolveInterface, url: string, title?:string) => {
    ga4Util.gaPageView(ga4,url,title);
};


