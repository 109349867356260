// import querystring from 'querystring'
// import { parseFloatValue, parseValueFloat } from './CoinUtils'

const decodeQuery = (key: string, query: string) => {
  // try {
  //   const decodeUrl = decodeURI(query)
  //   const obj = querystring.decode(decodeUrl.substring(1, query.length))
  //   const res = obj[key] as string
  //   return res
  // }
  // catch (err) {
  //   console.log(`undefined --- ${key}`)
  // }
  return decodeChineseQueryForMultiPara(key, query)
}

const decodeQueryForMultiPara = (name: string, query: string) => {
  try {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = query.substr(1).match(reg);
    if (r != null) return (r[2]); return null;
  }
  catch (err) {
    console.log(`undefined --- ${name}`)
  }
}

const decodeChineseQueryForMultiPara = (name: string, query: string) => {
  try {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = query.substr(1).match(reg);
    if (r != null) return (decodeURI(r[2]));
    return null;
  }
  catch (err) {
    console.log(`undefined --- ${name}`)
  }
}



// const encodeQuery = (obj: any) => {
//   return encodeURI(querystring.encode(obj))
// }
// const encodeQuery = (obj: any) => {
//   return encodeURI(querystring.encode(obj))
// }

function encodeQuery(obj: any) {
  return Object.keys(obj).map(key => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
  }).join('&');
}

const formatEUT2USD = (value: string | undefined) => {
  if (!value) {
    return '0'
  }
  const eut = Number(value)
  if (isNaN(eut)) {
    return '0'
  }

  const usd = eut * 0.025
  return usd.toFixed(2)
}

const formatString2Num = (value: string) => {
  if (!value) {
    return 0
  }
  const num = Number(value)
  if (isNaN(num)) {
    return 0
  }
  return num
}

const formatFixString = (value: string) => {
  if (!value) {
    return ''
  }
  const num = Number(value)
  if (isNaN(num)) {
    return ''
  }
  return num.toFixed(2)
}

const generateDownloadUrl = (urlPrefix: string, url: string, name: string) => {
  let result = ''
  const index = url.lastIndexOf('.')
  if (index !== -1) {
    const extension = url.substring(index, url.length)
    const reg = /\\|\,|\,|\/|\?|\？|\*|\"|\“|\”|\'|\‘|\’|\<|\>|\{|\}|\[|\]|\【|\】|\：|\:|\、|\^|\$|\!|\~|\`|\|/g;
    var subName = name.replace(reg, "");
    result = `${urlPrefix}${url}?download=${subName}${extension}`
  } else {
    result = `${urlPrefix}${url}`
  }
  return result
}

function insertStr(str: string, index: number, insertStr: string) {
  return str.substring(0, index) + insertStr + str.substring(index)
}


/**
 * 随机生成字符串
 * @param len 指定生成字符串长度
 */
function getRandomStr(len: number) {
  if (len < 1) return undefined;
  let _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789',
    min = 0,
    max = _charStr.length - 1,
    _str = '';                    //定义随机字符串 变量
  //判断是否指定长度，否则默认长度为15
  len = len || 15;
  //循环生成字符串
  for (var i = 0, index; i < len; i++) {
    index = (function (randomIndexFunc, i) {
      return randomIndexFunc(min, max, i, randomIndexFunc);
    })(function (min: number, max: number, i: number, _self: any) {
      let indexTemp = Math.floor(Math.random() * (max - min + 1) + min),
        numStart = _charStr.length - 10;
      if (i == 0 && indexTemp >= numStart) {
        indexTemp = _self(min, max, i, _self);
      }
      return indexTemp;
    }, i);
    _str += _charStr[index];
  }
  return _str;
}

function filterHTML(html: string) {
  return html
    .replace(/<(?:.|\n)*?>/gm, '')
    .replace(/(&rdquo;)/g, '"')
    .replace(/&ldquo;/g, '"')
    .replace(/&mdash;/g, '-')
    .replace(/&nbsp;/g, '')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/<[\w\s"':=\/]*/, '')
}

const isLowcaseChar = (char: string) => {
  if (char.length !== 1) {
    return;
  }
  
  if (char >= 'A' && char <= 'Z') {
    return false
  } else if (char >= 'a' && char <= 'z') {
    return true
  } else {
    return 
  }
};

const isUpperChar = (char: string) => {
  const result =isLowcaseChar(char)
  if(result === undefined) return result
  else return !result;
};

export {
  decodeQuery,
  decodeQueryForMultiPara,
  decodeChineseQueryForMultiPara,
  encodeQuery,
  formatEUT2USD,
  formatFixString,
  formatString2Num,
  generateDownloadUrl,
  insertStr,
  getRandomStr,
  filterHTML,
  isLowcaseChar,
  isUpperChar
}