import * as React from "react";
import type { SVGProps } from "react";
const SvgSearchClear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#D9D9D9"
      d="M12.003 22.8c5.965 0 10.8-4.835 10.8-10.8 0-5.964-4.835-10.8-10.8-10.8S1.203 6.036 1.203 12s4.835 10.8 10.8 10.8"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m15 9-6 6M9 9l6 6"
    />
  </svg>
);
export default SvgSearchClear;
