import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Contracts as IconContracts, ContractsHome as IconContractsHome, Clauses as IconClauses } from '../../svgr/components'
import { CONTRACTS_HOME, CONTRACTS_CONTRACTS, CONTRACTS_CLAUSES } from '../../router/constants'
import MenuItem from './MenuItem'

type Props = {
  onClose: () => void
}

const ContractsMenu: React.FC<Props> = ({ onClose }: Props) => {

  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener('click', onClose)
    return () => {
      window.removeEventListener('click', onClose)
    }
  }, [])

  return <>
    <div
      className='absolute top-[68px] left-0 p-4 bg-white rounded-2xl border border-[#F2F2F2] shadow-4xl z-50 space-y-1'
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <MenuItem
        icon={<IconContractsHome />}
        onClick={e => {
          onClose()
          navigate(CONTRACTS_HOME)
        }}
      >Contracts Home</MenuItem>
      <MenuItem
        icon={<IconContracts />}
        onClick={e => {
          onClose()
          navigate(`${CONTRACTS_CONTRACTS}`)
        }}
      >Contracts</MenuItem>
      <MenuItem
        icon={<IconClauses />}
        onClick={e => {
          onClose()
          navigate(CONTRACTS_CLAUSES)
        }}
      >Clauses</MenuItem>
    </div>
  </>
}

export default ContractsMenu