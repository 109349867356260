import React, { useEffect, useState } from 'react'

import { Right as IconRight } from '../svgr/components'

type Props = {
  id: string,
  onCheckChanged?: (id: string, checked: boolean) => void,
  children: React.ReactNode
}

const Checkbox: React.FC<Props> = ({ id, children, onCheckChanged }: Props) => {

  const [checked, setChecked] = useState<boolean>(false)

  return <label className='flex flex-row flex-1 items-center' htmlFor={id}>
    <div className='relative inline-flex mr-2.5'>
      <input
        id={id}
        type="checkbox"
        className="appearance-none size-5 bg-white border border-[#DDDDDD] rounded outline-none cursor-pointer
                  checked:bg-green checked:border-green"
        checked={checked}
        onChange={e => {
          const checked = e.target.checked
          setChecked(checked)
          onCheckChanged && onCheckChanged(id, checked)
        }}
      />
      <span
        aria-hidden={!checked}
        className='absolute inset-0 inline-flex items-center justify-center text-white 
                    aria-hidden:hidden'>
        <IconRight />
      </span>
    </div>
    {children}
  </label>
}

export default Checkbox