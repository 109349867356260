import * as React from "react";
import type { SVGProps } from "react";
const SvgContractsHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#4B4B4B"
      d="m16.667 6.667-5-4.384a2.5 2.5 0 0 0-3.334 0l-5 4.384A2.5 2.5 0 0 0 2.5 8.55v7.283a2.5 2.5 0 0 0 2.5 2.5h10a2.5 2.5 0 0 0 2.5-2.5V8.542a2.5 2.5 0 0 0-.833-1.875m-5 10H8.333V12.5a.833.833 0 0 1 .834-.833h1.666a.834.834 0 0 1 .834.833zm4.166-.834a.833.833 0 0 1-.833.834h-1.667V12.5a2.5 2.5 0 0 0-2.5-2.5H9.167a2.5 2.5 0 0 0-2.5 2.5v4.167H5a.833.833 0 0 1-.833-.834V8.542a.83.83 0 0 1 .283-.625l5-4.375a.833.833 0 0 1 1.1 0l5 4.375a.83.83 0 0 1 .283.625z"
    />
  </svg>
);
export default SvgContractsHome;
