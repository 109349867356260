import React, { useEffect, useState } from 'react'

import { Close, Eye, EyeOff } from '../svgr/components'

type Props = {
  title: string,
  value?: string,
  onValueChanged?: (e: string) => void,
  onClearValue?: () => void,
  type?: 'text' | 'password',
  optional?: boolean,
  error?: string,
  disabled?: boolean,
}

const Input: React.FC<Props> = ({ title, value, onValueChanged, onClearValue, type = 'text', optional, error, disabled }: Props) => {

  const [displayType, setDisplayType] = useState<'text' | 'password'>()

  useEffect(() => {
    setDisplayType(type)
  }, [type])

  return <div className='flex flex-col'>
    <div className='flex flex-row mb-1 text-sm'>
      <span className='text-[#4B4B4B]'>{title}</span>
      {!optional && <span className='text-[#F54040]'>*</span>}
    </div>
    <div
      aria-disabled={disabled}
      className='group flex flex-row items-center p-4 h-[56px] border border-[#ddd] rounded-full focus-within:border-[#1BA4A4] focus:invalid:border-[#F54040] 
      aria-disabled:bg-[#F2F2F2]'
    >
      <input
        className='flex-1 text-sm text-[#111111] bg-transparent outline-none'
        disabled={disabled}
        value={value}
        onChange={e => { onValueChanged && onValueChanged(e.target.value) }}
        type={displayType}
      />
      <div className='flex flex-row space-x-2'>
        {
          (value && value.length > 0) && (
            <button
              className='group-focus-within:inline-flex hidden items-center justify-center text-2xl'
              onClick={onClearValue}
            >
              <Close />
            </button>
          )
        }
        {
          type === 'password' && (
            <button
              type='button'
              className='inline-flex items-center justify-center text-2xl'
              onClick={() => { setDisplayType(displayType === 'password' ? 'text' : 'password') }}
            >
              {displayType === 'password' ? <Eye /> : <EyeOff />}
            </button>
          )
        }
      </div>
    </div>
    {error && <span className='text-sm text-[#F54040]'>{error}</span>}
  </div>
}

export default Input