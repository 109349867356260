import { useSelector } from "react-redux";
import { AppState } from "../../redux/app-state";
import { userInfoLocalData } from "../../redux/local";
import { sharedSelectors } from "../../redux";
import { useEffect, useState } from "react";
import { ResponseServiceData, onGetArticleTypesInfo, onPopContractList } from "./service";
import { ArticleTypeInfoResponseData,ContractSearchResponseData } from "./response";
import { ArticleType } from "./app";

//获取对应的一级或者二级类型
export const useContractTypes = (parentId:number,type:ArticleType)=>{
    const userInfoCacheData = useSelector<
      AppState,
      userInfoLocalData.Selectors
    >(({ shared }) =>
      sharedSelectors.local.userInfoLocalData(
        shared.local.userInfoLocalData,
        {}
      )
    );

  useEffect(() => {
    if(!userInfoCacheData?.getData?.uid) return;
    setData(undefined)
    doRequest()
    return () => {
      
    }
  }, [userInfoCacheData?.getData?.uid,type])

  const [data, setData] = useState<ArticleTypeInfoResponseData>()

  const doRequest = async()=>{
   const result:ResponseServiceData<ArticleTypeInfoResponseData> =await onGetArticleTypesInfo({
        pageNo: 1,
        pageSize: 10,
        parentId: parentId,
        type: type
    })

    // console.log('aType-----',aType,type)
    // if(aType !== type) return;
    if(result.success){
        setData(result.data)
    }
  }

  return{data}
}

//获取热门合约下载列表
export const usePopDownload = ()=>{
  const userInfoCacheData = useSelector<
    AppState,
    userInfoLocalData.Selectors
  >(({ shared }) =>
    sharedSelectors.local.userInfoLocalData(
      shared.local.userInfoLocalData,
      {}
    )
  );

useEffect(() => {
  if(!userInfoCacheData?.getData?.uid) return;
  setData(undefined)
  doRequest()
  return () => {
    
  }
}, [userInfoCacheData?.getData?.uid])

const [data, setData] = useState<ContractSearchResponseData>()

const doRequest = async()=>{
 const result:ResponseServiceData<ContractSearchResponseData> =await onPopContractList(1,5)

  if(result.success){
      setData(result.data)
  }
}

return{data}
}