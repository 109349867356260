import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
//@ts-ignore
import htmlToDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";

import { Header, Loading } from "../components";
import {
  Download as IconDownload,
  AngleRight as IconArrowLeft,
} from "../svgr/components";
import { onAddDownloadCount, onContractDetail } from "../api/contract/service";
import useStateRef from "react-usestateref";
import { getFileExtension } from "../utils/FileUtils";
import { useGA4React } from "ga-4-react";
import { gaDownloadContractEvent } from "../service/ga4Service";

const AgreementDownload: React.FC = () => {
  const docRef = useRef<HTMLDivElement>(null);

  const params = useParams();
  const { id } = params;

  const navigate = useNavigate();

  useEffect(() => {
    console.log("id***", id);
    id && setContractId(id);
    return () => {};
  }, [id]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");
  const [contractId, setContractId] = useState("");

  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [docUrl, setDocUrl] = useState("");

  useEffect(() => {
    if (searchParams && searchParams.size > 0) {
      const n = searchParams.get("n");
      n && setName(n);
      // w && searchParams.delete('q')
    }

    return () => {};
  }, [searchParams]);

  useEffect(() => {
    if (!contractId) return;
    getDetail();

    return () => {};
  }, [contractId]);

  const getDetail = async () => {
    if (!contractId) return;
    setLoading(true)
    const result = await onContractDetail(contractId);
    setLoading(false)
    if (result.success && result.data?.content) {
      console.log("onContractDetail**result**", result.data);
      setContent(result.data?.content);
      setTitle(result.data?.title);
      setDocUrl(result.data?.docUrl);
      return true;
    }
    return false;
  };

  // const onDownloadClick = () => {
  //   if(title && content){
  //   const cssHTML = `
  //   `;
  //   const outerHTML = content//docRef.current?.outerHTML
  //   let contentDocument =outerHTML
  //   // let contentDocument = `
  //   // <!DOCTYPE html>
  //   // <html>
  //   //   <head>
  //   //     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  //   //     <style>
  //   //       ${cssHTML}
  //   //     </style>
  //   //   </head>
  //   //   <body>
  //   //     ${outerHTML}
  //   //   </body>
  //   // </html>
  //   // `;
  //   // const contentDocument = '<!DOCTYPE html>' + outerHTML;
  //   const converted = htmlToDocx.asBlob(contentDocument, { orientation: 'portrait' }) as Blob;
  //   saveAs(converted,   `${title}.docx`);
  // }
  // }
const [downloading, setDownloading] = useState(false)
const ga4 =useGA4React()

  const submitGA = (data:any)=>{
    ga4&& gaDownloadContractEvent(ga4,data)
  }

  const onDownloadClick2 =async () => {
    if(downloading) return;
    // docUrl && window.open(docUrl)
    setDownloading(true)
    onAddDownloadCount(contractId)
    submitGA({id:contractId})
   await handleDownload(docUrl, title);
    setDownloading(false)
  };

  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      let newFileName = fileName.length > 20?fileName.substring(0,20):fileName
      const extentionName =getFileExtension(fileUrl)
      newFileName = extentionName? newFileName +'.'+extentionName:newFileName
      a.download = newFileName; // 设置下载文件的名称
      document.body.appendChild(a);
      a.click(); // 触发点击事件以启动下载
      a.remove(); // 移除元素
      window.URL.revokeObjectURL(url); // 释放内存
    } catch (error) {
      console.error("下载文件时发生错误:", error);
    }
  };

  // useEffect(() => {
  //   // Fetch the HTML file
  //   fetch('/assets/test.html')
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.text();
  //     })
  //     .then((data) => {
  //       setContent(data); // Set the HTML content to state
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching the HTML file:', error);
  //     });
  // }, []); // Empty dependency array means this effect runs once on mount

  // const onDownloadClick2 = () => {
  //   if(title && content){
  //  console.log('ccc---',content)
  //   const outerHTML = content
  //   let contentDocument =outerHTML
  //   const converted = htmlToDocx.asBlob(contentDocument, { orientation: 'portrait' }) as Blob;
  //   saveAs(converted,   `${title}.docx`);
  // }
  // }

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current) {
      // 获取容器的宽度和内容的宽度
      const containerWidth = scrollRef.current.clientWidth;
      const scrollWidth = scrollRef.current.scrollWidth;

      // 计算大致中心位置
      const scrollToPosition = (scrollWidth - containerWidth) / 2;

      // 设置滚动位置
      scrollRef.current.scrollLeft = scrollToPosition;
    }
  }, [content]);

  const [loading, setLoading, loadingRef] = useStateRef<boolean>(false);

  const renderLoading = () => {
    return (
      <div className="flex-grow flex items-center justify-center">
        <Loading></Loading>
      </div>
    );
  };

  return (
    <section className="relative flex flex-col flex-1 h-full bg-[#F9F9F9]">
      <div className="flex flex-col bg-white">
        <Header type="default" />
      </div>
      <button
        className="inline-flex flex-row items-center mx-20 mt-4 mb-6 space-x-2"
        onClick={() => {
          navigate(-1);
        }}
      >
        <span className="text-xl rotate-180">
          <IconArrowLeft />
        </span>
        <span className="text-base text-[#111]">{name}</span>
      </button>
      {<div className={loading ? "relative flex flex-col flex-1 overflow-auto hidden": "relative flex flex-col flex-1 overflow-auto"}>
        {/* <iframe src={'/assets/test.html'} className='flex-1 w-[1000px] mx-auto p-6 bg-white text-sm rounded-2xl overflow-auto'>
    </iframe> */}

      <div ref={docRef} className='flex flex-col items-center flex-1 w-[1000px] mx-auto p-6 bg-white text-sm rounded-2xl overflow-auto notranslate'>
        <div className="zoom-large" dangerouslySetInnerHTML={{ __html: content }}/>
      </div>
      <div className='flex flex-row justify-center py-5'>
          <button
            className={downloading? "inline-flex flex-row items-center justify-center w-[200px] h-12 bg-blue rounded-full space-x-1 cursor-not-allowed":
            "inline-flex flex-row items-center justify-center w-[200px] h-12 bg-blue rounded-full space-x-1"}
            onClick={onDownloadClick2}
          >
            <span className="text-2xl text-white">
              <IconDownload />
            </span>
            <span className="text-base font-semibold text-white">Download</span>
          </button>
          {/* <a
          className='inline-flex flex-row items-center justify-center w-[200px] h-12 bg-blue rounded-full space-x-1' href={docUrl} download={title?title+'.docx':''}
        >
          <span className='text-2xl text-white'>
            <IconDownload />
          </span>
          <span className='text-base font-semibold text-white'>Download</span>
        </a> */}
        </div>
      </div>}
      {loading && renderLoading()}
    </section>
  );
};

export default AgreementDownload;
