import React, { useState } from "react";

import { Upload } from "../";
import { Button } from "../../components/Button";
import { Star as IconStar } from "../../svgr/components";
import { ImgUpload } from "../../images";
import { useDispatch } from "react-redux";
import { persistActions } from "../../redux";
import { useNavigate } from "react-router-dom";
import { TEST_SEARCH, TRADEMARK_REPORT } from "../../router/constants";
import { useGA4React } from "ga-4-react";
import { gaSearchTrademarkEvent } from "../../service/ga4Service";

type Props = {};

const Search: React.FC<Props> = ({}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateTrademark = (data: string) =>
    dispatch(persistActions.local.trademarkListLocalData.updateTrademark(data));

  const updateComparisonTrademarks = (data: any[]) =>
    dispatch(
      persistActions.local.trademarkListLocalData.updateComparisonTrademarks(
        data
      )
    );

  const updateAnalysisTrademark = (data: any) =>
    dispatch(
      persistActions.local.trademarkListLocalData.updateAnalysisTrademark(data)
    );

  // const [avatorUrl, setAvatorUrl] = useState<string>();s
  const [headFile, setHeadFile] = useState<any>();
  const [title, setTitle] = useState("");

  const handlePicker = (event: any) => {
    setHeadFile(event);
  };

  const onSubmit = () => {
    if (title) {
      submitSearchGA({title})
      updateAnalysisTrademark(undefined);
      updateTrademark(title);
      navigate(TRADEMARK_REPORT);
    } else if (headFile) {
      updateTrademark("");
      updateAnalysisTrademark(headFile);
      const reader = new FileReader();
      submitSearchGA({name: (headFile as File)?.name})
      reader.onload = (e) => {
        console.log('updateAnalysisTrademark***',e.target?.result)
        updateAnalysisTrademark(e.target?.result as string);
        // state.fileData = e.target?.result as string; // 使用 Base64 字符串
        navigate(TRADEMARK_REPORT);
      };
      reader.readAsDataURL(headFile);
    }
  };

  const ga4 =useGA4React()

  const submitSearchGA = (data:any)=>{
    ga4&& gaSearchTrademarkEvent(ga4,data)
  }

  return (
    <>
      <div className="flex flex-col mx-auto w-[1003px] space-y-5">
        <div className="flex flex-row p-3 rounded-full shadow-[0px_4px_4px_0px_#0000001A,0px_-1px_4px_0px_#0000000D]">
          <input
            className="flex-1 px-5 text-xl leading-[34px] bg-transparent outline-none"
            placeholder="Input  trademark"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <Button
            type="second"
            disabled={!title && !headFile}
            onClick={onSubmit}
          >
            <span className="inline-flex justify-center w-[192px] text-[36px]">
              <IconStar />
            </span>
          </Button>
        </div>
        <Upload
          onChanged={(e) => {
            handlePicker(e);
          }}
        />
      </div>
    </>
  );
};

export default Search;
