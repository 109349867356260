import React, { ChangeEvent, useEffect, useState } from 'react'
import { ImgUpload } from '../images'

type Props = {
  onChanged?: (file: File) => void
}

const Upload: React.FC<Props> = ({ onChanged }: Props) => {

  const [imgUrl, setImgUrl] = useState<string>(ImgUpload)
  const [imgFile, setImgFile] = useState<File>()

  useEffect(() => {
    return () => {
      if (imgUrl) {
        window.URL.revokeObjectURL(imgUrl);
      }
    }
  }, [])

  const handlePicker = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      if (imgUrl) {
        window.URL.revokeObjectURL(imgUrl);
      }
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      setImgUrl(imageUrl);
      setImgFile(file)
      onChanged && onChanged(file)
    }
  }

  return <>
    <div className='relative flex flex-col items-center p-6 border border-green border-dashed rounded-4xl'>
      <img className='size-[61px]' src={imgUrl} />
      <div className='mt-6 mb-2.5 text-base font-semibold text-black'>Add or drop a trademark image here.</div>
      <div className='text-sm text-[#4B4B/4B]'>JPG, PNG / Max. 60 MB / Min. 224px X 224px</div>
      <input
        className='absolute inset-0 opacity-0 z-10'
        type='file'
        // accept={'image/*'}
        accept=".png, .jpg, .jpeg"
        onChange={e => { handlePicker(e) }}
      />
    </div>
  </>
}

export default Upload