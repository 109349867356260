
// export const baseUrl = process.env.REACT_APP_AI_INTERFACE_URL;

export const authApi = '/business/auth'
export const signup = authApi+'/register'
export const login = authApi+'/login'

export const useApi = '/business/site-user'
export const sendCode = useApi+'/send-verify-code'
export const checkCode = useApi+'/verify-email-code'
export const userInfo = useApi+'/get-personal-info'
export const updateUserInfo = useApi+'/modify-user-info'
export const resetPwd = useApi+'/change-password'
export const resetPwdWithOldPwd = useApi+'/change-password-by-origin'