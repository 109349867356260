import React from 'react'

import { ImgBanner1 } from '../images'
import { ArrowLeft } from '../svgr/components'

const Banner: React.FC = () => {
  return <div className='flex flex-1'>
    <div className='relative flex-1'>
      <img className='w-full h-screen object-cover' src={ImgBanner1} />
      <div className='absolute left-[64px] right-[64px] bottom-[64px]'>
        <h4 className='text-2.5xl text-white font-semibold'>"The Master of Trademark Law has handled thousands of trademark infringement cases for us."</h4>
        <div className='flex flex-row justify-between mt-8'>
          <div className='flex flex-col'>
            <div className='mb-1 text-lg text-[#F9F9F9] font-semibold'>Abigail Davis</div>
            <div className='text-sm text-[#F9F9F9]'>Marketing Director of Barbie Company</div>
          </div>
          <div className='flex flex-row space-x-4'>
            <button className='inline-flex items-center justify-center size-14 text-2xl text-white rounded-full border border-[#F2F2F2]'>
              <ArrowLeft />
            </button>
            <button className='inline-flex items-center justify-center size-14 text-2xl text-black bg-[#F2F2F2] rounded-full border border-[#F2F2F2] rotate-180'>
              <ArrowLeft />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Banner