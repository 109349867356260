import BigNumber from "bignumber.js"



const format = (val: any, lowerExp: number = -18, upperExp: number = 18): string => {
  // if (!checkPriceAsFloat(val)) return val?.toString();
  const result = new BigNumber(val).toFormat()
  if (result == 'NaN') return ''
  return result
  // return new BigNumber(mathJSFormat(bignumber(val),lowerExp,upperExp).toString()).toFormat()

}

/**
 * 最多保留多少位小数
 * @param val 
 * @param upperExp 
 * @returns 
 */
const toFixed = (val: any, upperExp: number = 18): string => {

  const result = new BigNumber(val)
  if (result.toFormat() == 'NaN') return ''
  return new BigNumber(new BigNumber(val).toFixed(upperExp)).toFixed()

}

const smoothValuesToPercents = (values: number[], minPercent: number = 0): number[] => {
  let totalLogValue = 0;
  const logValues = values.map((val,index) => {
    const l = Math.log10(val + 1)
    totalLogValue += l;
    return { index, value: l }
  })
  if (minPercent > 0) {
    let rest = 100
    //还是会有特殊情况存在的bug
    //如果小值过多的话，会超过1
    //todo后续优化合计超过1的问题
    const sortValues = logValues.sort(function (a, b) { return a.value - b.value; });
    console.log('sortValues**', sortValues)
    const percentKeypairs = sortValues.map((val, index) => {
      //最后一位换为剩余值
      if (index === logValues.length - 1) {
        return {
          index: val.index,
          value: rest
        };
      }
      let cVal = val.value * 100 / totalLogValue
      cVal = cVal < minPercent ? minPercent : cVal;
      rest -= cVal
      return {
        index: val.index,
        value: cVal
      };
    }).sort(function (a, b) { return a.index - b.index; });

    const percents = percentKeypairs.map(val => val.value)
    return percents;
  }
  const percents = logValues.map(val => val.value * 100 / totalLogValue)
  return percents;
}

const calValuesToPercents = (values: number[], minPercent: number = 0): number[] => {
  let totalLogValue = 0;
  const logValues = values.map((val, index) => {
    console.log('index**', index)
    const l = val
    totalLogValue += l;
    return { index, value: l }
  })
  if (minPercent > 0) {
    let rest = 100
    //还是会有特殊情况存在的bug
    //如果小值过多的话，会超过1
    //todo后续优化合计超过1的问题
    const sortValues = logValues.sort(function (a, b) { return a.value - b.value; });
    console.log('sortValues**', sortValues)
    const percentKeypairs = sortValues.map((val, index) => {
      //最后一位换为剩余值
      if (index === logValues.length - 1) {
        return {
          index: val.index,
          value: rest
        };
      }
      let cVal = val.value * 100 / totalLogValue
      cVal = cVal < minPercent ? minPercent : cVal;
      rest -= cVal
      return {
        index: val.index,
        value: cVal
      };
    }).sort(function (a, b) { return a.index - b.index; });

    const percents = percentKeypairs.map(val => val.value)
    return percents;
  }
  const percents = logValues.map(val => val.value * 100 / totalLogValue)
  return percents;
}

export { format, toFixed, smoothValuesToPercents, calValuesToPercents }