import * as React from "react";
import type { SVGProps } from "react";
const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 37 37"
    {...props}
  >
    <path
      fill="#fff"
      d="M30 35a1.667 1.667 0 1 1-3.333 0A1.667 1.667 0 0 1 30 35M36.667 28.333a1.667 1.667 0 1 1-3.334 0 1.667 1.667 0 0 1 3.334 0M3.333 8.333a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0M10 1.667a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m18.333 9.138-1.087 4.483a4.93 4.93 0 0 1-3.625 3.625l-4.483 1.087 4.483 1.087a4.93 4.93 0 0 1 3.625 3.626l1.087 4.483 1.087-4.483a4.93 4.93 0 0 1 3.626-3.626l4.483-1.087-4.483-1.087a4.93 4.93 0 0 1-3.626-3.625zm2.176-2.427c-.553-2.281-3.798-2.281-4.351 0l-1.522 6.277a2.24 2.24 0 0 1-1.648 1.648l-6.277 1.522c-2.281.553-2.281 3.798 0 4.35l6.277 1.523c.814.197 1.45.833 1.648 1.648l1.522 6.277c.553 2.281 3.798 2.281 4.35 0l1.523-6.277a2.24 2.24 0 0 1 1.648-1.648l6.277-1.522c2.281-.553 2.281-3.798 0-4.351l-6.277-1.522a2.24 2.24 0 0 1-1.648-1.648zM13.341 35.525a1.5 1.5 0 0 1-1.93.88 19.47 19.47 0 0 1-11.128-10.7 1.5 1.5 0 0 1 2.767-1.158 16.47 16.47 0 0 0 9.41 9.048 1.5 1.5 0 0 1 .881 1.93M35.526 13.368a1.5 1.5 0 0 1-1.931-.88 16.47 16.47 0 0 0-9.146-9.434A1.5 1.5 0 0 1 25.589.28a19.47 19.47 0 0 1 10.816 11.158 1.5 1.5 0 0 1-.88 1.93"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStar;
