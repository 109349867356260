import React from "react";
import Input from "../Input";
import { Button } from '../Button'
import useResetPassword from "./password-resetwithold-hook";

const PasswordReset: React.FC = () => {

  const {setOldPassword,setConfirmPassword,setPassword,formData,onSubmit,canNext}=useResetPassword()

  return <article className="relative flex flex-col flex-1 py-4 mt-4 bg-white rounded-4xl overflow-hidden">
    <div className="flex flex-col w-[420px] mx-auto my-[80px] space-y-6">
      <h6 className='font-semibold text-3.5xl'>Password Reset</h6>
      <Input
        title='Old password'
        type='password'
        value={formData.oldPassword.value}
        optional={true}
        onClearValue={() => {
          setOldPassword('')
        }}
        onValueChanged={(e)=>{
          setOldPassword(e)
        }}
      />
      <Input
        title='Password'
        type='password'
        value={formData.password.value}
        optional={true}
        onClearValue={() => {
          setPassword('')
        }}
        onValueChanged={(e)=>{
          setPassword(e)
        }}
      />
      <Input
        title='Confirm Password'
        type='password'
        value={formData.confirmpassword.value}
        optional={true}
        onClearValue={() => {
          setConfirmPassword('')
        }}
        onValueChanged={(e)=>{
          setConfirmPassword(e)
        }}
      />
      <Button type={canNext?'primary':'normal'} disabled={!canNext} onClick={()=>{
        onSubmit()
      }}>Submit</Button>
    </div>
  </article>
}

export default PasswordReset