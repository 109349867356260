import * as React from "react";
import type { SVGProps } from "react";
const SvgClauses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#4B4B4B"
      d="M2.917 5a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667m3.334 1.667h11.666a.833.833 0 0 0 0-1.667H6.251a.833.833 0 0 0 0 1.667m0 2.5a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666m3.333 4.166a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667m8.333-4.166H9.584a.833.833 0 1 0 0 1.666h8.333a.834.834 0 0 0 0-1.666m0 4.166h-5a.833.833 0 1 0 0 1.667h5a.833.833 0 0 0 0-1.667"
    />
  </svg>
);
export default SvgClauses;
