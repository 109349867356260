import React, { useEffect, useState } from 'react'

import { Filter as IconFilter, AngleRight as IconArrow, Right as IconRight } from '../../svgr/components'
import { Radio } from '../Button'
import { Color as StatusColor } from '../Trademark/Status'
import { Checkbox } from '../'
import { OwnerText } from '../EllipsisTooltipText'

type Class = {
  id: string,
  name: string,
  amount: string | number,
  onChecked?: (checked: boolean) => void,
}

type Owner = {
  id: string,
  name: string,
  onChecked?: (checked: boolean) => void,
}

type Props = {
  classData?: Class[]
  ownerData?: Owner[]
  onCountryChanged?: (current: string) => void
  onStatusChanged?: (current: string) => void
  onRegistrationDateChanged?: (current: string) => void
}

const Country = [
  'US',
]

const RegistrationDate = [
  'ALL',
  'Last Year',
  'Last 5 Years',
  'Last 10 Years',
  'Last 20 Years',
  'Last 50 Years',
]

const Filter: React.FC<Props> = ({ classData, ownerData, onCountryChanged, onStatusChanged, onRegistrationDateChanged }: Props) => {

  const [expandeds, setExpandeds] = useState<boolean[]>(Array(5).fill(true))

  const [currentCountry, setCurrentCountry] = useState<string>('US')
  const [currentStatus, setCurrentStatus] = useState<string>('ALL')
  const [currentRegistrationDate, setCurrentRegistrationDate] = useState<string>('ALL')

  useEffect(() => {
    onCountryChanged && onCountryChanged(currentCountry)
  }, [currentCountry])

  useEffect(() => {
    onStatusChanged && onStatusChanged(currentStatus)
  }, [currentStatus])

  useEffect(() => {
    onRegistrationDateChanged && onRegistrationDateChanged(currentRegistrationDate)
  }, [currentRegistrationDate])

  const onExpandClick = (index: number) => {
    const data = [...expandeds]
    data[index] = !data[index]
    setExpandeds(data)
  }

  return <section className='relative flex flex-col w-[360px] pt-3 pb-[90px] bg-white border-l border-[#DDDDDD] overflow-auto'>
    <div className='flex flex-row py-3 px-4 space-x-2'>
      <span className='text-2xl'>
        <IconFilter />
      </span>
      <span className='text-base line-clamp-4 font-semibold text-[#111]'>Filters</span>
    </div>
    <ul className='flex flex-col space-y-3'>
      <li aria-expanded={expandeds[0]} className='group flex flex-col'>
        <div className='flex flex-row items-center justify-between px-6 py-3'>
          <span className='text-base leading-6 font-semibold'>Country</span>
          <button
            className='inline-flex text-2xl rotate-90 group-aria-expanded:-rotate-90'
            onClick={() => { onExpandClick(0) }}
          >
            <IconArrow />
          </button>
        </div>
        <div className='flex flex-row flex-wrap gap-4 px-6 py-3 group-aria-[expanded="false"]:hidden'>
          {
            Country.map((item, index) => (
              <Radio
                key={index}
                checked={currentCountry === item}
                onClick={() => { setCurrentCountry(item) }}
              >{item}</Radio>
            ))
          }
        </div>
      </li>
      <li aria-expanded={expandeds[1]} className='group flex flex-col'>
        <div className='flex flex-row items-center justify-between px-6 py-3'>
          <span className='text-base leading-6 font-semibold'>Status</span>
          <button
            className='inline-flex text-2xl rotate-90 group-aria-expanded:-rotate-90'
            onClick={() => { onExpandClick(1) }}
          >
            <IconArrow />
          </button>
        </div>
        <div className='flex flex-row flex-wrap gap-4 px-6 py-3 group-aria-[expanded="false"]:hidden'>
          <Radio
            checked={currentStatus === 'ALL'}
            onClick={() => { setCurrentStatus('ALL') }}
          >
            <span>ALL</span>
          </Radio>
          {
            StatusColor.map(({ type, color }, index) => (
              <Radio
                key={index}
                checked={currentStatus === type}
                onClick={() => { setCurrentStatus(type) }}
              >
                <span className={`inline-flex size-2 mr-2 bg-[${color}] rounded-full`} />
                {type}
              </Radio>
            ))
          }
        </div>
      </li>
      <li aria-expanded={expandeds[2]} className='group flex flex-col'>
        <div className='flex flex-row items-center justify-between px-6 py-3'>
          <span className='text-base leading-6 font-semibold'>Class</span>
          <button
            className='inline-flex text-2xl rotate-90 group-aria-expanded:-rotate-90'
            onClick={() => { onExpandClick(2) }}
          >
            <IconArrow />
          </button>
        </div>
        <ul className='flex flex-col gap-1 px-3 pb-3 group-aria-[expanded="false"]:hidden'>
          {
            classData?.map(({ id, name, amount, onChecked }, index) => (
              <li key={index} className='flex px-3 h-10 rounded-lg hover:bg-[#F2F2F2] cursor-pointer'>
                <Checkbox id={`class-${id}`} onCheckChanged={(_, checked) => { onChecked && onChecked(checked) }}>
                  <div className='flex flex-row flex-1 items-center justify-between'>
                    <span className="text-sm text-[#111]">{name}</span>
                    <span className="text-xs text-[#9D9FA1]">{amount}</span>
                  </div>
                </Checkbox>
              </li>
            ))
          }
        </ul>
      </li>
      <li aria-expanded={expandeds[3]} className='group flex flex-col'>
        <div className='flex flex-row items-center justify-between px-6 py-3'>
          <span className='text-base leading-6 font-semibold'>Owner</span>
          <button
            className='inline-flex text-2xl rotate-90 group-aria-expanded:-rotate-90'
            onClick={() => { onExpandClick(3) }}
          >
            <IconArrow />
          </button>
        </div>
        <ul className='flex flex-col gap-1 px-3 pb-3 group-aria-[expanded="false"]:hidden'>
          {
            ownerData?.map(({ id, name, onChecked }, index) => (
              <li key={index} className='flex px-3 h-10 rounded-lg hover:bg-[#F2F2F2] cursor-pointer'>
                <Checkbox id={`owner-${id}`} onCheckChanged={(_, checked) => { onChecked && onChecked(checked) }}>
                  {/* <span className="max-w-[280px] text-sm text-[#111] truncate">{name}</span> */}
                  {expandeds[3] && <OwnerText text={name}/>}
                </Checkbox>
              </li>
            ))
          }
        </ul>
      </li>
      <li aria-expanded={expandeds[4]} className='group flex flex-col'>
        <div className='flex flex-row items-center justify-between px-6 py-3'>
          <span className='text-base leading-6 font-semibold'>Registration date</span>
          <button
            className='inline-flex text-2xl rotate-90 group-aria-expanded:-rotate-90'
            onClick={() => { onExpandClick(4) }}
          >
            <IconArrow />
          </button>
        </div>
        <div className='flex flex-row flex-wrap gap-4 px-6 py-3 group-aria-[expanded="false"]:hidden'>
          {
            RegistrationDate.map((item, index) => (
              <Radio
                key={index}
                checked={currentRegistrationDate === item}
                onClick={() => { setCurrentRegistrationDate(item) }}
              >{item}</Radio>
            ))
          }
        </div>
      </li>
    </ul>
  </section>
}

export default Filter