import React from 'react'

type Props = {
  icon: React.ReactNode,
  children: React.ReactNode,
  onClick: (e: any) => void
}

const MenuItem: React.FC<Props> = ({ icon, children, onClick }: Props) => {

  return <button
    className='flex flex-row items-center w-[240px] h-12 px-4 rounded-2xl hover:bg-[#F2F2F2] space-x-2.5'
    onClick={onClick}
  >
    <span className='text-xl'>
      {icon}
    </span>
    <span>{children}</span>
  </button>
}

export default MenuItem