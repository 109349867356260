import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

export type StatusType ='ALL' | 'Registered' | 'Pending' | 'Abandoned' | 'Other'


export function format2StatusType(a: string): StatusType {
  if (a.toLowerCase().includes('register')) {
    return 'Registered'
  } else if (a.toLowerCase().includes('pending')) {
    return 'Pending'
  } else if (a.toLowerCase().includes('abandon')) {
    return 'Abandoned'
  } else if (a.toLowerCase().includes('other')) {
    return 'Other'
  }
  return 'Other'
}


type ColorData = {
  type: StatusType,
  color: string
}
export const Color: ColorData[] = [
  {
    type: 'Registered',
    color: '#13AE85'
  },
  {
    type: 'Pending',
    color: '#FCD536'
  },
  {
    type: 'Abandoned',
    color: '#F54040'
  },
  {
    type: 'Other',
    color: '#0063F2'
  }
]

type Props = {
  type: StatusType
}

const Status: React.FC<Props> = ({ type }: Props) => {

  if (type == 'Registered') {
    return <div className='inline-flex flex-row items-center space-x-1'>
      <span className={`size-2 bg-[#13AE85] rounded-full`}></span>
      <span className={`text-base leading-[22px] font-semibold text-[#13AE85]`}>{type}</span>
    </div>
  }

  if (type == 'Pending') {
    return <div className='inline-flex flex-row items-center space-x-1'>
      <span className={`size-2 bg-[#FCD536] rounded-full`}></span>
      <span className={`text-base leading-[22px] font-semibold text-[#FCD536]`}>{type}</span>
    </div>
  }

  if (type == 'Abandoned') {
    return <div className='inline-flex flex-row items-center space-x-1'>
      <span className={`size-2 bg-[#F54040] rounded-full`}></span>
      <span className={`text-base leading-[22px] font-semibold text-[#F54040]`}>{type}</span>
    </div>
  }

  return <div className='inline-flex flex-row items-center space-x-1'>
    <span className={`size-2 bg-[#0063F2] rounded-full`}></span>
    <span className={`text-base leading-[22px] font-semibold text-[#0063F2]`}>{type}</span>
  </div>
}

export default Status