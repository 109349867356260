import * as React from "react";
import type { SVGProps } from "react";
const SvgList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.278}
      d="M5.605 11.948h8.31M2.41 11.948h.006M5.605 8.113h8.31M2.41 8.113h.006M5.605 4.278h8.31M2.41 4.278h.006"
    />
  </svg>
);
export default SvgList;
