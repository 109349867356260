import React from 'react'

type Props = {
  children: React.ReactNode,
  type: string,
  filed: string,
  jurisdiction: string,
  onClick?: () => void
}

const ContractAgreement: React.FC<Props> = ({ children, type, filed, jurisdiction, onClick }: Props) => {

  return <>
    <li
      className='flex flex-col flex-1 p-4 bg-[#F9F9F9] rounded-2xl space-y-4 hover:bg-[#F2F2F2]'
      onClick={onClick}
    >
      <div className='text-base leading-6 text-[#111] hover:text-blue cursor-pointer'
      >{children}</div>
      <ul className='flex flex-row space-x-4'>
        <li className='inline-flex flex-col w-[200px] text-sm leading-[21px]'>
          <div className='text-[#9D9FA1]'>Contract Type</div>
          <div className='text-[#111]'>{type}</div>
        </li>
        <li className='inline-flex flex-col w-[200px] text-sm leading-[21px]'>
          <div className='text-[#9D9FA1]'>Filed</div>
          <div className='text-[#111]'>{filed}</div>
        </li>
        <li className='inline-flex flex-col w-[200px] text-sm leading-[21px]'>
          <div className='text-[#9D9FA1]'>Jurisdiction</div>
          <div className='text-[#111]'>{jurisdiction}</div>
        </li>
      </ul>
    </li>
  </>
}

export default ContractAgreement