export default class RandomNumberPicker {
    private min: number;
    private max: number;
    private numbers: number[];
    
  
    constructor(min: number, max: number) {
      this.min = min;
      this.max = max;
      this.numbers = Array.from({ length: max - min + 1 }, (_, i) => i + min);
    }
  
    public pickOneNumber(): number | null {
      console.log('RandomNumberPicker**pick**',this.numbers)
      if (this.numbers.length < 1) {
        this.reset()
        return null;
      }
      if (this.numbers.length < 2) {
        const a = this.numbers[0]
        this.numbers=[]
        return a;
      }
  
      const newNumbers= [...this.numbers]
      const randomIndex1 = Math.floor(Math.random() * newNumbers.length);
      const number1 = newNumbers[randomIndex1];
      newNumbers.splice(randomIndex1, 1);
  
      
      console.log('RandomNumberPicker**pick**after',number1, newNumbers,this.numbers)
      return number1;
    }

    public pickTwoNumbers(): number[] | null {
      console.log('RandomNumberPicker**pick**',this.numbers)
      if (this.numbers.length < 1) {
        this.reset()
        return null;
      }
      if (this.numbers.length < 2) {
        const a = this.numbers[0]
        this.numbers=[]
        return [a];
      }
  
      const newNumbers= [...this.numbers]
      const randomIndex1 = Math.floor(Math.random() * newNumbers.length);
      const number1 = newNumbers[randomIndex1];
      newNumbers.splice(randomIndex1, 1);
  
      const randomIndex2 = Math.floor(Math.random() * (newNumbers.length - 1));
      const number2 = newNumbers[randomIndex2];
      newNumbers.splice(randomIndex2, 1);
      console.log('RandomNumberPicker**pick**after',number1,number2, newNumbers,this.numbers)
      return [number1, number2];
    }

    public remove(i:number){
      console.log('RandomNumberPicker**remove**',i,this.numbers)
      this.numbers=this.numbers.filter(item => item!=i)
      console.log('RandomNumberPicker**remove**after',i,this.numbers)
    }

    public reset(){
      this.numbers = Array.from({ length: this.max - this.min + 1 }, (_, i) => i + this.min);
    }
  }