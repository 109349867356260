import React from 'react'
import { Outlet } from 'react-router-dom'

import { CustomerService } from '../components'

const ContractMain: React.FC = () => {

  return <>
    <Outlet />
    <CustomerService />
  </>
}

export default ContractMain