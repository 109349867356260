import React, { useEffect, useRef, useState } from 'react'

import { ImgLogo, ImgCongratulation } from '../images'
import { Button, ButtonText, ButtonRound } from '../components/Button'
import { Input } from '../components'
import { ArrowLeft, CheckCircle } from '../svgr/components'
import { useNavigate } from 'react-router-dom'
import { SignupFormDataType2 } from '../service/formdata'
import { useSessionStorageString } from 'react-use-window-sessionstorage'
import { APP_KEY } from '../service/constant'
import { useDispatch } from 'react-redux'
import { sharedActions } from '../redux'
import { persistActions } from '../redux'

import { decode } from '../utils/saltBase64'
import { SIGNUP, SOLUTIONS } from '../router/constants'
import { login, sendCode, signUp } from '../api/user/app'
import useLogin from '../hooks/login-hook'
import { ResponseData } from '../api/response'
import { SignUpResponseData } from '../api/user/response'

const EmailVertification: React.FC = () => {

 
  const navigate = useNavigate();
  const timerRef = useRef<number>();

  const [countdownTime, setCountdownTime] = useState(0);
  const [displayCountdownTime, setDisplayCountdownTime] = useState(true);
  const [canNext, setCanNext] = useState<boolean>(false);

  useEffect(() => {
    // startCountdownTime();
    return () => {
      clearCountdownTime();
    };
  }, []);

  const startCountdownTime = () => {
    clearCountdownTime();
    setDisplayCountdownTime(true);
    setCountdownTime(60)
    timerRef.current = window.setInterval(() => {
      setCountdownTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          setDisplayCountdownTime(false);
          return 60;
        }
        return --prevTime;
      });
    }, 1000);
  };

  const clearCountdownTime = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const [formData, setFormData] = useState<SignupFormDataType2>();
  const [
    formLocalData,
    setFormLocalData,
    loadingFormLocalData,
    available,
    reset,
  ] = useSessionStorageString(APP_KEY+"Sign.Info", "");

  const [verifyCode, setVerifyCode] = useState("");

  const [isRegistered, setIsRegistered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isInited, setIsInited] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);

  const {onLogin}=useLogin()
  useEffect(() => {
    if (loadingFormLocalData) return;
    console.log(
      "email**loadingFormLocalData**",
      formLocalData,
      loadingFormLocalData
    );
    if (isRegistered) return;
    if (!formLocalData) {
      //没有注册数据，倒退回去
      navigate(SIGNUP);
    }
    const decodeStr = decode(formLocalData || "");
    // console.log("email**formData**decodeStr**", decodeStr)
    //没有缓存信息或者缓存信息有误，直接返回
    if (!decodeStr) return;
    const decodeJsonData = JSON.parse(decodeStr);
    console.log("email**formData**decodeJson**", decodeJsonData);
    setFormData(decodeJsonData);
    setIsInited(true);
    //页面刷新也不自动发邮件
    //onSendEmail()
    return () => {};
  }, [loadingFormLocalData]);

  useEffect(() => {
    console.log("email**isRegisterSuccess**", isRegisterSuccess, formLocalData);
    if (isInited) {
      console.log(
        "email**isRegisterSuccess**formLocalData**",
        isRegisterSuccess,
        formLocalData
      );
      navigate(SOLUTIONS, { replace: true });
      
    }
    return () => {};
  }, [isRegisterSuccess]);

  useEffect(() => {
    console.log("email**formData**", formData);
    if (!isInited) return;
    if (!formData) {
      //清空数据
      setFormLocalData("");
    }
    return () => {};
  }, [formData]);

  const onSignup = async () => {
    if (!formData) return;
    setErrorMessage("");
    const signupPromise = await signUp({
      companyName: formData.company.value,
      email: formData.email.value,
      password: formData.password.value,
      username: formData.username.value,
      verifyCode: verifyCode
    }
    );
    const data: SignUpResponseData = signupPromise.data;
    console.log("signUp**", data);
    if (data.success) {
      //注册成功
      const loginResult = await onLogin(formData.email.value,formData.password.value,true);
      console.log("loginResult**", loginResult);
      //清空数据
      // reset();
      setFormData(undefined);
      // setFormLocalData('')
      setIsRegistered(true);
      setIsRegisterSuccess(loginResult.success);
      // if (loginResult) {
      //   //登陆成功
      //   navigate(REGISTER_SINGUP_SUCCESS)
      // }
      // else {
      //   //登陆失败
      //   //todo:登陆失败的处理,暂时跳转到主页吧
      //   navigate(HOME)
      // }
    } else {
      //注册失败
      setErrorMessage(data.msg);
    }
  };

  // const [resend, setResend] = useState(false)

  // useEffect(() => {
  //   if(resend) return;
  //    timerRef.current && clearTimeout(timerRef.current);
  //     timerRef.current = window.setTimeout(() => {
  //       console.log("登出重定向3");
  //       window.location.href = window.location.origin;
  //     }, 0.1 * 1000);
  
  //   return () => {
      
  //   }
  // }, [resend])
  
  const onSendEmail = async () => {
    if (!formData) return;
    setErrorMessage("");
    const result = await sendCode({email: formData.email.value,vt:'REGISTER'});
    const responseData: ResponseData<boolean> = result.data;
    console.log("getEmailVerifyCode**responseData**", responseData);
    if (!responseData.success) setErrorMessage(responseData.msg);
    else{
      startCountdownTime()
    }
  };

  // const [authLocalData, setAuthLocalData] = useLocalStorage(
  //   "Login.AuthInfo",
  //   undefined
  // );

// const dispatch = useDispatch()
//   const updateAuthInfoCacheData = (
//     data:any
//   ) => dispatch(persistActions.local.authLocalData.update(data));

  // const onLogin = async () => {
    
  //   if (!formData) return false;
  //   const result = await login(
  //     formData?.username.value,
  //     formData?.password.value
  //   );
  //   const data: LoginResponseData = result.data;
  //   console.log("login**", data);
  //   if (data.success) {
  //     //登陆成功
  //     //更新auth信息
  //     const loginData = buildAuthInfoCacheData(data.data);
  //     updateAuthInfoCacheData(loginData)
  //     const jsonStr = JSON.stringify(loginData);
  //     const encodeStr = encode(jsonStr);
  //     if (authLocalData != encodeStr) setAuthLocalData(encodeStr);

  //     //发送消息给plugin
  //     const msg = { ...loginData, sender: "web",key:process.env.REACT_APP_DOMAIN_NAME };
  //     window.postMessage(JSON.stringify(msg), document.URL);
  //     console.log("发送消息给plugin****msg****", msg);
  //   } else {
  //     //todo:登陆失败的处理,暂时跳转到主页吧
  //     // navigate(HOME)
  //   }
  //   return data.success;
  // };

  const renderStep = () => {
    return <section className='p-16 bg-white rounded-4xl'>
      <div className='flex flex-col w-[420px] space-y-12'>
        <ButtonRound>
          <ArrowLeft />
        </ButtonRound>
        <div className='flex flex-col space-y-6'>
          <div className='flex flex-col space-y-2'>
            <h5 className='text-3.5xl font-semibold text-black'>Email Verification</h5>
            <span className='text-sm text-[#4B4B4B]'>Enter the code that was sent to your email.</span>
          </div>
          <div className='py-2 px-4 bg-[#F2F2F2] text-sm text-[#4B4B4B] rounded-lg'>To finish registering, please enter the verification code we sent you. It might take a few minutes to receive your code.</div>
          {countdownTime >= 56 && <div className='flex flex-row items-center py-2 px-4 bg-[#DAFBF2] rounded-lg'>
          <span className='text-2xl'>
            <CheckCircle />
          </span>
          <span className='ml-2 text-xs text-[#13AE85]'>We've sent you another code</span>
        </div> }
          <Input
            title='Email Verification Code'
            value={verifyCode}
            onClearValue={()=>{setVerifyCode('')}}
            onValueChanged={(e)=>{setVerifyCode(e)}}
          />
        </div>
        <div className='flex flex-col space-y-4'>
          <Button type='primary' onClick={()=>{onSignup()}}>Submit</Button>
          <div className='flex flex-row justify-center'>
            <span className='text-sm text-[#4B4B4B]'>Didn't receive the code?&nbsp;</span>
            <ButtonText onClick={()=>{onSendEmail()}}>Resend code by email</ButtonText>
          </div>
        </div>
      </div>
    </section>
  }

  const renderSuccess = () => {
    return <section className='p-16 bg-white '>
      <div className='flex flex-col w-[420px] space-y-12'>
        <img className='h-[96px] object-contain' src={ImgCongratulation} />
        <div className='flex flex-col space-y-6'>
          <div className='flex flex-col text-center space-y-2'>
            <h5 className='text-3.5xl font-semibold text-black'>Welcome, William!</h5>
            <span className='text-sm text-[#4B4B4B]'>Free trial</span>
          </div>
        </div>
        <Button type='primary'>Confirm</Button>
      </div>
    </section>
  }

  return <section className='relative flex flex-row items-center justify-center h-full bg-[#F2F2F2]'>
    <img className='absolute left-16 top-16 h-8 object-contain' src={ImgLogo} />
    {!isRegisterSuccess && renderStep()}
    {isRegisterSuccess && renderSuccess()}
  </section>
}

export default EmailVertification