import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Header } from "../components";
import { AngleRight as IconArrow } from "../svgr/components";
import { SOLUTIONS } from "../router/constants";
import { InfringementComparison } from "../components/Dialog";
import { InfringementReport as Report } from "../components/Trademark";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/app-state";
import { trademarkListLocalData } from "../redux/presist";
import { persistActions, persistSelectors } from "../redux";
import { base64ToFile } from "../utils/FileUtils";
import { useTrademarkList } from "../components/Solution/TrademarkListHook";
import { ChatTradeMarkReportData } from "../components/Chat/chatdatatype";
import { ReportDataData } from "../api/ai/response";
import useStateRef from "react-usestateref";
import { parseFloatValue } from "../utils/CoinUtils";

const InfringementReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const upoloadDialogRef = useRef<HTMLDialogElement>(null);

  const trademarkListCacheData = useSelector<
    AppState,
    trademarkListLocalData.Selectors
  >(({ persist }) =>
    persistSelectors.local.trademarkListLocalData(
      persist.local.trademarkListLocalData,
      {}
    )
  );

  const [imgFile1, setImgFile1] = useState<File>();
  const [imgFile2, setImgFile2] = useState<File>();

  const [img64, setImg64] = useState<string>();
  const [img642, setImg642] = useState<string>();
  const [imgLocalUrl, setImgLocalUrl] = useState<string>();
  const [imgLocalUrl2, setImgLocalUrl2] = useState<string>();

  const updateComparisonTrademarks = (data: string[]) =>
    dispatch(
      persistActions.local.trademarkListLocalData.updateComparisonTrademarks(
        data
      )
    );

  useEffect(() => {
    console.log(
      "trademarkListCacheData?.getData?.comparisonTrademarks***",
      trademarkListCacheData?.getData?.comparisonTrademarks
    );
    const marks = trademarkListCacheData?.getData?.comparisonTrademarks;
    if (marks && marks.length === 2) {
      const file1 = base64ToFile(marks[0]);
      // if (file1) {
      //   setImgFile1(file1);
      //   setImg64(marks[0]);
      // }
      const file2 = base64ToFile(marks[1]);
      // if (file2) {
      //   setImgFile2(file2);
      //   setImg642(marks[1]);
      // }
      if (file1 && file2) {
        if(imgLocalUrl){
          window.URL.revokeObjectURL(imgLocalUrl)
        }
        if(imgLocalUrl2){
          window.URL.revokeObjectURL(imgLocalUrl2)
        }
        const imageUrl = URL.createObjectURL(file1);
        const imageUrl2 = URL.createObjectURL(file2);
        setImgLocalUrl(imageUrl)
        setImgLocalUrl2(imageUrl2)
        // doSearch(file1, file2, marks[0], marks[1]);
        doSearch(file1, file2, imageUrl, imageUrl2);
      }
    }
    return () => {
      if(imgLocalUrl){
        window.URL.revokeObjectURL(imgLocalUrl)
      }
      if(imgLocalUrl2){
        window.URL.revokeObjectURL(imgLocalUrl2)
      }
    };
  }, [trademarkListCacheData?.getData?.comparisonTrademarks]);

  // useEffect(() => {
  //   if (imgFile1 && imgFile2 && img64 && img642) {
  //     doSearch(imgFile1, imgFile2, img64, img642);
  //   }

  //   return () => {};
  // }, [imgFile1, imgFile2, img64, img642]);

  const [data, setData] = useState<ChatTradeMarkReportData | undefined>();
  useEffect(() => {
    return () => {
      if (data?.data?.localGoodUrl) {
        window.URL.revokeObjectURL(data?.data?.localGoodUrl);
      }
      if (data?.data?.localTradeMarkUrl) {
        window.URL.revokeObjectURL(data?.data?.localTradeMarkUrl);
      }
    };
  }, []);

  const [reportDataData, setReportDataData] = useState<ReportDataData>();

  async function doSearch(
    imgFile1: File,
    imgFile2: File,
    img64: string,
    img642: string
  ) {
    setData(undefined);
    setReportDataData(undefined);
    setImgFile1(imgFile1);
    setImg64(img64);
    setImgFile2(imgFile2);
    setImg642(img642);
    const data = await compareTrademarksByImages(
      img642,
      imgFile2,
      img64,
      imgFile1
    );
    console.log("doSearch---", data);
    setData(data);
    setReportDataData(data?.data?.data);
  }

  const { compareTrademarksByImages } = useTrademarkList();
  const final_score_score = reportDataData
    ? parseFloatValue((reportDataData?.final_score.score * 100),0,2) 
    : "--";
  console.log("final_score_score**", reportDataData, final_score_score);


  const [marks, setMarks, markRef] = useStateRef<string[]>([]);
  return (
    <section className="relative flex flex-col flex-1 h-full">
      <div className="flex flex-col shadow-[0px_4px_5.8px_0px_#0000000D]">
        <Header type="primary" buttonHover="primary" />
      </div>
      <section className="flex flex-row flex-1 mt-0.5 pb-3 bg-[#F9F9F9] overflow-auto">
        <div className="relative flex flex-col w-[628px]">
          <div className="flex flex-row pl-20 pt-6">
            <button
              className="inline-flex flex-row items-center space-x-2"
              onClick={() => {
                navigate(SOLUTIONS);
              }}
            >
              <span className="text-xl rotate-180">
                <IconArrow />
              </span>
              <span className="text-base font-semibold text-[#111]">
                Trademark search
              </span>
            </button>
          </div>
          <div className="flex flex-col flex-1 pl-20">
            <h6 className="mt-[72px] text-5.5xl font-semibold">
              <span className="text-[#111111]">final Similarity:&nbsp;</span>
              <span className="text-[#F54040] notranslate">{final_score_score}%</span>
            </h6>
            <div className="text-base font-semibold text-[#111111]">
              Comparison of two brands
            </div>
            <ul className="mt-[35px] flex flex-row space-x-12">
              <li className="flex flex-col">
                {/* <img className="size-[200px] object-scale-down" src={img64} />
                 */}
                 <img className="size-[200px] object-scale-down" src={imgLocalUrl} />
                <span className="text-base font-semibold text-[#111] mt-[16px] text-center">
                  Trademark image
                </span>
              </li>
              <li className="flex flex-col">
                {/* <img className="size-[200px] object-scale-down" src={img642} /> */}
                <img className="size-[200px] object-scale-down" src={imgLocalUrl2} />
                <span className="text-base font-semibold text-[#111] mt-[16px] text-center">
                Infringing images
                </span>
              </li>
            </ul>
          </div>
          <button
            className={
              !reportDataData
                ? "inline-flex justify-center self-center w-[420px] py-[18px] border border-[#BDBDBD] rounded-4xl cursor-not-allowed"
                : "inline-flex justify-center self-center w-[420px] py-[18px] border border-[#BDBDBD] rounded-4xl"
            }
            disabled={!reportDataData}
            onClick={() => {
              upoloadDialogRef.current?.show();
            }}
          >
            <span className="text-base text-[#111] font-semibold text-center">
              Re-upload
            </span>
          </button>
        </div>
        <div className="flex flex-1 bg-white">
          <Report
          hideInfringementDetails={true}
            reportDataData={reportDataData}
            yourTrademark={data?.data.localTradeMarkUrl}
            goodUrl={data?.data.localGoodUrl}
          />

          {/* <Report reportDataData={reportDataData} yourTrademark={data?.data.tradeMarkUrl} goodUrl={data?.data.goodUrl} yourTrademarkFile={data?.data.localGoodUrl} goodUrl={data?.data.goodUrl}/> */}
          {/* <Report reportDataData={reportDataData} yourTrademark={img64} goodUrl={img642}/> */}
        </div>
      </section>
      <InfringementComparison
        ref={upoloadDialogRef}
        pickImage={async (file, imgUrl, goodFile, goodUrl) => {
          console.log("log***", file, imgUrl, goodFile, goodUrl);
          setMarks([])
          if (file && imgUrl && goodFile && goodUrl) {
            const headFile = file;
            const headFile1 = goodFile;
            const reader = new FileReader();

            reader.onload = (e) => {
              console.log("headFile***", e.target?.result);
              if (markRef.current.length > 0) {
                const marks = [e.target?.result as string, ...markRef.current];
                window.URL.revokeObjectURL(imgUrl); 
                window.URL.revokeObjectURL(goodUrl); 
                updateComparisonTrademarks(marks);
              } else {
                setMarks([e.target?.result as string]);
              }
            };
            reader.readAsDataURL(headFile);

            const reader1 = new FileReader();

            reader1.onload = (e) => {
              console.log("headFile1***", e.target?.result);
              if (markRef.current.length > 0) {
                const marks = [...markRef.current, e.target?.result as string];
                window.URL.revokeObjectURL(imgUrl); 
                window.URL.revokeObjectURL(goodUrl); 
                updateComparisonTrademarks(marks);
              } else {
                setMarks([e.target?.result as string]);
              }
            };
            reader1.readAsDataURL(headFile1);

            // doSearch(file, goodFile, imgUrl, goodUrl);
          }

          // upoloadDialogRef.current?.hide();
        }}
      />
    </section>
  );
};

export default InfringementReport;
