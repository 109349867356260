import React from 'react'

type Props = {
  size?: 'large' | 'medium' | 'small' | 'xs',
  name?: string,
  avatar?: string,
}

const DefaultAvator: React.FC<Props> = ({ size = 'medium', name, avatar }: Props) => {

  if (avatar !== '/assets/default_avator.png') {
    if (size === 'xs') {
      return <img className='size-10 rounded-full' src={avatar} />
    }  
    if (size === 'small') {
      return <img className='size-12 rounded-full' src={avatar} />
    }
    if (size === 'medium') {
      return <img className='size-20 rounded-full' src={avatar} />
    }
    if (size === 'large') {
      return <img className='size-[102px] rounded-full' src={avatar} />
    }
  }


  if (size === 'xs') {
    return <div className='inline-flex items-center justify-center size-10 bg-[#0063F2] rounded-full'>
      <span className='text-[#fff] text-xl font-medium'>{name?.charAt(0)}</span>
    </div>
  }

  if (size === 'small') {
    return <div className='inline-flex items-center justify-center size-12 bg-[#0063F2] rounded-full'>
      <span className='text-[#fff] text-xl font-medium'>{name?.charAt(0)}</span>
    </div>
  }

  if (size === 'medium') {
    return <div className='inline-flex items-center justify-center size-20 bg-[#0063F2] rounded-full'>
      <span className='text-[#fff] text-2xl font-medium'>{name?.charAt(0)}</span>
    </div>
  }

  return <div className='inline-flex items-center justify-center size-[102px] bg-[#0063F2] rounded-full'>
    <span className='text-[#fff] text-3xl font-medium'>{name?.charAt(0)}</span>
  </div>
}

export default DefaultAvator