import React, { useState, useEffect, useRef, forwardRef, FC } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Header, Loading } from "../components";
import {
  Search as IconSearch,
  SearchClear as IconSearchClear,
} from "../svgr/components";
import { ClausesAgreement, ContractAgreement } from "../components/Contract";
import { ImgSearchNotFound } from "../images";
import {
  onClauseSearch,
  onContractSearch,
  onSearchTypes,
} from "../api/contract/service";
import { DEFAULT_ARCTICLE_SEARCH_COUNT } from "../service/constant";
import {
  ClausePropsData,
  ClauseSearchItemData,
  ClauseSearchResponseData,
  ContractPropsData,
  ContractSearchItemData,
  ContractSearchResponseData,
  isClauseSearchItemData,
} from "../api/contract/response";
import { ArticleType } from "../api/contract/app";
import { AGREEMENT, AGREEMENT_DOWNLOAD } from "../router/constants";
import { filterHTML } from "../utils/string";
import copy from "copy-to-clipboard";
import useStateRef from "react-usestateref";
import { TipCopied } from "../components/Tips";
import toast from "react-hot-toast";
import { gaCopyClauseEvent } from "../service/ga4Service";
import { useGA4React } from "ga-4-react";

// const options = [
//   { value: "all", label: "ALL" },
//   { value: "contracts", label: "Contracts" },
//   { value: "clauses", label: "Clauses" },
// ];

const options = [
  { value: "all", label: "ALL", placeholder: "Search contracts or clauses" },
  { value: "contracts", label: "Contracts", placeholder: "Search Contracts " },
  { value: "clauses", label: "Clauses", placeholder: "Search Clauses" },
];

export type ContractOptionType = "all" | "contracts" | "clauses";

interface PageData {
  keyword: string;
  type: ContractOptionType;
  contractPageIndex: number;
  clausePageIndex: number;
  contractResultList?: ContractSearchResponseData;
  clasueResultList?: ClauseSearchResponseData;
}

let initPageData: PageData = {
  keyword: "",
  type: "all",
  contractPageIndex: 0,
  clausePageIndex: 0,
  // contractResultList: undefined,
  // clasueResultList: undefined,
};

type Search4RequestData = {
  pageNo: number;
  pageSize: number;
  query: string;
  type: ContractOptionType;
};

const ContractsSearch: FC = () => {
  const [selectOption, setSelectOption] = useState<{
    value: string;
    label: string;
    placeholder: string;
  }>(options[0]);

  const [resultOptions, setResultOptions] = useState<
    { value: ContractOptionType; label: string }[]
  >([
    { value: "contracts", label: "Contracts" },
    { value: "clauses", label: "Clauses" },
  ]);
  const [resultOption, setResultOption, resultOptionRef] = useStateRef<{
    value: ContractOptionType;
    label: string;
  }>(resultOptions[0]);

  useEffect(() => {
    console.log('setResultOption**',resultOption)
  
    return () => {
      
    }
  }, [resultOption])
  

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [pageData, setPageData] = useState<PageData>({ ...initPageData });
  const pageDataRef = useRef(pageData);

  useEffect(() => {
    console.log("pageData***", pageData);
    console.log("pageData***current**", pageDataRef.current);

    pageDataRef.current = pageData;

    return () => {};
  }, [pageData]);

  // const [keyword, setKeyword] = useState<string>("");

  // const [type, setType] = useState<ContractOptionType>("all");
  // const [contractPageIndex, setContractPageIndex] = useState<number>(0);

  // const [clausePageIndex, setClausePageIndex] = useState<number>(0);

  // const [searchData, setSearchData] = useState<Search4RequestData>({
  //   ...initSearchData,
  // });

  // const [contractResultList, setContractResultList] = useState<
  //   ContractSearchItemData[]
  // >([]);

  // const [clasueResultList, setClasueResultList] = useState<
  //   ClauseSearchItemData[]
  // >([]);

  const [resultContractTypeList, setResultContractTypeList] = useState<
    ContractPropsData[]
  >([]);
  const [resultClauseTypeList, setResultClauseTypeList] = useState<
    ClausePropsData[]
  >([]);

  // const p =useParams()
  // console.log('p**',p)
  const { state, pathname } = useLocation();

  // const clearState = () => {
  //   // 替换为当前路径，但不带状态
  //   navigate(pathname,{replace:true});
  // };
  // console.log('lp**',l)

  // const searchDataRef = useRef(searchData)

  // useEffect(() => {
  //   searchDataRef.current =searchData

  //   return () => {

  //   }
  // }, [searchData])

  useEffect(() => {
    if (state) {
      const w = state?.q;
      // w && setKeyword(w);

      const t: ContractOptionType = state?.t as ContractOptionType;
      // t && setType(t as ContractOptionType);

      console.log("state***", state, w, t);

      if (w && t) {
        // setPageData({ ...pageData, keyword: w, type: t });
        setPageData((p) => {
          const n: PageData = { ...p, keyword: w, type: t };
          console.log("setwt**pageDatan", n);
          return n;
        });
      } else if (w) {
        // setPageData({ ...pageData, keyword: w });
        setKeyword(w);
      } else if (t) {
        // setPageData({ ...pageData, type: t });
        setType(t);
      }

      const firstData = state?.firstData;
      const firstList = state?.firstList;
      if (firstData && firstList) {
        setFirstData(firstData);
        setFirstList(firstList);
        setClausePageIndex(1);
        setResultOption( resultOptions[1] )
      } else {
        doSearch({
          pageNo: 1,
          pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
          query: w || "",
          type: t || "all",
        });
      }
      // else if(w && t){
      //   setSearchData({
      //     ...searchData,
      //     query: w,
      //     type:t as ContractOptionType
      //   });
      // }
      // else if(w){
      //   setSearchData({
      //     ...searchData,
      //     query: w,
      //   });
      // }
      // else if(t){
      //   setSearchData({
      //     ...searchData,
      //     type:t as ContractOptionType
      //   });
      // }
    }

    return () => {};
  }, [state]);

  // useEffect(() => {

  //   if (searchParams && searchParams.size > 0) {
  //     const w = searchParams.get("q");
  //     w && setKeyword(w)
  //     // w && searchParams.delete('q')

  //     const t:ContractOptionType = searchParams.get("t") as ContractOptionType;
  //     console.log("searchParams***", searchParams, w, t);
  //     t && setType(t as ContractOptionType);

  //     // t && searchParams.delete('t')
  //     if(w && t){
  //       setSearchData({
  //         ...searchData,
  //         query: w,
  //         type:t as ContractOptionType
  //       });
  //     }
  //     else if(w){
  //       setSearchData({
  //         ...searchData,
  //         query: w,
  //       });
  //     }
  //     else if(t){
  //       setSearchData({
  //         ...searchData,
  //         type:t as ContractOptionType
  //       });
  //     }

  //   }

  //   return () => {};
  // }, [searchParams]);

  // useEffect(() => {
  //   doSearch()

  //   return () => {};
  // }, [searchData]);

  // const updateSearchData = ()=>{
  //   keyword &&
  //   setSearchData({
  //     ...searchData,
  //     query: keyword,
  //     type:type
  //   });
  //   // getNewSearchResultList();
  // }

  const setContractResultList = (v: ContractSearchResponseData | undefined) => {
    setPageData((p) => {
      const n: PageData = { ...p, contractResultList: v };
      console.log("setContractResultList**pageData**", n);
      return n;
    });
  };

  const setClasueResultList = (v: ClauseSearchResponseData | undefined) => {
    setPageData((p) => {
      const n: PageData = { ...p, clasueResultList: v };
      console.log("setClasueResultList**pageDatan", n);
      return n;
    });
    // setPageData(n);
  };

  const setContractPageIndex = (v: number) => {
    setPageData((p) => {
      const n: PageData = { ...p, contractPageIndex: v };
      console.log("setContractPageIndex**pageDatan", n);
      return n;
    });
  };

  const setClausePageIndex = (v: number) => {
    setPageData((p) => {
      const n: PageData = { ...p, clausePageIndex: v };
      console.log("setClausePageIndex**pageDatan", n);
      return n;
    });
    // setPageData({ ...pageData, clausePageIndex: v });
  };

  const setType = (v: ContractOptionType) => {
    setPageData((p) => {
      const n: PageData = { ...p, type: v };
      console.log("setType**pageDatan", n);
      return n;
    });
  };

  const setKeyword = (v: string) => {
    setPageData((p) => {
      const n: PageData = { ...p, keyword: v };
      console.log("setKeyword**pageDatan", n);
      return n;
    });
  };

  const doSearch = async (searchData: Search4RequestData) => {
    setClausePageIndex(0);
    setContractPageIndex(0);
    setLoading(true);
    // const s = searchData;
    // if (!s.query) return;
    let result = false;
    (
      await Promise.all([
        getSearchArticleTypeList({ ...searchData, pageNo: 1 }),
        getNewSearchResultList(searchData),
      ])
    )?.map((item) => {
      result = result || item;
    });
    console.log("doSearch**result**", result);
    setHasSearchResult(result);
    setLoading(false);
    // const result1=await getNewSearchResultList();
    // const result2=await getSearchArticleTypeList();
    // setHasSearchResult(result1 || result2)
  };

  const getNewSearchResultList = async (searchData: Search4RequestData) => {
    const s = searchData; //searchDataRef.current
    // if (s.query)
    {
      // if(s.pageNo > 1) setContractResultList([])

      if (s.type == "all") {
        setResultOption(resultOptions[0]);
        const result = await onContractSearch({
          pageNo: s.pageNo,
          pageSize: s.pageSize,
          query: s.query,
        });
        console.log("getNewSearchResultList**result**", result.data);
        let resultAll = false;
        if (result.success && result.data && result.data?.total > 0) {
          result.data?.list && setContractResultList(result.data);
          // setHasSearchResult(true)
          resultAll = resultAll || true;
          setContractPageIndex(1);
        } else resultAll = resultAll || false;

        const result2 = await onClauseSearch({
          pageNo: s.pageNo,
          pageSize: s.pageSize,
          query: s.query,
        });
        console.log("onClauseSearch**result**", result2.data);

        if (result2.success && result2.data && result2.data?.total > 0) {
          result2.data?.list && setClasueResultList(result2.data);
          // setHasSearchResult(true)
          resultAll = resultAll || true;
          setClausePageIndex(1);
        } else resultAll = resultAll || false;
        return resultAll;
      } else if (s.type === "contracts") {
        setResultOption(resultOptions[0]);
        const result = await onContractSearch({
          pageNo: s.pageNo,
          pageSize: s.pageSize,
          query: s.query,
        });
        console.log("getNewSearchResultList**result**", result.data);

        if (result.success && result.data && result.data?.total > 0) {
          result.data?.list && setContractResultList(result.data);
          // setHasSearchResult(true)
          setContractPageIndex(1);
          return true;
        }
        return false;
      } else {
        setResultOption(resultOptions[1]);
        const result = await onClauseSearch({
          pageNo: s.pageNo,
          pageSize: s.pageSize,
          query: s.query,
        });
        console.log("onClauseSearch**result**", result.data);

        if (result.success && result.data && result.data?.total > 0) {
          result.data?.list && setClasueResultList(result.data);
          // setHasSearchResult(true)
          setClausePageIndex(1);
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const getNextSearchResultList = async (searchData: Search4RequestData) => {
    const s = searchData; //searchDataRef.current
    console.log("getNextSearchResultList***searchData**", s);
    console.log("getNextSearchResultList***pageData****", pageDataRef.current);
    if (s.type !== "clauses") {
      const result = await onContractSearch({
        pageNo: s.pageNo,
        pageSize: s.pageSize,
        query: s.query,
      });

      const contractResultList = pageDataRef.current.contractResultList;
      console.log(
        "getNextSearchResultList***onContractSearch**result**",
        contractResultList,
        result.data
      );

      if (result.success && result.data && result.data?.total > 0) {
        if (result.data?.list) {
          if (contractResultList?.list) {
            setContractResultList({
              list: [...contractResultList?.list, ...result.data?.list],
              total: result.data?.total,
            });
          } else setContractResultList(result.data);
        }

        // setHasSearchResult(true)
        setContractPageIndex(s.pageNo);
        return true;
      }
      return false;
    } else {
      const result = await onClauseSearch({
        pageNo: s.pageNo,
        pageSize: s.pageSize,
        query: s.query,
      });

      const clasueResultList = pageDataRef.current.clasueResultList;
      console.log(
        "getNextSearchResultList*onClauseSearch**result**",
        result.data
      );

      if (result.success && result.data && result.data?.total > 0) {
        // result.data?.list &&
        //   setClasueResultList([...clasueResultList, ...result.data?.list]);

        if (result.data?.list) {
          if (clasueResultList?.list) {
            setClasueResultList({
              list: [...clasueResultList?.list, ...result.data?.list],
              total: result.data?.total,
            });
          } else setClasueResultList(result.data);
        }
        setClausePageIndex(s.pageNo);
        return true;
      }
      return false;
    }
  };

  const getSearchArticleTypeList = async (searchData: Search4RequestData) => {
    const s = searchData; //searchDataRef.current
    //  if (s.query && s.type === 'all') {
    //   if(s.pageNo > 1){
    //     setResultContractTypeList([])
    //     setResultClauseTypeList([])
    //   }
    //    const result = await onSearchTypes(s.query);
    //    console.log("getSearchArticleTypeList**result**", result.data);

    //    if (result.success && (result.data?.contracts && result.data?.contracts.length > 0 || result.data?.clauses && result.data?.clauses.length > 0)) {
    //     result.data?.contracts && setResultContractTypeList(result.data?.contracts)
    //     result.data?.clauses && setResultClauseTypeList(result.data?.clauses)
    //     return true;
    //   }
    //   return false  ;
    //  }
    //  else
    // if (s.query) {
    // if (s.pageNo > 1) {
    //   setResultContractTypeList([]);
    //   setResultClauseTypeList([]);
    // }
    const type: ArticleType | undefined =
      s.type !== "clauses"
        ? s.type === "contracts"
          ? ArticleType.Contract
          : undefined
        : ArticleType.Clause;

    if (type === ArticleType.Contract) {
      // resultOption !== resultOptions[0] && setContractResultList(undefined);
      // resultOption !== resultOptions[1] && setClasueResultList(undefined);
      // setResultOption(resultOptions[0]);

      const result = await onSearchTypes(s.query, type);
      console.log("getSearchArticleTypeList**result**", result.data);

      if (
        result.success &&
        ((result.data?.contracts && result.data?.contracts.length > 0) ||
          (result.data?.clauses && result.data?.clauses.length > 0))
      ) {
        if (result.data?.contracts) {
          if (s.query && result.data?.contracts.length > 10)
            setResultContractTypeList(
              result.data?.contracts.filter((item, index) => index < 10)
            );
          else setResultContractTypeList(result.data?.contracts);
        }

        if (result.data?.clauses) {
          if (s.query && result.data?.clauses.length > 10)
            setResultClauseTypeList(
              result.data?.clauses.filter((item, index) => index < 10)
            );
          else setResultClauseTypeList(result.data?.clauses);
        }

        return true;
      }
    } else if (type === ArticleType.Clause) {
      // resultOption !== resultOptions[1] && setContractResultList(undefined);
      // resultOption !== resultOptions[1] && setClasueResultList(undefined);
      // setResultOption(resultOptions[1]);

      const result = await onSearchTypes(s.query, type);
      console.log("getSearchArticleTypeList**result**", result.data);

      if (
        result.success &&
        ((result.data?.contracts && result.data?.contracts.length > 0) ||
          (result.data?.clauses && result.data?.clauses.length > 0))
      ) {
        if (result.data?.contracts) {
          if (s.query && result.data?.contracts.length > 10)
            setResultContractTypeList(
              result.data?.contracts.filter((item, index) => index < 10)
            );
          else setResultContractTypeList(result.data?.contracts);
        }

        if (result.data?.clauses) {
          if (s.query && result.data?.clauses.length > 10)
            setResultClauseTypeList(
              result.data?.clauses.filter((item, index) => index < 10)
            );
          else setResultClauseTypeList(result.data?.clauses);
        }
        return true;
      }
    } else {
      // setContractResultList(undefined);
      // setClasueResultList(undefined);

      const result = await onSearchTypes(s.query);
      console.log("getSearchArticleTypeList**result**", result.data);

      if (
        result.success &&
        ((result.data?.contracts && result.data?.contracts.length > 0) ||
          (result.data?.clauses && result.data?.clauses.length > 0))
      ) {
        if (result.data?.contracts) {
          if (s.query && result.data?.contracts.length > 10)
            setResultContractTypeList(
              result.data?.contracts.filter((item, index) => index < 10)
            );
          else setResultContractTypeList(result.data?.contracts);
        }

        if (result.data?.clauses) {
          if (s.query && result.data?.clauses.length > 10)
            setResultClauseTypeList(
              result.data?.clauses.filter((item, index) => index < 10)
            );
          else setResultClauseTypeList(result.data?.clauses);
        }
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const type = pageData.type;
    if (!type) return;
    switch (type) {
      case "all":
        setSelectOption(options[0]);
        break;
      case "contracts":
        setSelectOption(options[1]);
        break;
      case "clauses":
        setSelectOption(options[2]);
        break;

      default:
        break;
    }

    return () => {};
  }, [pageData.type]);

  const [firstData, setFirstData] = useState<
    ContractSearchItemData | ClauseSearchItemData
  >();
  const [firstList, setFirstList] = useState<
    ContractSearchItemData[] | ClauseSearchItemData[]
  >();

  //判断当前情况是否可以下拉加载
  useEffect(() => {
    console.log(
      "是否可以下拉加载***setHasMore**",
      pageData.clasueResultList,
      pageData.contractResultList,
      resultOption
    );
    if (resultOption === resultOptions[0]) {
      if (pageData.contractResultList === undefined) {
        setHasMore(false);
        return;
      }
      if (
        pageData.contractResultList.total >
        pageData.contractResultList.list.length
      ) {
        setHasMore(true);
      } else setHasMore(false);
    } else if (resultOption === resultOptions[1]) {
      if (pageData.clasueResultList === undefined) {
        setHasMore(false);
        return;
      }
      if (
        pageData.clasueResultList.total > pageData.clasueResultList.list.length
      ) {
        setHasMore(true);
      } else setHasMore(false);
    }

    return () => {};
  }, [pageData.clasueResultList, pageData.contractResultList, resultOption]);

  useEffect(() => {
    if (firstData && firstList) {
      (async () => {
        const searchData: Search4RequestData = {
          pageNo: 1,
          pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
          query: pageData.keyword,
          type: pageData.type,
        };
        getSearchArticleTypeList(searchData);

        if (!isClauseSearchItemData(firstData)) {
          const data: ContractSearchItemData =
            firstData as unknown as ContractSearchItemData;
          const list: ContractSearchResponseData =
            firstList as unknown as ContractSearchResponseData;
          const fixList = list.list.filter((item) => item.id !== data.id);
          const newList = [data, ...fixList];
          console.log("setContractResultList**", newList);
          setContractResultList({ list: newList, total: list.total });
        } else {
          const data: ClauseSearchItemData =
            firstData as unknown as ClauseSearchItemData;
          const list: ClauseSearchResponseData =
            firstList as unknown as ClauseSearchResponseData;
          const fixList = list.list.filter((item) => item.id !== data.id);
          const newList = [data, ...fixList];
          console.log("setClasueResultList**", newList);
          setClasueResultList({ list: newList, total: list.total });
        }
        setHasSearchResult(true);
      })();
    }

    return () => {};
  }, [firstList]);

  const renderNoResult = () => {
    return (
      <>
        <div className="flex flex-col items-center mt-[180px]">
          <img className="size-[124px]" src={ImgSearchNotFound} />
          <div className="mt-[13px] mb-[7px] text-base text-[#111] font-semibold">
            Oops!
          </div>
          <span className="text-xs text-[#9D9FA1]">No Result Found</span>
        </div>
      </>
    );
  };

  const [hasSearchResult, setHasSearchResult] = useState<boolean>();

  const listRef = useRef(document.getElementById("root") as HTMLElement);
  // const listRef =ref as  React.LegacyRef<HTMLElement>
  const [loading, setLoading, loadingRef] = useStateRef<boolean>(false);
  const [hasMore, setHasMore, hasMoreRef] = useStateRef<boolean>(false);

  useEffect(() => {
    console.log("hasMore***", hasMore);

    return () => {};
  }, [hasMore]);

  useEffect(() => {
    //   // 添加滚动事件监听器
    const handleScroll = () => {
      // console.log("handleScroll***");

      if (listRef?.current) {
        const { scrollTop, scrollHeight, clientHeight } = (listRef as any)
          .current;
        // console.log(
        //   "handleScroll***sss",
        //   scrollTop + clientHeight >= scrollHeight - 5,
        //   !loadingRef.current,
        //   hasMoreRef.current,
        //   scrollTop,
        //   scrollHeight,
        //   clientHeight
        // );
        // 检查是否滚动到底部
        if (
          scrollTop + clientHeight >= scrollHeight - 5 &&
          !loadingRef.current &&
          hasMoreRef.current
        ) {
          console.log("handleScroll***loadMoreData");
          loadMoreData();
        }
      }
    };

    listRef?.current?.addEventListener("scroll", handleScroll);

    return () => {
      listRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const loadMoreData = async () => {
    setLoading(true);

    await getNextSearchResultList({
      pageNo:
        //pageDataRef.current.type === "contracts"//?
        resultOptionRef.current.value === "contracts"
          ? pageDataRef.current.contractPageIndex + 1
          : pageDataRef.current.clausePageIndex + 1,
      pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
      query: pageDataRef.current.keyword,
      type: resultOptionRef.current.value, //resultOption.value as ContractOptionType//
    });

    console.log(
      "loadingmore**",
      resultOption,
      resultOptionRef.current,
      pageDataRef.current.type,
      pageDataRef.current.contractPageIndex,
      pageDataRef.current.clausePageIndex
    );
    setLoading(false);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      clearSearchs();
      doSearch({
        pageNo: 1,
        pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
        query: pageDataRef.current.keyword,
        type: pageDataRef.current.type,
      });
    }
  };

  const renderLoading = () => {
    return (
      <div className="flex-grow flex items-center justify-center">
        <Loading></Loading>
      </div>
    );
  };

  const clearSearchs = () => {
    setResultContractTypeList([]);
    setResultClauseTypeList([]);
    setContractResultList(undefined);
    setClasueResultList(undefined);
    setHasSearchResult(false)
  };

  const ga4 =useGA4React()

  const submitGA = (data:any)=>{
    ga4&& gaCopyClauseEvent(ga4,data)
  }

  // ref={ref as React.LegacyRef<HTMLElement>}
  return (
    <section className="relative flex flex-col flex-1 bg-white h-full">
      <Header type="default" />
      <div className="flex flex-row justify-between mx-20 mt-4 mb-2">
        <h6 className="text-5.5xl font-semibold text-black">search</h6>
        <div className="group flex flex-row items-center w-[57.5%] h-[54px] px-8 border border-[#ddd] rounded-full">
          <input
            className="flex-1 bg-transparent text-base text-[#111] outline-none placeholder:text-[#9D9FA1]"
            placeholder={selectOption.placeholder}
            value={pageData?.keyword || ""}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
          <div className="flex flex-row items-center space-x-4">
            <button
              className="hidden text-2xl group-focus-within:inline-flex"
              onClick={() => {
                setKeyword("");
              }}
            >
              <IconSearchClear />
            </button>
            <button
              className="inline-flex text-[28px]"
              onClick={() => {
                clearSearchs();
                doSearch({
                  pageNo: 1,
                  pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
                  query: pageData?.keyword,
                  type: pageData?.type,
                });
              }}
            >
              <IconSearch />
            </button>
          </div>
        </div>
        <h6 className="text-5.5xl font-semibold text-black opacity-0">
          search
        </h6>
      </div>
      <ul className="flex flex-row mx-auto space-x-6">
        {options.map((item, index) => (
          <li
            key={index}
            aria-checked={item.value === selectOption.value}
            className="group inline-flex items-center h-12 px-4 bg-transparent rounded-full border border-[#ddd] cursor-pointer
             aria-checked:bg-[#F2F2F2] aria-checked:border-[#F2F2F2]"
            onClick={() => {
              // setSelectOption(item);
              setType(item.value as ContractOptionType);
            }}
          >
            <span
              className="text-base text-[#282828] 
            group-aria-checked:text-black group-aria-checked:font-semibold"
            >
              {item.label}
            </span>
          </li>
        ))}
      </ul>
      {loading && !pageData?.contractResultList?.list && renderLoading()}
      {hasSearchResult && (
        <div className="flex flex-col mx-20 pb-5 space-y-8">
          {resultContractTypeList?.length > 0 && (
            <div className="flex flex-col">
              <h6 className="font-semibold text-xl leading-[30px] text-[#111]">
                Contracts
              </h6>
              <ul className="grid grid-cols-320auto gap-[18px] mt-4">
                {resultContractTypeList?.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="flex flex-row p-4 bg-[#F9F9F9] rounded-2xl hover:bg-[#F2F2F2] cursor-pointer"
                      onClick={() => {
                        const url = `${AGREEMENT}/contracts?id=${item.id}&n=${item.name}`;
                        navigate(url);
                      }}
                    >
                      <span className="text-base text-[#111] line-clamp-1">
                        {item.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {resultClauseTypeList?.length > 0 && (
            <div className="flex flex-col">
              <h6 className="font-semibold text-xl leading-[30px] text-[#111]">
                Clauses
              </h6>
              <ul className="grid grid-cols-320auto gap-[18px] mt-4">
                {resultClauseTypeList?.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="flex flex-row p-4 bg-[#F9F9F9] rounded-2xl hover:bg-[#F2F2F2] cursor-pointer"
                      onClick={() => {
                        const url = `${AGREEMENT}/clauses?id=${item.id}&n=${item.name}`;
                        navigate(url);
                      }}
                    >
                      <span className="text-base text-[#111] line-clamp-1">
                        {item.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {(resultContractTypeList.length > 0 ||
            resultClauseTypeList.length > 0) && (
            <hr className="border-[#DDD]" />
          )}
          <div className="flex flex-col">
            <ul className="flex flex-row mb-4 space-x-6">
              {resultOptions.map((item, index) => (
                <li
                  key={index}
                  aria-checked={item.value === resultOption.value}
                  className="group inline-flex items-center h-12 px-4 bg-transparent rounded-full border border-[#ddd] cursor-pointer
             aria-checked:bg-[#F2F2F2] aria-checked:border-[#F2F2F2]"
                  onClick={() => {
                    setResultOption(item);
                  }}
                >
                  <span
                    className="text-base text-[#282828] 
                group-aria-checked:text-black group-aria-checked:font-semibold"
                  >
                    {item.label}
                  </span>
                </li>
              ))}
            </ul>
            <ul className="flex flex-col space-y-4">
              {resultOption === resultOptions[0] &&
                pageData?.contractResultList?.list.map((item) => {
                  return (
                    <ContractAgreement
                      key={item.id}
                      type={item.typeName}
                      filed={item.filedDate}
                      jurisdiction={item.jurisdiction}
                      onClick={() => {
                        const url = `${AGREEMENT_DOWNLOAD}/${item.id}`;
                        navigate(url);
                      }}
                    >
                      {item.title}
                    </ContractAgreement>
                  );
                })}

              {resultOption === resultOptions[1] &&
                pageData?.clasueResultList?.list.map((item) => {
                  return (
                    <ClausesAgreement
                      key={item.id}
                      onCopyClick={() => {
                        toast.custom((t) => <TipCopied />, {
                          duration: 500,
                        });
                        submitGA({id:item.id})
                        copy(filterHTML(item.content));
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></div>
                    </ClausesAgreement>
                  );
                })}
              {resultOption === resultOptions[0] &&
                pageData?.contractResultList?.list &&
                loading &&
                renderLoading()}
              {resultOption === resultOptions[1] &&
                pageData?.clasueResultList?.list &&
                loading &&
                renderLoading()}
            </ul>
          </div>
        </div>
      )}
      {hasSearchResult === false &&
        !loading &&
        !pageData?.contractResultList?.list &&
        renderNoResult()}
    </section>
  );
};

export default ContractsSearch;
