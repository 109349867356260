import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { decode, encode } from "../../utils/saltBase64";
import { MD5 } from "../../utils/saltMD5";
import { DASHBOARD } from "../../router/constants";

import * as stringUtils from "../../utils/string";
import {
  checkContainSpeicalChars,
  checkIsEmail,
  checkIsPassword,
} from "../../utils/regularUtils";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/app-state";
import { userInfoLocalData } from "../../redux/local";
import { persistSelectors, sharedActions, sharedSelectors } from "../../redux";
import { authLocalData } from "../../redux/presist";
import { FormAnyUnitType, FormUnitType2 } from "../../service/formdata";
import useLogin from "../../hooks/login-hook";
import useUserInfo from "../../hooks/userinfo-hook";
import { uplaodAvatarImage, uplaodFeedbackImage } from "../../api/file/app";
import { FeedbackType, feedback } from "../../api/feedback/app";
import { ServiceResponse } from "../../service/data";
import { getUserInfo, updateUserInfo } from "../../api/user/app";
import { UpdateUserInfoRequestData } from "../../api/user/app";
import { UserInfoData } from "../../api/user/response";
import { buildUserInfoCacheData } from "../../service/user";
import { UserInfoLocalData } from "../../redux/local/userinfo-local-data";

export type PageData = {
  user: FormUnitType2;
  company: FormUnitType2;
  email: FormUnitType2;
  imageFile: any
};

const initData: PageData = {
  user: {
    value: "",
    type: "empty",
  },
  company: {
    value: '',
    type: "empty",
  },
  email: {
    value: "",
    type: "empty",
  },
  imageFile:undefined
};

const useMyprofile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState<PageData>({...initData});

  const { userName,companyName,email,avatar } = useUserInfo();
  
  useEffect(() => {
   const data:PageData ={...initData,
    email: {
      value: email ||"",
      type:!!email?'useful':'empty'
    },
    user: {
      value: userName||"",
      type:!!userName?'useful':'empty'
    },
    company: {
      value: companyName||"",
      type:!!companyName?'useful':'empty'
    },
  }
  setFormData(data)
    return () => {
      
    }
  }, [userName])
  

  const setUser = (value: string) => {
    const type = !!value ? "useful" : "empty";
    console.log("setUser**", type);
    setFormData({
      ...formData,
      user: {
        value,
        type: type,
      },
    });
  };

  const setCompany = (value: string) => {
    const type = !!value ? "useful" : "empty";
    console.log("setCompany**", type);
    setFormData({
      ...formData,
      company: {
        value,
        type: type,
      },
    });
  };

  const setImageFile = (value: any) => {
    console.log("imageFile**", value);
    setFormData({
      ...formData,
      imageFile: value
    });
  };


  const checkAvailableListForRegister = (list: PageData): boolean => {
    let available = false;
    if((!!list.company.value && list.company.value !== companyName)||(!!list.user.value && list.user.value !== userName)|| (!!list.imageFile)) available = true;
    return available;
  };

  const [canNext, setCanNext] = useState(false)
  useEffect(() => {
    setCanNext(checkAvailableListForRegister(formData))
  
    return () => {
      
    }
  }, [formData])
  

  const onSubmit= async():Promise<ServiceResponse>  => {
    if (!canNext) {
      return {success:false,error:'para error'};
    }
    setCanNext(false)
    //上传图片
    let imgUrl=avatar;
    if(formData.imageFile){
     const uploadResult= (await uplaodAvatarImage(formData.imageFile))
     if(uploadResult?.status !== 200){
      return {success:false,error:'upload error'};
     }
     imgUrl = uploadResult?.data
    }
    //提交反馈
    const data:UpdateUserInfoRequestData = {
      avatar: imgUrl,
      companyName: formData.company.value,
      nickname: formData.user.value
    }
    const result = (await updateUserInfo(data))?.data?.success
    console.log('updateUserInfo**',result)
    if(result){
      updateUserInfoAfter()
    }
    return {success:result,error:'updateUserInfo '+result.toString()};
  };


  const userInfoCacheData = useSelector<AppState, userInfoLocalData.Selectors>(
    ({ shared }) =>
      sharedSelectors.local.userInfoLocalData(
        shared.local.userInfoLocalData,
        {}
      )
  );

  const authInfoCacheData = useSelector<AppState, authLocalData.Selectors>(
    ({ persist }) =>
      persistSelectors.local.authLocalData(persist.local.authLocalData, {})
  );

  const { onLogout } = useLogin()
  const dispatch = useDispatch();
  const updateData = (data: UserInfoLocalData) =>
  dispatch(sharedActions.local.userInfoLocalData.update(data));

  const updateUserInfoAfter = async () => {
    try {
      if (!authInfoCacheData.getData.accessToken) return;
      let needLogOut = false;
      const responseData = await getUserInfo().catch((e) => {
        console.log("getInfo**error", e);
        if(e?.response?.status === 401){
          needLogOut = true;
          onLogout();
        }
      });

      console.log("getInfo**", responseData?.data);
      if ( needLogOut) return;
      if (responseData?.data.code === 401) {
        onLogout();
      }
      const resData: UserInfoData = responseData?.data.data;
      console.log("getInfo**responseData", resData);

      //判断用户是否存在
      if (!!resData.email) {
        const buildUserData = buildUserInfoCacheData(resData);
        console.log("getInfo**buildUserData**", buildUserData);
        updateData({ ...userInfoCacheData.getData, ...buildUserData });

        //获取手续费设定
        // getFeeSettingData();
        // //获取playerv2的数据
        // getVideoPlayerFeeSettingData();
        // //获取账户余额
        // getAccounts();
        ////mock获取player充能/升级费用
        // getPlayerFee();
      } else {
        //应该不会出现这种情况
        console.log("user id is null");
      }
    } catch (error) {}
  };

  const initFormData =()=>{
setFormData({...initData})
  }

  return { formData,setImageFile, setUser,setCompany,initFormData, onSubmit,canNext };
};

export default useMyprofile;
