import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import clsx from "clsx";

import { Header, Loading } from '../components'
import { RankSelect } from '../components/Select'
import { Search as IconSearch } from "../svgr/components";
import { AGREEMENT, SEARCH } from "../router/constants";
import { ArticleTypeInfoItemData, ArticleTypeInfoResponseData } from "../api/contract/response";
import { ResponseServiceData, onGetArticleTypesInfo } from "../api/contract/service";
import { ArticleType } from "../api/contract/app";
import { useContractTypes } from "../api/contract/hook";
import { formatNumber0a } from "../utils/numberUtils";
import useStateRef from "react-usestateref";

const options = [
  { value: 'contracts', label: 'Contracts' },
  { value: 'clauses', label: 'Clauses' },
];

const controlStyles = {
  base: "min-w-[132px] rounded-lg bg-white border-none hover:cursor-pointer",
  focus: "border-none",
  nonFocus: "border-none",
};
const valueContainerStyles = "gap-1 text-[#282828] text-xl leading-[34px]";
const singleValueStyles = "mr-2";
const menuStyles = "min-w-[160px] p-3 mt-2 border border-[#F2F2F2] bg-white rounded-2xl shadow-4xl";
const optionStyles = {
  base: "hover:cursor-pointer px-4 py-2.5 text-sm text-[#282828] rounded-2xl",
  focus: "bg-[#F2F2F2] active:bg-[#F2F2F2]",
  selected: "text-[#282828]",
}

const Clauses: React.FC = () => {

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectOption, setSelectOption] = useState<{ value: string, label: string }>();

  const [firstTypeList, setFirstTypeList] = useState<ArticleTypeInfoResponseData>()

  useEffect(() => {
    console.log('firstTypeList**',firstTypeList)
    if (!firstTypeList || firstTypeList.list.length < 1) return;
    // setSelect1stTypeIndex(0)
    search2ndTypes(firstTypeList.list[select1stTypeIndex].id);
    return () => {};
  }, [firstTypeList]);

  const location = useLocation();
  // const { data:originData,selectIndex } = location?.state;
  const [originData, setOriginData] = useState()

  useEffect(() => {
    console.log('state***',location.state)
    if(location?.state?.data){
      setOriginData(location?.state?.data)
    }

    if(location?.state?.selectIndex){
      setSelect1stTypeIndex(location?.state?.selectIndex)
    }
  
    return () => {
      
    }
  }, [location?.state])
  

  
  useEffect(() => {
    console.log('originData**',originData)
    originData && setFirstTypeList(originData)
  
    return () => {
      
    }
  }, [originData])

  const { data: contractTypesData } = useContractTypes(0,ArticleType.Clause);
  const [select1stTypeIndex, setSelect1stTypeIndex] = useState(0)

  useEffect(() => {
    if(originData) return;
    if(contractTypesData){
      setFirstTypeList(contractTypesData);
      setLoading1(false)
    } 
    return () => {
      
    }
  }, [contractTypesData])

  // useEffect(() => {
  //   console.log('firstTypeList**',firstTypeList)
  //   if (!firstTypeList || firstTypeList.total < 1) return;
  //   setSelect1stTypeIndex(0)
  //   search2ndTypes(firstTypeList.list[0].id);
  //   return () => {};
  // }, [firstTypeList]);

  const [secondTypesList, setSecondTypesList] = useState<
    ArticleTypeInfoItemData[]
  >([]);

  const [secondTypesUIList, setSecondTypesUIList] = useState<
  ArticleTypeInfoItemData[]
>([]);

  const doFilter= ()=>{
    if(!searchValue) setSecondTypesUIList(secondTypesList)
    if(searchValue){
      setSecondTypesUIList(secondTypesList?.filter(item=>item.name.toLowerCase().includes(searchValue.toLowerCase())))
    }
  }

  const handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      doFilter();
    }
  };

  const search2ndTypes = async (id: string) => {
    setLoading2(true)
    const result: ResponseServiceData<ArticleTypeInfoResponseData> =
      await onGetArticleTypesInfo({
        pageNo: 1,
        pageSize: 100,
        parentId: id,
        type: 2,
      });

    if (result.success && result.data?.list) {
      setSecondTypesList(result.data?.list);
      setSecondTypesUIList(result.data?.list);
    }
    setLoading2(false)
  };

  const on1stTypeClick = (item:ArticleTypeInfoItemData,index:number)=>{
    setSecondTypesList([])
    setSecondTypesUIList([])
    setSelect1stTypeIndex(index)
    search2ndTypes(item.id);
  }

  const [searchValue, setSearchValue] = useState('')

  const [loading1, setLoading1, loading1Ref] = useStateRef<boolean>(true);
  const [loading2, setLoading2, loading2Ref] = useStateRef<boolean>(false);

  const renderLoading = () => {
    return (
      <div className="flex-grow flex items-center justify-center">
        <Loading></Loading>
      </div>
    );
  };

  return <section className="relative flex flex-col flex-1 h-full bg-white">
    <Header type='default' />
    <div className='flex flex-row mx-20'>
      <h6 className='w-[306px] mr-16 text-5.5xl font-semibold text-black'>Clauses</h6>
      <div className='flex flex-row items-center w-[57.5%] h-[54px] px-8 border border-[#ddd] rounded-full'>
        <input
          className='flex-1 bg-transparent text-base text-[#111] outline-none placeholder:text-[#9D9FA1]'
          placeholder='Search Clauses'
          value={searchValue}
          onChange={(e)=>{
            setSearchValue(e.target.value)
          }}
          onKeyDown={handleKeyDown}
        />
        <button className='inline-flex text-[28px]' onClick={()=>{
            doFilter();
          }}>
          <IconSearch />
        </button>
      </div>
    </div>
    {!loading1 && <div className='flex flex-row mt-8 flex-1 overflow-auto'>
      <ul className="flex flex-col pl-20 space-y-4">
        {firstTypeList?.list?.map((item,index) => {
            return (
              <button
                aria-busy={index === select1stTypeIndex}
                key={item.id}
                className="inline-flex w-[306px] p-4 rounded-2xl hover:bg-[#F2F2F2]
                aria-busy:bg-[#F2F2F2]"
                onClick={() => {
                  // if(index === select1stTypeIndex) return;
                  on1stTypeClick(item,index)
                }}
              >
                <span
                  className="text-base text-[#111] text-left line-clamp-1"
                >
                  {item.name}
                </span>
              </button>)
          })}
      </ul>
      {
        (firstTypeList && firstTypeList.list.length > 0) && <div className='h-full w-[1px] mx-8 bg-[#D9D9D9]' />
      }
      <div className='flex flex-1 flex-col pr-20 pb-8 overflow-auto'>
        {!loading2 && <div className='grid grid-cols-2 gap-4 items-start'>
        
           {secondTypesUIList?.map((item) => {
              return (
                <button key={item.id}
                  className="flex flex-row items-center justify-between p-4 bg-[#F9F9F9] rounded-2xl text-base hover:bg-[#F2F2F2]"
                  onClick={() => {
                    const url = `${AGREEMENT}/clauses?id=${item.id}&n=${item.name}`;
                    navigate(url);
                  }}
                >
                  <span className="text-[#111] line-clamp-1 mr-4">
                    {item.name}
                  </span>
                  <span className="text-[#9D9FA1]">{formatNumber0a(item.contractCount)?.omit}</span>
                </button>
              );
            })}
        </div>}
        {loading2 && renderLoading()}
      </div>
    </div>}
    {loading1 && renderLoading()}
  </section>
}

export default Clauses