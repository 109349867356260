import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'

import Input from "../Input";
import { Button, ButtonRound, ButtonRoundBorder } from '../Button'
import { ArrowLeft } from '../../svgr/components'
import useUserInfo from "../../hooks/userinfo-hook";
import useMyprofile from "./myporofile-hook";
import DefaultAvator from "../DefaultAvator";

const Myprofile: React.FC = () => {

  const navigate = useNavigate()
  const { avatar, userName } = useUserInfo()

  const { setImageFile, setUser, setCompany, initFormData, formData, canNext, onSubmit } =
    useMyprofile();

  const [avatorUrl, setAvatorUrl] = useState<string>(avatar);

  const handlePicker = (event: any) => {
    const files = event.target.files;
    console.log("handlePicker**files**", files);
    if (files && files.length > 0) {
      console.log("handlePicker**avatorUrl**", avatorUrl);
      if (avatorUrl && avatorUrl !== avatar) {
        window.URL.revokeObjectURL(avatorUrl);
      }
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      console.log("handlePicker**file**", file);
      console.log("handlePicker**imageUrl**", imageUrl);
      setAvatorUrl(imageUrl);
      setImageFile(file);
    }
  };



  return <article className="relative flex flex-col flex-1 py-4 mt-4 bg-white rounded-4xl overflow-hidden">
    {/* <img src={imageData} alt="base64 image" /> */}
    <div className="flex flex-col w-[420px] mx-auto my-[80px] space-y-6">
      <div className='flex flex-row space-x-4'>
        <ButtonRound onClick={() => { navigate(-1) }}>
          <ArrowLeft />
        </ButtonRound>
        <h6 className='font-semibold text-3.5xl'>My profile</h6>
      </div>
      <div className="flex flex-row items-end space-x-4">
        {/* <img className='size-[102px] rounded-full object-cover' src={avatorUrl} /> */}
        <DefaultAvator name={userName} avatar={avatorUrl} size={'large'}/>
        <ButtonRoundBorder>
          <div className='relative'>
            <span>Upload</span>
            <input
              type='file'
              className='absolute inset-0 opacity-0 z-10'
              onChange={(event) => {
                console.log("onChange**event**", event);
                handlePicker(event);
                event.target.value = "";
              }}
            />
          </div>
        </ButtonRoundBorder>
      </div>
      <div className="flex flex-col space-y-4 pb-6">
        <Input
          title='Name'
          value={formData.user.value}
          onClearValue={() => { setUser('') }}
          onValueChanged={(e) => { setUser(e) }}
        />
        <Input
          title='Firm/Company Name'
          value={formData.company.value}
          onClearValue={() => { setCompany('') }}
          onValueChanged={(e) => { setCompany(e) }}
        />
        <Input
          disabled
          value={formData.email.value}
          title='Email'
          optional={false}
        />
      </div>
      <Button disabled={!canNext} type={canNext ? 'primary' : 'normal'} onClick={async () => {
        const result = await onSubmit();
        console.log('result**', result)
      }}>Submit</Button>
    </div>
  </article>
}

export default Myprofile