//"(?:[^"\\]|\\.)*"匹配双引号
//\d匹配所有整数
//\d+匹配单或多位整数

import { ResponseData } from "../response";
import { ArticleType } from "./app";

export type SearchTypeResponseData = ResponseData<SearchTypeData>;

export enum ContractType {}

export enum ClauseType {}

export type ContractPropsData = {
  id: number;
  name: string;
  type: ContractType;
};

export type ClausePropsData = {
  id: number;
  name: string;
  type: ClauseType;
};

export type SearchTypeData = {
  contracts: ContractPropsData[];
  clauses: ClausePropsData[];
};

export interface ContractSearchItemData {
  id: string;
  typeId: string|number;
  type1Id:string|number;
  typeName:string;
  title: string;
  filedDate: string;
  jurisdiction: string;
  content: string;
  downloadNum: number;
  docUrl:string;
  pdfUrl:string;
};

export type ContractSearchResponseData = {
  list: ContractSearchItemData[];
  total: number;
};

export type ArticleTypeInfoItemData = {
  id: string;
  name: string;
  type: ArticleType;
  contractCount: number;
};

export type ArticleTypeInfoResponseData = {
  list: ArticleTypeInfoItemData[];
  total: number;
};


export interface ClauseSearchItemData {
  id: string;
  typeId: string;
  type1Id:string;
  typeName:string;
  title: string;
  content: string;
  copyNum: number;
};

export function isClauseSearchItemData(a: any): a is ClauseSearchItemData {
  return (
    typeof a === 'object' &&
    a !== null &&
    (typeof a.id === 'string' || typeof a.id === 'number') &&
    (typeof a.typeId === 'string' || typeof a.typeId === 'number') &&
    (typeof a.type1Id === 'string' || typeof a.type1Id === 'number')  &&
    typeof a.typeName === 'string' &&
    typeof a.title === 'string' &&
    typeof a.content === 'string' &&
    typeof a.copyNum === 'number'
  );
}

export type ClauseSearchResponseData = {
  list: ClauseSearchItemData[];
  total: number;
};