import React from 'react'

import { Header, Navbar, ComingSoon, CustomerService } from '../components'
import { Search, InfringingAnalysis } from '../components/Trademark'
import { Right as IconRight } from '../svgr/components'
import { ImgLogos, ImgAdidas, ImgMap, ImgCheckInfringement, ImgMyTrademark, ImgBarrage } from '../images'

const TestCompare: React.FC = () => {

  return <>
   
  </>

}

export default TestCompare