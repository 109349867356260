import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import { Button, ButtonRound } from '../Button'
import { CloseDialog } from '../../svgr/components'
import { ImgUpload } from '../../images'

type Props = {
  pickImage: (file: any, url: string) => void,
  onSubmit?: (file: any, url: string) => void
}

const UploadTrademark = forwardRef(({ pickImage, onSubmit }: Props, ref) => {

  const dialogRef = useRef<HTMLDialogElement>(null)

  const [trademarkUrl, setTrademarkUrl] = useState<string>('');
  const [trademarkFile, setTrademarkFile] = useState<any>();

  const [isReupload, setIsReupload] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      //释放选中的图片
      if (trademarkUrl) {
        window.URL.revokeObjectURL(trademarkUrl);
      }
    };
  }, []);

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }))

  const show = (isReupload?: boolean) => {
    setTrademarkUrl('')
    setTrademarkFile(undefined)
    dialogRef.current?.showModal()
    // if (trademarkUrl) {
    //   window.URL.revokeObjectURL(trademarkUrl);
    // }
    if (isReupload !== undefined) {
      setIsReupload(isReupload)
    }
  }

  const hide = () => {
    dialogRef.current?.close()
    setIsReupload(false)
  }

  const handlePicker = (event: any) => {
    const files = event.target.files;
    console.log("handlePicker**files**", files)
    if (files && files.length > 0) {
      console.log("handlePicker**trademarkUrl**", trademarkUrl)
      if (trademarkUrl) {
        window.URL.revokeObjectURL(trademarkUrl);
      }
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      console.log("handlePicker**file**", file)
      console.log("handlePicker**imageUrl**", imageUrl)
      setTrademarkUrl(imageUrl);
      setTrademarkFile(file);
      pickImage(file, imageUrl)
    }
  };

  return <dialog className='bg-white rounded-4xl backdrop:bg-black/50' ref={dialogRef}>
    <div className='relative flex flex-col p-16'>
      <ButtonRound
        className='absolute top-4 right-[19px]'
        onClick={hide}
      >
        <CloseDialog color='#000' />
      </ButtonRound>
      <div className='flex flex-col w-[420px]'>
        <h6 className='mb-[27px] text-2.5xl leading-[34px] font-semibold text-black text-center'>Your Trademark</h6>
        <div className='flex flex-col space-y-4'>
          <div className='flex flex-col'>
            <span className='mb-1 text-sm text-[#4B4B4B]'>Trademark image</span>
            {isReupload && <span className='mb-1 text-sm text-[#F54040]'>(The uploaded trademark will be replaced)</span>}
            <div className='relative flex flex-col items-center py-6 border border-dashed border-[#1BA4A4] rounded-4xl'>
              <img className='size-[61px]' src={!!trademarkUrl ? trademarkUrl : ImgUpload} />
              <h6 className='mt-6 mb-2.5 text-base font-semibold text-black'>Add or drop a trademark image here.</h6>
              <span className='text-sm text-[#4B4B4B]'>JPG, PNG / Max. 60 MB / Min. 224px X 224px</span>
              <input
                className='absolute inset-0 z-10 opacity-0'
                type='file'
                accept={'image/*'}
                onChange={(event) => {
                  handlePicker(event);
                  event.target.value = '';
                }}
              />
            </div>
          </div>
          <Button
            type='second'
            onClick={() => { onSubmit && onSubmit(trademarkFile, trademarkUrl) }}
          >Submit</Button>
        </div>
      </div>
    </div>
  </dialog>
})

export default UploadTrademark