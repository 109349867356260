import React from 'react'

import { ImgLogo, ImgSuccess } from '../images'
import { Button, ButtonText, ButtonRound } from '../components/Button'
import { Input } from '../components'
import { ArrowLeft } from '../svgr/components'
import useResetPwd from '../hooks/useResetPwd'
import { useNavigate } from 'react-router'
import { LOGIN } from '../router/constants'

const PasswordReset: React.FC = () => {
  const navigate = useNavigate();
  const{formData,setEmail,setPasssword,setConfirmPasssword,setCode,onResetPwd,onSendEmail,onCheckCode}=useResetPwd();
  const renderStep1 = () => {
    return <section className='p-16 bg-white rounded-4xl'>
      <div className='flex flex-col w-[420px] space-y-12'>
        <ButtonRound onClick={()=>{navigate(-1)}}>
          <ArrowLeft />
        </ButtonRound>
        <div className='flex flex-col space-y-6'>
          <div className='flex flex-col space-y-2'>
            <h5 className='text-3.5xl font-semibold text-black'>Password Reset</h5>
            <span className='text-sm text-[#4B4B4B]'>Enter your email and we'll send you a verification code to reset your password</span>
          </div>
          <Input
            title='Email'
            onClearValue={()=>{setEmail('')}}
            onValueChanged={(e)=>{setEmail(e)}}
          />
        </div>
        <Button type='primary' disabled={formData.email.type !== 'legal'} onClick={()=>{
          // console.log(formData)
          if(formData.email.type !== 'legal') return;
          onSendEmail()}}>Submit</Button>
      </div>
    </section>
  }

  const renderStep2 = () => {
    return <section className='p-16 bg-white rounded-4xl'>
      <div className='flex flex-col w-[420px] space-y-12'>
        <ButtonRound onClick={()=>{navigate(-1)}}>
          <ArrowLeft />
        </ButtonRound>
        <div className='flex flex-col space-y-6'>
          <div className='flex flex-col space-y-2'>
            <h5 className='text-3.5xl font-semibold text-black'>Password Reset</h5>
            <span className='text-sm text-[#4B4B4B]'>Enter the code that was sent to your email.</span>
          </div>
          <Input
            title='Email Verification Code'
            onClearValue={()=>{setCode('')}}
            onValueChanged={(e)=>{setCode(e)}}
          />
        </div>
        <Button type='primary' disabled={formData.code.type !== 'legal'} onClick={()=>{
          if(formData.code.type !== 'legal') return;
          onCheckCode()
          }}>Submit</Button>
      </div>
    </section>
  }

  const renderStep3 = () => {
    return <section className='p-16 bg-white rounded-4xl'>
      <div className='flex flex-col w-[420px] space-y-12'>
        <ButtonRound onClick={()=>{navigate(-1)}}>
          <ArrowLeft />
        </ButtonRound>
        <div className='flex flex-col space-y-6'>
          <div className='flex flex-col'>
            <h5 className='text-3.5xl font-semibold text-black'>Password Reset</h5>
          </div>
          <Input
            title='Password'
            type='password'
            onClearValue={()=>{setPasssword('')}}
            onValueChanged={(e)=>{setPasssword(e)}}
          />
          <Input
            title='Confirm Password'
            type='password'
            onClearValue={()=>{setConfirmPasssword('')}}
            onValueChanged={(e)=>{setConfirmPasssword(e)}}
          />
        </div>
        <Button type='primary' disabled={formData.password.type !== 'legal'|| formData.confirmpassword.type !== 'legal'} 
        onClick={()=>{
          if(formData.password.type !== 'legal'|| formData.confirmpassword.type !== 'legal') return;
          onResetPwd()
          }}>Submit</Button>
      </div>
    </section>
  }

  const renderSuccess = () => {
    return <section className='p-16 bg-white rounded-4xl'>
      <div className='flex flex-col w-[420px] space-y-12'>
        <div className='mx-auto'>
          <img className='size-[96px]' src={ImgSuccess} />
        </div>
        <h5 className='text-3.5xl font-semibold text-center text-black'>Reset successful</h5>
        <Button type='primary' onClick={()=>{navigate(LOGIN)}}>Log in</Button>
      </div>
    </section>
  }

  return <section className='relative flex flex-row items-center justify-center h-full bg-[#F2F2F2]'>
    <img className='absolute left-16 top-16 h-8 object-contain' src={ImgLogo} />
    {formData.step ===0 && renderStep1()}
    {formData.step ===1 &&renderStep2()}
    {formData.step ===2 &&renderStep3()}
    {formData.step ===3 &&renderSuccess()}
  </section>
}

export default PasswordReset