import React, { useCallback } from 'react'

type Props = {
  score: number
}

const scoreColor = [
  { max: 100, min: 71, bg: '#FFEBE8', color: '#F54040' }, //100-71
  { max: 70, min: 41, bg: '#FFF1E4', color: '#FF6333' }, //70-41
  { max: 40, min: 0, bg: '#DAFBF2', color: '#13AE85' }  //40-0
]

const SimilarityScore: React.FC<Props> = ({ score }: Props) => {


  const renderImilarityScore = useCallback((score: number) => {
    const index = scoreColor.findIndex(item => (item.min <= score && score <= item.max))
    // console.log(index)
    if (index !== -1) {
      return <span className={`px-1 font-semibold rounded`}
        style={{
          color: scoreColor[index].color,
          background: scoreColor[index].bg
        }}
      >{score.toFixed(2)}%</span>
    }
    return <span className='px-1 bg-[#FFEBE8] text-[#F54040] font-semibold rounded'>{score.toFixed(2)}%</span>
  }, [score])

  return renderImilarityScore(score)
}

export default SimilarityScore