import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Security as IconSecurity, MyTrademark as IconMyTrademark } from '../../svgr/components'
import { SOLUTIONS, MYTRADEMARK } from '../../router/constants'
import MenuItem from './MenuItem'

type Props = {
  onClose: () => void
}

const TrademarkMenu: React.FC<Props> = ({ onClose }: Props) => {

  const navigate = useNavigate()
  const location =useLocation()

  useEffect(() => {
    window.addEventListener('click', onClose)
    return () => {
      window.removeEventListener('click', onClose)
    }
  }, [])


  return <>
    <div
      className='absolute top-[68px] left-0 p-4 bg-white rounded-2xl border border-[#F2F2F2] shadow-4xl z-50 space-y-1'
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <MenuItem
        icon={<IconSecurity />}
        onClick={e => {
          onClose()
          if(location.pathname !==SOLUTIONS) navigate(SOLUTIONS)
          else window.location.reload();
        }}
      >Solutions</MenuItem>
      {/* <MenuItem
        icon={<IconMyTrademark />}
        onClick={e => {
          onClose()
          navigate(MYTRADEMARK)
        }}
      >My Trademark</MenuItem> */}
    </div>
  </>
}

export default TrademarkMenu