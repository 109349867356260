import { ResponseServiceData } from "../contract/service";
import { FeedType, feedChick } from "./app";

export type FeedChickType = FeedType


export const onFeedChick= async(msg:FeedChickType):Promise<ResponseServiceData<string>>  => {
    const response =await feedChick(msg)
    if(response.status === 200){
      return {success:true,data:response.data}
    }
    return {success:false, error:response.status.toString()}
  };
  