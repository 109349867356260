import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { SimilarityScore, Header, Loading } from "../components/";
import { Filter, Status } from "../components/Trademark";
import { AngleRight as IconArrow } from "../svgr/components";
import { ImgDocument } from "../images";
import { SOLUTIONS } from "../router/constants";

import { AnswerTable } from "../components/Answer";
import { AppState } from "../redux/app-state";
import { useDispatch, useSelector } from "react-redux";
import { chatlistLocalData } from "../redux/local";
import { persistActions, persistSelectors } from "../redux";
import { useTrademarkList } from "../components/Solution/TrademarkListHook";
import { SummaryTableData } from "../components/Chat/chatdatatype";
import { useNavigate } from "react-router-dom";
import { trademarkListLocalData } from "../redux/presist";
import { base64ToFile, isFileInstance } from "../utils/FileUtils";
import { uniqBy, uniq, map, filter, countBy, entries } from "lodash";
import { getDaysBeforeNow } from "../utils/time";
import classData from "../data/class.json";
import { StatusType, format2StatusType } from "../components/Trademark/Status";
import { TrademarkDetailReport as TrademarkDetailReportCom } from "../components/Report";
import useStateRef from "react-usestateref";
import { ClassText } from "../components/EllipsisTooltipText";
const tableTitle = [
  "LOGO",
  "Name",
  "Status",
  "Class",
  "Similarity Score",
  "Details",
];

const periodDatas = [
  "All",
  "Last Year",
  "Last 5 Years",
  "Last 10 Years",
  "Last 20 Years",
  "Last 50 Years",
];

type kv = {
  id: string;
  name: string;
  selected: boolean;
  onChecked: (checked: boolean) => void;
};

type akv = kv & {
  amount: number;
};

// 定义数据的类型
interface ClassData {
  [key: string]: string; // 使用字符串索引签名
}
const testData1 = [
  {
    id: "026",
    name: "026",
    amount: "75",
  },
  {
    id: "009",
    name: "009",
    amount: "12",
    onChecked: (checked: boolean) => {
      console.log(checked);
    },
  },
];

const testData2 = [
  {
    id: "1",
    name: "Nike, Inc.",
  },
  {
    id: "2",
    name: "Gucci America, Inc.",
  },
  {
    id: "3",
    name: "CHIPIRV, LLCCHIPIRV, LLCCHIPIRV, LLCCHIPIRV, LLC",
  },
];

const TrademarkDetailReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading, loadingRef] = useStateRef(false);

  const trademarkListCacheData = useSelector<
    AppState,
    trademarkListLocalData.Selectors
  >(({ persist }) =>
    persistSelectors.local.trademarkListLocalData(
      persist.local.trademarkListLocalData,
      {}
    )
  );

  const updateAnalysisTrademark = (data: any) =>
    dispatch(
      persistActions.local.trademarkListLocalData.updateAnalysisTrademark(data)
    );

  const [tableData, setTableData] = useState<SummaryTableData>();
  const [tableUIData, setTableUIData] = useState<SummaryTableData>();
  const [tableRadioUIData, setTableRadioUIData] = useState<SummaryTableData>();

  const { searchTrademarksByName, searchTrademarksByImage } =
    useTrademarkList();

  useEffect(() => {
    const t = trademarkListCacheData?.getData?.trademark;
    t &&
      (async () => {
        setLoading(true);
        setTableData(await searchTrademarksByName(t));
        setLoading(false);
      })();

    return () => {};
  }, [trademarkListCacheData?.getData?.trademark]);

  useEffect(() => {
    const t = trademarkListCacheData?.getData?.analysisTrademark;
    t &&
      (async () => {
        const imgFile = base64ToFile(t, Date.now().toString());
        const isFile = isFileInstance(imgFile);
        setLoading(true);
        if (isFile) {
          setTableData(await searchTrademarksByImage(imgFile));
          setLoading(false);
        } else updateAnalysisTrademark(undefined);
      })();

    return () => {};
  }, [trademarkListCacheData?.getData?.analysisTrademark]);

  const timerRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    console.log(
      "trademarkListCacheData?.getData***",
      trademarkListCacheData?.getData
    );
    if (
      !trademarkListCacheData?.getData?.analysisTrademark &&
      !trademarkListCacheData.getData.trademark
    ) {
      timerRef.current = setTimeout(() => {
        navigate(-1);
      }, 0.3 * 1000);
    } else {
      timerRef.current && clearTimeout(timerRef.current);
    }
    return () => {};
  }, [trademarkListCacheData?.getData]);

  const [classes, setClasses, classesRef] = useStateRef<akv[]>();
  const [owners, setOwners, owenersRef] = useStateRef<akv[]>();

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const c = countBy(tableData, "class");
      const entriesC = Object.entries(c);
      const c2 = map(entriesC, ([owner, count], index) => ({
        id: owner,
        name: owner,
        selected: false,
        amount: count,
        onChecked: (checked: boolean) => {
          console.log(
            checked,
            index,
            !classesRef.current,
            classesRef.current?.length
          );
          if (!classesRef.current || classesRef.current.length < index) return;

          classesRef.current[index].selected = checked; //!classes[index].selected;
          setClasses([...classesRef.current]);
        },
      }));

      // // const c3 = uniqBy(
      // //   map(tableData, (tableItem) => ({
      // //     id: tableItem.class,
      // //     name: tableItem.class,
      // //     selected: false,
      // //   })),
      // //   "id"
      // // );
      setClasses(c2);
      // console.log("xxxx**", c2);
      // setClasses(uniq(map(tableData, "class")));

      const o = countBy(tableData, "owner");
      const entriesO = Object.entries(o);
      const o2 = map(entriesO, ([owner, count], index) => ({
        id: owner,
        name: owner,
        selected: false,
        amount: count,
        onChecked: (checked: boolean) => {
          console.log(
            checked,
            index,
            !owenersRef.current,
            owenersRef.current?.length
          );
          if (!owenersRef.current || owenersRef.current.length < index) return;
          owenersRef.current[index].selected = checked; //!owners[index].selected;
          setOwners([...owenersRef.current]);
        },
      }));
      // const o3 = uniqBy(
      //   map(tableData, (tableItem) => ({
      //     id: tableItem.owner,
      //     name: tableItem.owner,
      //     selected: false,
      //   })),
      //   "id"
      // );
      setOwners(o2);
      console.log("tableData**", tableData);
    }
    setTableRadioUIData(tableData);
    return () => {};
  }, [tableData]);

  const [selectedClasses, setSelectedClasses] = useState<string[]>();
  useEffect(() => {
    const c = map(
      filter(classes, (item) => item.selected === true),
      "id"
    );
    // const t = selectedOwners
    //   ? filterWithOwner(selectedOwners, tableData)
    //   : tableData;
    // console.log("filterWithOwner**", t, selectedOwners);
    // const t2 = filterWithClass(c, t);
    // console.log("filterWithClass**", t2, c);
    // setTableRadioUIData(t2);
    setSelectedClasses(c);
    filterDatas(c, selectedOwners, statusState, yearState);
    return () => {};
  }, [classes]);

  const filterWithClass = (cs: string[], t: SummaryTableData | undefined) => {
    if (!cs || cs.length < 1 || !t) return t;
    else {
      return t?.filter((item) => {
        const targetIndex = cs.findIndex((item2) => item2 === item.class);
        if (targetIndex > -1) return true;
        return false;
      });
    }
  };

  const [selectedOwners, setSelectedOwners] = useState<string[]>();
  useEffect(() => {
    const o = map(
      filter(owners, (item) => item.selected === true),
      "id"
    );
    // const t = selectedClasses
    //   ? filterWithClass(selectedClasses, tableData)
    //   : tableData;
    // console.log("filterWithClass**", t, selectedClasses);
    // const t2 = filterWithOwner(o, t);
    // console.log("filterWithOwner**", t2, o);
    // setTableRadioUIData(t2);
    setSelectedOwners(o);
    filterDatas(selectedClasses, o, statusState, yearState);
    return () => {};
  }, [owners]);

  useEffect(() => {
    setTableUIData(tableRadioUIData);

    return () => {};
  }, [tableRadioUIData]);

  const filterWithOwner = (cs: string[], t: SummaryTableData | undefined) => {
    if (!cs || cs.length < 1 || !t) return t;
    else {
      return t?.filter((item) => {
        const targetIndex = cs.findIndex((item2) => item2 === item.owner);
        if (targetIndex > -1) return true;
        return false;
      });
    }
  };

  const filterWithStatus = (
    status: StatusType,
    t: SummaryTableData | undefined
  ) => {
    if (!t || status === "ALL") return t;
    else {
      return t?.filter((item) => {
        return item.status === status;
      });
    }
  };

  const filterWithYear = (date: string, t: SummaryTableData | undefined) => {
    const t3 = t;
    if (!t3) return;
    let filterTable;
    switch (date) {
      case "ALL":
        filterTable = t3;
        break;
      case "Last week":
        filterTable = t3.filter(
          (item) => getDaysBeforeNow(item.fillingDate) <= 7
        );
        break;
      case "Last month":
        filterTable = t3.filter(
          (item) => getDaysBeforeNow(item.fillingDate) <= 30
        );
        break;
      case "Last 3 months":
        filterTable = t3.filter(
          (item) => getDaysBeforeNow(item.fillingDate) <= 90
        );
        break;
      case "Last 6 months":
        filterTable = t3.filter(
          (item) => getDaysBeforeNow(item.fillingDate) <= 180
        );
        break;
      case "Last Year":
        filterTable = t3.filter(
          (item) => getDaysBeforeNow(item.fillingDate) <= 365
        );
        break;
      case "Last 5 Years":
        filterTable = t3.filter(
          (item) => getDaysBeforeNow(item.fillingDate) <= 365 * 5
        );
        break;
      case "Last 10 Years":
        filterTable = t3.filter(
          (item) => getDaysBeforeNow(item.fillingDate) <= 365 * 10
        );
        break;
      case "Last 20 Years":
        filterTable = t3.filter(
          (item) => getDaysBeforeNow(item.fillingDate) <= 365 * 20
        );
        break;
      case "Last 50 Years":
        filterTable = t3.filter(
          (item) => getDaysBeforeNow(item.fillingDate) <= 365 * 50
        );
        break;
      default:
        break;
    }
    // setTableUIData(filterTable);
    return filterTable;
  };

  const filterDatas = (
    selectedClasses: string[] | undefined,
    selectedOwners: string[] | undefined,
    status: StatusType,
    date: string
  ) => {
    const t = selectedClasses
      ? filterWithClass(selectedClasses, tableData)
      : tableData;
    console.log("filterWithClass**", t, selectedClasses);
    const t2 = selectedOwners ? filterWithOwner(selectedOwners, t) : t;
    console.log("filterWithOwner**", t2, selectedOwners);
    // setTableRadioUIData(t2);

    if (!t2) return;
    const t3 = filterWithStatus(status, t2);
    console.log("filterWithStatus**", t3, status);
    const t4 = filterWithYear(date, t3);
    console.log("filterWithYear**", t4, date);
    setTableRadioUIData(t4);
  };

  const buildClass = (item: string) => {
    const data: ClassData = classData;
    return data[Number(item).toString()];
  };

  const buildStatus = (item: string) => {
    return item as StatusType; // format2StatusType(item)
  };

  const totalPercent =
    tableData && tableData.length > 0
      ? ((1 - tableData[0].score) * 100).toFixed(0)
      : "--";

  const reportRef = useRef<any>(null);

  const [statusState, setStatusState] = useState<StatusType>("ALL");
  const [yearState, setYearState] = useState<string>("ALL");

  const renderLoading = () => {
    return (
      <div className="flex-grow flex items-center justify-center h-[842px] w-full overflow-auto" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
        <Loading></Loading>
      </div>
    );
  };

  // useEffect(() => {
  //   setLoading(true)
  
  //   return () => {
      
  //   }
  // }, [loading])
  

  return (
    <section className="relative flex flex-col flex-1 h-full">
      <div className="flex flex-col shadow-[0px_4px_5.8px_0px_#0000000D]">
        <Header type="primary" buttonHover="primary" />
      </div>
      {!loading &&<section className="flex flex-row flex-1 mt-0.5 bg-white overflow-auto">
        <div className="flex flex-col flex-1 pl-20 pr-10 py-6 overflow-auto">
          <div className="flex flex-row">
            <button
              className="inline-flex flex-row items-center space-x-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              <span className="text-xl rotate-180">
                <IconArrow />
              </span>
              <span className="text-base font-semibold text-[#111]">
                Trademark search
              </span>
            </button>
          </div>
          {!loading && <div className="flex flex-col items-end mt-4 mb-10">
            <h5 className="text-5.5xl font-semibold text-[#F54040]">
              {totalPercent}%
            </h5>
            <div className="text-base font-semibold text-[#111]">
              Trademark registration success rate
            </div>
          </div>}
          {!loading && <table className="table-fixed">
            <thead className="bg-[#F9F9F9]">
              <tr>
                {tableTitle.map((item, index) => (
                  <th
                    key={index}
                    className="h-16 px-4 text-left first:pl-4 first:rounded-l-xl last:pr-4 last:rounded-r-xl last:text-right"
                  >
                    <span className="font-normal text-sm text-[#111111] text-nowrap">
                      {item}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableUIData?.map((item, index) => {
                return (
                  <tr key={index} className="border-b border-[#ddd]">
                    <td className="w-[120px] p-4">
                      {item.logo ? (
                        <img
                          className="size-[88px] object-contain"
                          src={item.logo}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#FFF0",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        ></div>
                      )}
                    </td>
                    <td className="w-3/12 p-4">
                      <div className="flex flex-col text-[#111] space-y-4">
                        <div className="text-base leading-6 font-semibold">
                          {item.name}
                        </div>
                        <div className="inline-flex h-[44px] items-center">
                          <div className="text-sm line-clamp-2">
                            <span>{item.owner}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="w-2/12 p-4">
                      <div className="flex flex-col text-[#111] space-y-4">
                        <Status type={buildStatus(item.status)} />
                        <div className="inline-flex h-[44px] items-center">
                          <div className="text-sm line-clamp-2">
                            <span>{item.fillingDate}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="w-3/12 p-4">
                      <div className="flex flex-col text-[#111] space-y-4">
                        <div className="text-base leading-6 font-semibold">
                          {item.class}
                        </div>
                        <div className="inline-flex h-[44px] items-center">
                          {/* <div className="text-sm line-clamp-2">
                            <span>{buildClass(item.class)}</span>
                          </div> */}
                          <ClassText text={buildClass(item.class)} />
                        </div>
                      </div>
                    </td>
                    <td className="w-2/12 p-4">
                      <div className="flex">
                        <SimilarityScore score={item.score * 100} />
                      </div>
                    </td>
                    <td className="w-12 p-4">
                      <div className="flex flex-row justify-end">
                        <button
                          className="inline-flex"
                          onClick={() => {
                            reportRef.current?.show(item);
                          }}
                        >
                          <img className="size-6" src={ImgDocument} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>}
        </div>
        
        {!loading && classes && owners && (
          <Filter
            // classData={testData1}
            // ownerData={testData2}
            classData={classes}
            ownerData={owners}
            onCountryChanged={(country) => {}}
            onStatusChanged={(status) => {
              const s = status as StatusType;
              setStatusState(s);
              filterDatas(selectedClasses, selectedOwners, s, yearState);
            }}
            onRegistrationDateChanged={(registrationDate) => {
              console.log("registrationDate**", registrationDate);
              setYearState(registrationDate);
              filterDatas(
                selectedClasses,
                selectedOwners,
                statusState,
                registrationDate
              );
              // filterWithYear(registrationDate);
            }}
          />
        )}
      </section>}
      {loading &&(
        <section className="flex flex-row flex-1 mt-0.5 bg-white overflow-auto">
          <div className="flex flex-col flex-1 pl-20 pr-10 py-6 overflow-auto">
          <div className="flex flex-row">
            <button
              className="inline-flex flex-row items-center space-x-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              <span className="text-xl rotate-180">
                <IconArrow />
              </span>
              <span className="text-base font-semibold text-[#111]">
                Trademark search
              </span>
            </button>
          </div>
        {renderLoading()}

          </div>
          {/* {renderLoading()} */}
      </section>
      ) }
      {<TrademarkDetailReportCom ref={reportRef} />}
    </section>
  );
};

export default TrademarkDetailReport;
