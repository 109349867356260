import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { ButtonRoundBorder } from "../components/Button";
import { ComingSoon, Header, Navbar } from "../components";
import { ContractSearch, PopularScroll } from "../components/Contract";
import {
  AGREEMENT,
  CONTRACTS_CLAUSES,
  CONTRACTS_CONTRACTS,
  SEARCH,
} from "../router/constants";
import {
  ResponseServiceData,
  onClauseSearch,
  onContractSearch,
  onGetArticleTypesInfo,
} from "../api/contract/service";
import { ArticleType } from "../api/contract/app";
import { useContractTypes } from "../api/contract/hook";
import {
  ArticleTypeInfoItemData,
  ArticleTypeInfoResponseData,
  ClauseSearchItemData,
  ClauseSearchResponseData,
  ContractSearchItemData,
  ContractSearchResponseData,
} from "../api/contract/response";
import { DEFAULT_ARCTICLE_SEARCH_COUNT } from "../service/constant";
import {
  ImgCreditAgreement,
  ImgRewrite,
  ImgRiskCheck,
  ImgSimiarClauses,
  ImgCustomer,
} from "../images";
import {
  Edit as IconEdit,
  List as IconList,
  Check as IconCheck,
} from "../svgr/components";
import { Barrage, Barrage2 } from "../components/Trademark";
import useStateRef from "react-usestateref";
import { useRandomQuestion } from "../contexts/RandomQuestionContext";
import { useGA4React } from "ga-4-react";
import { gaSearchContractEvent } from "../service/ga4Service";

const AIAssistantData = [
  ImgCreditAgreement,
  ImgRiskCheck,
  ImgRewrite,
  ImgSimiarClauses,
];

const ContractsHome: React.FC = () => {
  const [AIAssistant, setAIAssistant] = useState<number>(0);
  const [type, setType] = useState<"Contracts" | "Clauses">("Contracts");
  const navigate = useNavigate();

  // console.log("ContractsHome***type***", type);
  const { data: firstTypesData } = useContractTypes(
    0,
    type === "Contracts" ? ArticleType.Contract : ArticleType.Clause
  );
  // const [select1stTypeIndex, setSelect1stTypeIndex] = useState(0)

  useEffect(() => {
    console.log("firstTypesData**", firstTypesData);
    if (!firstTypesData || firstTypesData.total < 1) {
      setSecondTypesList([]);
      return;
    }
    // setSelect1stTypeIndex(0)

    search2ndTypes(
      firstTypesData.list,
      type === "Contracts" ? ArticleType.Contract : ArticleType.Clause
    );
    return () => {};
  }, [firstTypesData]);

  const [secondTypesList, setSecondTypesList] = useState<
    ArticleTypeInfoItemData[][]
  >([]);

  const search2ndTypes = async (
    itemDatas: ArticleTypeInfoItemData[],
    typeId: number
  ) => {
    const allResult = await Promise.all(
      itemDatas.map(async (item) => {
        const result: ResponseServiceData<ArticleTypeInfoResponseData> =
          await onGetArticleTypesInfo({
            pageNo: 1,
            pageSize: 100,
            parentId: item.id,
            type: typeId,
          });

        if (result.success && result.data?.list) {
          // resultList.push(result.data?.list);
          return { data: result.data?.list };
        }
        return { data: undefined };
      })
    );
    console.log("ContractsHome***rallResult**", allResult);
    const b = allResult.filter((item) => item.data !== undefined) as {
      data: ArticleTypeInfoItemData[];
    }[];
    const resultList: ArticleTypeInfoItemData[][] = b.map((item) => item.data);
    console.log("ContractsHome***resultList**", resultList);
    setSecondTypesList(resultList);
  };

  const renderFirstType = () => {
    return (
      <>
        <div className="flex flex-row mx-auto mt-[56px] space-x-[120px]">
          {firstTypesData?.list?.map((cItem, cIndex) => {
            return (
              <div key={cItem.id}>
                <ol className="flex flex-col w-[280px] text-center space-y-6">
                  <li
                    className="text-xl leading-[30px] text-black font-semibold cursor-pointer"
                    onClick={() => {
                      if (type === "Contracts") {
                        navigate(CONTRACTS_CONTRACTS, {
                          state: {
                            data: firstTypesData,
                            selectIndex: cIndex,
                          },
                        });
                      } else {
                        navigate(CONTRACTS_CLAUSES, {
                          state: {
                            data: firstTypesData,
                            selectIndex: cIndex,
                          },
                        });
                      }
                    }}
                  >
                    {cItem.name}
                  </li>
                  <ol className="flex flex-col text-base">
                    {secondTypesList &&
                      secondTypesList.length > cIndex &&
                      secondTypesList[cIndex].map((sItem, sIndex) => {
                        if (sIndex > 4) return null;
                        return (
                          <li
                            className="py-4 text-[#111] cursor-pointer hover:text-blue"
                            key={sItem.id}
                          >
                            <span
                              onClick={() => {
                                const url = `${AGREEMENT}/contracts?id=${sItem.id}&n=${sItem.name}`;
                                navigate(url);
                              }}
                            >
                              {sItem.name}
                            </span>
                          </li>
                        );
                      })}
                  </ol>
                </ol>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderClauses = () => {
    return (
      <>
        <div className="flex flex-row mx-auto mt-[56px] space-x-[120px]">
          <ol className="flex flex-col w-[280px] text-center space-y-6">
            <li className="text-xl leading-[30px] text-black font-semibold">
              Progetto Esecutivo
            </li>
            <ol className="flex flex-col text-[#111] text-base">
              <li className="py-4 cursor-pointer">
                <span>Credit Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Joint Filing Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Registration Rights Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Credit Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Trust Agreement</span>
              </li>
            </ol>
          </ol>
          <ol className="flex flex-col w-[280px] text-center space-y-6">
            <li className="text-xl leading-[30px] text-black font-semibold">
              Confidentiality
            </li>
            <ol className="flex flex-col text-[#111] text-base">
              <li className="py-4 cursor-pointer">
                <span>Release Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Joinder Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Participant Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Release Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Agreement</span>
              </li>
            </ol>
          </ol>
          <ol className="flex flex-col w-[280px] text-center space-y-6">
            <li className="text-xl leading-[30px] text-black font-semibold">
              Indemnity
            </li>
            <ol className="flex flex-col text-[#111] text-base">
              <li className="py-4 cursor-pointer">
                <span>Release Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Joinder Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Participant Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Release Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Agreement</span>
              </li>
            </ol>
          </ol>
          <ol className="flex flex-col w-[280px] text-center space-y-6">
            <li className="text-xl leading-[30px] text-black font-semibold">
              Limitation of Liability
            </li>
            <ol className="flex flex-col text-[#111] text-base">
              <li className="py-4 cursor-pointer">
                <span>Release Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Joinder Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Participant Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Release Agreement</span>
              </li>
              <li className="py-4 cursor-pointer">
                <span>Agreement</span>
              </li>
            </ol>
          </ol>
        </div>
      </>
    );
  };

  const [randomBarrage, setRandomBarrage] = useState<number[]>([]);

  // const [dataArray, setDataArray,dataArrayRef] = useStateRef([]);
  const random = useRandomQuestion();
  const chickPicker = random?.chick;
  // const dataArray =random?.chick.datas
  useEffect(() => {
    return () => {
      chickPicker?.reset();
      chickPicker?.resetPickNumbers();
    };
  }, []);

  const addNum = () => {
    let num: number | null | undefined = null;
    do {
      num = chickPicker?.pickOneNumber();
    } while (num == null);
    console.log("addNum**", num);
    const datas = random?.chick.getPickNumbers();
    if (!datas) return;
    const d = [...datas.filter((item) => item !== num), num];
    console.log("addNumall**", datas, d);
    random?.chick?.setPickNumbers(d);
    setRandomBarrage(d);
  };

  // const dataArray = random?.chick.getPickNumbers();

  // useEffect(() => {
  //   console.log("dataArray22**", dataArray);

  //   return () => {};
  // }, [dataArray]);

  const ga4 = useGA4React();
  //提交到ga
  const submitGA = (data: any) => {
    if (!!ga4) {
      gaSearchContractEvent(ga4, data);
    }
  }

  return (
    <section className="relative flex flex-col flex-1">
      <div className="relative flex flex-col h-screen bg-[#EEF2F9]">
        <Header type="default" buttonHover="default" />
        <Navbar />
        <div className="absolute left-0 right-0 bottom-0">
          <Player
            autoplay
            loop
            src="/assets/animation/mainpage-animation.json"
            style={{ width: "83.35%" }}
          ></Player>
        </div>
        <div className="relative flex flex-col mt-[54px] z-10">
          <h2 className="mx-auto font-semibold text-[64px] leading-[96px] text-black text-center">
            Draft Contract Smarter
          </h2>
          <ContractSearch
            onSearch={(type, value) => {
              // onSearch({
              //   pageNo: 1,
              //   pageSize: 10,
              //   query: "1"
              // })
              // if (!value) return;
              submitGA({
                q: value || "",
                t: type,
              });
              navigate(`${SEARCH}`, {
                state: {
                  q: value || "",
                  t: type,
                },
              });
              // navigate(`${SEARCH}?q=${value}&i=0&t=${type}`);
            }}
            onChange={async (type, value) => {
              let contracts: ContractSearchResponseData | undefined = undefined;
              let clauses: ClauseSearchResponseData | undefined = undefined;
              console.log("onChange**", type, value);
              switch (type) {
                case "all":
                  {
                    const result = await onContractSearch({
                      pageNo: 1,
                      pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
                      query: value,
                      // typeId: type,
                    });
                    console.log("onContractSearch**result**", result.data);

                    if (
                      result.success &&
                      result.data &&
                      result.data?.total > 0
                    ) {
                      if (result.data?.list) contracts = result.data;
                      // setHasSearchResult(true)
                    }

                    const result1 = await onClauseSearch({
                      pageNo: 1,
                      pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
                      query: value,
                      // typeId: type,
                    });
                    console.log("onClauseSearch**result**", result1.data);

                    if (
                      result1.success &&
                      result1.data &&
                      result1.data?.total > 0
                    ) {
                      if (result1.data?.list) clauses = result1.data;
                      // setHasSearchResult(true)
                    }

                    return {
                      contracts,
                      clauses,
                    };
                  }
                  break;
                case "contracts":
                  const result = await onContractSearch({
                    pageNo: 1,
                    pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
                    query: value,
                    // typeId: type,
                  });
                  console.log("onContractSearch**result**", result.data);

                  if (result.success && result.data && result.data?.total > 0) {
                    if (result.data?.list) contracts = result.data;
                    // setHasSearchResult(true)
                  }

                  return {
                    contracts,
                    clauses,
                  };
                  break;
                case "clauses":
                  {
                    const result1 = await onClauseSearch({
                      pageNo: 1,
                      pageSize: DEFAULT_ARCTICLE_SEARCH_COUNT,
                      query: value,
                      // typeId: type,
                    });
                    console.log("onClauseSearch**result**", result1.data);

                    if (
                      result1.success &&
                      result1.data &&
                      result1.data?.total > 0
                    ) {
                      if (result1.data?.list) clauses = result1.data;
                      // setHasSearchResult(true)
                    }

                    return {
                      contracts,
                      clauses,
                    };
                  }

                  break;

                default:
                  break;
              }
              return {
                contracts,
                clauses,
              };
            }}
          />
          <div className="flex flex-col mt-6">
            <ul className="flex flex-row flex-wrap justify-center mx-auto w-[1003px] h-10 space-x-6 overflow-hidden">
              {/* <ButtonRoundBorder color={'blue'}>Non Disclosure Agreement</ButtonRoundBorder>
              <ButtonRoundBorder color={'blue'}>Performer Agreement</ButtonRoundBorder>
              <ButtonRoundBorder color={'blue'}>IP Licensing Agreement</ButtonRoundBorder>
              <ButtonRoundBorder color={'blue'}>Service Agreement</ButtonRoundBorder> */}
              {secondTypesList &&
                secondTypesList.length > 0 &&
                secondTypesList[0].map((sItem, sIndex) => {
                  // if(sIndex> 3) return null
                  return (
                    <div key={sItem.id} className="flex flex-row shrink-0">
                      <ButtonRoundBorder
                        key={sItem.id}
                        color={"blue"}
                        onClick={() => {
                          const url = `${AGREEMENT}/contracts?id=${sItem.id}&n=${sItem.name}`;
                          navigate(url);
                        }}
                      >
                        {sItem.name}
                      </ButtonRoundBorder>
                    </div>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-white pt-[63px] pb-[73px]">
        <div className="flex flex-col mb-[88px]">
          <ul className="flex flex-row mx-auto p-1 bg-[#F2F2F2] rounded-4xl">
            <button
              aria-checked={type === "Contracts"}
              className="group inline-flex items-center justify-center w-[160px] h-12 rounded-4xl
            aria-checked:bg-white"
              onClick={() => {
                setType("Contracts");
              }}
            >
              <span
                className="text-base text-[#4B4B4B] cursor-pointer
                    group-aria-checked:font-semibold group-aria-checked:text-[#111]"
              >
                Contracts
              </span>
            </button>
            <button
              aria-checked={type === "Clauses"}
              className="group inline-flex items-center justify-center w-[160px] h-12 rounded-4xl
            aria-checked:bg-white"
              onClick={() => {
                setType("Clauses");
              }}
            >
              <span
                className="text-base text-[#4B4B4B] cursor-pointer
                    group-aria-checked:font-semibold group-aria-checked:text-[#111]"
              >
                Clauses
              </span>
            </button>
          </ul>
          {renderFirstType()}
          {/* {type === 'Contracts' && renderFirstType()}
          {type === 'Clauses' && renderClauses()} */}
        </div>
        <div className="flex flex-col">
          <h6 className="mx-[355px] mb-[41px] font-semibold text-[44px] leading-[66px] text-black">
            Popular downloads
          </h6>
          <PopularScroll />
        </div>
      </div>
      <div className="flex flex-col pt-[75px] pb-[143px] bg-[#F9F9F9]">
        <div className="flex flex-row mx-auto space-x-5">
          <img className="size-20" src={ImgCustomer} />
          <h6 className="text-6.5xl font-semibold text-black">
            Hi , I am your AI assistant👋
          </h6>
        </div>
        <div className="mt-4 mb-7 mx-auto text-xl line-clamp-[30px] text-[#4B4B4B]">
          I can help you read, understand, and process documents more
          efficiently.
        </div>
        <ul className="flex flex-row mx-auto space-x-2.5">
          <button
            aria-checked={AIAssistant === 1}
            className="inline-flex flex-row items-center px-2.5 py-1.5 text-[#4B4B4B] border border-[#ddd] rounded-lg 
                      aria-checked:bg-blue aria-checked:text-white"
            onClick={() => {
              setAIAssistant(1);
            }}
          >
            <span className="text-base">
              <IconCheck />
            </span>
            <span className="ml-1 text-sm line-clamp-[26px]">Risk check</span>
          </button>
          <button
            aria-checked={AIAssistant === 2}
            className="inline-flex flex-row items-center px-2.5 py-1.5 text-[#4B4B4B] border border-[#ddd] rounded-lg 
                      aria-checked:bg-blue aria-checked:text-white"
            onClick={() => {
              setAIAssistant(2);
            }}
          >
            <span className="text-base">
              <IconEdit />
            </span>
            <span className="ml-1 text-sm line-clamp-[26px]">Rewrite</span>
          </button>
          <button
            aria-checked={AIAssistant === 3}
            className="inline-flex flex-row items-center px-2.5 py-1.5 text-[#4B4B4B] border border-[#ddd] rounded-lg 
                      aria-checked:bg-blue aria-checked:text-white"
            onClick={() => {
              setAIAssistant(3);
            }}
          >
            <span className="text-base">
              <IconList />
            </span>
            <span className="ml-1 text-sm line-clamp-[26px]">
              Similar clauses
            </span>
          </button>
        </ul>
        <div className="mt-[26px] mb-10 mx-auto">
          <img
            className="w-[895px] object-contain"
            src={AIAssistantData[AIAssistant]}
          />
        </div>

        <div className="relative h-[1px]">
          <Barrage2
            random={randomBarrage}
            setRandom={(n) => {
              setRandomBarrage(n);
              random?.chick?.setPickNumbers(n)
              random?.chick?.setNumbersByPickNumbers();
            }}
          />
        </div>

        <ComingSoon
          type="primary"
          feed="AI_ASSISTANT"
          onClick={() => {
            // setRandomBarrage(Math.floor(Math.random() * 100))
            addNum();
          }}
        />
      </div>
    </section>
  );
};

export default ContractsHome;
