
// export const baseUrl = process.env.REACT_APP_AI_INTERFACE_URL;

export const commomUrl = '/business/contract'
export const api = commomUrl
export const contractSearch = api + '/search'
export const types = api + '/types'
export const subTypes = api + '/sub-types'
export const addDownloadCount = api + '/download-inc'

export const articleDetail = api + '/detail'
export const popContractList = api + '/pop-list'
export const clauseSearch = api + '/search-clause'

