import { get, longPost, post, put, upload } from "../request";
import * as api from "./index";

export type SearchRequestData = {
  pageNo: number;
  pageSize: number;
  typeId?: number | string;
  query: string;
};

export const contractSearch = (data: SearchRequestData) => {
  const raw = data;
  const url = `${api.contractSearch}`;
  return post(url, raw);
};

export enum ArticleType{
  Contract = 1,
  Clause=2
}
export const searchTypes = (keyword: string,type?:ArticleType) => {
  const raw = type === undefined ? {
    query: keyword,
  }:{
    query: keyword,
    type:type,
  };
  const url = `${api.types}`;
  return post(url, raw);
};

export type GetArticleTypesRequestData = {
  pageNo: number;
  pageSize: number;
  parentId: number | string;
  type: number | string;
};

export const getArticleTypesInfoData = (data:GetArticleTypesRequestData) => {
  const raw = data
  const url = `${api.subTypes}`;
  return post(url, raw);
};


export const addDownloadCount = (id:string) => {
  const url = `${api.addDownloadCount}`;
  const form = new FormData();

  form.append("contractId", id);
  return post(url, form);
};

export const articleDetail = (id:string|number) => {
  const url = `${api.articleDetail}`;
  const form = new FormData();

  form.append("contractId", id.toString());
  return post(url, form);
};

export const popContractList = (pageNo:number,pageSize:number) => {
  const raw = {
    pageNo,
    pageSize
  };
  const url = `${api.popContractList}`;
  return post(url, raw);
};

export const clauseSearch = (data: SearchRequestData) => {
  const raw = data;
  const url = `${api.clauseSearch}`;
  return post(url, raw);
};