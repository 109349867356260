import { createContext, useContext } from 'react';
import { SupabaseClient, createClient } from '@supabase/supabase-js';

const SupabaseContext = createContext<SupabaseClient | undefined>(undefined);
type Props = {
    children?: React.ReactNode
  }

export const SupabaseProvider = ({ children }:Props) => {
// 使用你的Supabase URL和ANON_KEY初始化客户端
const supabase = createClient('https://asxbjlvuguwjsrhdepaa.supabase.co', 
'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFzeGJqbHZ1Z3V3anNyaGRlcGFhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTE0OTcyMDQsImV4cCI6MjAyNzA3MzIwNH0.CQhhyQxaQxlpaY2U-GQBvRp-wQOcQCuyp3v4Qi6ePKQ');
 
  return (
    <SupabaseContext.Provider value={supabase}>
      {children}
    </SupabaseContext.Provider>
  );
};

export const useSupabase = () => {
  return useContext(SupabaseContext);
};