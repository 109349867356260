import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useRandomQuestion } from '../../contexts/RandomQuestionContext'

type ItemProps = {
  animation: boolean,
  className?: string,
  onAnimationEnd?: () => void
}

const BarrageItem: React.FC<ItemProps> = ({ animation, className, onAnimationEnd }: ItemProps) => {

  const [runAnimation, setRunAnimation] = useState<boolean>()

  useEffect(() => {
    // console.log('className**',className,animation)
    setRunAnimation(animation)
  }, [animation])

  return <div
    className={clsx(`absolute inline-flex flex-row items-center px-4 bg-black/65 opacity-0 rounded-full space-x-2`,
      className,
      runAnimation && 'barrage')}
    onAnimationEnd={() => {
      setRunAnimation(false)
      onAnimationEnd && onAnimationEnd()
    }}
  >
    <span className='text-base leading-8 text-[#111]'>🍗</span>
    <span className='text-xs font-semibold text-white'>Development time: -5 minutes</span>
  </div>
}

type Props = {
  random: number[],
  setRandom:(nums:number[])=>void
}

const classStr: string[] = [
  'top-0 left-0',
  'top-[53px] left-[163px]',
  'top-[106px] left-[124px]'
]

const Barrage: React.FC<Props> = ({ random,setRandom }: Props) => {

  const [randomIndex, setRandomIndex] = useState<number[]>(random)

  // useEffect(() => {
  //   if (random !== undefined) {
  //     const index = random % classStr.length;
  //     setRandomIndex(index)
  //   }
  // }, [random])

  const randomPicker = useRandomQuestion();
  // const chickPicker = random?.chick.picker;
  // const dataArray =randomPicker?.chick.getPickNumbers()

  // useEffect(() => {
  //   console.log('dataArray**',dataArray)
  
  //   return () => {
      
  //   }
  // }, [dataArray])

  // useEffect(() => {
  //   console.log('randomIndex**',randomIndex)
  
  //   return () => {
      
  //   }
  // }, [])

  useEffect(() => {
    console.log('Barrage**addrandom**',random)
    setRandomIndex(random)
    return () => {
      
    }
  }, [random])
  


  

  return <div className='absolute top-[494px] left-[432px] w-[413px] z-[3]'>
    {random &&
      classStr.map((item, index) => (
        <BarrageItem
          key={index}
          className={item}
          animation={randomIndex?.findIndex(item=> item === index)>-1}
          onAnimationEnd={() => { 
            const r =randomIndex.filter(item=>item !== index)
            console.log('Barrage**onAnimationEndsetrandom**',r)
            setRandomIndex(r)
            setRandom(r)
           }}
        />
      ))}
  </div>
}

export default Barrage