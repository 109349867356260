
// export const baseUrl = process.env.REACT_APP_AI_INTERFACE_URL;

export const commomUrl = '/business/api'
export const api = commomUrl + '/lawtech'
export const search = api + '/search'
export const compare = api + '/compare'
export const upload = api + '/upload'
export const infringement  = api + '/infringement'


export const monitorSearch = commomUrl + '/monitor/search' 
export const monitorCompareData  = commomUrl + '/monitor/compareData'
export const storeInfo  = commomUrl + '/monitor/storeInfo'

export const sendMsg = api+'/chat'