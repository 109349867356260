import React from "react";

type Props = {
  className?: string,
  children?: React.ReactNode,
  onClick?: (event: any) => void,
}

const ButtonRound: React.FC<Props> = ({ className, children, onClick }: Props) => {

  return <button
    className={`inline-flex items-center justify-center size-12 bg-[#F2F2F2] text-xl rounded-full ${className}`}
    onClick={onClick}>
    {children}
  </button>
}

export default ButtonRound