// export const USER_ID = 1;
export const PWD_MIN_LEN = 6
export const PWD_MAX_LEN = 16
export const APP_KEY = `${process.env.REACT_APP_KEY}${process.env.REACT_APP_DOMAIN_NAME}`

export const DEFAULT_AVATOR='/assets/default_avator.png'


export const DEFAULT_ARCTICLE_SEARCH_COUNT=20//5//

//合同各个子类
export const CONTRACT_TYPE_ID =569557950287941

