
// import ReactGA from 'react-ga';
import GA4React, { useGA4React } from "ga-4-react";
import { GA4ReactResolveInterface } from "ga-4-react/dist/models/gtagModels";

// const gid = 'G-1SLJLD8VQE'
let gid = ''
// const ga4react = new GA4React(gid, { debug_mode: true });

export async function gaInitialize(ga4react: GA4React, id: string) {
    gid = id;
    return ga4react.initialize().then((ga4) => {
        ga4.pageview(window.location.pathname + window.location.search)
        console.log("ga4**gaInitialize**", window.location.pathname + window.location.search, ga4)
        // ga4.gtag('event','pageview','path') // or your custom gtag event
    }, (err) => {
        console.error(err)
    })
}

// export async function gaInitialize() {
//     return ga4react.initialize().then((ga4) => {
//         ga4.pageview(window.location.pathname + window.location.search)
//         console.log("ga4**gaInitialize**", window.location.pathname + window.location.search, ga4)
//         // ga4.gtag('event','pageview','path') // or your custom gtag event
//     }, (err) => {
//         console.error(err)
//     })
// }

// export const gaEvent = (ga4: GA4ReactResolveInterface, category: string, action: string, value?: any) => {
//     if (!ga4) return;
//     console.log("ga4**gaEvent**", category, "**", action, "**", value, ga4)
//     if (value === undefined) {
//         ga4.gtag('event', action, {
//             'event_category': category,
//         });
//     }
//     else {
//         ga4.gtag('event', action, {
//             'event_category': category,
//             value: value
//         });
//     }
// };

export const gaEvent = (ga4: GA4ReactResolveInterface, action: string, value?: any) => {
    if (!ga4) return;
    console.log("ga4**gaEvent**", action, "**value**", value, !!ga4)
    if (value === undefined) {
        ga4.gtag('event', action);
    }
    else {
        ga4.gtag('event', action, {
            value: value
        });
    }
};


export const gaEventWithJson = (ga4: GA4ReactResolveInterface, action: string, value: any) => {
    if (!ga4) return;
    console.log("ga4**gaEventWithJson**", action, "**value**", value, !!ga4)

        ga4.gtag('event', action, value);
    
};

// export const gaEvent = (ga4: GA4ReactResolveInterface, category: string, action: string, value?: any) => {
//     if (!ga4) return;
//     console.log("ga4**gaEvent**", category, "**", action, "**", value, ga4)
//     if (value === undefined) {
//         // ga4.gtag('send', 'event', {
//         //     'eventCategory': category,
//         //     'eventAction': action,
//         //   });
//         ga4.gtag('event', action, {
//             'event_category': category,
//         });
//     }
//     else {
//         // ga4.gtag('send', 'event', {
//         //     'eventCategory': category,
//         //     'eventAction': action,
//         //     'eventValue': value
//         //   });
//         ga4.gtag('event', action, {
//             'event_category': category,
//             value: value
//         });
//     }

//     // ReactGA.event({
//     //     category: category,
//     //     action: action,
//     //     value:value
//     //   });
// };

export const gaSetUser = (ga4: GA4ReactResolveInterface, userId: string) => {
    if (!ga4) return;
    // ReactGA.set({ userId});
    console.log("ga4**gaSetUser**", userId, ga4)
    // if(!!ga4.ga) ga4.ga('set','userId',userId) 
    // ga4.gtag('set','userId',userId) // or your custom gtag event
    ga4.gtag('config', gid, {
        'user_id': userId,
        // 'cookie_domain': 'app.eut.com'
    });
};

export const gaPageView = (ga4: GA4ReactResolveInterface, url: string, title?: string) => {
    // return;
    if (!ga4) return;
    // ReactGA.set(url);
    // ga4.pageview(url)
    if (title) {
        ga4.gtag('config', gid, {
            'page_title': title,
        });
    }
    console.log("ga4**gaPageView**",title, url, !!ga4)
    ga4.gtag('event', 'pageview', url)

};


