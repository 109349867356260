import { addDownloadCount, ArticleType, getArticleTypesInfoData, GetArticleTypesRequestData, contractSearch,SearchRequestData, searchTypes, clauseSearch, articleDetail, popContractList } from "./app";
import { ArticleTypeInfoResponseData, ClauseSearchResponseData, ContractSearchResponseData, SearchTypeData } from "./response";

export interface ResponseServiceData<T>{
  success:boolean,
  error?:string,
  data?:T
}

export const onContractSearch = async(data: SearchRequestData): Promise<ResponseServiceData<ContractSearchResponseData>> => {
  const response =await contractSearch(data)
  // console.log('onSearch**',response)

  if(response.status === 200){
    return {success:true,data:response.data}
  }
  return {success:false,error:response.status.toString()}
};

export const onClauseSearch = async(data: SearchRequestData): Promise<ResponseServiceData<ClauseSearchResponseData>> => {
  const response =await clauseSearch(data)
  // console.log('onSearch**',response)

  if(response.status === 200){
    return {success:true,data:response.data}
  }
  return {success:false,error:response.status.toString()}
};

export const onSearchTypes = async(keyword: string,type?:ArticleType): Promise<ResponseServiceData<SearchTypeData>> => {
  const response =type=== undefined?await searchTypes(keyword): await searchTypes(keyword,type)
  if(response.data.success){
    return {success:true,data:response.data.data}
  }
  return {success:false,error:response.status.toString()}
};

export const onGetArticleTypesInfo = async(data: GetArticleTypesRequestData): Promise<ResponseServiceData<ArticleTypeInfoResponseData>>  => {
  const response =await getArticleTypesInfoData(data)
  if(response.status === 200){
    return {success:true,data:response.data}
  }
  return {success:false,error:response.status.toString()}
};


export const onAddDownloadCount = async(id: string) => {
  const response =await addDownloadCount(id)
  if(response.data.success){
    return {success:true}
  }
  return {success:false,error:response.status.toString()}
};

export const onContractDetail = async(id: string|number) => {
  const response =await articleDetail(id)
  if(response.data.success){
    return {success:true,data:response.data.data}
  }
  return {success:false, error:response.status.toString()}
};


export const onPopContractList= async(pageNo:number, pageSize:number) => {
  const response =await popContractList(pageNo,pageSize)
  if(response.status === 200){
    return {success:true,data:response.data}
  }
  return {success:false, error:response.status.toString()}
};
