import React, { ReactElement, useEffect, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";

import {
  Main,
  Solution,
  MyTrademark,
  Login,
  Signup,
  EmailVertification,
  PasswordReset,
  GoogleLoginCallback,
  ContractsHome,
  Contracts,
  Clauses,
  Agreement,
  AgreementDownload,
  Search,
  ContractMain,
  Trademark,
  TestSearch,
  TestCompare,
  InfringementReport,
  TrademarkDetailReport
} from "../pages";
import { Myprofile, PasswordReset as PersonalPasswordReset, PersonalCenter } from '../components/PersonalCenter'
import * as routerConstants from "./constants";
import useUserInfoControl from "../hooks/get-user-info3-hook";
import { useGA4React } from "ga-4-react";
import { gaPageView } from "../service/ga4Service";

type Props = {
  children?: React.ReactNode;
};

const WithRouter = ({ children }: Props) => {
  const location = useLocation();
  const { } = useUserInfoControl();
  const ga4 = useGA4React();

  useLayoutEffect(() => {
    //页面加载后回滚到顶端
    document.documentElement.scrollTo(0, 0);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    // document.title = getTitle(location.pathname) || ''//'1'
  }, [location.pathname]);

   //发送页面访问记录到ga
   useEffect(() => {
    // if (!!ga4) gaPageView(ga4, window.location.pathname + window.location.search)
    if (!!ga4) gaPageView(ga4, location.pathname + location.search,document.title)
    return () => {

    }
  }, [location.pathname, ga4])

  return children as ReactElement;
};

const MainRoute = () => {
  return <Router>
    <WithRouter>
      <Routes>
        <Route path={routerConstants.DASHBOARD} element={<Navigate to={routerConstants.CONTRACTS_HOME} />} />
        <Route path={routerConstants.MAIN} element={<Main />}>
          <Route path={routerConstants.MAIN} element={<Navigate to={routerConstants.CONTRACTS_HOME} />} />
          {/* <Route path={routerConstants.SOLUTIONS} element={<Solution />} /> */}
          <Route path={routerConstants.MYTRADEMARK} element={<MyTrademark />} />
          {/* <Route path={routerConstants.SOLUTIONS} element={<Solution />} /> */}
          <Route path={routerConstants.PERSONAL_CENTER} element={<PersonalCenter />} />
          <Route path={routerConstants.PERSONAL_CENTER_MYPROFILE} element={<Myprofile />} />
          <Route path={routerConstants.PERSONAL_CENTER_PASSWORD_RESET} element={<PersonalPasswordReset />} />
        </Route>
        <Route path={routerConstants.CONTRACTS} element={<ContractMain/>}>
          <Route path={routerConstants.CONTRACTS_HOME} element={<ContractsHome />} />
          <Route path={routerConstants.CONTRACTS_CONTRACTS} element={<Contracts />} />
          <Route path={routerConstants.CONTRACTS_CLAUSES} element={<Clauses />} />
          <Route path={`${routerConstants.AGREEMENT}/:type`} element={<Agreement />} />
          <Route path={`${routerConstants.AGREEMENT_DOWNLOAD}/:id`} element={<AgreementDownload />} />
          <Route path={routerConstants.SEARCH} element={<Search />} />
          <Route path={routerConstants.CONTRACTS} element={<Navigate to={routerConstants.CONTRACTS_HOME} />} />
        </Route>
        <Route path={routerConstants.SOLUTIONS} element={<Trademark />} />
        <Route path={routerConstants.TRADEMARK_REPORT} element={<TrademarkDetailReport />} />
        <Route path={routerConstants.INFRINGEMENT_REPORT} element={<InfringementReport />} />
        <Route path={routerConstants.LOGIN} element={<Login />} />
        <Route path={routerConstants.SIGNUP} element={<Signup />} />
        <Route
          path={routerConstants.EMAIL_VERIFICATION}
          element={<EmailVertification />}
        />
        <Route
          path={routerConstants.PASSWORD_RESET}
          element={<PasswordReset />}
        />
        <Route
          path={routerConstants.GOOGLE_CALLBACK}
          element={<GoogleLoginCallback />}
        />
        <Route
          path={routerConstants.TEST_SEARCH}
          element={<TestSearch />}
        />
        <Route
          path={routerConstants.TEST_COMPARE}
          element={<TestCompare />}
        />
      </Routes>
    </WithRouter>
  </Router>;
};

export default MainRoute;
