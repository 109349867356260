export const getFileExtension = (url: string) => {
    const match = url.match(/\.([a-zA-Z0-9]+)$/);
    return match ? match[1] : null;
  };

  export const isFileInstance = (data: any): data is File => {
    return data instanceof File; // 判断是否为 File 对象
  };

  export function base64ToFile(base64String: string, filename?: string): File | null {
    let fileName = filename
    if(!fileName) fileName = Date.now().toString();
    // 处理 Base64 字符串，提取出 MIME 类型和数据部分
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/);
    const mimeType = mime ? mime[1] : '';
  
    // 解码 Base64 数据
    const byteString = atob(arr[1]);
    const ab = new Uint8Array(byteString.length);
  
    // 将解码后的数据写入 Uint8Array
    for (let i = 0; i < byteString.length; i++) {
      ab[i] = byteString.charCodeAt(i);
    }
  
    // 创建 Blob 对象
    const blob = new Blob([ab], { type: mimeType });
  
    // 使用 Blob 来创建 File 对象
    return new File([blob], fileName, { type: mimeType });
  }