import { combineReducers } from 'redux';

import * as persistSettingsLocalData from './persist-setting-data'
import * as authLocalData from './auth-local-data'
import * as trademarkListLocalData from './trademarklist-local-data'

const combinedReducer = combineReducers({
  // track: track.reducer(),
  // collect: collect.reducer(),
  persistSettingsLocalData:persistSettingsLocalData.reducer(),
  authLocalData: authLocalData.reducer(),
  trademarkListLocalData:trademarkListLocalData.reducer()
});

const actions = {
  // track: track.actions,
  // collect: collect.actions,
  persistSettingsLocalData:persistSettingsLocalData.actions,
  authLocalData: authLocalData.actions,
  trademarkListLocalData:trademarkListLocalData.actions

};

const selectors = {
  // track: track.select,
  // collect: collect.select,
  persistSettingsLocalData:persistSettingsLocalData.select,
  authLocalData: authLocalData.select,
  trademarkListLocalData:trademarkListLocalData.select

};

export {
  actions,
  selectors,
  combinedReducer,

  persistSettingsLocalData,
  authLocalData,
  trademarkListLocalData
}
