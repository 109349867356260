import React from "react";
import { Toaster } from 'react-hot-toast';

import MainRoute from "./router/router";
import { createClient } from "@supabase/supabase-js";
import { SupabaseProvider } from "./contexts/SupabaseContext";

import { Provider } from "react-redux";
import { store } from "./redux";
import { RandomQuestionProvider } from "./contexts/RandomQuestionContext";

const App: React.FC = () => (
  <Provider store={store}>
    <SupabaseProvider>
      <RandomQuestionProvider>
        <MainRoute />
        <Toaster containerStyle={{ top: '50%' }} />
      </RandomQuestionProvider>
    </SupabaseProvider>
  </Provider>
);
export default App;
