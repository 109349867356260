import React from 'react'

import { ImgQueryLoading } from '../images'

const Loading: React.FC = () => {

  return <li className='flex flex-row justify-center'>
    <img className='size-[120px]' src={ImgQueryLoading} />
  </li>
}

export default Loading