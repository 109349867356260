import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { Button } from '../Button'
import { Copy as IconCopy, Security as IconSecurity, Upgrade as IconUpgrade, MyTrademark as IconMyTrademark } from '../../svgr/components'

import { MYTRADEMARK, PERSONAL_CENTER, PERSONAL_CENTER_PASSWORD_RESET } from '../../router/constants'
import useUserInfo from '../../hooks/userinfo-hook'
import copy from 'copy-to-clipboard'
import { DefaultAvator } from './../'

type Props = {
  onClose: () => void,
  onLogout?: () => void,
}

const UserMenu = forwardRef(({ onClose, onLogout }: Props, ref) => {

  const navigate = useNavigate()

  const { userName, uid, avatar } = useUserInfo()

  useEffect(() => {
    window.addEventListener('click', onClose)
    return () => {
      window.removeEventListener('click', onClose)
    }
  }, [])


  return <div
    className='absolute top-[79px] right-9 flex flex-col w-[368px] h-[462px] p-6 bg-white rounded-4xl shadow-[0px_0px_12px_0px_#0000001A] z-50'
    onClick={e => { e.stopPropagation() }}
  >
    <div
      className='flex flex-row items-centers p-4 border border-[#ddd] rounded-2xl hover:bg-[#F2F2F2] cursor-default'
      onClick={() => {
        onClose()
        navigate(PERSONAL_CENTER)
      }}
    >
      <DefaultAvator
        name={userName}
        avatar={avatar}
        size={'small'} />
      {/* <img className='size-12 rounded-full' src={avatar} /> */}
      <div className='flex flex-col ml-2.5'>
        <h6 className='font-semibold text-base text-[#111]'>{userName}</h6>
        <div className='flex flex-row items-center space-x-1'>
          <span className='text-xs text-[#4B4B4B]'>ID: {uid}</span>
          <span className='text-base cursor-pointer'
            onClick={(e) => {
              e.stopPropagation()
              uid && copy(uid)
            }}>
            <IconCopy />
          </span>
        </div>
      </div>
    </div>
    <ul className='flex flex-col flex-1 my-4 space-y-1'>
      <li className='flex flex-row'>
        <button
          className='flex flex-row flex-1 items-center px-4 py-3.5 hover:bg-[#F2F2F2] rounded-2xl'
          onClick={() => {
            onClose()
            navigate(PERSONAL_CENTER_PASSWORD_RESET)
          }}
        >
          <span className='inline-flex text-xl'>
            <IconSecurity />
          </span>
          <div className='flex-1 ml-2.5 text-sm text-[#282828] text-left'>Security</div>
        </button>
      </li>
      {/* <li className='flex flex-row'>
        <button
          className='flex flex-row flex-1 items-center px-4 py-3.5 hover:bg-[#F2F2F2] rounded-2xl'
          onClick={() => {
            onClose()
            // navigate(PERSONAL_CENTER_PASSWORD_RESET)
            navigate(MYTRADEMARK)
          }}
        >
          <span className='inline-flex text-xl'>
            <IconMyTrademark />
          </span>
          <div className='flex-1 ml-2.5 text-sm text-[#282828] text-left'>My Trademark</div>
        </button>
      </li> */}
      {/* <li className='flex flex-row'>
        <button
          className='flex flex-row flex-1 items-center px-4 py-3.5 hover:bg-[#F2F2F2] rounded-4xl'
          onClick={() => {
            onClose()
            navigate(PERSONAL_CENTER)
          }}
        >
          <span className='inline-flex text-xl'>
            <IconUpgrade />
          </span>
          <div className='flex-1 ml-2.5 text-sm text-[#282828] text-left'>Upgrade Plans</div>
          <div className='py-0.5 px-2 bg-gradient-primary rounded-full'>
            <span className='text-xs text-[#111]'>PLUS</span>
          </div>
        </button>
      </li> */}
    </ul>
    <Button
      size='medium'
      type='normal'
      onClick={onLogout}
    >Log out</Button>
  </div>
})

export default UserMenu