import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'

import { Search as SearchIcon } from '../../svgr/components'
import { SOLUTIONS, CONTRACTS_HOME, SEARCH, TRADEMARK_REPORT, INFRINGEMENT_REPORT } from '../../router/constants'

const NOT_DISPLAY_PATH: string[] = [
  SOLUTIONS,
  CONTRACTS_HOME,
  SEARCH,
  TRADEMARK_REPORT,
  INFRINGEMENT_REPORT
  // CONTRACTS_CONTRACTS,
  // CONTRACTS_CLAUSES
]

type Props = {

}

const Search: React.FC<Props> = () => {

  const location = useLocation()
  const navigate = useNavigate()

  const [display, setDisplay] = useState(false)

  useEffect(() => {
    const path = location.pathname
    const index = NOT_DISPLAY_PATH.findIndex(item => item === path)
    setDisplay(index === -1 ? true : false)
  }, [location.pathname])

  

  const [keyword, setKeyword] = useState('')

  const handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      // 处理回车键时的提交逻辑，这里只是一个简单的示例
      console.log('提交的输入值:', keyword);
      navigate(`${SEARCH}`, {
        state: {
          q: keyword||'',
          t: 'all',
        },
      });
      // 清空输入框
      setKeyword('');
    }
  };

  if (!display) {
    return null
  }

  // return <search className='inline-flex flex-row items-center w-[267px] px-4 border border-[#DDD] rounded-full'>
  //   <input
  //     className='flex-1 bg-none text-base text-[#111] placeholder:text-[#9D9FA1] outline-none'
  //     placeholder='Search'
  //   />
  //   <button className="inline-flex text-2xl">
  //     <SearchIcon />
  //   </button>
  // </search>


  return <search className="flex flex-row items-center">
    <button
      className="inline-flex items-center justify-center size-12 bg-[#F2F2F2] text-2xl rounded-full"
      onClick={() => { 
        // navigate(SEARCH)
        navigate(`${SEARCH}`, {
          state: {
            q: keyword||'',
            t: 'all',
          },
        });
       }}
    >
      {/* onChange={(e)=>{
        setKeyword(e.target.value)
      }}
      onKeyDown={handleKeyDown} */}
      <SearchIcon />
    </button>
  </search>
}

export default Search