import { ReportDataData, StoreInfoData, TrademarksMonitoringData } from "../api/ai/response";
import { SendMessageData } from "../api/chat/response";
import { SendMessageData as SendMessageData2 } from "../api/ai/response";
import {
  ChatChooseType,
  ChatData,
  ChatImage2Data,
  ChatImageData,
  ChatStoreInfoSummaryData,
  ChatTradeMarkAnalysisSummaryData,
  ChatTradeMarkAskChooseData,
  ChatTradeMarkChooseData,
  ChatTradeMarkReportData,
  ChatTradeMarkSummaryData,
  ChatType,
  SummaryTableData,
} from "../components/Chat/chatdatatype";
import { getMsTimeStamp } from "../utils/time";
import { getRandomStr } from "../utils/string";

import questionJson from "../data/questions.json";


const _ = require('lodash');


const buildId =()=>{return getMsTimeStamp().toString()+"_"+getRandomStr(6)};

export const buildAnswerResponseData = (data: SendMessageData): ChatData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "word",
    name: "Answer",
    avatar: "/assets/system.png",
    sender: "system",
    data: {
      message: data.value.content,
    },
  };
};

export const buildAnswerResponseData2 = (data: SendMessageData2): ChatData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "word",
    name: "Answer",
    avatar: "/assets/system.png",
    sender: "system",
    data: {
      message: data.message.content,
    },
  };
};

export const buildUserWordData = (data: string): ChatData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "word",
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message: data,
    },
  };
};

export const buildNormalQuestionChatData = (data: string): ChatData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "word",
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message: data,
    },
  };
};



export const buildUploadPrefixChatData = (): ChatData[] => {
  const id = buildId();
  const id1 = id.toString();
  const id2 = (id + 1).toString();
  return [
    {
      id: id1,
      localId: id1,
      type: "word",
      name: "User",
      avatar: "/assets/default_avator.png",
      sender: "self",
      data: {
        message: "Comparison of trademarks across the entire web",
        questionId:1
      },
    },
    {
      id: id2,
      localId: id2,
      type: "answer-upload",
      name: "Answer",
      avatar: "/assets/system.png",
      sender: "system",
      data: {
        message:
          "Please enter your trademark name or upload a trademark image.",
          questionId:1
      },
    },
  ];
};

export const buildUploadFirst = (question:ChatData): ChatData[] => {
  return buildUploadPrefixChatData()
  const id = buildId();
  const id1 = id.toString();
  return [
    {...question},
    {
      id: id1,
      localId: id1,
      type: "answer-upload",
      name: "Answer",
      avatar: "/assets/system.png",
      sender: "system",
      data: {
        message:
          "Please enter your trademark name or upload a trademark image.",
      },
    },
  ];
};

export const buildUploadWordTrademarkData = (data: string): ChatData => {
  const id =buildId();
  return {
    id: id,
    localId: id,
    type: "upload-word",
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message: data,
    },
  };
};

export const buildUploadImageData = (url: string, file: any): ChatImageData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "upload-image",
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message: url,
      url: url,
      file: file,
    },
  };
};

export const buildUpload21ImageData = (url: string, file: any,url1:string,file1:any): ChatImage2Data => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "upload-image21",
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message: url,
      url: url,
      file: file,
      url1: url1,
      file1: file1,
    },
  };
};

// export const buildSearchQuickQuestion2 = (data: QuestionData): ChatData[] => {
//   const id = getMsTimeStamp();
//   const id1 = id.toString();
//   return [
//     {
//       id: id1,
//       localId: id1,
//       type: "word",
//       name: "User",
//       avatar: "/assets/default_avator.png",
//       sender: "self",
//       data: {
//         message: data.data.message,
//       },
//     }
//   ];
// };

export const buildFilterTableData = (
  data: SummaryTableData,title:string,hasPercent:boolean=false
): ChatTradeMarkSummaryData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "answer-filter-summary",
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message: title,
      hasPercent,
      data: data,
    },
  };
};

export const buildTableData = (
  data: SummaryTableData,title:string,hasPercent:boolean=false
): ChatTradeMarkSummaryData => {
  const id =buildId();
  return {
    id: id,
    localId: id,
    type: "answer-summary",
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message: title,
      hasPercent,
      data: data,
    },
  };
};

export const buildAnalysisTableData = (
  data: TrademarksMonitoringData,title:string,url:string,file:any
): ChatTradeMarkAnalysisSummaryData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "answer-analysis-summary",
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message: title,
      data: data,
      url:url,file:file,
    },
  };
};


export const buildStoreInfoTableData = (
  data: StoreInfoData[],title:string,file:any
): ChatStoreInfoSummaryData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: 'answer-store-summary',
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message: title,
      data: data,
      file:file
    },
  };
};




export const buildAnswerButtonData = (data: any[],type:ChatChooseType): ChatTradeMarkChooseData => {
  const id = buildId();
  let msg= ''
  switch (type) {
    case "class":
      msg = 'The industries involved with the trademark are'
      break;
    case "owner":
      msg = 'The trademark owner is'
      break;
    case "time":
      msg = 'Range of trademark registration dates'
      break;
    case "country":
      msg = 'The countries where the trademark is registered are'
      break;
  
    default:
      break;
  }
  return {
    id: id,
    localId: id,
    type: 'answer-choose',
    name: "Answer",
    avatar: "/assets/system.png",
    sender: "system",
    data: {
      message: '',
      data:data,
      type:type
    },
  };
};

export const buildAskChooseWordData = (data: string,type:ChatChooseType): ChatTradeMarkAskChooseData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "ask-choose",
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message:data,
      data: data,
      type:type
    },
  };
};

export const buildFixQuestionChatData = (data: string,type:ChatType,level:number,questionId:string): ChatData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: type,
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message:data,
      data: data,
      level:level,
      questionId:questionId
    },
  };
};

export const buildReportData = (data:ReportDataData,localGoodUrl:string,
  localTradeMarkUrl:string,goodUrl:string,tradeMarkUrl:string): ChatTradeMarkReportData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: 'report',
    name: "Answer",
    avatar: "/assets/system.png",
    sender: "system",
    data: {
      message: '',
      data: data,
      goodUrl,
      tradeMarkUrl:tradeMarkUrl,
      localGoodUrl,
      localTradeMarkUrl
    },
  };
};



export const buildFixQuestionChatData6 = (data: string,type:ChatType,level:number): ChatData[] => {
  const id = buildId();
  const id1 = id.toString();
  const id2 = (id+1).toString();
  return [{
    id: id1,
    localId: id1,
    type: type,
    name: "User",
    avatar: "/assets/default_avator.png",
    sender: "self",
    data: {
      message:data,
      data: data,
      level:level,
      questionId:6
    },
  },{
    id: id2,
    localId: id2,
    type: 'answer-typography',
    name: "Answer",
    avatar: "/assets/system.png",
    sender: "system",
    data: {
      message: `Trademark registration involves a series of laws and procedures. The following is an overview of the laws and procedures in general:
      
      Trademark Law: Trademark law stipulates the definition of a trademark, registration conditions, scope of rights, infringement, and regulations on trademark use and protection. Trademark laws may vary from country to country, but they generally follow the basic principles of international trademark law.
      
      Trademark Search: Before submitting a trademark registration application, it is usually necessary to conduct a trademark search to ensure that the trademark being applied for does not conflict with an existing trademark. Trademark searches can be conducted globally to eliminate the risk of similar trademarks.
      
      Trademark Application: Applications for trademark registration are usually filed with national or regional trademark offices. When applying, you need to provide trademark graphic samples, registration categories, trademark owner information and other information. Applicants need to pay the application fee and follow relevant regulations during the application process.
      
      Trademark review: The Trademark Office will review a submitted trademark application to ensure that the application meets legal requirements. The review process may include an assessment of the mark's registrability, the existence of similar marks, etc.
      
      Trademark Publication: After passing preliminary examination, the Trademark Office will usually publish the trademark application so that the public can raise objections. Within a certain period, anyone has the right to object.
      
      Trademark Registration: If no objections are received, the trademark application will be approved for registration and a Trademark Registration Certificate will be issued. Trademark registrations are usually valid for 10 years, but can be renewed before expiration.
      
      Trademark protection and maintenance: After a trademark is registered, the trademark owner has the right to use and protect the trademark within the scope of registration. Trademark owners need to monitor the market to prevent infringement by others and renew their trademark registration regularly.
      
      The laws and procedures for trademark registration will vary from country to country, so if you need to register a trademark, it is recommended to consult a professional trademark attorney or lawyer for specific advice.`,
    },
  }];
};

export interface QuestionData {
  id: string;
  type: "quick" | "restart" | 'upload-analysis';
  level:number;
  data: {
    message: string;
  };
}

export const buildAnalysisReuploadQuestionData =()=>{
return questionJson["restart-analysis"] as QuestionData
}

export const buildAnalysisQuickQuestionData = (
  data: QuestionData
): ChatData[]|undefined => {
  const id = buildId();
  const id1 = id.toString();
  const id2 = (id+1).toString();
  const level = data.level
  switch (data.id) {
    case "1":
      return buildAnalysisUploadPrefixChatData();
    case "2":
      return [buildFixQuestionChatData(data.data.message,'aq2',level,data.id)];
    case "3":
      return buildAnalysisUploadPrefix21ChatData(buildFixQuestionChatData(data.data.message,'aq3',level,data.id));
      break;
    default:
      break;
  }
  
};

// export interface QuestionData {
//   id: string;
//   type: "quick" | "restart" | 'upload-analysis';
//   level:number;
//   data: {
//     message: string;
//   };
// }

// export const buildSearchQuickQuestionDataWithType = (
//   data: QuestionData
// ): ChatData[]|undefined => {
//   const id = buildId();
//   const id1 = id.toString();
//   const id2 = (id+1).toString();
//   const level = data.level
//   switch (data.id) {
//     case "1":
//       return buildUploadPrefixChatData();
//     case "2":
//       return [buildFixQuestionChatData(data.data.message,'sq2',level,)];
//     case "3":
//       return [buildFixQuestionChatData(data.data.message,'sq3',level)];
//       break;
//     case "4":
//       return [buildFixQuestionChatData(data.data.message,'sq4',level)];
//       break;
//     case "5":
//       return [buildFixQuestionChatData(data.data.message,'sq5',level)];
//       break;
//     case "6":
//       return buildFixQuestionChatData6(data.data.message,'sq6',level);
//       break;
//     default:
//       break;
//   }
  
// };

export const buildSearchQuickQuestionData = (
  data: QuestionData
): ChatData[]|undefined => {
  const id = buildId();
  const id1 = id.toString();
  const id2 = (id+1).toString();
  const level = data.level
  switch (data.id) {
    case "1":
      return buildUploadPrefixChatData();
    case "2":
      return [buildFixQuestionChatData(data.data.message,'sq2',level,data.id)];
    case "3":
      return [buildFixQuestionChatData(data.data.message,'sq3',level,data.id)];
      break;
    case "4":
      return [buildFixQuestionChatData(data.data.message,'sq4',level,data.id)];
      break;
    case "5":
      return [buildFixQuestionChatData(data.data.message,'sq5',level,data.id)];
      break;
    case "6":
      return buildFixQuestionChatData6(data.data.message,'sq6',level);
      break;
    default:
      break;
  }
  
};

export const buildAnalysisUploadPrefixChatData = (): ChatData[] => {
  const id =buildId();
  const id1 = id.toString();
  const id2 = (id + 1).toString();
  return [
    {
      id: id1,
      localId: id1,
      type: "word",
      name: "User",
      avatar: "/assets/default_avator.png",
      sender: "self",
      data: {
        message: "Intelligent trademark monitoring",
      },
    },
    {
      id: id2,
      localId: id2,
      type: "answer-upload2",
      name: "Answer",
      avatar: "/assets/system.png",
      sender: "system",
      data: {
        message:
          "Please upload your trademark image",
      },
    },
  ];
};
export const buildAnalysisUploadPrefix21ChatData = (question:ChatData): ChatData[] => {
  const id =buildId();
  const id1 = id.toString();
  const id2 = (id + 1).toString();
  return [
    {...question},
    {
      id: id2,
      localId: id2,
      type: "answer-upload21",
      name: "Answer",
      avatar: "/assets/system.png",
      sender: "system",
      data: {
        message:
          "Please upload your trademark image",
      },
    },
  ];
};

export const buildAnalysisUploadFirst = (question:ChatData): ChatData[] => {
  return buildAnalysisUploadPrefixChatData();
  const id = buildId();
  const id1 = id.toString();
  const id2 = (id + 1).toString();
  return [
    {...question},
    {
      id: id2,
      localId: id2,
      type: "answer-upload2",
      name: "Answer",
      avatar: "/assets/system.png",
      sender: "system",
      data: {
        message:
          "Please upload your trademark image",
      },
    }
  ];
};

export const buildLoadingData = (): ChatData => {
  const id = buildId();
  return {
    id: id,
    localId: id,
    type: "waiting",
    name: "System",
    avatar: "/assets/default_avator.png",
    sender: 'system',
    data: {
      message: ''
    },
  };
};



export const buildClassDataFromTableData =(data:SummaryTableData)=>{
  const classData = data.map(item=>item.class)
  const uniqueArr = _.uniq(classData).sort((a:any, b:any) => a - b);
  return uniqueArr;
}

export const buildOwnerDataFromTableData =(data:SummaryTableData)=>{
  const classData = data.map(item=>item.owner)
  const uniqueArr = _.uniq(classData).sort((a:any, b:any) => a - b);
  return uniqueArr;
}