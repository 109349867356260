import { get, longPost, post, put, upload } from "../request";
import * as api from "./index";


export type UploadImageType = 'AVATAR' | 'FEEDBACK' |'MESSAGE'

export const uplaodAvatarImage= (file:any) => {
 return uplaodImage(file, 'AVATAR');
};


export const uplaodFeedbackImage= (file:any) => {
  return uplaodImage(file, 'FEEDBACK');
};

export const uplaodMessageImage= (file:any) => {
  return uplaodImage(file, 'MESSAGE');
};


export const uplaodImage= (file:any,type:UploadImageType) => {
  const url = `${api.uploadImage}`;
  const form = new FormData();
  form.append("file", file);
  form.append("fileType", type);
  return upload(url, form);
};
