import React, { useEffect, useState } from "react";
import { FeedChickType, onFeedChick } from "../api/chat/trademarkService";
import useStateRef from "react-usestateref";
import { useRandomQuestion } from "../contexts/RandomQuestionContext";
import { useGA4React } from "ga-4-react";
import { gaFreeChickAIAssistantEvent, gaFreeChickInfringementAnalysisEvent } from "../service/ga4Service";
import { FeedType } from "../api/chat/app";

type Props = {
  type: "primary" | "second";
  feed: FeedChickType;
  onClick?: () => void;
};

const ComingSoon: React.FC<Props> = ({ type, feed, onClick }: Props) => {
  const [loading, setLoading, loadingRef] = useStateRef(false);
  const [clickTimes, setClickTimes, clickTimesRef] = useStateRef(0);
  
  const ga4 = useGA4React();
  //提交到ga
  const submitGA = (data: FeedType) => {
    if (!!ga4) {
      const json = {type:data}
      switch (data) {
       case "AI_ASSISTANT":
        gaFreeChickAIAssistantEvent(ga4, json);
        break;
       case "AI_MONITOR":
        gaFreeChickInfringementAnalysisEvent(ga4, json);
        break;
        default:
          break;
      }
      
    }
  }

  return (
    <>
      <div className="flex flex-col mx-auto text-center z-[1]">
        {type === "primary" && (
          <>
            <div className="text-4.5xl text-blue font-semibold">
              Coming soon
            </div>
          </>
        )}
        {type === "second" && (
          <>
            <div className="text-4.5xl text-green font-semibold">
              Coming soon
            </div>
          </>
        )}
        <div className="text-sm text-black">
          Add chicken legs to the development team to replenish energy and get
          features online faster
        </div>
        <button
          className={
            loadingRef.current
              ? "inline-flex justify-center w-[420px] px-2.5 py-4 mx-auto mt-8  border border-[#BDBDBD] rounded-4xl space-x-2 cursor-not-allowed"
              : "inline-flex justify-center w-[420px] px-2.5 py-4 mx-auto mt-8  border border-[#BDBDBD] rounded-4xl space-x-2"
          }
          onClick={async (e) => {
            // if (loadingRef.current) return;
            // setLoading(true);
            // addNum();
            onClick && onClick();
            submitGA(feed)
            clickTimesRef.current < 1 && (await onFeedChick(feed));

            setClickTimes((t) => ++t);

            // setLoading(false);
          }}
        >
          <span className="text-base font-semibold text-[#111]">
            Free chicken legs
          </span>
          <span>🍗</span>
        </button>
      </div>
    </>
  );
};

export default ComingSoon;
