import React from 'react'

type Props = {
  className?: string,
  children: React.ReactNode
  onClick?:()=>void
}

const ButtonText: React.FC<Props> = ({ className, children,onClick }: Props) => {
  return <button type='button' onClick={onClick} className={className ? `${className} inline-flex text-sm text-[#0063F2]` : 'inline-flex text-sm text-[#0063F2]'}>
    {children}
  </button>
}

export default ButtonText