import React, { useEffect, useState } from 'react'

import { Header, Navbar, ComingSoon, CustomerService } from '../components'
import { Search, InfringingAnalysis, Barrage } from '../components/Trademark'
import { Right as IconRight } from '../svgr/components'
import { ImgLogos, ImgAdidas, ImgMap, ImgCheckInfringement, ImgMyTrademark, ImgBarrage } from '../images'
import { useRandomQuestion } from '../contexts/RandomQuestionContext'


const Trademark: React.FC = () => {

  const [randomBarrage, setRandomBarrage] = useState<number[]>([])

  const random = useRandomQuestion();
  const chickPicker = random?.chick;
  // const dataArray =random?.chick.datas
  useEffect(() => {

    return () => {
      chickPicker?.reset();
      chickPicker?.resetPickNumbers();
    };
  }, []);

  const addNum =()=>{
    let num:number|null|undefined =null;
    do {
      num =chickPicker?.pickOneNumber();
    } while (num == null);
    console.log('addNum**',num)
    const datas =randomBarrage;// random?.chick.getPickNumbers();
    if(!datas) return;
   const d= [...datas.filter(item => item!==num),num]
   console.log('addNumall**',datas,d)
   random?.chick?.setPickNumbers(d)
   setRandomBarrage(d)
  }

  // const dataArray =random?.chick.getPickNumbers()

  useEffect(() => {
    console.log('randomBarrage** int',randomBarrage)
  
    return () => {
      
    }
  }, [randomBarrage])

  

  return <>
    <section className="relative flex flex-col flex-1">
      <div className="relative flex flex-col h-screen">
        <div className='flex flex-col shadow-[0px_4px_5.8px_0px_#0000000D]'>
          <Header type='primary' buttonHover='primary' />
        </div>
        <Navbar />
        <div className='relative flex flex-col flex-shrink-0 h-main-body min-h-[800px] overflow-hidden'>
          <div className='flex flex-row flex-wrap mx-auto mt-[65px] max-w-[722px] text-6.5xl font-semibold text-center'>
            <span className='text-black'>Search over</span>
            <span className='text-green'>&nbsp;1 million+&nbsp;</span>
            <span className='text-black'>Trademarks for free</span>
          </div>
          <div className='mt-[34px] mb-[66px] mx-auto text-base font-semibold text-black'>FairIP is the most trusted trademark search company globally</div>
          <Search />
          <div className='absolute left-0 right-0 bottom-[-34px] overflow-hidden'>
            <img className='mx-auto h-[112px] object-contain' src={ImgLogos} />
          </div>
        </div>
        <div className='flex flex-row justify-center pt-[150px] pb-[133px] bg-[#F9F9F9]'>
          <div className='flex flex-col mr-[218px]'>
            <h6 className='mt-[54px] mb-[39px] max-w-[532px] text-5.5xl font-semibold'>Comparison of infringing trademarks</h6>
            <ul className='flex flex-col space-y-[22px]'>
              <li className='flex flex-row space-x-4'>
                <span className='text-[28px] text-green'>
                  <IconRight />
                </span>
                <span className='text-xl leading-[30px] text-[#111]'>Protecting Brand Reputation</span>
              </li>
              <li className='flex flex-row space-x-4'>
                <span className='text-[28px] text-green'>
                  <IconRight />
                </span>
                <span className='text-xl leading-[30px] text-[#111]'>Reducing Legal Risks</span>
              </li>
              <li className='flex flex-row space-x-4'>
                <span className='text-[28px] text-green'>
                  <IconRight />
                </span>
                <span className='text-xl leading-[30px] text-[#111]'>Enhancing Market Competitiveness</span>
              </li>
              <li className='flex flex-row space-x-4'>
                <span className='text-[28px] text-green'>
                  <IconRight/>
                </span>
                <span className='text-xl leading-[30px] text-[#111]'>Ensuring Financial Security</span>
              </li>
            </ul>
            {/* <div className='mt-[79px]'>
              <img className='w-[239px]' src={ImgAdidas} />
            </div> */}
          </div>
          <InfringingAnalysis />
        </div>
        <div className='flex flex-col w-[1200px] pt-[87px] pb-[133px] mx-auto'>
          <h6 className='text-6.5xl font-semibold text-black text-center'>Infringement Analysis</h6>
          <div className='mt-[26px] mb-10 text-sm text-black text-center'>The entire platform is monitored  24 hours a day, and if any infringement is discovered, an early warning will be issued immediately.</div>
          <div className='relative h-[591px]'>
            <img className='absolute w-[1193px]' src={ImgMap} />
            <img className='absolute top-5 left-[524px] w-[140px] z-[1]' src={ImgCheckInfringement} />
            <img className='absolute top-[152px] left-[294px] w-[618px] z-[2]' src={ImgMyTrademark} />
            {/* <img className='absolute top-[494px] left-[432px] w-[413px] z-[3]' src={ImgBarrage} /> */}
            <Barrage random={randomBarrage}  setRandom={(n)=>{
            setRandomBarrage(n)
            random?.chick?.setPickNumbers(n)
            random?.chick?.setNumbersByPickNumbers();
           }}/>
          </div>
          <ComingSoon
            type='second'
            feed='AI_MONITOR'
            onClick={() => {
              addNum();
              // setRandomBarrage(Math.floor(Math.random() * 100))
            }}
          />
        </div>
      </div>
    </section>
    <CustomerService />
  </>

}

export default Trademark