import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { PDFDownloadLink } from '@react-pdf/renderer'

import { ButtonRound } from "../Button";
import { ImgReport1, ImgReport2 } from "../../images";

import { monitorCompareData } from "../../api/ai/app";
import {
  ReportDataData,
  TrademarksMonitoringDataItem,
  ReportResponseData,
} from "../../api/ai/response";
import moment from "moment";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import htmlToPdf from "../../utils/pdf";
import useStateRef from "react-usestateref";
import Loading from "../Loading";
import { Download as IconDownload } from "../../svgr/components";
import { formatNumberWithThousandCode } from "../../utils/numberUtils";

import { getImageBase64Data } from "../../utils/ImageUtils";
import InfringementReportPdf from "../Report/InfringementReportPdf";
import { parseFloatValue } from "../../utils/CoinUtils";

type Props = {
  hideInfringementDetails?: boolean;
  reportDataData:ReportDataData |undefined,
  yourTrademark:string|undefined,
  goodUrl:string|undefined,
};

const InfringementReport = forwardRef(
  ({ hideInfringementDetails = false,reportDataData,yourTrademark,goodUrl }: Props, ref) => {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const [reportTrademarksMonitor, setReportTrademarksMonitor] =
      useState<TrademarksMonitoringDataItem>();
    // const [yourTrademark, setYourTrademark] = useState<string>();
    // const [goodUrl, setGoodUrl] = useState<string>();
    // const [reportDataData, setReportDataData] = useState<ReportDataData>();

    useEffect(() => {
      if (reportDataData) setLoading(false)
      else setLoading(true)

      return () => {

      }
    }, [reportDataData])


    useImperativeHandle(ref, () => ({
      show: show,
      show2: show2,
      hide: hide,
    }));

    const show = (
      yourTrademark: string,
      trademarksMonitor: TrademarksMonitoringDataItem
    ) => {
      // setReportTrademarksMonitor(trademarksMonitor);
      // setGoodUrl(trademarksMonitor.imageUrl);
      // setYourTrademark(yourTrademark);
      // const url = trademarksMonitor.imageUrl;
      // queryReport(url);
      // dialogRef.current?.showModal();
    };

    const show2 = (
      yourTrademark: string,
      goodUrl: string,
      reportData: ReportDataData
    ) => {
      // setYourTrademark(yourTrademark);
      // setGoodUrl(goodUrl);
      // setReportDataData(reportData);
      // dialogRef.current?.showModal();
    };

    const hide = () => {
      // // setReportDataData(undefined);
      // setReportTrademarksMonitor(undefined);
      // setYourTrademark(undefined);
      // dialogRef.current?.close();
    };

    const timerRef = useRef<NodeJS.Timeout>();
    useEffect(() => {
      return () => {
        timerRef.current && clearTimeout(timerRef.current);
      };
    }, []);

    const download = async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setDownloading(true)
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        const suc = () => {
          console.log("success");
          setDownloading(false)
        };
        let dom = document.getElementById("infringement-report");
        let pdf = new htmlToPdf(
          dom,
          "infringement-report",
          "splitPages",
          "breakPages",
          suc,
          2
        );
        pdf.outPutPdfFn();
      }, 0.2 * 1000);

      //文字分割，但是没有空白页2
      // const domElement = document.getElementById('infringement-report');

      // if (domElement) {
      //   html2canvas(domElement, {
      //     useCORS: true,
      //     scrollY: 0 // Ensure the entire element is rendered without scrolling
      //   }).then((canvas) => {
      //     const imgData = canvas.toDataURL('image/png');
      //     const pdf = new jsPDF('p', 'mm', 'a4');
      //     const pdfWidth = pdf.internal.pageSize.getWidth();
      //     const pdfHeight = pdf.internal.pageSize.getHeight();
      //     const imgWidth = pdfWidth;
      //     const imgHeight = (canvas.height * pdfWidth) / canvas.width;
      //     const pageHeight = pdfHeight;
      //     const totalHeight = canvas.height;

      //     let yOffset = 0;

      //     while (yOffset < totalHeight) {
      //       const newCanvas = document.createElement('canvas');
      //       newCanvas.width = canvas.width;
      //       newCanvas.height = Math.min(
      //         canvas.height - yOffset,
      //         pageHeight * (canvas.width / pdfWidth)
      //       );

      //       const newCtx = newCanvas.getContext('2d');
      //       newCtx?.drawImage(
      //         canvas,
      //         0,
      //         yOffset,
      //         canvas.width,
      //         newCanvas.height,
      //         0,
      //         0,
      //         canvas.width,
      //         newCanvas.height
      //       );

      //       const pageData = newCanvas.toDataURL('image/png');

      //       if (yOffset > 0) {
      //         pdf.addPage();
      //       }

      //       const imgHeightInPDF = (newCanvas.height * pdfWidth) / newCanvas.width;
      //       pdf.addImage(pageData, 'PNG', 0, 0, imgWidth, imgHeightInPDF, '', 'FAST');

      //       yOffset += newCanvas.height;
      //     }

      //     pdf.save('infringement-report.pdf');
      //   }).catch((error) => {
      //     console.error('Error generating PDF:', error);
      //   });
      // }
    };

    const [loading, setLoading, loadingRef] = useStateRef(true)

    const queryReport = async (url: string) => {
      setLoading(true)
      const response = await monitorCompareData(url);
      console.log(
        "********** monitorCompareData -----response.data:-----",
        response.data
      );
      const responseData = response.data as ReportResponseData;
      const data = responseData.data.data;
      // setReportDataData(data);
    };

    const similarity_in_trademark_text_content_score =
      reportDataData?.similarity_in_trademark_text_content?.score;
    const similarity_in_trademark_text_pronunciation_score =
      reportDataData?.similarity_in_trademark_text_pronunciation.score;
    const similarity_in_trademark_text_meaning_score =
      reportDataData?.similarity_in_trademark_text_meaning.score;
    const similarity_in_trademark_text_font_score =
      reportDataData?.similarity_in_trademark_text_font.score;
    const similarity_in_trademark_color_score =
      reportDataData?.similarity_in_trademark_color.score;
    const similarity_in_trademark_design_layout_score =
      reportDataData?.similarity_in_trademark_design_layout.score;
    const overall_visual_expression_similarity_score =
      reportDataData?.overall_visual_expression_similarity.score;
    const final_score_score = reportDataData?.final_score.score;
    // const final_score_score = reportDataData
    // ? parseFloatValue((reportDataData?.final_score.score * 100),0,2) 
    // : "--";

    const renderLoading = () => {
      return (
        <div className="flex-grow flex items-center justify-center" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          <Loading></Loading>
        </div>
      );
    };

    const [downloading, setDownloading] = useState(false);

    // const [goodBase64, setGoodBase64] = useState('');

    // useEffect(() => {
    //   getImageBase64Data(goodUrl)
    //     .then(res => {
    //       setGoodBase64(res as string)
    //     })
    // }, [goodUrl])


    // const [yourTrademarkBase64, setYourTrademarkGoodBase64] = useState('');
    // useEffect(() => {
    //   getImageBase64Data(yourTrademark)
    //     .then(res => {
    //       setYourTrademarkGoodBase64(res as string)
    //     })
    // }, [yourTrademark])
    
    return (
      <section className="relative flex flex-col w-full h-full font-timeRoman text-black" onClick={hide} >
        {
          !loading && <>
            <div id="infringement-report" className="flex flex-col p-20 space-y-12 overflow-auto">
              <div className="flex flex-col">
                <h6 className="text-3.5xl leading-9 font-bold">
                  Infringement Report
                </h6>
              </div>
              <div className="flex flex-col">
                <ol className="space-y-4">
                  <li className="text-base font-bold">
                    1.The applicant holds the following trademark
                  </li>
                  <ol>
                    <li className="indent-2 text-base leading-9">
                      1.1 The trademark uploaded by the applicant is as follows:
                    </li>
                    <li className="my-[60px]">
                      <img
                        className="w-[233px] mx-auto object-contain"
                        src={yourTrademark}
                      />
                    </li>
                  </ol>
                  <li className="text-base font-bold">2.Examination basis</li>
                  <ol className="space-y-2">
                    <li className="indent-2 text-base leading-9">
                      2.1 The comparison and assessment will be based on: text,
                      spelling, pronunciation, meaning, image, pattern similarity
                      (visual similarity), shape, and content.
                    </li>
                    <li className="indent-2 text-base leading-9">
                      2.2 FairIP uses the trademark provided by the applicant as
                      the material suspected of being infringed for this
                      application assessment. Based on the above examination
                      materials, FairIP monitors all shops on Taobao and
                      automatically captures products with high similarity.
                      According to FairIP's assessment requirements, design
                      elements are used for infringement
                    </li>
                  </ol>
                </ol>
              </div>
              <div className="flex flex-col">
                <ol className="space-y-4">
                  <li className="text-base font-bold">3.Assessment process</li>
                  <ol className="space-y-2">
                    <li className="indent-2 text-base leading-9">
                      3.1 Examination materials:
                    </li>
                    <ol>
                      <li className="indent-4 text-base leading-9">
                        (1) Material 1: Trademark held by the applicant
                      </li>
                      <li className="mt-[20px] mb-[30px]">
                        <img
                          className="w-[233px] mx-auto object-contain"
                          src={yourTrademark}
                        />
                      </li>
                      {!hideInfringementDetails && (
                        <li className="indent-4 text-base leading-9">
                          (2) Material 2: Trademark on clothes from the{" "}
                          {reportTrademarksMonitor?.platform}{" "}
                          {reportTrademarksMonitor?.storeName} shop monitored by
                          FairIP
                        </li>
                      )}
                      {hideInfringementDetails && (
                        <li className="indent-4 text-base leading-9">
                          (2) Material 2: Comparison images
                        </li>
                      )}

                      <li className="mt-[30px]">
                        <img
                          className="w-[207px] mx-auto object-contain"
                          src={goodUrl}
                        />
                      </li>
                    </ol>
                  </ol>
                </ol>
              </div>
              <div className="flex flex-col">
                <ol className="space-y-4 indent-2">
                  <ol className="space-y-2">
                    <li className="text-base leading-9">
                      3.2 Examination materials:
                    </li>
                    <ol className="flex flex-col space-y-6">
                      <li className="text-base leading-9">
                        <div>
                          Text Content Similarity:{" "}
                          {similarity_in_trademark_text_content_score !== undefined ? similarity_in_trademark_text_content_score * 100 : '--'}%
                        </div>
                        <div className="text-[#9D9FA1]">
                          {
                            reportDataData?.similarity_in_trademark_text_content
                              .explanation
                          }
                        </div>
                      </li>
                      <li className="text-base leading-9">
                        <div>
                          Text Pronunciation Similarity:{" "}
                          {similarity_in_trademark_text_pronunciation_score !== undefined ? similarity_in_trademark_text_pronunciation_score * 100 : '--'}
                          %
                        </div>
                        <div className="text-[#9D9FA1]">
                          {
                            reportDataData
                              ?.similarity_in_trademark_text_pronunciation
                              .explanation
                          }
                        </div>
                      </li>
                      <li className="text-base leading-9">
                        <div>
                          Text Meaning Similarity:{" "}
                          {similarity_in_trademark_text_meaning_score !== undefined ? similarity_in_trademark_text_meaning_score * 100 : '--'}%
                        </div>
                        <div className="text-[#9D9FA1]">
                          {
                            reportDataData?.similarity_in_trademark_text_meaning
                              .explanation
                          }
                        </div>
                      </li>
                      <li className="text-base leading-9">
                        <div>
                          Text font Similarity:{" "}
                          {similarity_in_trademark_text_font_score !== undefined ? similarity_in_trademark_text_font_score * 100 : '--'}%
                        </div>
                        <div className="text-[#9D9FA1]">
                          {
                            reportDataData?.similarity_in_trademark_text_font
                              .explanation
                          }
                        </div>
                      </li>
                      <li className="text-base leading-9">
                        <div>
                          Color Similarity:{" "}
                          {similarity_in_trademark_color_score !== undefined ? similarity_in_trademark_color_score * 100 : '--'}%
                        </div>
                        <div className="text-[#9D9FA1]">
                          {
                            reportDataData?.similarity_in_trademark_color
                              .explanation
                          }
                        </div>
                      </li>
                      <li className="text-base leading-9">
                        <div>
                          Design Layout Similarity:{" "}
                          {similarity_in_trademark_design_layout_score !== undefined ? similarity_in_trademark_design_layout_score * 100 : '--'}%
                        </div>
                        <div className="text-[#9D9FA1]">
                          {
                            reportDataData?.similarity_in_trademark_design_layout
                              .explanation
                          }
                        </div>
                      </li>
                      <li className="text-base leading-9">
                        <div>
                          Overall Visual Expression Similarity:{" "}
                          {overall_visual_expression_similarity_score !== undefined ? overall_visual_expression_similarity_score * 100 : '--'}%
                        </div>
                        <div className="text-[#9D9FA1]">
                          {
                            reportDataData?.overall_visual_expression_similarity
                              .explanation
                          }
                        </div>
                      </li>
                    </ol>
                  </ol>
                </ol>
              </div>
              <div className="flex flex-col">
                <ol className=" space-y-4">
                  <ol className="space-y-2 indent-2">
                    <li className="text-base leading-9">
                      3.3 Assessment Conclusion
                    </li>
                    <li className="text-base leading-9">

                      <div>final Similarity: {final_score_score !== undefined ? parseFloatValue((final_score_score * 100),0,2) : '--'}%</div>
                      <div className="text-[#9D9FA1]">
                        {reportDataData?.final_score.explanation}
                      </div>
                    </li>
                    {((final_score_score !== undefined && final_score_score >= 0.5) && (
                      <li className="text-base leading-9">
                        Although Material 2 and Material 1 differ in details, the
                        design elements and overall visual effect are
                        substantially the same or nearly identical, constituting a
                        replication relationship.
                      </li>
                    ))}
                    {(final_score_score !== undefined && final_score_score < 0.5) && (
                      <li className="text-base leading-9">
                        Material 2 and Material 1 differ overall, with design
                        elements and overall visual effect being substantially
                        similar by less than 50%, not constituting a replication
                        relationship.
                      </li>
                    )}
                  </ol>
                </ol>
              </div>
              {!hideInfringementDetails && (
                <>
                  <div className="flex flex-col">
                    <ol className=" space-y-4">
                      <li className="text-base font-bold">
                        4．Infringement Details
                      </li>
                      <ul className="flex flex-col">
                        <li className="text-base leading-9">
                          Taobao Shop: {reportTrademarksMonitor?.storeName}
                        </li>
                        <li className="text-base leading-9">
                          Product Unit Price: $
                          {reportTrademarksMonitor?.productPrice}
                        </li>
                        <li className="text-base leading-9">
                          Units Sold: {reportTrademarksMonitor?.sellingUnit}
                        </li>
                        <li className="text-base leading-9">
                          Total Sales: ${reportTrademarksMonitor?.totalAmount}
                        </li>
                      </ul>
                      <li className="flex flex-row text-base font-bold leading-9">
                        <span>Estimated trademark infringement amount: </span>
                        <span className="text-[#1BA4A4]">
                          ${reportTrademarksMonitor?.totalAmount ? formatNumberWithThousandCode(reportTrademarksMonitor?.totalAmount).omit : reportTrademarksMonitor?.totalAmount}
                        </span>
                      </li>
                    </ol>
                  </div>
                </>
              )}
            </div>
            <div className='absolute bottom-0 left-0 right-0 py-5 text-center font-sans'>
              <PDFDownloadLink
                fileName='infringement-report.pdf'
                document={(
                  <InfringementReportPdf
                    hideInfringementDetails={hideInfringementDetails}
                    yourTrademark={yourTrademark}
                    goodUrl={goodUrl}
                    reportTrademarksMonitor={reportTrademarksMonitor}
                    reportDataData={reportDataData}
                    similarity_in_trademark_text_content_score={similarity_in_trademark_text_content_score}
                    similarity_in_trademark_text_pronunciation_score={similarity_in_trademark_text_pronunciation_score}
                    similarity_in_trademark_text_meaning_score={similarity_in_trademark_text_meaning_score}
                    similarity_in_trademark_text_font_score={similarity_in_trademark_text_font_score}
                    similarity_in_trademark_color_score={similarity_in_trademark_color_score}
                    similarity_in_trademark_design_layout_score={similarity_in_trademark_design_layout_score}
                    overall_visual_expression_similarity_score={overall_visual_expression_similarity_score}
                    final_score_score={final_score_score}
                  />
                )}
              >
                {({ loading }) => (
                  <>
                    <button
                      disabled={loading}
                      className={'inline-flex items-center justify-center w-[200px] py-3 mx-auto bg-[#1BA4A4] rounded-4xl text-white disabled:cursor-wait'}>
                      <span className='text-2xl'>
                        <IconDownload />
                      </span>
                      <span className='text-base font-semibold'>Download</span>
                    </button>
                  </>
                )}
              </PDFDownloadLink>
            </div>
          </>
        }
        {loading && renderLoading()}
      </section>
    );
  }
);

export default InfringementReport;
