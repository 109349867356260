import React, { useEffect, useState } from "react";

import { ImgLogo, ImgGoolge } from "../images";
import { Banner, Input } from "../components";
import { Button, ButtonText } from "../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { SignupFormDataType2, initSignupFormData2 } from "../service/formdata";
import { useSessionStorageString } from "react-use-window-sessionstorage";
import {
  checkContainSpeicalChars,
  checkIsEmail,
  checkIsPassword,
} from "../utils/regularUtils";
import { APP_KEY, PWD_MAX_LEN, PWD_MIN_LEN } from "../service/constant";
import { EMAIL_VERIFICATION, LOGIN } from "../router/constants";
import { sendCode } from "../api/user/app";
import { ResponseData } from "../api/response";
import { encode, decode } from "../utils/saltBase64";
import { MD5 } from "../utils/saltMD5";
import * as stringUtils from "../utils/string";
import { Logo } from "../components";

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] =
    useState<SignupFormDataType2>(initSignupFormData2);
  //是否能发邮件获取验证码
  const [canSendEmailForRegister, setCanSendEmailForRegister] =
    useState<boolean>(false);
  //是否能点击注册按钮
  const [canClickNextBtn, setCanClickNextBtn] = useState<boolean>(false);
  //是否点击了按钮
  const [nextClicked, setNextClicked] = useState(false);

  const [
    formLocalData,
    setFormLocalData,
    loadingFormLocalData,
    available,
    reset,
  ] = useSessionStorageString(APP_KEY + "Sign.Info", "");


  // const [inviteCode, setInviteCode] = useState<string>(
  //   stringUtils.decodeQuery("code", location.search) || ""
  // );

  // useEffect(() => {
  //   console.log("inviteCode***", inviteCode);
  //   // setFormData({ ...formData, inviteCode: {
  //   //   value: inviteCode,
  //   //   type: inviteCode?'useful':'empty',
  //   // }})

  //   return () => {};
  // }, [inviteCode]);

  // const [id, setId] = useState<string>(
  //   stringUtils.decodeQuery("id", location.search) || ""
  // );

  // useEffect(() => {
  //   console.log("id***", id);

  //   return () => {};
  // }, [id]);

  useEffect(() => {
    if (loadingFormLocalData) return;
    console.log(
      "singup**loadingFormLocalData**",
      formLocalData,
      loadingFormLocalData,
      available
    );

    //初始状态从缓存读取数据
    if (!formLocalData) {
      // setFormData(initFormData)
      // console.log("singup**formLocalData**initsate",formLocalData)
    } else {
      const decodeStr = decode(formLocalData);
      // console.log("singup**formLocalData**decodeStr**", decodeStr)
      //没有缓存信息或者缓存信息有误，直接返回
      if (!decodeStr) {
        return;
      }
      const decodeJsonData: SignupFormDataType2 = JSON.parse(decodeStr);
      console.log("singup**loadingFormLocalData**origindata**", decodeJsonData);
      //清空密码
      decodeJsonData.password = initSignupFormData2.password;
      decodeJsonData.confirmpassword = initSignupFormData2.confirmpassword;
      //清空codemix
      decodeJsonData.codeMix = "init";
      //清空 email
      decodeJsonData.email.type = "legal";
      console.log("singup**loadingFormLocalData**fixdata**", decodeJsonData);
      setFormData(decodeJsonData);
    }
  }, [loadingFormLocalData]);

  let didCancel = false;
  useEffect(() => {
    if (formData.codeMix == "mixed") return;

    //转换为session存储
    // console.log("singup**formData**", formData,loadingFormLocalData)
    // return;
    const rebuildData: SignupFormDataType2 = JSON.parse(JSON.stringify(formData));
    //md5密码
    if (rebuildData.codeMix == "needmix") {
      rebuildData.password.value = MD5(rebuildData.password.value);
      rebuildData.confirmpassword.value = rebuildData.password.value;
    }
    //不记录邮箱和用户名的校验状态
    //不能不记录密码，否则无法传递给后台
    // rebuildData.password = initFormData.password
    // rebuildData.confirmpassword = initFormData.confirmpassword
    if (
      rebuildData.email.type == "useful" ||
      rebuildData.email.type == "registered"
    )
      rebuildData.email.type = "legal";
    if (
      rebuildData.username.type == "useful" ||
      rebuildData.username.type == "registered"
    )
      rebuildData.username.type = "legal";
    console.log("singup**formData**", formData);
    const jsonStr = JSON.stringify(rebuildData);
    const encodeStr = encode(jsonStr);
    if (formLocalData != encodeStr && !loadingFormLocalData) {
      // console.log("singup**setFormLocalData**encodeStr**", encodeStr,loadingFormLocalData)
      setFormLocalData(encodeStr);
    }

    // if (rebuildData.codeMix == "init") {
    //   if (checkCanClickNextBtn() && nextClicked) {
    //     console.log("onsendemail**");
    //     onSendEmail();
    //     // setNextClicked(false)
    //   }
    //   if (!canSendEmailForRegister) checkCanSendEmailForRegister();
    //   else if (canSendEmailForRegister) checkCanClickNextBtn();
    //   // checkCanClickNextBtn();
    // }
    !nextClicked && checkCanClickNextBtn();
    checkCanSendEmailForRegister();
    return () => {
      didCancel = true;
    };
  }, [formData]);

  useEffect(() => {
    if (formData.codeMix == "needmix" && nextClicked) {
      setNextClicked(false);
      navigate(EMAIL_VERIFICATION);
    }
    return () => { };
  }, [formLocalData]);

  const onSendEmail = async () => {
    //发送email和验证合在一起了
    if (!formData || didCancel) return;
    //需要md5数据一下
    setFormData({ ...formData, codeMix: "needmix" });
    // if (!formData || didCancel || !cfToken) return;
    // const getEmailResult = await getEmailVerifyCode(formData.email.value, EmailVerifyCodeType.Register)
    //注册获取验证码方法变更
    // const getEmailResult = await getEmailVerifyCodeForRegister(
    //   formData.email.value,
    //   cfToken
    // );
    // console.log("signup***getEmailVerifyCode**result**", getEmailResult.data);
    // if (getEmailResult.data.success) {
    //   didCancel = true;
    //   //需要md5数据一下
    //   setFormData({ ...formData, codeMix: "needmix" });
    //   // navigate(REGISTER_VERTIFY_EMAIL)
    // } else {
    //   //重置两个参数为false
    //   setCanSendEmailForRegister(false);
    //   setCanClickNextBtn(false);
    //   //已经被注册
    //   setFormData({
    //     ...formData,
    //     email: {
    //       ...formData.email,
    //       type: "manual-error",
    //     },
    //     error: getEmailResult.data.msg,
    //   });
    // }
  };

  useEffect(() => {
    // console.log("canSendEmailForRegister**", canSendEmailForRegister)
    canSendEmailForRegister && onSendEmail();
    return () => { };
  }, [canSendEmailForRegister]);


  const checkCanClickNextBtn = () => {
    const val = checkAvailableListForRegister(formData);
    console.log("checkCanClickNextBtn**", val, formData);
    setCanClickNextBtn(val);
  };

  const checkAvailableListForRegister = (list: any): boolean => {
    let available = true;
    // console.log("checkAvailableListForRegister**list**", list);
    for (const item in list) {
      if (typeof list[item] === "object") {
        // console.log("checkAvailableListForRegister**listitem**", typeof (list[item]), item, list[item], item == 'isIllegal')
        available = available && checkAvailableListForRegister(list[item]);
        // console.log("available0**", item, list[item], available)
      } else if (item == "type") {
        available =
          available && (list[item] == "legal" || list[item] == "useful");
        // console.log("checkAvailableListForRegister**listitem**available1**", item, !!!list[item])
        if (!available) return available; //不满足的情况直接可以返回false
      }
    }
    // console.log("checkAvailableListForRegister**listitem**allavailable**",available)
    return available;
  };

  const checkCanSendEmailForRegister = () => {
    if (!nextClicked) return false;
    //暂时只检查用户名是否注册，邮箱注册的接口暂不可用
    const val =
      formData.username.type == "useful" && formData.email.type == "useful";
    console.log("checkCanSendEmailForRegister**", val, formData);
    setCanSendEmailForRegister(val);
    return val;
  };


  const [requesting, setRequesting] = useState(false);

  const onNext = async () => {
    setNextClicked(true);
    console.log(
      "onNext**",
      canSendEmailForRegister,
      canClickNextBtn,
      formData,
      requesting
    );
    setRequesting(true);
    if (
      formData.email.type == "legal" ||
      formData.email.type == "useful"
    ) {
      ////mock未注册
      // data.data = false;
      //发送验证码请求并检查email
      const result = await sendCode({ email: formData.email.value, vt: 'REGISTER' });
      const emailResponseData: ResponseData<boolean> = result.data;
      console.log("isemail**", emailResponseData);
      ////mock未注册
      // data.data = false;

      //请求成功
      if (!emailResponseData.success) {
        setCanSendEmailForRegister(false);
        //已经被注册
        setFormData({
          ...formData,
          email: {
            ...formData.email,
            type: "registered",
          },
          username: {
            ...formData.username,
            type: "useful",
          },
        });
      } else {
        //未被注册
        setFormData({
          ...formData,
          email: {
            ...formData.email,
            type: "useful",
          },
          username: {
            ...formData.username,
            type: "useful",
          },
        });
      }
      setRequesting(false);
    }
  };
  // const btnAvailable = !((!canClickNextBtn && !canSendEmailForRegister) || requesting) && !!cfToken;
  const btnAvailable = canClickNextBtn && !requesting;
  console.log("btnAvailable**", btnAvailable);
  console.log("canClickNextBtn**", canClickNextBtn);
  console.log("!requesting**", !requesting);

  const [inviteCode, setInviteCode] = useState<string>(
    stringUtils.decodeQuery("code", location.search) || ""
  );
  // console.log("inviteCode***", inviteCode);
  useEffect(() => {
    console.log("uf***inviteCode***", inviteCode);
    !loadingFormLocalData &&
      inviteCode &&
      setFormData((value) => {
        return { ...value, inviteCode };
      });

    return () => { };
  }, [inviteCode, loadingFormLocalData]);

  return (
    <section className="flex flex-row">
      <Banner />
      <div className='flex flex-1 h-screen overflow-auto'>
        <div className='relative flex flex-1 flex-col justify-center min-h-[926px]'>
          <div className='absolute top-[64px] left-[64px]'>
            <Logo type={'normal'} />
          </div>
          <form
            className="flex flex-col mx-auto w-[420px]"
            onSubmit={(e) => {
              // console.log('submit')
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
          >
            <h5 className="text-3.5xl font-semibold text-black mt-2">
              Get started
            </h5>
            <span className="text-sm text-[#4B4B4B]">
              Create an Trademark LLM account for free trial.
            </span>
            <div className="flex flex-col space-y-4 my-12">
              <Input
                title="Name"
                value={formData.username.value}
                onClearValue={() => {
                  console.log('clear')
                  setFormData({
                    ...formData,
                    username: {
                      value: "",
                      type: "empty",
                    },
                  });
                }}
                onValueChanged={(e) => {
                  const hasSpeicalChar = checkContainSpeicalChars(e);
                  setFormData({
                    ...formData,
                    username: {
                      value: e || "",
                      type: hasSpeicalChar ? "illegal" : !e ? "empty" : "legal",
                    },
                  });
                }}
              />
              <Input
                title="Firm/Company Name"
                value={formData.company.value}
                onClearValue={() => {
                  setFormData({
                    ...formData,
                    company: {
                      value: "",
                      type: "empty",
                    },
                  });
                }}
                onValueChanged={(e) => {
                  const hasSpeicalChar = checkContainSpeicalChars(e);
                  setFormData({
                    ...formData,
                    company: {
                      value: e || "",
                      type: hasSpeicalChar ? "illegal" : !e ? "empty" : "legal",
                    },
                  });
                }}
              />
              <Input
                title="Email"
                value={formData.email.value}
                onClearValue={() => {
                  setFormData({
                    ...formData,
                    email: {
                      value: "",
                      type: "empty",
                    },
                  });
                }}
                onValueChanged={(e) => {
                  setFormData({
                    ...formData,
                    email: {
                      value: e || "",
                      type: !e ? "empty" : !checkIsEmail(e) ? "illegal" : "legal",
                    },
                  });
                }}
              />
              <Input
                title="Password"
                type="password"
                value={formData.password.value}
                onClearValue={() => {
                  setFormData({
                    ...formData,
                    password: {
                      value: "",
                      type: "empty",
                    },
                  });
                }}
                onValueChanged={(e) => {
                  // const hasSpeicalChar = checkContainSpeicalChars(e.target.value);
                  const value = e || "";
                  const passwordType = !value
                    ? "empty"
                    : value.length < PWD_MIN_LEN
                      ? "tooshort"
                      : value.length > PWD_MAX_LEN
                        ? "toolong"
                        : !checkIsPassword(value, PWD_MIN_LEN, PWD_MAX_LEN)
                          ? "illegal"
                          : "legal";
                  console.log("passwordType**", passwordType);
                  setFormData({
                    ...formData,
                    password: {
                      value,
                      type: passwordType,
                    },
                    confirmpassword: {
                      value: value,
                      type: "legal",
                    },
                  });
                }}
              />
            </div>
            <div className="flex flex-col">
              <Button
                type="primary"
                disabled={!btnAvailable}
                onClick={() => {
                  if (!btnAvailable) return;
                  onNext();
                }}
              >
                Create Account
              </Button>
              <div className="flex flex-row justify-center mt-4">
                <span className="text-sm">Already have an account?&nbsp;</span>
                <ButtonText
                  onClick={() => {
                    navigate(LOGIN);
                  }}
                >
                  Login
                </ButtonText>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Signup;
