import React from "react";
import { Document, Page, PDFDownloadLink, View, Text, Image, Font } from '@react-pdf/renderer'
import { createTw } from "react-pdf-tailwind";
import moment from "moment";

import {
  ReportDataData,
  TrademarksMonitoringDataItem,
} from "../../api/ai/response";
// @ts-ignore
import timeRomanBold from "../../fonts/TimesNewRoman/timesnewromanbold.ttf"
// @ts-ignore
import timeRoman from "../../fonts/TimesNewRoman/timesnewroman.ttf"

Font.register({
  family: 'TT',
  fonts: [
    { src: timeRoman, fontWeight: 'normal' },
    { src: timeRomanBold, fontWeight: 'bold' },
  ]
});

Font.register({
  family: 'Noto Serif SC',
  src: 'https://cdn.jsdelivr.net/fontsource/fonts/noto-serif-sc@latest/chinese-simplified-400-normal.ttf'
});

const tw = createTw({
  theme: {
    fontFamily: {
      'timesRoman': ['TT'],
      'noto': ['Noto Serif SC'],
    },
    extend: {
      fontSize: {
        'xs': ['12px', '20px'],
        'sm': ['14px'],
        'xl': ['20px', '32px'],
        '2.5xl': ['28px', '42px'],
      },
    },
  },
});

type Props = {
  hideInfringementDetails: boolean,
  yourTrademark?: string,
  goodUrl?: string,
  reportTrademarksMonitor?: TrademarksMonitoringDataItem,
  reportDataData?: ReportDataData,
  similarity_in_trademark_text_content_score: number | undefined,
  similarity_in_trademark_text_pronunciation_score: number | undefined,
  similarity_in_trademark_text_meaning_score: number | undefined,
  similarity_in_trademark_text_font_score: number | undefined,
  similarity_in_trademark_color_score: number | undefined,
  similarity_in_trademark_design_layout_score: number | undefined,
  overall_visual_expression_similarity_score: number | undefined,
  final_score_score: number | undefined,
}

const InfringementReportPdf = ({
  hideInfringementDetails,
  yourTrademark,
  goodUrl,
  reportTrademarksMonitor,
  reportDataData,
  similarity_in_trademark_text_content_score,
  similarity_in_trademark_text_pronunciation_score,
  similarity_in_trademark_text_meaning_score,
  similarity_in_trademark_text_font_score,
  similarity_in_trademark_color_score,
  similarity_in_trademark_design_layout_score,
  overall_visual_expression_similarity_score,
  final_score_score
}: Props) => {

  // console.log('InfringementReportPdfyourTrademark:', yourTrademark)
  // console.log('InfringementReportPdfgoodUrl:', goodUrl)

  return <Document>
    <Page size='A4' style={tw('py-[88px]')} wrap={true}>
      <View style={tw("flex flex-col w-[595px] gap-4 font-timesRoman")}>
        <View style={tw("relative flex flex-col w-[420px] mx-auto")}>
          <Text style={tw("absolute right-0 top-0 text-sm")}>
            FairIP [2024] No. 5
          </Text>
          <Text style={tw("mt-[259px] mb-[284px] text-2.5xl font-bold text-center")}>
            Infringement Report
          </Text>
          <Text style={tw("text-sm leading-7")}>
            FairIP received a trademark infringement report application on{" "}
            {moment().format("MMM DD, YYYY")}. Following the principles of
            independence, objectivity, and fairness, an infringement
            assessment was conducted.
          </Text>
        </View>
        <View style={tw("relative flex flex-col w-[420px] mx-auto")}>
          <View style={tw("flex flex-col gap-5")}>
            <Text style={tw("text-sm font-bold")}>
              1.The applicant holds the following trademark
            </Text>
            <View style={tw('flex flex-col')}>
              <Text style={tw("indent-2 text-sm leading-7")}>
                1.1 The trademark uploaded by the applicant is as follows:
              </Text>
              <View style={tw("flex flex-col mt-[65px] mb-[61px]")}>
                {
                  yourTrademark && <>
                    <Image
                      style={tw("h-[177px] mx-auto object-contain")}
                      src={yourTrademark}
                    />
                  </>
                }
              </View>
            </View>
            <Text style={tw("text-sm font-bold")}>2.Examination basis</Text>
            <View style={tw("gap-2")}>
              <Text style={[tw("indent-2 text-sm leading-7")]}>
                2.1 The comparison and assessment will be based on: text,
                spelling, pronunciation, meaning, image, pattern similarity
                (visual similarity), shape, and content.
              </Text>
              <Text style={[tw("indent-2 text-sm leading-7")]}>
                2.2 FairIP uses the trademark provided by the applicant as
                the material suspected of being infringed for this
                application assessment. Based on the above examination
                materials, FairIP monitors all shops on Taobao and
                automatically captures products with high similarity.
                According to FairIP's assessment requirements, design
                elements are used for infringement
              </Text>
            </View>
          </View>
        </View>
        <View style={tw("relative flex flex-col w-[420px] mx-auto")} break>
          <View style={tw("mx-auto w-[420px] gap-5")}>
            <Text style={tw("text-sm font-bold")}>3.Assessment process</Text>
            <View style={tw("gap-5")}>
              <Text style={tw("indent-2 text-sm leading-7")}>
                3.1 Examination materials:
              </Text>
              <View>
                <Text style={tw("indent-4 text-sm leading-7")}>
                  (1) Material 1: Trademark held by the applicant
                </Text>
                <View style={tw("mt-[20px] mb-[36px]")}>
                  {
                    yourTrademark && <>
                      <Image
                        style={tw("h-[177px] mx-auto object-contain")}
                        src={yourTrademark}
                      />
                    </>
                  }
                </View>
                {!hideInfringementDetails && (
                  <Text style={tw("indent-4 text-sm leading-7")}>
                    (2) Material 2: Trademark on clothes from the{" "}
                    {reportTrademarksMonitor?.platform}{" "}
                    <Text style={tw('font-noto')}>{reportTrademarksMonitor?.storeName}</Text>
                    shop monitored by
                    FairIP
                  </Text>
                )}
                {hideInfringementDetails && (
                  <Text style={tw("indent-4 text-sm leading-7")}>
                    (2) Material 2: Comparison images
                  </Text>
                )}
                <View style={tw("mt-[30px]")}>
                  {
                    goodUrl && <>
                      <Image
                        style={tw("h-[209px] mx-auto object-contain")}
                        src={goodUrl}
                      />
                    </>
                  }
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={tw("relative flex flex-col w-[420px] mx-auto")} break>
          <View style={tw("mx-auto w-[420px] gap-6")}>
            <View style={tw("gap-6")}>
              <Text style={tw("text-sm leading-7")}>
                3.2 Examination materials:
              </Text>
              <View style={tw("flex flex-col gap-6")}>
                <View style={tw("text-sm leading-7 gap-6")}>
                  <Text>
                    Text Content Similarity:{" "}
                    {similarity_in_trademark_text_content_score !== undefined ? similarity_in_trademark_text_content_score * 100 : '--'}%
                  </Text>
                  <Text style={tw("text-[#9D9FA1]")}>
                    {
                      reportDataData?.similarity_in_trademark_text_content
                        .explanation
                    }
                  </Text>
                </View>
                <View style={tw("text-sm leading-7 gap-6")}>
                  <Text>
                    Text Pronunciation Similarity:{" "}
                    {similarity_in_trademark_text_pronunciation_score !== undefined ? similarity_in_trademark_text_pronunciation_score * 100 : '--'}
                    %
                  </Text>
                  <Text style={tw("text-[#9D9FA1]")}>
                    {
                      reportDataData
                        ?.similarity_in_trademark_text_pronunciation
                        .explanation
                    }
                  </Text>
                </View>
                <View style={tw("text-sm leading-7 gap-6")}>
                  <Text>
                    Text Meaning Similarity:{" "}
                    {similarity_in_trademark_text_meaning_score !== undefined ? similarity_in_trademark_text_meaning_score * 100 : '--'}%
                  </Text>
                  <Text style={tw("text-[#9D9FA1]")}>
                    {
                      reportDataData?.similarity_in_trademark_text_meaning
                        .explanation
                    }
                  </Text>
                </View>
                <View style={tw("text-sm leading-7 gap-6")}>
                  <Text>
                    Text font Similarity:{" "}
                    {similarity_in_trademark_text_font_score !== undefined ? similarity_in_trademark_text_font_score * 100 : '--'}%
                  </Text>
                  <Text style={tw("text-[#9D9FA1]")}>
                    {
                      reportDataData?.similarity_in_trademark_text_font
                        .explanation
                    }
                  </Text>
                </View>
                <View style={tw("text-sm leading-7 gap-6")}>
                  <Text>
                    Color Similarity:{" "}
                    {similarity_in_trademark_color_score !== undefined ? similarity_in_trademark_color_score * 100 : '--'}%
                  </Text>
                  <Text style={tw("text-[#9D9FA1]")}>
                    {
                      reportDataData?.similarity_in_trademark_color
                        .explanation
                    }
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={tw("relative flex flex-col w-[420px] mx-auto")} break>
          <View style={tw('flex flex-col gap-6')}>
            <View style={tw("text-sm leading-7 gap-6")}>
              <Text>
                Design Layout Similarity:{" "}
                {similarity_in_trademark_design_layout_score !== undefined ? similarity_in_trademark_design_layout_score * 100 : '--'}%
              </Text>
              <Text style={tw("text-[#9D9FA1]")}>
                {
                  reportDataData?.similarity_in_trademark_design_layout
                    .explanation
                }
              </Text>
            </View>
            <View style={tw("text-sm leading-7 gap-6")}>
              <Text>
                Overall Visual Expression Similarity:{" "}
                {overall_visual_expression_similarity_score !== undefined ? overall_visual_expression_similarity_score * 100 : '--'}%
              </Text>
              <Text style={tw("text-[#9D9FA1]")}>
                {
                  reportDataData?.overall_visual_expression_similarity
                    .explanation
                }
              </Text>
            </View>
          </View>
          <View style={tw("flex flex-col mt-[80px] gap-6")}>
            <Text style={tw("text-sm leading-7")}>
              3.3 Assessment Conclusion
            </Text>
            <View style={tw("flex flex-col text-sm leading-7 gap-6")}>
              <Text>final Similarity: {final_score_score !== undefined ? final_score_score * 100 : '--'}%</Text>
              <Text style={tw("text-[#9D9FA1]")}>
                {reportDataData?.final_score.explanation}
              </Text>
            </View>
            {((final_score_score !== undefined && final_score_score >= 0.5) && (
              <Text style={tw("text-sm leading-7")}>
                Although Material 2 and Material 1 differ in details, the
                design elements and overall visual effect are
                substantially the same or nearly identical, constituting a
                replication relationship.
              </Text>
            ))}
            {(final_score_score !== undefined && final_score_score < 0.5) && (
              <Text style={tw("text-sm leading-7")}>
                Material 2 and Material 1 differ overall, with design
                elements and overall visual effect being substantially
                similar by less than 50%, not constituting a replication
                relationship.
              </Text>
            )}
          </View>
        </View>
        {
          !hideInfringementDetails && (
            <>
              <View style={tw("relative flex flex-col w-[420px] mx-auto gap-4")} break>
                <Text style={tw("text-sm font-bold")}>
                  4.Infringement Details
                </Text>
                <View style={tw("flex flex-col")}>
                  <Text style={tw("text-sm leading-7")}>
                    Taobao Shop: <Text style={tw('font-noto')}>{reportTrademarksMonitor?.storeName}</Text>
                  </Text>
                  <Text style={tw("text-sm leading-7")}>
                    Product Unit Price: $
                    {reportTrademarksMonitor?.productPrice}
                  </Text>
                  <Text style={tw("text-sm leading-7")}>
                    Units Sold: {reportTrademarksMonitor?.sellingUnit}
                  </Text>
                  <Text style={tw("text-sm leading-7")}>
                    Total Sales: ${reportTrademarksMonitor?.totalAmount}
                  </Text>
                </View>
                <Text style={tw("flex flex-row text-sm font-bold leading-7")}>
                  <Text>Estimated trademark infringement amount: </Text>
                  <Text style={tw("text-[#1BA4A4]")}>
                    ${reportTrademarksMonitor?.totalAmount}
                  </Text>
                </Text>
              </View>
            </>
          )
        }
      </View>
    </Page >
  </Document >
}

export default InfringementReportPdf;
