import React from 'react'

import { CloseDialog } from '../../svgr/components'

type Props = {
  selected: boolean,
  cover: string,
  onClick?: () => void,
  onDeletedClick?: () => void,
}

const TrademarkCover: React.FC<Props> = ({ selected, cover, onClick, onDeletedClick }: Props) => {

  return <div
    aria-selected={selected}
    className='group relative inline-flex round'
    onClick={onClick}>
    <button className='inline-flex'>
      <img className='h-20 object-contain' src={cover} />
    </button>
    <button
      className='group-hover:inline-flex absolute top-[-12px] right-[-12px] hidden items-center justify-center size-6 bg-[#F2F2F2] text-[10px] rounded-full'
      onClick={onDeletedClick}
    >
      <CloseDialog color='#000'/>
    </button>
  </div>
}

export default TrademarkCover