import * as React from "react";
import type { SVGProps } from "react";
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#9D9FA2"
      d="M14 5.96a1 1 0 0 0-.04-.18v-.06a.7.7 0 0 0-.127-.187l-4-4a.7.7 0 0 0-.186-.126.2.2 0 0 0-.06 0 .6.6 0 0 0-.22-.074h-2.7a2 2 0 0 0-2 2V4H4a2 2 0 0 0-2 2v6.667a2 2 0 0 0 2 2h5.333a2 2 0 0 0 2-2V12H12a2 2 0 0 0 2-2V5.96m-4-2.353 1.727 1.726h-1.06A.667.667 0 0 1 10 4.667zm0 9.06a.667.667 0 0 1-.667.666H4a.667.667 0 0 1-.667-.666V6A.667.667 0 0 1 4 5.333h.667V10a2 2 0 0 0 2 2H10zM12.667 10a.667.667 0 0 1-.667.667H6.667A.666.666 0 0 1 6 10V3.333a.667.667 0 0 1 .667-.666h2v2a2 2 0 0 0 2 2h2z"
    />
  </svg>
);
export default SvgCopy;
