import {
  ActionType,
  createReducer,
  createStandardAction,
} from "typesafe-actions";
import { createStructuredSelector } from "reselect";

export type UserInfoLocalData = {
  createBy?: string | undefined;
  createTime?: string | undefined;
  updateBy?: string | null | undefined;
  updateTime?: string | null | undefined;
  remark?: string | null | undefined;
  uid?: string | undefined;
  deptId?: string | null | undefined;
  username?: string | undefined;
  nickname?: string | undefined;
  email?: string | undefined;
  phonenumber?: string | undefined;
  sex?: string | undefined;
  header_image?: string | undefined;
  password?: string | undefined;
  status?: string | undefined;
  delFlag?: string | undefined;
  loginIp?: string | undefined;
  loginDate?: string | null | undefined;
  dept?: string | null | undefined;
  roles?: string[] | undefined;
  roleIds?: string | null | undefined;
  postIds?: string | null | undefined;
  roleId?: string | null | undefined;
  address?: string | null | undefined;
  admin?: boolean | undefined;
  companyName?:string | undefined;
  musicToken?: string | undefined;
};

const initialState = {
  createBy : undefined,
  createTime : undefined,
  updateBy :  undefined,
  updateTime :  undefined,
  remark :  undefined,
  uid: undefined,
  deptId :  undefined,
  username : undefined,
  nickname : undefined,
  email : undefined,
  phonenumber : undefined,
  sex : undefined,
  header_image : undefined,
  password : undefined,
  status : undefined,
  delFlag : undefined,
  loginIp : undefined,
  loginDate :  undefined,
  dept :  undefined,
  roles: undefined,
  roleIds :  undefined,
  postIds :  undefined,
  roleId :  undefined,
  address :  undefined,
  admin: undefined,
  companyName: undefined,
  
  musicToken : undefined
};

export type Selectors = {
  getData: UserInfoLocalData;
};

const actions = {
  update: createStandardAction(
    "UPDATE_USERINFO_CACHE_DATA"
  )<UserInfoLocalData>(),
  reset: createStandardAction("RESET_USERINFO_CACHE_DATA")(),
};

export type UserInfoLocalDataActions = ActionType<typeof actions>;

const reducer = () =>
  createReducer<UserInfoLocalData, UserInfoLocalDataActions>(
    initialState
  )
    .handleAction(actions.update, (state, { payload }) => ({
      ...state,
      ...payload
    }))
    .handleAction(actions.reset, () => ({...initialState}
      
    ));


const select = createStructuredSelector<UserInfoLocalData, {}, Selectors>({
  getData: (state) => state,
});

export { actions, reducer, select };
