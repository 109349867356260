import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { decode, encode } from "../../utils/saltBase64";
import { MD5 } from "../../utils/saltMD5";
import { DASHBOARD } from "../../router/constants";

import * as stringUtils from "../../utils/string";
import {
  checkContainSpeicalChars,
  checkIsEmail,
  checkIsPassword,
} from "../../utils/regularUtils";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/app-state";
import { userInfoLocalData } from "../../redux/local";
import { persistSelectors, sharedActions, sharedSelectors } from "../../redux";
import { authLocalData } from "../../redux/presist";
import { FormAnyUnitType, FormUnitType2 } from "../../service/formdata";
import useLogin from "../../hooks/login-hook";
import useUserInfo from "../../hooks/userinfo-hook";
import { uplaodAvatarImage, uplaodFeedbackImage } from "../../api/file/app";
import { FeedbackType, feedback } from "../../api/feedback/app";
import { ServiceResponse } from "../../service/data";
import { ResetPwdWithOldPwdRequestData, getUserInfo, resetPwdWithOldPwd, updateUserInfo } from "../../api/user/app";
import { UpdateUserInfoRequestData } from "../../api/user/app";
import { UserInfoData } from "../../api/user/response";
import { buildUserInfoCacheData } from "../../service/user";
import { UserInfoLocalData } from "../../redux/local/userinfo-local-data";
import { PWD_MIN_LEN } from "../../service/constant";
import { PWD_MAX_LEN } from "../../service/constant";

export type PageData = {
  oldPassword: FormUnitType2;
  password: FormUnitType2;
  confirmpassword: FormUnitType2;
};

const initData: PageData = {
  oldPassword: {
    value: "",
    type: "empty",
  },
  password: {
    value: '',
    type: "empty",
  },
  confirmpassword: {
    value: "",
    type: "empty",
  },
};

const useResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState<PageData>({...initData});
  const {onLogout}=useLogin()
  
  const setOldPassword = (value: string) => {
    const type = !!value ? "useful" : "empty";
    console.log("setUser**", type);
    setFormData({
      ...formData,
      oldPassword: {
        value,
        type: type,
      },
    });
  };

  const setPassword = (value: string) => {
    const passwordType = !value
      ? "empty"
      : value.length < PWD_MIN_LEN
      ? "tooshort"
      : value.length > PWD_MAX_LEN
      ? "toolong"
      : !checkIsPassword(value)
      ? "illegal"
      : "legal";
    // console.log("passwordType**", passwordType);
    setFormData({
      ...formData,
      password: {
        value,
        type: passwordType,
      },
      confirmpassword: {
        value: formData.confirmpassword.value,
        type: !formData.confirmpassword.value ? 'empty' : (value !== formData.confirmpassword.value ? 'illegal' : 'legal')
      }

    });
  };

  const setConfirmPassword = (value: string) => {
    setFormData({
      ...formData, confirmpassword: {
        value: value || '',
        type: !value ? 'empty' : (value !== formData.password.value ? 'illegal' : 'legal')
      }
    })
  };


  const checkAvailableListForRegister = (list: any): boolean => {
    let available = true;
    // console.log("checkAvailableListForRegister**list**", list);
    for (const item in list) {
      if (typeof list[item] === "object") {
        // console.log("checkAvailableListForRegister**listitem**", typeof (list[item]), item, list[item], item == 'isIllegal')
        available = available && checkAvailableListForRegister(list[item]);
        // console.log("available0**", item, list[item], available)
      } else if (item == "type") {
        available =
          available && (list[item] == "legal" || list[item] == "useful");
        // console.log("checkAvailableListForRegister**listitem**available1**", item, !!!list[item])
        if (!available) return available; //不满足的情况直接可以返回false
      }
    }
    // console.log("checkAvailableListForRegister**listitem**allavailable**",available)
    return available;
  };

  const [canNext, setCanNext] = useState(false)
  useEffect(() => {
    setCanNext(checkAvailableListForRegister(formData))
  
    return () => {
      
    }
  }, [formData])
  

  const onSubmit= async():Promise<ServiceResponse>  => {
    if (!canNext) {
      return {success:false,error:'para error'};
    }
    setCanNext(false)
    
    //提交反馈
    const data:ResetPwdWithOldPwdRequestData = {
      originPassword: MD5(formData.oldPassword.value),
      newPassword: MD5(formData.password.value),
    }
    const result = (await resetPwdWithOldPwd(data))?.data?.success
    console.log('updateUserInfo**',result)
    if(result){
      onLogout()
    }
    return {success:result,error:'updateUserInfo '+result.toString()};
  };


  

  const initFormData =()=>{
setFormData({...initData})
  }

  return { formData,setOldPassword, setPassword,setConfirmPassword,initFormData, onSubmit,canNext };
};

export default useResetPassword;
