import React, { useState } from "react";
import Select from "react-select";
import { clsx } from "clsx";

import { Search as IconSearch } from "../../svgr/components";
import {
  ClauseSearchItemData,
  ClauseSearchResponseData,
  ContractSearchItemData,
  ContractSearchResponseData,
} from "../../api/contract/response";
import { AGREEMENT_DOWNLOAD, SEARCH } from "../../router/constants";
import { useNavigate } from "react-router-dom";
import { convertToPlainText } from "../../utils/domUtils";
import useStateRef from "react-usestateref";

type Props = {
  onSearch: (type: string, value: string) => void;
  onChange?: (
    type: string,
    value: string
  ) => Promise<{
    contracts: ContractSearchResponseData | undefined;
    clauses: ClauseSearchResponseData | undefined;
  }>;
};

const options = [
  { value: "all", label: "ALL", placeholder: "Search contracts or clauses" },
  { value: "contracts", label: "Contracts", placeholder: "Search Contracts " },
  { value: "clauses", label: "Clauses", placeholder: "Search Clauses" },
];

const controlStyles = {
  base: "rounded-lg bg-white border-none hover:cursor-pointer",
  focus: "border-none",
  nonFocus: "border-none",
};
const valueContainerStyles = "gap-1 text-[#282828] text-xl leading-[34px]";
const singleValueStyles = "mr-2";
const menuStyles =
  "min-w-[184px] p-4 mt-2 border border-[#F2F2F2] bg-white rounded-2xl shadow-4xl";
const optionStyles = {
  base: "hover:cursor-pointer px-4 py-3 text-sm text-[#282828] rounded-2xl",
  focus: "bg-[#F2F2F2] active:bg-[#F2F2F2]",
  selected: "text-[#282828]",
};

const itemStyles =
  "inline-flex items-center h-12 px-4 text-left rounded-2xl cursor-pointer hover:bg-[#F2F2F2]";

const ContractSearch: React.FC<Props> = ({ onSearch, onChange }: Props) => {
  const [selectOption, setSelectOption] = useState<{
    value: string;
    label: string;
    placeholder: string;
  }>(options[0]);
  const [value, setValue, valueRef] = useStateRef<string>("");

  const navigate = useNavigate();
  const [contractsList, setContractsList] =
    useState<ContractSearchResponseData>();
  const [clausesList, setClasusesList] = useState<ClauseSearchResponseData>();

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      onSearch && onSearch(selectOption?.value, value);
    }
  };

  const dosearch = async (v: string,sv:string) => {
    if (onChange) {
      const { contracts, clauses } = await onChange(
        sv,
        v
      );
      console.log(
        "onchange**",
        v,
        valueRef.current,
        contracts,
        clauses
      );
      if (v === valueRef.current) {
        setContractsList(contracts);
        setClasusesList(clauses);
      }
    }

  };

  return (
    <section className="group relative flex flex-col mx-auto">
      {/* <section className="relative flex flex-col mx-auto">  */}
      <search className="flex flex-row items-center w-[1003px] h-[88px] pl-8 pr-3 mx-auto bg-white rounded-full">
        <div>
          <Select
            isMulti={false}
            isSearchable={false}
            defaultValue={options[0]}
            value={selectOption}
            options={options}
            onChange={(e) => {
              e && setSelectOption(e);
              if(!e) return;
              if (e?.value === "all") {
                if(clausesList?.list && clausesList?.list?.length > 0 && contractsList?.list && contractsList?.list?.length > 0){

                }
                else{
                  setClasusesList(undefined);
                  setContractsList(undefined);
                  dosearch(value, e?.value);
                } 
              } else if (e?.value === "contracts") {
                setClasusesList(undefined);
                if(contractsList?.list && contractsList?.list?.length > 0){

                }
                else  dosearch(value, e?.value);
              } else if (e?.value === "clauses") {
                setContractsList(undefined);
                if(clausesList?.list && clausesList?.list?.length > 0 ){

                }
                else  dosearch(value, e?.value);
              }
             
            }}
            unstyled
            components={{
              IndicatorSeparator: null,
            }}
            classNames={{
              control: ({ isFocused }) =>
                clsx(
                  isFocused ? controlStyles.focus : controlStyles.nonFocus,
                  controlStyles.base
                ),
              valueContainer: () => valueContainerStyles,
              singleValue: () => singleValueStyles,
              menu: () => menuStyles,
              option: ({ isFocused, isSelected }) =>
                clsx(
                  isFocused && optionStyles.focus,
                  isSelected && optionStyles.selected,
                  optionStyles.base
                ),
            }}
          />
        </div>
        <div className="w-[1px] h-8 mx-4 bg-[#D9D9D9]" />
        <input
          className="flex-1 bg-transparent text-xl font-normal leading-[34px] outline-none placeholder:text-[#9D9FA1]"
          placeholder={selectOption.placeholder}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={async (e) => {
            const v = e.target.value;
            setValue(v);
            setContractsList(undefined);
            setClasusesList(undefined);

            if (onChange) {
              const { contracts, clauses } = await onChange(
                selectOption?.value,
                v
              );
              console.log(
                "onchange**",
                v,
                valueRef.current,
                contracts,
                clauses
              );
              if (v === valueRef.current) {
                setContractsList(contracts);
                setClasusesList(clauses);
              }
            }
          }}
        />
        <button
          className="inline-flex items-center justify-center w-[192px] h-16 bg-[#0A65FF] text-white text-[32px] rounded-full"
          onClick={() => {
            onSearch(selectOption?.value, value);
          }}
        >
          <IconSearch />
        </button>
      </search>
      {/* 模糊搜索 */}
      {((contractsList && contractsList?.list?.length > 0) ||
        (clausesList && clausesList?.list?.length > 0)) && (
        <div className="absolute group-focus-within:block hidden top-[92px] left-0 right-0 max-h-[400px] p-4 bg-white border border-[#F2F2F2] rounded-2xl shadow-4xl overflow-auto">
          {contractsList && contractsList?.list?.length > 0 && (
            <div className="flex flex-col">
              <h6 className=" mb-1 text-base text-[#383838]">Contracts</h6>
              <ul className="flex flex-col text-base text-[#282828] space-y-1">
                {contractsList?.list?.map((item) => {
                  // console.log('rrrrr***',item?.title)
                  const title = item?.title;
                  const lowTitle = title.toLowerCase();
                  const lowValue = value.toLowerCase();
                  const index = lowTitle.indexOf(lowValue);
                  // console.log('rrrrr***',title,index,"**",title[index])

                  if (index < 0) {
                    return (
                      <button
                        className={clsx(itemStyles)}
                        key={item.id}
                        onClick={() => {
                          const url = `${AGREEMENT_DOWNLOAD}/${item.id}`;
                          navigate(url);
                        }}
                      >
                        <span className="line-clamp-1">{title}</span>
                      </button>
                    );
                  } else if (index == 0) {
                    const targetValue = title.substring(
                      index,
                      index + value.length
                    );
                    const left = title.replace(targetValue, "");

                    return (
                      <button
                        className={clsx(itemStyles)}
                        key={item.id}
                        onClick={() => {
                          const url = `${AGREEMENT_DOWNLOAD}/${item.id}`;
                          navigate(url);
                        }}
                      >
                        <span className="line-clamp-1">
                          <b>{targetValue}</b> {left}
                        </span>
                      </button>
                    );
                  } else if (index > 100) {
                    const targetValue = title.substring(
                      index,
                      index + value.length
                    );
                    const results = item?.title.split(targetValue);
                    // console.log('rrrrr***',targetValue,results,title,index,value.length,title[index])
                    if (results.length > 1) {
                      return (
                        <button
                          className={clsx(itemStyles)}
                          key={item.id}
                          onClick={() => {
                            const url = `${AGREEMENT_DOWNLOAD}/${item.id}`;
                            navigate(url);
                          }}
                        >
                          <span className="line-clamp-1">
                            {/* ...{results[0].substring(results[0].length - 10)}{" "} */}
                            {results[0].substring(results[0].length - 10)}{" "}
                            <b>{targetValue}</b> {results[1]}
                          </span>
                        </button>
                      );
                    } else if (results.length == 1) {
                      return (
                        <button
                          className={clsx(itemStyles)}
                          key={item.id}
                          onClick={() => {
                            const url = `${AGREEMENT_DOWNLOAD}/${item.id}`;
                            navigate(url);
                          }}
                        >
                          <span className="line-clamp-1">
                            {/* ...{results[0].substring(results[0].length - 10)}{" "} */}
                            {results[0].substring(results[0].length - 10)}{" "}
                            <b>{targetValue}</b>
                          </span>
                        </button>
                      );
                    }
                  } else if (index > 0) {
                    const targetValue = title.substring(
                      index,
                      index + value.length
                    );
                    const results = item?.title.split(targetValue);
                    // console.log('rrrrr***',targetValue,results,title,index,value.length,title[index])
                    if (results.length > 1) {
                      return (
                        <button
                          className={clsx(itemStyles)}
                          key={item.id}
                          onClick={() => {
                            const url = `${AGREEMENT_DOWNLOAD}/${item.id}`;
                            navigate(url);
                          }}
                        >
                          <span className="line-clamp-1">
                            {results[0]} <b>{targetValue}</b> {results[1]}
                          </span>
                        </button>
                      );
                    } else if (results.length == 1) {
                      return (
                        <button
                          className={clsx(itemStyles)}
                          key={item.id}
                          onClick={() => {
                            const url = `${AGREEMENT_DOWNLOAD}/${item.id}`;
                            navigate(url);
                          }}
                        >
                          <span className="line-clamp-1">
                            {results[0]} <b>{targetValue}</b>
                          </span>
                        </button>
                      );
                    }
                  }

                  return (
                    <button
                      className={clsx(itemStyles)}
                      key={item.id}
                      onClick={() => {
                        const url = `${AGREEMENT_DOWNLOAD}/${item.id}`;
                        navigate(url);
                      }}
                    >
                      <span className="line-clamp-1">
                        <b>{value}</b> {1}
                      </span>
                    </button>
                  );
                })}
              </ul>
            </div>
          )}

          {clausesList && clausesList?.list?.length > 0 && (
            <div className="flex flex-col">
              <h6 className=" mb-1 text-base text-[#383838]">Clauses</h6>
              <ul className="flex flex-col text-base text-[#282828] space-y-1">
                {clausesList?.list?.map((item) => {
                  // const index = item?.title.indexOf(value);
                  const title = convertToPlainText(item?.content);
                  const lowTitle = title.toLowerCase();
                  const lowValue = value.toLowerCase();
                  const index = lowTitle.indexOf(lowValue);
                  // console.log('rrrrr***',title,index,"**",title[index])

                  if (index < 0) {
                    return (
                      <button
                        className={clsx(itemStyles)}
                        key={item.id}
                        onClick={() => {
                          navigate(`${SEARCH}`, {
                            state: {
                              q: value,
                              t: "clauses",
                              firstData: item,
                              firstList: clausesList,
                            },
                          });
                        }}
                      >
                        <span className="line-clamp-1">{title}</span>
                      </button>
                    );
                  } else if (index == 0) {
                    // const left = title.replace(value, "");
                    const targetValue = title.substring(
                      index,
                      index + value.length
                    );
                    const left = title.replace(targetValue, "");

                    return (
                      <button
                        className={clsx(itemStyles)}
                        key={item.id}
                        onClick={() => {
                          navigate(`${SEARCH}`, {
                            state: {
                              q: value,
                              t: "clauses",
                              firstData: item,
                              firstList: clausesList,
                            },
                          });
                        }}
                      >
                        <span className="line-clamp-1">
                          <b>{targetValue}</b> {left}
                        </span>
                      </button>
                    );
                  } else if (index > 100) {
                    // const results = item?.title.split(value);
                    const targetValue = title.substring(
                      index,
                      index + value.length
                    );
                    const results = title.split(targetValue);
                    // console.log('rrrrr***',targetValue,results)

                    if (results.length > 1) {
                      // console.log('rrrrr***',results[0],"**",targetValue,"**",results[1])
                      return (
                        <button
                          className={clsx(itemStyles)}
                          key={item.id}
                          onClick={() => {
                            navigate(`${SEARCH}`, {
                              state: {
                                q: value,
                                t: "clauses",
                                firstData: item,
                                firstList: clausesList,
                              },
                            });
                          }}
                        >
                          <span className="line-clamp-1">
                            {/* ...{results[0].substring(results[0].length - 10)}{" "} */}
                            {results[0].substring(results[0].length - 10)}{" "}
                            <b>{targetValue}</b> {results[1]}
                          </span>
                        </button>
                      );
                    } else if (results.length == 1) {
                      return (
                        <button
                          className={clsx(itemStyles)}
                          key={item.id}
                          onClick={() => {
                            navigate(`${SEARCH}`, {
                              state: {
                                q: value,
                                t: "clauses",
                                firstData: item,
                                firstList: clausesList,
                              },
                            });
                          }}
                        >
                          <span className="line-clamp-1">
                            {/* ...{results[0].substring(results[0].length - 10)}{" "} */}
                            {results[0].substring(results[0].length - 10)}{" "}
                            <b>{targetValue}</b>
                          </span>
                        </button>
                      );
                    }
                  } else if (index > 0) {
                    // const results = item?.title.split(value);
                    const targetValue = title.substring(
                      index,
                      index + value.length
                    );
                    const results = title.split(targetValue);
                    // console.log('rrrrr***',targetValue,results)

                    if (results.length > 1) {
                      // console.log('rrrrr***',results[0],"**",targetValue,"**",results[1])
                      return (
                        <button
                          className={clsx(itemStyles)}
                          key={item.id}
                          onClick={() => {
                            navigate(`${SEARCH}`, {
                              state: {
                                q: value,
                                t: "clauses",
                                firstData: item,
                                firstList: clausesList,
                              },
                            });
                          }}
                        >
                          <span className="line-clamp-1">
                            {results[0]} <b>{targetValue}</b> {results[1]}
                          </span>
                        </button>
                      );
                    } else if (results.length == 1) {
                      return (
                        <button
                          className={clsx(itemStyles)}
                          key={item.id}
                          onClick={() => {
                            navigate(`${SEARCH}`, {
                              state: {
                                q: value,
                                t: "clauses",
                                firstData: item,
                                firstList: clausesList,
                              },
                            });
                          }}
                        >
                          <span className="line-clamp-1">
                            {results[0]} <b>{targetValue}</b>
                          </span>
                        </button>
                      );
                    }
                  }

                  return (
                    <button
                      className={clsx(itemStyles)}
                      key={item.id}
                      onClick={() => {
                        navigate(`${SEARCH}`, {
                          state: {
                            q: value,
                            t: "clauses",
                            firstData: item,
                            firstList: clausesList,
                          },
                        });
                      }}
                    >
                      <span className="line-clamp-1">
                        <b>{value}</b> {1}
                      </span>
                    </button>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default ContractSearch;
