export const removeBackgroundColor = (html: string): string => {
    // 使用 DOMParser 解析 HTML 字符串
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // 选择所有的元素并移除 background-color 样式
    const elements = doc.body.getElementsByTagName('*');
    Array.from(elements).forEach((element) => {
        const htmlElement = element  as HTMLElement; // 转换为 HTMLElement
        htmlElement.style.backgroundColor = ''; // 清除 background-color
    });

    // 返回处理后的 HTML 字符串
    return doc.body.innerHTML;
  };

  export const convertToPlainText = (htmlString: string) => {
    // 创建一个临时的容器元素
    const tempDiv = document.createElement('div');
    // 将 HTML 字符串设置为容器的 innerHTML
    tempDiv.innerHTML = htmlString;
    // 提取文本内容
    return tempDiv.innerText || tempDiv.textContent || '';
  };
