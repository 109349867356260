import React, { useEffect, useState } from 'react'

import { ImgLogo } from '../images'
import { ButtonRound } from '../components/Button'
import { useNavigate } from 'react-router-dom'
import useLogin from '../hooks/login-hook'
import { UserMenu } from './User'
import useUserInfo from '../hooks/userinfo-hook'
import DefaultAvator from './DefaultAvator'
import Logo from './Logo'
import { TrademarkMenu, ContractsMenu } from './Menu'
import clsx from 'clsx'

import Search from './Header/Search'

type Props = {
  type: 'primary' | 'default',
  buttonHover?: 'primary' | 'default',
}

const Header: React.FC<Props> = ({ type, buttonHover = 'primary' }: Props) => {

  console.log('buttonHover:', buttonHover)

  const navigate = useNavigate()
  const { onLogout } = useLogin()

  const [displayMenu, setDisplayMenu] = useState(false)
  const [displayTrademarkMenu, setDisplayTrademarkMenu] = useState(false)
  const [displayContractsMenu, setDisplayContractsMenu] = useState(false)

  const { avatar, userName } = useUserInfo()

  return <>
    <header
      aria-expanded={buttonHover === 'default'}
      className='group flex flex-row items-center justify-between py-5 px-8 bg-transparent rounded-3xl'>
      <div className='inline-flex flex-row justify-start w-[400px]'>
        <Logo type={type} />
      </div>
      <div className='flex flex-row space-x-12'>
      <div className='relative'>
          <button
            className={`inline-flex px-4 py-2 rounded-full hover:bg-[#F2F2F2]
            group-aria-expanded:hover:bg-white`}
            onClick={(e) => {
              e.stopPropagation()
              setDisplayMenu(false)
              setDisplayTrademarkMenu(false)
              setDisplayContractsMenu(true)
            }}
          >
            <span className='text-[#282828] text-base'>Contracts</span>
          </button>
          {
            displayContractsMenu && <>
              <ContractsMenu onClose={() => { setDisplayContractsMenu(false) }} />
            </>
          }
        </div>
        <div className='relative'>
          <button
            className={`inline-flex px-4 py-2 rounded-full hover:bg-[#F2F2F2]
            group-aria-expanded:hover:bg-white`}
            onClick={(e) => {
              e.stopPropagation()
              setDisplayMenu(false)
              setDisplayContractsMenu(false)
              setDisplayTrademarkMenu(true)
            }}
          >
            <span className='text-[#282828] text-base'>Trademark</span>
          </button>
          {
            displayTrademarkMenu && <>
              <TrademarkMenu onClose={() => { setDisplayTrademarkMenu(false) }} />
            </>
          }
        </div>
       
      </div>
      <div className='flex flex-row justify-end w-[400px] space-x-8'>
        <Search />
        <div className='flex flex-row space-x-4'>
          <ButtonRound onClick={(e) => {
            // onLogout();
            e.stopPropagation()
            setDisplayContractsMenu(false)
            setDisplayTrademarkMenu(false)
            setDisplayMenu(true)
          }}>
            <DefaultAvator
              name={userName}
              avatar={avatar}
              size={'small'} />
          </ButtonRound>
        </div>
      </div>
    </header>
    {displayMenu && <UserMenu
      onLogout={onLogout}
      onClose={() => { setDisplayMenu(false) }} />}
  </>
}

export default Header